import React from "react";

function ReleaseIcon({ fill, ...restProps }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <g id="Sale-05">
        <path
          id="Icon"
          d="M4.02614 14.5315C4.02614 15.2789 4.02614 15.6526 4.17159 15.9381C4.29954 16.1892 4.5037 16.3933 4.7548 16.5213C5.04028 16.6667 5.41398 16.6667 6.16139 16.6667H13.9017C14.6491 16.6667 15.0228 16.6667 15.3083 16.5213C15.5594 16.3933 15.7635 16.1892 15.8915 15.9381C16.0369 15.6526 16.0369 15.2789 16.0369 14.5315V12.1177M16.0369 9.70387C16.0369 9.32062 16.0369 9.12899 15.9875 8.95252C15.9438 8.7962 15.8719 8.64918 15.7754 8.51867C15.6664 8.37134 15.5151 8.25368 15.2126 8.0184L10.687 4.49848C10.4526 4.31615 10.3354 4.22498 10.2059 4.18994C10.0917 4.15902 9.97135 4.15902 9.85715 4.18994C9.72772 4.22498 9.61051 4.31615 9.37608 4.49848L4.85047 8.0184C4.54795 8.25369 4.39669 8.37134 4.28772 8.51867C4.1912 8.64918 4.11929 8.7962 4.07553 8.95252C4.02614 9.12899 4.02614 9.32062 4.02614 9.70387V12.1177M13.6302 14.4272L16.0369 12.1166L18.3475 14.5233M6.38528 9.75746L4.02614 12.1166L1.66699 9.75746"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

export default ReleaseIcon;
