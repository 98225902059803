import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    containerDuplicatePropertyAlertDialog :{
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        gap:'10px',
        justifyContent:'center'
    },containerCardDuplicated:{
        width:'310px',
        minWidth:'310px',
        maxWidth:'310px',
        border :`1px solid ${theme.palette.border.gray_200}`,
        borderRadius:'12px',
        padding:'16px',
        display:'flex',
        flexDirection:'column',
        gap:'16px'
    },imagesContainer:{
        display:'flex',
        flexDirection:'row',
        gap:'8px',
    },imageWrapper:{
        width:'135px',
        border:`1px solid ${theme.palette.border.gray_200}`,
        borderRadius:'12px',
        height:'76px'
    },wapperPropretyInfo:{
        display:'flex',
        flexDirection:'column',
    },propretyTitle:{
        height:'40px',
        maxHeight:'40px',
        minHeight:'40px',
        display:'flex',
        alignItems:'center',
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.palette.text.primary
    },propretyAddress:{
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.palette.text.primary
    }
}
))