import React from "react";
import moment from "moment";
import clsx from "clsx";
import { Box, Avatar, Typography } from "@material-ui/core";

import { CustomCopyToClipboard } from "..";
import { CustomCard } from "../CustomCard";
import { getDownloadableLink } from "../../../Helper";

// Styles
import useStyles from "./styles";

function DuplicatedContactCard({ contact }) {
  const styles = useStyles();

  function formatLanguageAndNationality(language, nationality) {
    if (language && nationality) {
      return `${language}, ${nationality}`;
    }
    return language || nationality || "";
  }

  const formattedDate = contact?.createdAt
    ? moment(contact?.createdAt).locale("en").format("D MMM YYYY")
    : null;

  return (
    <CustomCard
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.cardWrapper}
    >
      <Box className={styles.contentContainer}>
        <Box className={styles.contactNameWrapper}>
          <Avatar
            className={clsx({
              [styles.avatarStyle]: contact?.relatedContactImageFileId,
              [styles.defaultAvatarStyle]: !contact?.relatedContactImageFileId,
            })}
            src={
              contact?.relatedContactImageFileId
                ? getDownloadableLink(contact?.relatedContactImageFileId) ??
                  null
                : "/images/contacts/MaleAvatar.svg"
            }
          ></Avatar>

          <Box className={styles.flexCol}>
            <Typography
              className={styles.contactName}
              variant="h6"
              align="center"
            >
              {contact?.fullName}
            </Typography>

            <CustomCopyToClipboard
              fontSize={"14px"}
              data={contact?.contactId}
              childrenData={`#${contact?.contactId}`}
            />
          </Box>
        </Box>
      </Box>
      <Box className={styles.infoContainer}>
        <Box className={styles.infoItem}>
          <Typography
            className={styles.infoContent}
            variant="body1"
            align="center"
          >
            {contact?.phoneNumber}
          </Typography>
          <Typography
            className={styles.infoContent}
            variant="body1"
            align="center"
          >
            -
          </Typography>
          <Typography
            className={clsx(styles.infoContent, styles.truncateString)}
            variant="body1"
            align="center"
          >
            {contact?.email}
          </Typography>
        </Box>
        {(!!contact?.contactLanguage || !!contact?.contactNationality) && (
          <Box className={styles.infoItem}>
            <Typography
              className={clsx(styles.infoContent, styles.fw400)}
              variant="body1"
              align="center"
            >
              {formatLanguageAndNationality(
                contact?.contactLanguage,
                contact?.contactNationality
              )}
            </Typography>
          </Box>
        )}
      </Box>
      <span className={styles.createdAt}>Creation on: {formattedDate}</span>
    </CustomCard>
  );
}

export default DuplicatedContactCard;
