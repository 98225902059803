import React, { useEffect, useState } from 'react';
import { Tables } from '../../../../Components';
import { UpdateContactOpportunityAMLStatus } from '../../../../Services';
import {
  showError,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
} from '../../../../Helper';
import { useTranslation } from 'react-i18next';
import ContactOpportunityDetailsSlider from './components/ContactOpportunityDetailsSlider';
import './components/Style.scss';

function ContactsOpportunityTable({
  data,
  filter,
  headerData,
  setFilter,
  loading,
  reload,
  parentTranslationPath,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [activeItem, setActiveItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [AMLDetails, setAMLDetails] = useState({});

  const focusedRowChanged = (_index, item) => {
    if (!item) return;
    if (_index !== -1) {
      setActiveItem(item);
      handleLeftSideSlider(item);
    }
  };

  const contactOpportunityAMLStatus = async (contactOpportunityId) => {
    setIsLoading(true);
    try {
      const res = await UpdateContactOpportunityAMLStatus(contactOpportunityId);
      setAMLDetails(res);
    } catch (error) {
      showError('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLeftSideSlider = (item) => {
    contactOpportunityAMLStatus(item?.contactOpportunityId);
  };

  useEffect(() => {
    if (Object.keys(AMLDetails).length > 0) {
      sideMenuComponentUpdate(
        <ContactOpportunityDetailsSlider
          item={AMLDetails}
          activeItem={activeItem}
          reload={reload}
          t={t}
          isLoading={isLoading}
        />
      );
      sideMenuIsOpenUpdate(true);
    }
  }, [AMLDetails, isLoading]);

  useEffect(() => {
    return () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    };
  }, []);
  return (
    <div>
      <Tables
        focusedRowChanged={focusedRowChanged}
        headerData={headerData}
        data={data.result}
        totalItems={data.totalCount}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex - 1}
        onPageIndexChanged={(index) =>
          setFilter({ ...filter, pageIndex: index + 1 })
        }
        onPageSizeChanged={(size) => setFilter({ ...filter, pageSize: size })}
        isLoading={loading}
        defaultActions={[]}
        parentTranslationPath={parentTranslationPath}
        translationPath={''}
      />
    </div>
  );
}

export default ContactsOpportunityTable;
