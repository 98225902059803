import React, { memo } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, TextField, Chip, Avatar, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FBFieldWrapper } from "../FBFieldWrapper";
import { useSelectedTheme } from "../../../../Hooks";
import { FBLabelPosition } from "../Constant";

// Styles
import useStyles from "./styles";

// Icons
import { CheckIcon, ChevronDown, CloseXIcon } from "../../../../assets/icons";

function FBAutocomplete(props) {
  const {
    options,
    value,
    onChange,
    label,
    placeholder,
    helperText,
    error,
    disabled,
    variant,
    disableClearable,
    renderOption,
    groupBy,
    filterOptions,
    labelPosition,
    required,
    getOptionLabel,
    helperInfo,
    errorMessage,
    multiple,
    renderTags,
    limitTags,
    startIcon,
    showAvatar,
    ...restProps
  } = props;

  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();

  const renderAvatar = (option, inOption) => {
    if (!showAvatar) {
      return null;
    }

    const isReactElement = React.isValidElement(showAvatar);

    return (
      <Avatar
        className={clsx(styles.avatar, {
          [styles.avatarInOption]: inOption,
        })}
        src={option.avatarPath ?? null}
      >
        {isReactElement ? (
          React.cloneElement(showAvatar)
        ) : (
          <span>{(option?.label || option)?.match(/\b(\w)/gm).join("")}</span>
        )}
      </Avatar>
    );
  };

  return (
    <FBFieldWrapper
      label={label}
      required={required}
      helperInfo={helperInfo}
      labelPosition={labelPosition}
    >
      <Autocomplete
        id="autocomplete-input"
        aria-labelledby={`${label}-id`}
        options={options}
        value={value}
        onChange={onChange}
        multiple={multiple}
        getOptionLabel={getOptionLabel} // Input shows || Must return a string
        renderOption={
          // Dropdown shows detailed info || Can return any JSX to define how the option is rendered.
          renderOption ||
          ((option, { selected }) => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              {showAvatar && renderAvatar(option, true)}
              <Box className={styles.optionWrapper}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" className={styles.optionText}>
                    {getOptionLabel(option)}
                  </Typography>
                </Box>
                {selected && (
                  <CheckIcon
                    width="20"
                    height="20"
                    fill={palette.foreground.brandPrimary}
                  />
                )}
              </Box>
            </Box>
          ))
        }
        groupBy={groupBy}
        filterOptions={filterOptions}
        disableClearable={false} // Allows clearing via keyboard or programmatically
        // Prevent clearing the input field when pressing the Escape key,
        // as we already have a custom clear button (`CloseXIcon`) for this purpose.
        clearOnEscape={false}
        disabled={disabled}
        popupIcon={
          <ChevronDown
            width="24"
            height="24"
            fill={palette.foreground.quarterary}
          />
        }
        classes={{
          root: styles.root,
          paper: styles.popover, // Apply the custom popover styling
          option: styles.option, // Apply the custom option styling
          listbox: styles.listbox, // Styles for the <ul> element
          popupIndicator: styles.popupIndicator, // Override popup icon styles
          clearIndicator: styles.clearIndicator, // Hides the default clear button
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            helperText={!!error && errorMessage}
            error={error}
            variant={variant}
            className={styles.input}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {(multiple ? value.length > 0 : !!value) && (
                    <span
                      onClick={() => onChange(null, multiple ? [] : null)} // Clear based on the type of selection
                      className={styles.closeIconWrapper}
                    >
                      <CloseXIcon
                        width="16"
                        height="16"
                        fill={palette.foreground.quinary}
                        style={{ marginInlineEnd: "8px" }}
                      />
                    </span>
                  )}
                  {/* Retain the default adornments */}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              startAdornment: (
                <React.Fragment>
                  {startIcon && (
                    <Box className={styles.prefixIconWrapper}>
                      {startIcon} {/* Dynamically render the icon */}
                    </Box>
                  )}
                  {params.InputProps.startAdornment}
                  {/* Retain the default adornments */}
                </React.Fragment>
              ),
            }}
          />
        )}
        limitTags={limitTags}
        renderTags={
          renderTags ||
          ((value, getTagProps) => {
            return value.map((option, index) => (
              <Chip
                variant="outlined"
                key={index}
                label={getOptionLabel(option)}
                onDelete={() => {
                  const newValue = value.filter((v, i) => i !== index);
                  onChange(null, newValue);
                }}
                deleteIcon={
                  <CloseXIcon
                    className={styles.deleteTagIcon}
                    fill={palette.foreground.quinary}
                  />
                }
                avatar={renderAvatar(option)}
                {...getTagProps({ index })}
                className={styles.chip}
              />
            ));
          })
        }
        {...restProps}
      />
    </FBFieldWrapper>
  );
}

FBAutocomplete.defaultProps = {
  options: [],
  value: null,
  label: "",
  labelPosition: FBLabelPosition.SIDE,
  placeholder: "",
  helperText: "",
  error: false,
  disabled: false,
  variant: "outlined",
  disableClearable: false,
  renderOption: undefined,
  groupBy: undefined,
  filterOptions: undefined,
  multiple: false,
  getOptionLabel: (option) =>
    typeof option === "string" || typeof option === "number"
      ? option
      : option.label || "",
  renderTags: undefined,
  limitTags: 2,
  startIcon: null,
  showAvatar: false,
};

FBAutocomplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
      }),
    ])
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["outlined", "standard", "filled"]),
  disableClearable: PropTypes.bool,
  renderOption: PropTypes.func,
  groupBy: PropTypes.func,
  filterOptions: PropTypes.func,
  getOptionLabel: PropTypes.func,
  multiple: PropTypes.bool,
  renderTags: PropTypes.func,
  limitTags: PropTypes.number,
  startIcon: PropTypes.node,
  showAvatar: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
};

export default memo(FBAutocomplete);
