import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Tables } from "../../../../../../../../../Components";

export const TenantsTable = ({
  tenants,
  filter,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const repeated = (list, filed) =>
    list &&
    list.map((item, index) =>
      !item[filed] ? (
        <span key={index}>
          <span> </span>
        </span>
      ) : (
        <span key={index}>
          {" "}
          {item[filed]} {list.length - 1 != index && <span> , </span>}
        </span>
      )
    );

  const repeatedAgentBranch = (list, filed) => {
    if (list && Array.isArray(list) && list.length > 0) {
      if (list[0] && list[0].agentBranch)
        return <span key={"branch"}>{list[0] && list[0].agentBranch}</span>;
      else {
        list.map((element, index) => {
          if (element && element?.agentBranch) {
            return <span key={"branch"}>{element?.agentBranch}</span>;
          }
        });
      }
    } else return <span key={"branch"}> </span>;
  };

  return (
    <div className="tenant-table-wrapper presentational-wrapper mb-3">
      <div className="title-section mb-2">
        <span>{t(`${translationPath}contact-list-tenant`)}</span>
      </div>
      <Tables
        data={tenants}
        headerData={[
          {
            id: 1,
            isSortable: true,
            label: "contact-id",
            input: "contactId",
          },
          {
            id: 2,
            isSortable: true,
            label: "lead-id",
            input: "leadId",
          },
          {
            id: 3,
            isSortable: true,
            label: "contact-name",
            input: "contactName",
          },
          {
            id: 4,
            isSortable: false,
            label: "screeningId",
            input: "screeningId",
          },
          {
            id: 5,
            isSortable: false,
            label: "lead-type",
            input: "leadType",
            component: () => <span>{t(`${translationPath}tenant`)}</span>,
          },
          {
            id: 6,
            isSortable: false,
            label: "birth-date",
            input: "birthDate",
            component: (item) => (
              <>
                {(item &&
                  item.transactionTenantDetails &&
                  item.transactionTenantDetails.birthDate &&
                  moment(item.transactionTenantDetails.birthDate).format(
                    "DD MMM YYYY"
                  )) || <span> {""} </span>}
              </>
            ),
          },
          {
            id: 7,
            isSortable: false,
            label: "nationality",
            input: "nationality",
            component: (item) => (
              <span>
                {" "}
                {item &&
                  item.transactionTenantDetails &&
                  item.transactionTenantDetails.nationality}
              </span>
            ),
          },
          {
            id: 8,
            isSortable: false,
            label: "referred-by-name",
            input: "referredByName",
            component: (item) => (
              <span>
                {" "}
                {item &&
                  item.transactionTenantDetails &&
                  item.transactionTenantDetails.referredByName}
              </span>
            ),
          },
          {
            id: 9,
            isSortable: false,
            label: "referred-to-name",
            input: "referredToName",
            component: (item) => (
              <span>
                {" "}
                {item &&
                  item.transactionTenantDetails &&
                  item.transactionTenantDetails.referredToName}
              </span>
            ),
          },
          {
            id: 10,
            isSortable: false,
            label: "team-name",
            input: "teamName",
            component: (item) =>
              (item?.transactionTenantDetails?.transactionReferralDetails &&
                repeated(
                  item?.transactionTenantDetails?.transactionReferralDetails,
                  "teamName"
                )) || <span>{""}</span>,
          },
          {
            id: 11,
            isSortable: false,
            label: "agent-team-lead",
            input: "teamLeadName",
            component: (item) =>
              (item?.transactionTenantDetails?.transactionReferralDetails &&
                repeated(
                  item?.transactionTenantDetails.transactionReferralDetails,
                  "teamLeadName"
                )) || <span>{""}</span>,
          },
          {
            id: 12,
            isSortable: false,
            label: "agent-team-manager",
            input: "teamManager",
            component: (item) =>
              (item?.transactionTenantDetails?.transactionReferralDetails &&
                repeated(
                  item?.transactionTenantDetails?.transactionReferralDetails,
                  "teamManager"
                )) || <span>{""}</span>,
          },
          {
            id: 12,
            isSortable: false,
            label: "agent-branch",
            input: "agentBranch",
            component: (item) =>
              (item?.transactionTenantDetails?.transactionReferralDetails &&
                repeatedAgentBranch(
                  item.transactionTenantDetails.transactionReferralDetails,
                  "agentBranch"
                )) || <span>{""}</span>,
          },
          {
            id: 13,
            isSortable: false,
            label: "campaign-name",
            input: "campaignName",
            component: (item) => (
              <span>
                {" "}
                {item?.campaignName ||
                  (item &&
                    item.transactionTenantDetails &&
                    item.transactionTenantDetails.campaignName) ||
                  ""}
              </span>
            ),
          },
          {
            id: 15,
            isSortable: false,
            label: "media-detail",
            input: "mediaDetail",
          },
        ]}
        defaultActions={[]}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        totalItems={tenants.length}
      />
    </div>
  );
};

TenantsTable.propTypes = {
  tenants: PropTypes.instanceOf(Array).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
