import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Spinner,
  Tables,
  NoContentComponent,
} from "../../../../../../../../Components";
import { GetSaleReservationClient } from "../../../../../../../../Services";
import { bottomBoxComponentUpdate } from "../../../../../../../../Helper";

export const AssignedAgentsDetails = ({
  parentTranslationPath,
  translationPath,
  unitId,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [saleReservations, setSaleReservations] = useState({
    assignedBuyerAgents: [],
    assignedSellerAgents: [],
    listingAgents:  [],
    agents: [],
  });
  const [filter] = useState({
    pageSize: 9999999,
    pageIndex: 0,
    search: "",
  });

  const repeated = (list, filed) =>
    list &&
    list.map((item, index) => (

      
        !item[filed]  ? (<span key={index}>
         <span> </span>
        </span>)
       :<span key={index}> 
        {" "}
        {item[filed]} {list.length - 1 != index && <span> , </span>}
      </span>
    ));


  const getSaleReservationClient = useCallback(async () => {
    setIsLoading(true);
    const saleTransactionDetailsId = localStorage.getItem(
      "saleTransactionDetailsId"
    );
    const res = await GetSaleReservationClient({
      id: saleTransactionDetailsId,
      isForAccountTab: true,
    });
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      let buyers = [];
      let sellers = [];
      let  listingAgents = res?.listingAgents || [] ;
      let agents = res?.agents  || []; 


      res?.buyers &&
        res.buyers.map((t) => {
          if (
            t.transactionBuyerDetails &&
            t.transactionBuyerDetails?.transactionReferralDetails
          ) {
            t.transactionBuyerDetails.transactionReferralDetails.map((s) => {
              buyers.push({ ...s });
            });
          }
        });

      res?.sellers &&
        res.sellers.map((t) => {
          if (
            t.transactionSellerDetails &&
            t.transactionSellerDetails?.transactionReferralDetails
          ) {
            t.transactionSellerDetails.transactionReferralDetails.map((s) => {
              sellers.push({ ...s });
            });
          }

        });
      setSaleReservations({
        assignedSellerAgents: sellers || [],
        assignedBuyerAgents: buyers || [],
        listingAgents  ,
        agents  
      });
    } else {
      setSaleReservations({
        assignedSellerAgents: [],
        assignedBuyerAgents: [],
        listingAgents :[] , 
        agents   :[]
      });
    }
    setIsLoading(false);
  }, [unitId]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    if (unitId) getSaleReservationClient();
  }, [getSaleReservationClient, unitId]);
  return (
    <div className="contact-details-wrapper childs-wrapper">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="w-100 px-3">
        {saleReservations.agents?.length === 0 &&
        saleReservations.listingAgents?.length === 0 ? (
          <NoContentComponent />
        ) : (
          !isLoading && (
            <>
              <div className="seller-table-wrapper presentational-wrapper mb-4 mt-4">
                <div className="title-section mb-2">
                  <span>{t(`${translationPath}sellers-agents`)}</span>
                </div>
                <Tables
                  data={saleReservations?.listingAgents || []}
                  headerData={[
                    {
                      id: 1,
                      isSortable: false,
                      label: t(`${translationPath}sale-listing-agent`),
                      input: "listingAgentName",
                    },
                    {
                      id: 2,
                      isSortable: false,
                      label: "agent-branch",
                      input: "agentBranch",
                    },
                    {
                      id: 3,
                      isSortable: false,
                      label: "team-name",
                      component: (item) =>
                        repeated(item.teamLeads
                          , "teamName"),
                    },
                    {
                      id: 4,
                      isSortable: false,
                      label: "team-lead-name",
                      component: (item) =>
                        repeated(item.teamLeads
                          , "teamLeadName"),
                    },
                  
                    {
                      id: 5,
                      isSortable: false,
                      label: "agent-team-manager",
                      component: (item) =>
                        repeated(item.teamLeads
                          , "teamManager"),
                    },
                    
                    
                  ]}
                  defaultActions={[]}
                  activePage={1}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  totalItems={saleReservations?.listingAgents?.length}
                />
              </div>
              <div className="seller-table-wrapper presentational-wrapper mb-4 mt-4">
                <div className="title-section mb-2">
                  <span>{t(`${translationPath}buyers-agents`)}</span>
                </div>
                <Tables
                  data={saleReservations?.agents || []}
                  headerData={[
                    {
                      id: 1,
                      isSortable: false,
                      label: t(`${translationPath}sale-agent`),
                      input: "agentName",
                    },
                    {
                      id: 2,
                      isSortable: false,
                      label: "agent-branch",
                      input: "agentBranch",
                    },
                    {
                      id: 3,
                      isSortable: false,
                      label: "team-name",
                      component: (item) =>
                        repeated(item.teamLeads
                          , "teamName"),
                    },
                    {
                      id: 4,
                      isSortable: false,
                      label: "team-lead-name",
                      component: (item) =>
                        repeated(item.teamLeads
                          , "teamLeadName"),
                    },
                    {
                      id: 5,
                      isSortable: false,
                      label: "agent-team-manager",
                      component: (item) =>
                        repeated(item.teamLeads
                          , "teamManager"),
                    },
                  ]}
                  defaultActions={[]}
                  activePage={1}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  totalItems={saleReservations?.agents?.length}
                />
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

AssignedAgentsDetails.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
AssignedAgentsDetails.defaultProps = {};
