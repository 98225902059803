import React, { useCallback, useEffect, useReducer, useState } from "react";
import { CustomDialog } from "../CustomDialog";
import { useTranslate } from "../../../Hooks";
import { Box, Typography, Divider } from "@material-ui/core";
import { CustomInput } from "../CustomInput";
import { CustomAutocomplete } from "../CustomAutocomplete";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import {
  CheckForDuplicateUnit,
  GetAllPropertyUnitTypeAPI,
  PropertiesAdvanceSearchTest,
} from "../../../Services";
import { PropertiesMapper } from "../../../Views/Home";
import { useDebouncedAction } from "../../../Hooks/DebouncedAction";
import * as Yup from "yup";

//styles
import useStyles from "./styles";

const AddUnitCheckDuplicatedDialog = ({ open, onClose }) => {
  const styles = useStyles();
  const { translate } = useTranslate("HeaderView");

  const {
    setAlertBoxContent,
    addNewUnit,
    setAddNewUnit,
    duplicatedUnit,
    setDuplicatedUnit,
  } = useVerticalNav();

  const reducerUnitsAdd = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isOnSubmit, setIsOnSubmit] = useState(false);
  const [data, setData] = useReducer(reducerUnitsAdd, {
    propertyName: [],
    unitType: [],
  });
  const [selected, setSelected] = useReducer(reducerUnitsAdd, {
    propertyName: null,
    unitType: null,
    unitNumber: null,
    buildingNumber: "",
    phaseNumber: null,
  });

  const [saveDto, setSaveDto] = useReducer(reducerUnitsAdd, {
    propertyName: null,
    unitType: null,
    unitNumber: null,
    buildingNumber: "",
    phaseNumber: null,
  });
  const unitValidationSchema = Yup.object().shape({
    propertyName: Yup.string().required(translate("property-name-is-required")),
    unitType: Yup.string().required(translate("unit-type-is-required")),
    buildingNumber: Yup.string().nullable(),
    phaseNumber: Yup.string().nullable(),
    unitNumber: Yup.string()
      .matches(
        /^(?=.*[a-zA-Z0-9]).*$/,
        translate("input_cannot_be_special_characters")
      )
      .required(translate("unit-number-is-required")),
  });

  const getAllProperties = useCallback(async (search = "") => {
    const res = await PropertiesAdvanceSearchTest(
      { pageIndex: 0, pageSize: 25 },
      {
        criteria: { All: [{ searchType: 2, value: search }] },
        filterBy: "createdOn",
        orderBy: 2,
      }
    );
    if (!(res && res.status && res.status !== 200))
      setData({
        id: "propertyName",
        value:
          ((res && res.result) || []).map(
            (item) =>
              item.propertyJson &&
              PropertiesMapper(item, JSON.parse(item.propertyJson).property)
          ) || [],
      });
    else setData({ id: "propertyName", value: [] });
  }, []);

  const debouncedGetAllProperties = useDebouncedAction(getAllProperties, 700);

  const getUnitTypes = async (propertyId) => {
    let unitTypes;
    const res = await GetAllPropertyUnitTypeAPI(propertyId);
    if (!(res && res.status && res.status !== 200)) {
      unitTypes = res[0]?.propertyUnitType;
      setData({ id: "unitType", value: unitTypes || [] });
    } else setData({ id: "unitType", value: [] });
  };

  const restFiled = () => {
    setSaveDto({
      id: "edit",
      value: {
        propertyName: null,
        unitType: null,
        unitNumber: null,
        buildingNumber: "",
        phaseNumber: null,
      },
    });
    setSelected({
      id: "edit",
      value: {
        propertyName: null,
        unitType: null,
        unitNumber: null,
        buildingNumber: "",
        phaseNumber: null,
      },
    });
  };
  const checkDuplicatedUnitHandler = async (selectedValues) => {
    const values = selectedValues;
    setIsOnSubmit(true);
    try {
      await unitValidationSchema.validate(saveDto, { abortEarly: false });
      const res = await CheckForDuplicateUnit(saveDto);
      if (res.length > 0) {
        setDuplicatedUnit((prevState) => ({
          ...prevState,
          isDuplicate: true,
          matchingUnits: res || [],
          isOpenDuplicatedUnitsDialog: true,
          isOpenSucsessValidateDialog: false,
        }));
      } else if( res.length === 0) {
        setDuplicatedUnit((prevState) => ({
          ...prevState,
          isDuplicate: false,
          matchingUnits: res || [],
          isOpenDuplicatedUnitsDialog: false,
          isOpenSucsessValidateDialog: true,
        }));
      }
      setAddNewUnit((prevState) => ({
        ...prevState,
        addUnitModalOpened: false,
        unit: values,
      }));
      restFiled();
      onClose();
    } catch (error) {
      const errorMessages = error.inner.map((err) => err.message);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: errorMessages.join(", "),
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setIsOnSubmit(false);
    }
  };

  useEffect(() => {
    getAllProperties();
  }, [getAllProperties]);

  return (
    <CustomDialog
      open={open}
      width="688px"
      title={translate("Add-new-unit")}
      subtitle={translate(
        "Fill-in-the-details-below-to-add-a-new-unit-to-your-list-Ensure-all-required-fields-are-completed-before-saving"
      )}
      confirmText={translate("Add-unit")}
      onConfirm={() => {
        checkDuplicatedUnitHandler(selected);
      }}
      cancelText={translate("cancel")}
      onClose={() => {
        onClose();
        restFiled();
      }}
      isDisabled={isOnSubmit}
      content={
        <Box className={styles.wrapperUnitsAddDialogsView}>
          <Box className={styles.containerRowFiled}>
            <Typography className={styles.titleFiled}>
              {translate("Property-name")}*
            </Typography>
            <CustomAutocomplete
              placeholder={translate("Select")}
              options={data?.propertyName || []}
              selectedValues={selected?.propertyName}
              renderOption={(option) => option?.property_name || ""}
              getOptionLabel={(option) => option?.property_name || ""}
              onInputKeyUp={(e) => {
                const value = e.target.value;
                debouncedGetAllProperties(value);
              }}
              onChange={(e, value) => {
                setSelected({
                  id: "propertyName",
                  value: value,
                });
                setSaveDto({
                  id: "propertyName",
                  value: value?.property_name,
                });
                if (value?.id) {
                  getUnitTypes(value.id);
                }
              }}
            />
          </Box>
          <Divider />
          <Box className={styles.containerRowFiled}>
            <Typography className={styles.titleFiled}>
              {translate("Unit-type")}*
            </Typography>
            <CustomAutocomplete
              placeholder={translate("Select")}
              options={data.unitType || []}
              selectedValues={selected?.unitType}
              renderOption={(option) => option?.lookupItemName || ""}
              getOptionLabel={(option) => option?.lookupItemName || ""}
              onChange={(e, newValue) => {
                const value = String(newValue.lookupItemName);
                setSelected({
                  id: "unitType",
                  value: newValue,
                });
                setSaveDto({
                  id: "unitType",
                  value: value,
                });
              }}
            />
          </Box>
          <Divider />
          <Box className={styles.containerRowFiled}>
            <Typography className={styles.titleFiled}>
              {translate("Unit-number")}*
            </Typography>
            <CustomInput
              placeholder={translate("Enter")}
              hasSearchIcon={false}
              style={{ maxWidth: "100%" }}
              value={selected.unitNumber}
              errorMessage={translate("Invalid_Url")}
              onChange={(e) => {
                setSelected({
                  id: "unitNumber",
                  value: e.target.value,
                });
                setSaveDto({
                  id: "unitNumber",
                  value: e.target.value,
                });
              }}
            />
          </Box>
          <Divider />
          <Box className={styles.containerRowFiled}>
            <Typography className={styles.titleFiled}>
              {translate("Building-number")}
            </Typography>
            <CustomInput
              placeholder={translate("Enter")}
              hasSearchIcon={false}
              style={{ maxWidth: "100%" }}
              errorMessage={translate("Invalid_Url")}
              onChange={(e) => {
                setSelected({
                  id: "buildingNumber",
                  value: e.target.value || "",
                });
                setSaveDto({
                  id: "buildingNumber",
                  value: e.target.value || "",
                });
              }}
            />
          </Box>
          <Divider />
          <Box className={styles.containerRowFiled}>
            <Typography className={styles.titleFiled}>
              {translate("Phase-number")}
            </Typography>
            <CustomInput
              placeholder={translate("Enter")}
              hasSearchIcon={false}
              style={{ maxWidth: "100%" }}
              errorMessage={translate("Invalid_Url")}
              onChange={(e) => {
                setSelected({
                  id: "phaseNumber",
                  value: e.target.value,
                });
                setSaveDto({
                  id: "phaseNumber",
                  value: e.target.value,
                });
              }}
            />
          </Box>
        </Box>
      }
    />
  );
};

export default AddUnitCheckDuplicatedDialog;
