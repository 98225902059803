import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import {
  Button,
  Menu,
  Box,
  Typography,
  Icon,
  ButtonBase,
} from "@material-ui/core";
import { useIsAr, useTranslate } from "../../../../Hooks";
import { makeStyles } from "@material-ui/core/styles";
import { ActivitiesManagementDialog } from "../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog";
import { PropertyQuickAddDialog } from "../../../../Views/Home/Common/HeaderView/Sections/QuickAddPopoverComponent/Dialogs/PropertQuickAddDialog/PropertQuickAddDialog";
// import { LeadQuickAddDialog } from "../../../../Views/Home/Common/HeaderView/Sections/QuickAddPopoverComponent/Dialogs/LeadQuickAddDialog/LeadQuickAddDialog";
import { AddContactDialog } from "../../../../Views/Home/Common/HeaderView/Sections/QuickAddPopoverComponent/QuickAddView/ContactView/Dialogs/AddContactDialog";
import { UnitsAddDialogsView } from "../../../../Views/Home/UnitsView/UnitsAddDialogsView/UnitsAddDialogsView";
import { AddTaskDialog } from "../../../../Views/Home/TaskConfiguration/Dialogs";
import { useVerticalNav } from "../../../../Contexts/VerticalNavContext";

import { DuplicatedUnitsDialog } from "./AddQuickUnitsRelatedDialogs/DuplicatedUnitsDialog";

import { SimilarPropertyMatchesDialog } from "./AddQuickPropretyRelatedDialogs/SimilarPropertyMatchesDialog";
import { DuplicatePropertyAlertDialog } from "./AddQuickPropretyRelatedDialogs/DuplicatePropertyAlertDialog";
import { PropretySucsessValidateDialog } from "./AddQuickPropretyRelatedDialogs/PropretySucsessValidateDialog";
import {
  AddContact,
  DuplicatedContactCard,
  CustomDialog,
  AddNewContactDialog,
  AddPropretyCheckDuplicatedDialog,
  AddUnitCheckDuplicatedDialog,
} from "../..";
import { UnitSucsessValidateDialog } from "./AddQuickUnitsRelatedDialogs/UnitSucsessValidateDialog";

const useStyles = makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    menuContainer: {
      maxWidth: "617px",
      padding: "32px",
      display: "flex",
      flexWrap: "wrap",
      gap: "4px 24px",
      [theme.breakpoints.down("sm")]: {
        padding: "12px",
      },
    },
    menuItem: {
      display: "flex",
      alignItems: "flex-start",
      width: "calc(50% - 12px)",
      textAlign: "start",
      padding: "12px",
      borderRadius: "6px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "flex-start",
      },
      "&:hover": {
        background: theme.palette.background.primary_hover,
      },
    },
    icon: {
      color: theme.palette.foreground.brandPrimary,
      marginInlineEnd: theme.spacing(2),
    },
    customMenu: {
      boxShadow:
        "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
      borderRadius: "8px",
      border: `1px solid ${theme.palette.border.secondary}`,
      [theme.breakpoints.down("md")]: {
        bottom: "80px !important",
        height: "fit-content",
      },
      [theme.breakpoints.up("md")]: {
        top: "60px !important",
      },
    },
    collapseIcon: {
      color: theme.palette.text.tertiary,
      fontSize: "20px",
    },
    quickAddButton: (props) => ({
      color: theme.palette.text.tertiary,
      padding: "10px 14px",
      borderRadius: "8px",
      "&:hover": {
        background: theme.palette.background.primary_hover,
      },
      ...(isAr ? { marginLeft: "16px" } : { marginRight: "16px" }),
      "& .MuiButton-label": {
        flexDirection: isAr ? "row-reverse" : "row",
      },
      "& .MuiButton-endIcon": {
        ...(isAr ? { marginRight: "8px", marginLeft: 0 } : {}),
      },
    }),
    customMenuList: {
      padding: 0,
    },
    title: {
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      marginBottom: "4px",
    },
    description: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
      color: theme.palette.text.tertiary,
    },
    quickAddButtonIcon: {
      "&:hover": {
        background: "transparent",
      },
    },
    duplicateCardsWrapper: {
      display: "flex",
      flex: "1 1 100%",
      flexWrap: "wrap",
      gap: "20px",
      maxHeight: "502px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
  };
});

const menuItems = [
  {
    icon: "icon-quick-add-user",
    title: "contact",
    description: "Adding a new contact on our platform is quick and easy!",
    type: "contact",
    dialogSelect: 1,
  },
  {
    icon: "icon-quick-add-unit",
    title: "unit",
    description: "Adding units to our platform streamlines the process.",
    type: "unit",
    dialogSelect: 3,
  },
  {
    icon: "icon-quick-add-lead",
    title: "lead",
    description: "Add leads and turn opportunities into successful.",
    type: "lead",
    dialogSelect: 4,
  },
  {
    icon: "icon-quick-add-property",
    title: "property",
    description: "Adding new properties to your portfolio quick and easy!",
    type: "property",
    dialogSelect: 2,
  },
  {
    icon: "icon-quick-add-activity",
    title: "activity",
    description: "Our platform makes it easy to track and manage your tasks.",
    type: "activity",
    dialogSelect: 5,
  },
  {
    icon: "icon-quick-add-task",
    title: "task",
    description: "Adding new tasks for your team quick and easy!",
    type: "task",
    dialogSelect: 6,
  },
];

const parentTranslationPath = "HeaderView";
const translationPath = "";

function QuickAdd({ children }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useSelector((store) => store.theme);
  const styles = useStyles();
  const { isAr } = useIsAr(theme.themeDirection);
  const { translate } = useTranslate(parentTranslationPath);

  const [isOpenDialog, setisOpenDialog] = useState({
    ActivitiesDialog: "",
    ContactDialog: "",
    PropertyDialog: "",
    UnitDialog: "",
    LeadDialog: "",
    TaskDialog: "",
  });

  const {
    setAddNewContact,
    addNewContact,
    duplicatedContact,
    setDuplicatedContact,
    addNewUnit,
    setAddNewUnit,
    duplicatedUnit,
    setDuplicatedUnit,
    addNewProprety,
    setAddNewProprety,
    setDuplicatedProprety,
    duplicatedProprety,
  } = useVerticalNav();

  const [DialogSelect, setDialogSelect] = useState("");

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onQuickAdd = (dialogSelected) => {
    setDialogSelect(dialogSelected);
    handleMenuClose();
  };

  const anchorOrigin = isAr
    ? { vertical: "bottom", horizontal: "left" }
    : { vertical: "bottom", horizontal: "right" };

  const transformOrigin = isAr
    ? { vertical: "top", horizontal: "left" }
    : { vertical: "top", horizontal: "right" };

  const collapseIcon = anchorEl ? "icon-up-arrow" : "icon-down-arrow";

  useEffect(() => {
    if (DialogSelect === 6) {
      setisOpenDialog((item) => ({ ...item, TaskDialog: true }));
    } else if (DialogSelect === 5) {
      setisOpenDialog((item) => ({ ...item, ActivitiesDialog: true }));
    } else if (DialogSelect === 4) {
      setisOpenDialog((item) => ({ ...item, LeadDialog: true }));
    } else if (DialogSelect === 3) {
      setAddNewUnit({
        addUnitModalOpened: true,
        unit: {},
      });
    } else if (DialogSelect === 2) {
      setAddNewProprety({
        addPropretyModalOpened: true,
        Proprety: {},
      });
    } else if (DialogSelect === 1) {
      setAddNewContact({
        addContactModalOpened: true,
        contact: {},
      });
    }
  }, [DialogSelect]);

  return (
    <>
      <Button
        aria-controls="quick-add-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        endIcon={
          !children ? (
            <Icon className={clsx(collapseIcon, styles.collapseIcon)} />
          ) : null
        }
        className={clsx({
          [styles.quickAddButton]: !children,
          [styles.quickAddButtonIcon]: children,
        })}
        disableRipple
      >
        {children ?? translate("quick-add")}
      </Button>
      <Menu
        id="quick-add-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        getContentAnchorEl={null}
        PaperProps={{
          className: styles.customMenu,
        }}
        MenuListProps={{
          className: styles.customMenuList,
        }}
      >
        <Box className={styles.menuContainer}>
          {menuItems.map((item, index) => (
            <ButtonBase
              className={styles.menuItem}
              onClick={() => onQuickAdd(item.dialogSelect)}
              key={index}
              disableRipple
            >
              <Icon className={clsx(item.icon, styles.icon)}></Icon>
              <Box>
                <Typography component="h4" className={styles.title}>
                  {translate(item.title)}
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className={styles.description}
                >
                  {item.description}
                </Typography>
              </Box>
            </ButtonBase>
          ))}
        </Box>
      </Menu>

      {isOpenDialog.ActivitiesDialog && (
        <ActivitiesManagementDialog
          activeItem={null}
          open={isOpenDialog.ActivitiesDialog}
          isEdit={false}
          onSave={() => {
            setisOpenDialog((item) => ({ ...item, ActivitiesDialog: false }));
            setDialogSelect("");
          }}
          close={() => {
            setisOpenDialog((item) => ({ ...item, ActivitiesDialog: false }));
            setDialogSelect("");
          }}
          translationPath={translationPath}
          parentTranslationPath="ActivitiesView"
          isGeneralDialog
        />
      )}
      {isOpenDialog.PropertyDialog && (
        <PropertyQuickAddDialog
          open={isOpenDialog.PropertyDialog}
          onClose={() => {
            setisOpenDialog((item) => ({ ...item, PropertyDialog: false }));
            setDialogSelect("");
          }}
        />
      )}
      {isOpenDialog.ContactDialog && (
        <AddContactDialog
          open={isOpenDialog.ContactDialog}
          close={() => {
            setisOpenDialog((item) => ({ ...item, ContactDialog: false }));
            setDialogSelect("");
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}

      {addNewContact?.addContactModalOpened && (
        <AddContact
          onClose={() => {
            setAddNewContact({
              addContactModalOpened: false,
              contact: {},
            });
            setDialogSelect("");
          }}
        />
      )}

      {!duplicatedContact?.matchingContacts?.length &&
        addNewContact?.contact?.email && (
          <AddNewContactDialog
            onClose={() => {
              setAddNewContact({
                addContactModalOpened: true,
                contact: {},
              });
            }}
            onOpen={
              !duplicatedContact?.matchingContacts?.length &&
              addNewContact?.contact?.email
            }
          />
        )}

      {duplicatedContact?.isDuplicate &&
        duplicatedContact?.matchingContacts?.length > 0 && (
          <CustomDialog
            open={duplicatedContact?.isDuplicate}
            onClose={() => {
              setDuplicatedContact({
                isDuplicate: false,
                matchingContacts: [],
              });
            }}
            title={translate("Duplicated-contact")}
            subtitle={translate("Duplicated-contact-subtitle")}
            cancelText={translate("Discard")}
            width="688px"
            headerBorder
            content={
              <Box className={styles.duplicateCardsWrapper}>
                {duplicatedContact?.matchingContacts?.map((contact) => (
                  <DuplicatedContactCard contact={contact} />
                ))}
              </Box>
            }
          />
        )}

      {addNewUnit?.addUnitModalOpened && (
        <AddUnitCheckDuplicatedDialog
          open={addNewUnit?.addUnitModalOpened}
          onClose={() => {
            setAddNewUnit((prevState) => ({
              ...prevState,
              addUnitModalOpened: false,
            }));
            setDialogSelect("");
          }}
        />
      )}

      {duplicatedUnit.isDuplicate &&
        duplicatedUnit.matchingUnits.length > 0 &&
        duplicatedUnit.isOpenDuplicatedUnitsDialog && (
          <DuplicatedUnitsDialog
            open={duplicatedUnit.isOpenDuplicatedUnitsDialog}
            onClose={() => {
              setDuplicatedUnit({
                ...duplicatedUnit,
                isDuplicate: false,
                matchingUnits: [],
                isOpenDuplicatedUnitsDialog: false,
                isOpenSucsessValidateDialog: false,
              });
              setDialogSelect("");
            }}
          />
        )}

      {duplicatedUnit.matchingUnits.length === 0 &&
        duplicatedUnit.isOpenSucsessValidateDialog && (
          <UnitSucsessValidateDialog
            open={duplicatedUnit.isOpenSucsessValidateDialog}
            onClose={() => {
              setDuplicatedUnit({
                ...duplicatedUnit,
                isDuplicate: false,
                matchingUnits: [],
                isOpenDuplicatedUnitsDialog: false,
                isOpenSucsessValidateDialog: false,
              });
              setDialogSelect("");
            }}
          />
        )}

      <AddPropretyCheckDuplicatedDialog
        onOpen={addNewProprety.addPropretyModalOpened}
        onClose={() => {
          setAddNewProprety((prevState) => ({
            ...prevState,
            addPropretyModalOpened: false,
          }));
          setDialogSelect("");
        }}
      />

      {duplicatedProprety.PropretyFuzzyMatchingPropertiesDialogOpened &&
        duplicatedProprety.fuzzyMatchingProperties.length > 0 && (
          <SimilarPropertyMatchesDialog
            onOpen={
              duplicatedProprety.PropretyFuzzyMatchingPropertiesDialogOpened
            }
            onClose={() => {
              setDuplicatedProprety({
                ...duplicatedContact,
                PropretyFuzzyMatchingPropertiesDialogOpened: false,
              });
              setDialogSelect("");
            }}
          />
        )}

      {duplicatedProprety.PropretyMatchingPropertiesDialogOpened &&
        duplicatedProprety.matchingProperties.length > 0 && (
          <DuplicatePropertyAlertDialog
            onOpen={duplicatedProprety.PropretyMatchingPropertiesDialogOpened}
            onClose={() => {
              setDuplicatedProprety({
                ...duplicatedContact,
                PropretyMatchingPropertiesDialogOpened: false,
              });
              setDialogSelect("");
            }}
          />
        )}

      {duplicatedProprety?.PropretySucsessValidateDialog && (
        <PropretySucsessValidateDialog
          onOpen={duplicatedProprety.PropretySucsessValidateDialog}
          onClose={() => {
            setDuplicatedProprety({
              ...duplicatedContact,
              PropretySucsessValidateDialog: false,
            });
            setDialogSelect("");
          }}
        />
      )}

      {isOpenDialog.UnitDialog && (
        <UnitsAddDialogsView
          open={isOpenDialog.UnitDialog}
          close={() => {
            setisOpenDialog((item) => ({ ...item, UnitDialog: false }));
            setDialogSelect("");
          }}
        />
      )}
      {/* {isOpenDialog.LeadDialog && (
        <LeadQuickAddDialog
          isOpen={isOpenDialog.LeadDialog}
          close={() => {
            setisOpenDialog((item) => ({ ...item, LeadDialog: false }));
            setDialogSelect('');
          }}
        />
      )} */}
      {isOpenDialog.TaskDialog && (
        <AddTaskDialog
          isDialogOpen={isOpenDialog.TaskDialog}
          onSave={() => {
            setisOpenDialog((item) => ({ ...item, TaskDialog: false }));
            setDialogSelect("");
          }}
          onClose={() => {
            setisOpenDialog((item) => ({ ...item, TaskDialog: false }));
            setDialogSelect("");
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </>
  );
}

export default QuickAdd;
