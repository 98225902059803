import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    historySideMenu: {
      width: "652px",
      height: "100vh",
      position: "fixed",
      top: 0,
      right: 0,
      backgroundColor: "#fff",
      boxShadow: theme.shadows[5],
      transform: "translateX(0)",
      transition: "transform 0.3s ease-in-out",
      zIndex: 1300,
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      overflowY: "auto", 
      boxSizing: "border-box", 
    },
    hidden: {
      transform: "translateX(100%)",
    },
    header: {
      padding: "32px 32px 0px 32px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },containerTitlePlusBackIcon:{
      display:'flex',
      alignItems:'center',
      gap:'16px',
      flexDirection:'row'
    },title:{
      fontSize:'30px',
      lineHeight:'38px',
      fontWeight:'600',
      color:theme.palette.text.primary
    }
  };
});
