import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useReducer,
} from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import {
  GetAllCampaigns,
  getProperties,
  lookupItemsGetId,
} from "../../../Services";
import { returnPropsByPermissions } from "../../../Helper";
import {
  Tables,
  Spinner,
  PermissionsComponent,
  AutocompleteComponent,
  Inputs,
} from "../../../Components";
import { TableActions } from "../../../Enums";
import { CampaignsHeaderData } from "./CampaignsHeaderData";
import { DeleteDialog } from "./DeleteDialog";
import { CampaignMarketingDialog } from "./CampaignMarketingDialog";
import { PermissionDeniedLayout } from "../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout";
import { CampaignsPermissions } from "../../../Permissions";
import { PortalsCampaignChart } from "./PortalsCampaignChart";
import { StaticLookupsIds } from "../../../assets/json/StaticLookupsIds";

const parentTranslationPath = "Campaign";
const translationPath = "";

export const CampaignView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEventTypesLoading, setIsEventTypesLoading] = useState(false);
  const [isCountriesLoading, setIsCountriesLoading] = useState(false);
  const [isCitiesLoading, setIsCitiesLoading] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
    propertyId: null,
    eventTypeId: null,
    countryId: null,
    cityId: null,
    propertyCampaignName: null,
  });
  const [displayDetailsToUser, setDisplayDetailsToUser] = useState(null);
  const [marketingCampaigns, setMarketingCampaigns] = useState([]);
  const [campaognNameSearch, setCampaognNameSearch] = useState("");
  const [propertySearch, setPropertySearch] = useState("");
  const [data, setData] = useState({
    result: [],
    totalCount: 0,
  });
  const [marketingCampaignsTotal, setMarketingCampaignsTotal] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogChartOpen, setisDialogChartOpen] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [eventTypesSearch, setEventTypesSearch] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countriesSearch, setCountriesSearch] = useState(null);
  const [cities, setCities] = useState([]);
  const [citiesSearch, setCitiesSearch] = useState(null);
  const [selected, setSelected] = useReducer(reducer, {
    property: null,
  });

  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions.editText.key) {
      setActiveItem(item);
      setIsDialogOpen(true);
    } else if (actionEnum === TableActions.deleteText.key) {
      setActiveItem(item);
      setIsDeleteDialogOpen(true);
    } else if (actionEnum === TableActions.dashboardstable.key) {
      setActiveItem(item);
      setisDialogChartOpen(true);
    }
  }, []);

  const getActionTableWithPermissions = () => {
    const list = [];
    if (
      returnPropsByPermissions(CampaignsPermissions.EditCampaign.permissionsId)
    ) {
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      });
    }
    if (
      returnPropsByPermissions(
        CampaignsPermissions.DeleteCampaign.permissionsId
      )
    ) {
      list.push({
        enum: TableActions.deleteText.key,
        isDisabled: false,
        externalComponent: null,
      });
    }
    if (
      returnPropsByPermissions(
        CampaignsPermissions.ViewCampaignDashboard.permissionsId
      )
    ) {
      list.push({
        enum: TableActions.dashboardstable.key,
        isDisabled: false,
        externalComponent: null,
      });
    }
    return list;
  };

  const addNewHandler = () => {
    setIsDialogOpen(true);
  };
  const getAllCampaigns = useCallback(
    async (filter) => {
      setIsLoading(true);
      if (
        returnPropsByPermissions(
          CampaignsPermissions.ViewAllCampaigns.permissionsId
        )
      ) {
        const res = await GetAllCampaigns({
          ...filter,
          pageIndex: filter.pageIndex + 1,
        });
        if (!(res && res.status && res.status !== 200)) {
          setMarketingCampaigns(res.result || []);
          setMarketingCampaignsTotal(res.totalCount || 0);
        } else setMarketingCampaigns([]);

        setDisplayDetailsToUser("allowed");
      } else setDisplayDetailsToUser("notAllowed");

      setIsLoading(false);
    },
    [filter]
  );

  const getPropertiesOption = useCallback(async (propertySearch) => {
    setIsPropertyLoading(true);
    const res = await getProperties({
      pageSize: 10,
      pageIndex: 0,
      search: propertySearch,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ result: res.result, totalCount: res.totalCount });
    } else {
      setData({ result: [], totalCount: 0 });
    }

    setIsPropertyLoading(false);
  }, []);

  const getAllEventTypes = async () => {
    setIsEventTypesLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.EventType,
    });
    if (!(res && res.status && res.status !== 200)) setEventTypes(res);
    else setEventTypes([]);

    setIsEventTypesLoading(false);
  };

  const getAllCountries = async () => {
    setIsCountriesLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Country,
    });
    if (!(res && res.status && res.status !== 200)) setCountries(res);
    else setCountries([]);

    setIsCountriesLoading(false);
  };

  const getAllCities = async () => {
    setIsCitiesLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Cities,
    });
    if (!(res && res.status && res.status !== 200)) setCities(res);
    else setCities([]);

    setIsCitiesLoading(false);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  useEffect(() => {
    getAllCampaigns(filter);
  }, [filter, getAllCampaigns]);

  useEffect(() => {
    getPropertiesOption();
  }, []);

  useEffect(() => {
    getAllEventTypes();
    getAllCountries();
    getAllCities();
  }, [getPropertiesOption]);

  return (
    <div className="campain-wrapper">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="d-flex-v-center-h-between">
        <PermissionsComponent
          permissionsList={Object.values(CampaignsPermissions)}
          permissionsId={CampaignsPermissions.AddCampaign.permissionsId}
        >
          <Button
            className="btns mx-3 mb-2 theme-solid"
            onClick={addNewHandler}
            id="action_add-new_plus"
          >
            <span className="mdi mdi-plus" />
            {t(`${translationPath}add-new`)}
          </Button>
        </PermissionsComponent>
        {displayDetailsToUser && displayDetailsToUser === "allowed" && (
          <div className="d-flex-column-v-center-h-start mr-4">
            <div>
              <div className="w-75 d-inline-flex">
                <AutocompleteComponent
                  idRef={"propertySearchIdRef"}
                  wrapperClasses="m-2 w-50"
                  data={data?.result}
                  selectedValues={selected?.property || null}
                  displayLabel={(option) =>
                    option?.property?.property_name || ""
                  }
                  filterOptions={(options, { inputValue }) =>
                    options.filter((item) => {
                      return propertySearch
                        ? +item?.propertyId === +inputValue ||
                            item?.property?.property_name
                              ?.toLowerCase()
                              .includes(inputValue.toLowerCase())
                        : true;
                    })
                  }
                  chipsLabel={(option) => option?.property?.property_name || ""}
                  withoutSearchButton
                  inputPlaceholder={t(`${translationPath}filter-by-property`)}
                  isLoading={isPropertyLoading}
                  multiple={false}
                  onInputKeyUp={(event) => {
                    let value = event.target.value.trim();

                    if (!isNaN(value) && value !== "") {
                      value = Number(value);
                    }
                    setPropertySearch(value);
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getPropertiesOption(value);
                    }, 700);
                  }}
                  onChange={(e, newValue) => {
                    setFilter((f) => ({
                      ...f,
                      propertyId: newValue?.propertyId || null,
                    }));
                    setSelected({ id: "property", value: newValue });
                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
                <Inputs
                  idRef="DescriptionRef"
                  inputPlaceholder={t(
                    `${translationPath}filter-by-campain-name`
                  )}
                  value={campaognNameSearch}
                  wrapperClasses="m-2 w-50"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    const { value } = event.target;
                    setCampaognNameSearch(value);
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      setFilter((f) => ({ ...f, propertyCampaignName: value }));
                    }, 700);
                  }}
                />
              </div>
              <div className="d-flex">
                <AutocompleteComponent
                  idRef="EventTypeRef"
                  wrapperClasses="m-2 w-50"
                  multiple={false}
                  data={eventTypes || []}
                  displayLabel={(option) =>
                    (option && option.lookupItemName) || ""
                  }
                  chipsLabel={(option) =>
                    (option && option.lookupItemName) || ""
                  }
                  withoutSearchButton
                  inputPlaceholder={t(`${translationPath}filter-by-event-type`)}
                  isLoading={isEventTypesLoading}
                  isWithError
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputKeyUp={(event) => {
                    const { value } = event.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      setEventTypesSearch(value);
                    }, 700);
                  }}
                  onChange={(event, newValue) => {
                    setFilter((f) => ({
                      ...f,
                      eventTypeId: newValue && newValue.lookupItemId,
                    }));
                  }}
                />
                <AutocompleteComponent
                  idRef="CountryRef"
                  wrapperClasses="m-2 w-50"
                  multiple={false}
                  data={countries || []}
                  displayLabel={(option) =>
                    (option && option.lookupItemName) || ""
                  }
                  chipsLabel={(option) =>
                    (option && option.lookupItemName) || ""
                  }
                  withoutSearchButton
                  inputPlaceholder={t(`${translationPath}filter-by-country`)}
                  isLoading={isCountriesLoading}
                  isWithError
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputKeyUp={(event) => {
                    const { value } = event.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      setCountriesSearch(value);
                    }, 700);
                  }}
                  onChange={(event, newValue) => {
                    setFilter((f) => ({
                      ...f,
                      countryId: newValue && newValue.lookupItemId,
                    }));
                  }}
                />
                <AutocompleteComponent
                  idRef="CityRef"
                  wrapperClasses="m-2 w-50"
                  multiple={false}
                  data={cities || []}
                  displayLabel={(option) =>
                    (option && option.lookupItemName) || ""
                  }
                  chipsLabel={(option) =>
                    (option && option.lookupItemName) || ""
                  }
                  withoutSearchButton
                  inputPlaceholder={t(`${translationPath}filter-by-city`)}
                  isLoading={isCitiesLoading}
                  isWithError
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputKeyUp={(event) => {
                    const { value } = event.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      setCitiesSearch(value);
                    }, 700);
                  }}
                  onChange={(event, newValue) => {
                    setFilter((f) => ({
                      ...f,
                      cityId: newValue && newValue.lookupItemId,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {displayDetailsToUser && displayDetailsToUser === "allowed" && (
        <div className="p-3 w-100">
          <Tables
            data={marketingCampaigns}
            headerData={CampaignsHeaderData}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            defaultActions={getActionTableWithPermissions()}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isLoading={isLoading}
            totalItems={marketingCampaignsTotal}
          />
        </div>
      )}
      {displayDetailsToUser === "notAllowed" && <PermissionDeniedLayout />}

      {isDeleteDialogOpen && (
        <DeleteDialog
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          reloadData={() => {
            getAllCampaigns(filter);
          }}
          onCloseClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isDialogChartOpen && (
        <PortalsCampaignChart
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          isDialogChartOpen={isDialogChartOpen}
          reloadData={() => {
            getAllCampaigns(filter);
          }}
          onCloseClicked={() => {
            setisDialogChartOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setisDialogChartOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isDialogOpen && (
        <CampaignMarketingDialog
          isDialogOpen={isDialogOpen}
          activeItem={activeItem}
          onSave={() => {
            getAllCampaigns(filter);
            setIsDialogOpen(false);
            setActiveItem(null);
          }}
          onClose={() => {
            setActiveItem(null);
            setIsDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
