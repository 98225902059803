import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  textField: {
    "& .MuiInputBase-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      height: "44px",
      lineHeight: "24px",
      padding: "10px 14px",
      backgroundColor: theme.palette.background.primary,
      boxSizing: "border-box",
      position: "relative",

      "& fieldset": {
        borderRadius: "8px",
        borderColor: theme.palette.border.primary,
        borderWidth: "1px",
        pointerEvents: "none",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.border.primary,
      },
      "&.Mui-focused:not(.Mui-error) fieldset": {
        borderColor: theme.palette.border.brand,
        borderWidth: "1px",
        zIndex: 1,
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.background.disabled_subtle,
        cursor: "not-allowed",

        "& fieldset": {
          cursor: "not-allowed",
          borderColor: theme.palette.border.disabled,
        },
      },
    },
    "& .MuiFormHelperText-root": {
      color: theme.palette.text.tertiary + " !important",
      marginRight: 0,
      marginLeft: 0,
      padding: 0,
      marginTop: "6px",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
    },

    "& .MuiInputBase-input": {
      color: theme.palette.text.primary,
      padding: "0 !important", // Ensure no additional padding inside input
      height: "100%", // Matches parent height
      backgroundColor: "transparent !important", // No additional background
      "&.Mui-disabled": {
        backgroundColor: "orange",
        color: theme.palette.text.disabled,
        "&::placeholder": {
          color: theme.palette.text.disabled,
          opacity: 1,
        },
      },
    },
    "& .MuiInputBase-input::placeholder": {
      color: theme.palette.text.placeholder,
      opacity: 1,
    },
  },
  errorMessageContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "100%",
    direction: "ltr",
  },
  errorMessage: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: `${400} !important`,
    color: theme.palette.text.error_primary,
  },
  inputContainerError: {
    "& fieldset": {
      border: ` 1px solid ${theme.palette.border.error} !important`,
      boxShadow: `${theme.shadows[1]} !important`,
    },
  },
  inputContainerFocused: {
    "& fieldset": {
      border: ` 1px solid ${theme.palette.border.brand} !important`,
      boxShadow: `${theme.shadows[8]} !important`,
    },
  },
}));
