import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  imageWrapper: {
    width: "100%",
    "& .splide__arrow": {
      display: "none",
      width: "36px",
      height: "36px",
      backgroundColor: "rgba(255, 255, 255, 0.90)",
      backdropFilter: "blur(4px)",
    },
    "& .splide__pagination": {
      bottom: "20px",
      borderRadius: "24px",
      border: "1px solid rgba(255, 255, 255, 0.40)",
      background: "rgba(255, 255, 255, 0.50)",
      backdropFilter: "blur(4px)",
      width: "fit-content",
      height: "16px",
      padding: "0 8px",
      alignItems: "center",
      gap: "4px",
      justifySelf: "center",
      display: "none",
    },
    "& .splide__pagination__page": {
      margin: 0,
      backgroundColor: "#FFF",
      opacity: 1,
    },
    "& .is-active": {
      backgroundColor: "#101828",
    },
  },
  imageWrapperHover: {
    "& .splide__arrow": { display: "flex" },
    "& .splide__pagination": { display: "flex" },
  },
  image: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "12px 12px 0 0",
  },
  emptyImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.disabled,
    height: "100%",
  },
}));
