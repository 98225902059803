export const truncateString = (name, maxLength = 12) => {
  if (name?.length <= maxLength) {
    return name;
  }
  return name?.substring(0, maxLength) + "...";
};

export const flattenObject = (obj, parentKey = "", result = {}) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        flattenObject(obj[key], newKey, result);
      } else {
        if (newKey !== "imagePath") {
          result[newKey] = obj[key];
        } else {
          result[newKey] = obj[key];
        }
      }
    }
  }

  if (obj?.first_name) {
    result.fullName = `${obj?.first_name || ""} ${obj?.last_name || ""}`;
  }

  result.contactId = obj?.id;

  return result;
};
