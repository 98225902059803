import React, { useEffect, useRef } from 'react';
import Styles from './DuplicatedUnitOverview.module.scss';
import { useUnitValidationContext } from '../../../Contexts/UnitValidationContext/UnitValidationContext';
import ImagesCourasel from '../LeadsSalesView/LeadsSalesProfileManagementView/Sections/LeadInquiriesView/Utilities/UnitOverviewComponent/Sections/ImagesCourasel/ImagesCourasel';
import { CopyToClipboardComponents } from '../../../ReusableComponents/UtilityComponents';
import MiddleSectionDuplicated from './Sections/MiddleSectionDuplicated/MiddleSectionDuplicated';
import SideSectionDuplicated from './Sections/SideSectionDuplicated/SideSectionDuplicated';
import { GlobalHistory } from '../../../Helper';

function DuplicatedUnitOverview() {
  const {
    selectedDuplicatedUnit,
    onConfirmClaimUnitClicked,
    resetStateHandler,
    preventCleanup,
  } = useUnitValidationContext();

  const isNavigatingBack = useRef(true);
  const shouldCleanupRun = useRef(!preventCleanup);

  useEffect(() => {
    if (preventCleanup) return;
    if (
      !selectedDuplicatedUnit ||
      Object.keys(selectedDuplicatedUnit).length === 0
    ) {
      setTimeout(() => {
        isNavigatingBack.current = true;
        GlobalHistory.goBack();
      }, 500);
    }
    return () => {
      if (isNavigatingBack.current && shouldCleanupRun.current) {
        resetStateHandler();
      }
    };
  }, [selectedDuplicatedUnit]);
  return (
    <div className={Styles.pageLayout}>
      <div className={Styles.pageHeader}>
        <span className={Styles.pageTitle}>Unit overview</span>
        <span className={Styles.pageSubTitle}>
          Explore all the details and features of this unit, including images,
          pricing, and key amenities. You can view listing agent information or
          claim this unit for assignment if applicable.
        </span>
      </div>
      <div className={Styles.pageWrapper}>
        <ImagesCourasel
          couraselImages={selectedDuplicatedUnit?.unitImageUuid}
          unitId={selectedDuplicatedUnit?.unitId}
        />
        <div className={Styles.Top_Section}>
          <div className={Styles.Top_Details_Box}>
            <div className={Styles.Right_Side}>
              <div className={Styles.TypeWrapper}>
                <span className={Styles.UnitNumber}>
                  {selectedDuplicatedUnit?.unitNumber}
                </span>
                <span className={Styles.Type}>
                  {selectedDuplicatedUnit?.unitType}
                </span>
              </div>
              <div className={Styles.UnitNameWrapper}>
                <span className={Styles.UnitName}>
                  {selectedDuplicatedUnit?.propertyName}
                </span>
                <span>
                  <CopyToClipboardComponents
                    data={selectedDuplicatedUnit.unitRefNumber}
                    childrenData={selectedDuplicatedUnit.unitRefNumber}
                    labelClaass={Styles.idRef}
                  />
                </span>
              </div>
              <div className={Styles.Capsules_container}>
                {selectedDuplicatedUnit.unitStatus && (
                  <div className={Styles.Outlined_capsule__gray}>
                    <span>{selectedDuplicatedUnit.unitStatus}</span>
                  </div>
                )}
                {selectedDuplicatedUnit.unitOperationType && (
                  <div className={Styles.Outlined_capsule__gray}>
                    <span>{selectedDuplicatedUnit.unitOperationType}</span>
                  </div>
                )}
                {selectedDuplicatedUnit.unitSaleType ||
                selectedDuplicatedUnit.unitOperationType === 'Sale' ? (
                  <div className={Styles.Outlined_capsule__gray}>
                    <span>
                      {selectedDuplicatedUnit.unitSaleType || 'Primary Sale'}
                    </span>
                  </div>
                ) : null}
                {selectedDuplicatedUnit.furnished && (
                  <div className={Styles.Outlined_capsule__gray}>
                    <span>
                      {selectedDuplicatedUnit.furnished === 'No'
                        ? 'Not Furnished'
                        : 'Furnished'}
                    </span>
                  </div>
                )}
                {selectedDuplicatedUnit.unitUsageType && (
                  <div className={Styles.Outlined_capsule__gray}>
                    <span>{selectedDuplicatedUnit.unitUsageType}</span>
                  </div>
                )}
              </div>
            </div>
            <div className={Styles.Left_Side}>
              <span className={Styles.labelPrice}>AED</span>
              <span className={Styles.unitPrice}>
                {selectedDuplicatedUnit.price}
              </span>
            </div>
          </div>
        </div>
        <div className={Styles.Content}>
          <div className={Styles.Middle_Section}>
            <MiddleSectionDuplicated data={selectedDuplicatedUnit} />
          </div>
          <div className={Styles.Side_Section}>
            <SideSectionDuplicated
              data={selectedDuplicatedUnit}
              claimHandler={onConfirmClaimUnitClicked}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DuplicatedUnitOverview;
