import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "512px",
  },
  input: {
    "& .MuiOutlinedInput-root": {
      background: theme.palette.background.primary,
      borderRadius: theme.borderRadius.md,
      minHeight: "44px",
      rowGap: "6px",
      padding: "10px 14px",
      paddingInlineEnd: "34px !important",
      transition: "box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out", 
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.border.primary, // Default border color
        borderWidth: "1px !important",
        transition: "border-color 0.3s ease-in-out",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.border.primary, // Border color on hover
      },
      "&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.border.brand, // Border color when focused
        boxShadow: theme.shadows[12], // Box shadow when focused
      },
      "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.border.error, // Ensure the error color is also applied
        boxShadow: theme.shadows[16], // Box shadow when focused and error
        transition: "box-shadow 0.3s ease-in-out",
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.border.error,
      },
      // Add smooth transition to the box-shadow
      "&.Mui-focused": {
        transition: "box-shadow 0.3s ease-in-out",
      },

      "& .MuiAutocomplete-tag": {
        margin: "0",
        marginInlineEnd: "6px",
        padding: "2px 9px",
        color: theme.palette.text.secondary,
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        borderRadius: "6px",
        border: `1px solid ${theme.palette.border.primary}`,
        backgroundColor: "transparent",
      },
    },
    // Targeting the padding for the inner input element
    "& .MuiInputBase-input": {
      padding: "0 !important",
    },
    "& .MuiFormHelperText-root": {
      color: `${theme.palette.text.tertiary} !important`,
      marginRight: 0,
      marginLeft: 0,
      padding: 0,
      marginTop: "6px",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color : `${theme.palette.text.error_primary} !important`,
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  popover: {
    backgroundColor: theme.palette.background.primary,
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: '8px',
    padding: "4px 6px",
  },
  option: {
    color: theme.palette.text.primary,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    marginTop: "1px",
    marginBottom: "1px",
    borderRadius: "6px",
    padding: "10px 8px",

    "&:hover": {
      backgroundColor: `${theme.palette.background.hover} !important`, // Background color when hovered
    },
    "&[aria-selected='true']": {
      backgroundColor: `${theme.palette.background.hover} !important`, // Background color for selected option
    },
    "&[data-focus='true']": {
      backgroundColor: `${theme.palette.background.hover} !important`, // Background color for focused option
    },
  },
  listbox: {
    padding: 0,
  },
  popupIndicator: {
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent !important",
    },
  },
  clearIndicator: {
    display: "none",
  },
  closeIconWrapper: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  chip: {
    borderRadius: '6px !important',
    borderColor: theme.palette.border.primary,
    backgroundColor: 'transparent !important',
    padding: '2px 5px !important',
    marginInlineEnd: '6px !important',
    gap: '3px',
    height: 'fit-content',
    '& .MuiChip-label': {
      color: theme.palette.text.secondary,
      lineHeight: "20px",
      fontSize: "14px",
      fontWeight: 500,
      padding: "0",
    },
  },
  deleteTagIcon: {
    width: "16px",
    height: "16px",
    margin: "0px !important",
  },
  prefixIconWrapper: {
    marginInlineEnd: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    border: `0.5px solid ${theme.palette.border.avatarContrast} !important`,
    backgroundColor: theme.palette.background.secondary_hover,
    color: theme.palette.foreground.quarterary,
    width: "16px !important",
    height: "16px !important",
    margin: "0 !important",
    fontSize: "8px !important",
  },
  avatarInOption: {
    width: "24px !important",
    height: "24px !important",
    marginInlineEnd: "8px !important",
    fontSize: "12px !important",
    fontWeight: "600 !important",
    lineHeight: "18px !important",
    
    "& svg": {
      width: "14px !important",
      height: "14px !important",
    },
  },
  optionWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  optionText: {
    color: theme.palette.text.primary,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  },
}));
