/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import i18next from "i18next";
import { ButtonBase, Fab, Tooltip } from "@material-ui/core";
// import { useSelector } from 'react-redux';
import {
  CheckboxesComponent,
  LoadableImageComponant,
  ProgressComponet,
  Spinner,
  PermissionsComponent,
} from "../../../../../Components";
import {
  ActionsEnum,
  ContactTypeEnum,
  LeadsClassTypesEnum,
  LeadsPriorityEnum,
  LeadsStatusEnum,
  LeadsQualificationEnum,
  LoadableImageEnum,
  ContactPreferenceEnum,
} from "../../../../../Enums";
import { CloneLead } from "../../../../../Services";
import {
  getDownloadableLink,
  showError,
  showinfo,
  showSuccess,
} from "../../../../../Helper";
import { LeadsCAllCenterPermissions } from "../../../../../Permissions";
import { CopyToClipboardComponents } from "../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents";
import { CloneLeadView } from "../../../../Share/SharedActionsDFM/CloneLeadView/CloneLeadView";

export const LeadsCardsComponent = ({
  data,
  activeCard,
  isExpanded,
  onCardClicked,
  onFooterActionsClicked,
  parentTranslationPath,
  translationPath,
  notExpandedMax,
  withCheckbox,
  onCardCheckboxClick,
  selectedDetailsLeadItem,
  onActionClicked,
  isCheckBoxDisabled,
  selectedCards,
  relodedata,
  activeSelectedAction,
  checkIfSensitiveField,
  handleRemoveFavorite,
  folderIdValue,
  reloadDataAfterClone,
  isOwnerLeadClone,
  resetAllValues,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isActiveIteam, setIsActiveIteam] = useState(false);
  const [firsetUserType, setFirsetUserType] = useState(false);
  // const loginResponse = useSelector((state) => state.login.loginResponse);
  const getDefaultLeadImage = useCallback(
    (leadType) =>
      ContactTypeEnum[leadType] && ContactTypeEnum[leadType].defaultImg,
    []
  );
  const getIsSelectedCard = useCallback(
    (row) =>
      selectedCards &&
      selectedCards.findIndex((item) => item.id === row.id) !== -1,
    [selectedCards]
  );

  const cloneHandler = async (leadIds) => {
    setIsLoading(true);
    const result = await CloneLead(leadIds);
    if (!(result && result.status && result.status !== 200)) {
      showSuccess(t(`${translationPath}leads-cloned-successfully`));
      relodedata();
      setIsLoading(false);
    } else {
      showError(t(`${translationPath}leads-clone-failed`));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCards && selectedCards.length)
      setFirsetUserType(selectedCards[0].leadClass || "");
    else setFirsetUserType(false);
  }, [selectedCards]);
  return (
    <div className="leads-cards-wrapper childs-wrapper">
      <Spinner isActive={isLoading} />
      {data.result &&
        data.result.map((item, index) => (
          <>
            {item && (
              <div
                className={`leads-card-wrapper${
                  isExpanded ? " is-expanded" : ""
                }${
                  (((activeCard && activeCard.id === item.id) ||
                    (selectedDetailsLeadItem &&
                      selectedDetailsLeadItem.id === item.id)) &&
                    " is-open") ||
                  ""
                }`}
                key={`leadsCardItemRef${index + 1}`}
                id={item.id}
              >
                {withCheckbox && (
                  <div className="card-checkbox-wrapper">
                    <CheckboxesComponent
                      idRef={`leadsCheckboxItemRef${index + 1}`}
                      singleChecked={getIsSelectedCard(item)}
                      onSelectedCheckboxClicked={(event) => {
                        if (
                          activeSelectedAction === "clone-seeker-leads" &&
                          firsetUserType &&
                          firsetUserType !== item.leadClass
                        ) {
                          showError("Please Select same lead");
                          return;
                        }
                        event.preventDefault();
                        onCardCheckboxClick(index, item);
                      }}
                      isDisabled={
                        activeSelectedAction === "reassign-leads" &&
                        (item.status.lookupItemName === "Closed" ||
                          item.leadClass === "Landlord" ||
                          item.leadClass === "Seller")
                      }
                      // isDisabled={
                      //   (isCheckBoxDisabled && !getIsSelectedCard(item)) ||
                      //   (selectedCards &&
                      //     selectedCards[0] &&
                      //     selectedCards[0].leadTypeId !== item.leadTypeId) ||
                      //   (selectedCards &&
                      //     selectedCards[0] &&
                      //     selectedCards[0].name === item.name &&
                      //     !getIsSelectedCard(item))
                      // }
                    />
                  </div>
                )}

                <a className="cards-wrapper">
                  {item?.psi_opportunity === "Yes" && (
                    <div className="lauxary-banner">
                      <p>{t(`${translationPath}PSI-opportunity`)}</p>
                    </div>
                  )}
                  <div
                    className="cards-body-wrapper"
                    onClick={onCardClicked && onCardClicked(item, index)}
                  >
                    <div className="card-body-section">
                      <LoadableImageComponant
                        classes="cover-image"
                        type={LoadableImageEnum.div.key}
                        alt={t(`${translationPath}lead-image`)}
                        src={
                          (item &&
                            item.contact_name &&
                            item.contact_name.contact_image &&
                            item.contact_name.contact_image.uuid &&
                            getDownloadableLink(
                              item.contact_name.contact_image &&
                                item.contact_name.contact_image.uuid
                            )) ||
                          getDefaultLeadImage(item.type)
                        }
                      />
                      <div className="actions-wrapper">
                        {item &&
                          item.contactPreferences &&
                          item.contactPreferences &&
                          [
                            ...new Set(
                              item &&
                                item.contactPreferences &&
                                item.contactPreferences
                            ),
                          ].map(
                            (el) =>
                              el &&
                              checkIfSensitiveField &&
                              checkIfSensitiveField(
                                ContactPreferenceEnum &&
                                  ContactPreferenceEnum[el.toLowerCase()] &&
                                  ContactPreferenceEnum[el.toLowerCase()].value,
                                item && item.contact_name,
                                item.contact_name && +item.contact_name.type
                              ) && (
                                <ButtonBase
                                  disabled={
                                    (item &&
                                      item.status &&
                                      item.status.lookupItemId) ===
                                    LeadsStatusEnum.Closed.status
                                  }
                                  className={
                                    ContactPreferenceEnum[el.toLowerCase()] &&
                                    ContactPreferenceEnum[el.toLowerCase()]
                                      .buttonClasses
                                  }
                                  onClick={onActionClicked(
                                    ContactPreferenceEnum[el.toLowerCase()] &&
                                      ContactPreferenceEnum[el.toLowerCase()]
                                        .actionEnumKey,
                                    item
                                  )}
                                >
                                  <span
                                    className={
                                      ContactPreferenceEnum[el.toLowerCase()] &&
                                      ContactPreferenceEnum[el.toLowerCase()]
                                        .icon
                                    }
                                  />
                                </ButtonBase>
                              )
                          )}
                      </div>
                    </div>
                    <div
                      className={`card-body-section${
                        isExpanded ? " is-expanded" : ""
                      }`}
                    >
                      <div className="body-title-wrapper">
                        <div className="body-title d-flex-h-between">
                          <span>{item.name}</span>
                          {item && item.bulkUpload && (
                            <div className={"bulk-ribbon blue"}>
                              {t(`${translationPath}bulk`)}
                            </div>
                          )}
                        </div>
                        {item && item.isFavorite && (
                          <div
                            className={
                              folderIdValue
                                ? "is-Fav-heart lead"
                                : "is-Fav-heart lead"
                            }
                            onClick={() => handleRemoveFavorite(item)}
                          >
                            <span className="mdi mdi-heart"></span>
                          </div>
                        )}
                        <div className="lead-id">
                          <span className="mb-1">
                            {t(
                              `${translationPath}${
                                item &&
                                item.leadClass &&
                                item.leadClass.toLowerCase()
                              }`
                            )}
                          </span>
                          {item && item.averageLeadScore && (
                            <span className="mb-1">
                              <span className="details-text">
                                {t(`${translationPath}Lead-score`)}:
                              </span>
                              {item && item.averageLeadScore}
                            </span>
                          )}{" "}
                          <div className="id">
                            <span className="details-text">
                              {/* <span className='details-icon mdi mdi-minus mdi-18px' /> */}
                              {t(`${translationPath}ID`)}:
                            </span>
                            <Tooltip title={t(`${translationPath}copy`)}>
                              <CopyToClipboardComponents
                                data={item && item.id}
                                childrenData={item && item.id}
                              />
                            </Tooltip>
                          </div>
                        </div>

                        <div className="details-item-wrapper">
                          <span className="details-icon mdi mdi-calendar mdi-18px" />
                          <span>
                            <span className="details-text">
                              {t(`${translationPath}created`)}:
                            </span>
                            <span className="px-1">
                              {(item.createdOn &&
                                moment(item.createdOn)
                                  .locale(i18next.language)
                                  .format("DD/MM/YYYY")) ||
                                "N/A"}
                            </span>
                          </span>
                        </div>

                        <div className="body-card-tooltip-wrapper">
                          <span
                            className={`card-tooltip mx-0${
                              (((item.status &&
                                item.status.lookupItemName &&
                                LeadsStatusEnum[item.status.lookupItemName] &&
                                LeadsStatusEnum[item.status.lookupItemName]
                                  .value) ||
                                "N/A") === "closed" &&
                                " bg-danger c-white") ||
                              ""
                            }`}
                          >
                            <span>
                              {t(
                                `${translationPath}${
                                  (item.status &&
                                    item.status.lookupItemName &&
                                    item.status.lookupItemName) ||
                                  "N/A"
                                }`
                              )}
                            </span>
                          </span>
                          {(item.status &&
                            item.status.lookupItemName ===
                              LeadsStatusEnum.Closed.key &&
                            item &&
                            (item.leadClass.toLowerCase() ===
                              LeadsClassTypesEnum.tenant.value ||
                              item.leadClass.toLowerCase() ===
                                LeadsClassTypesEnum.seller.value ||
                              item.leadClass.toLowerCase() ===
                                LeadsClassTypesEnum.landlord.value ||
                              item.leadClass.toLowerCase() ===
                                LeadsClassTypesEnum.buyer.value) && (
                              <PermissionsComponent
                                permissionsList={Object.values(
                                  LeadsCAllCenterPermissions
                                )}
                                permissionsId={
                                  LeadsCAllCenterPermissions.CloneLead
                                    .permissionsId
                                }
                              >
                                <div className="Clone-bbt mx-3">
                                  <Tooltip title={t(`${translationPath}clone`)}>
                                    <Fab
                                      size="small"
                                      aria-label={t(`${translationPath}clone`)}
                                      label={t(`${translationPath}clone`)}
                                      onClick={() => {
                                        setIsOpen(true);
                                        setIsActiveIteam(item);
                                      }}
                                    >
                                      <span className="mdi mdi-animation-outline mdi-18px" />
                                    </Fab>
                                  </Tooltip>
                                </div>
                              </PermissionsComponent>
                            )) ||
                            ""}
                          {(item?.rating?.lookupItemName && (
                            <>
                              <span
                                className={
                                  LeadsPriorityEnum[
                                    item.rating.lookupItemName
                                  ] &&
                                  `card-tooltip mx-2 ${
                                    item.rating.lookupItemName || ""
                                  }`
                                }
                              >
                                <span
                                  className={
                                    LeadsPriorityEnum[
                                      item.rating.lookupItemName
                                    ] &&
                                    LeadsPriorityEnum[
                                      item.rating.lookupItemName
                                    ].icon
                                  }
                                />
                                <span className="px-1">
                                  {t(
                                    `${translationPath}${item.rating.lookupItemName}`
                                  )}
                                </span>
                              </span>
                            </>
                          )) || <span />}
                          {(item?.qualification?.lookupItemName && (
                            <>
                              <span
                                className={
                                  `${
                                    LeadsQualificationEnum[
                                      item.qualification.lookupItemName
                                    ]?.classes || ""
                                  } mx-1` || ""
                                }
                              >
                                {t(
                                  `${translationPath}${
                                    LeadsQualificationEnum[
                                      item.qualification.lookupItemName
                                    ]?.name || ""
                                  }`
                                )}
                              </span>
                            </>
                          )) || <span />}
                        </div>
                        <span className="details-text">
                          {/* <span className='details-icon mdi mdi-minus mdi-18px' /> */}
                          {t(`${translationPath}stage`)}:
                          {` ${
                            item.lead_stage && item.lead_stage.lookupItemName
                              ? item.lead_stage.lookupItemName
                              : "N/A"
                          }`}
                        </span>
                        <span className="details-text">
                          {/* <span className='details-icon mdi mdi-minus mdi-18px' /> */}
                          {t(`${translationPath}referred-to`)}:
                          {` ${
                            item.referredto && item.referredto
                              ? item.referredto.name
                              : "N/A"
                          }`}
                        </span>
                        <Tooltip
                          title={`${t(`${translationPath}campaign_name`)} : ${
                            item.campaign_name ? item.campaign_name.name : "N/A"
                          }`}
                        >
                          <span className="details-text-style">
                            {t(`${translationPath}campaign_name`)}:
                            {` ${
                              item.campaign_name
                                ? item.campaign_name.name
                                : "N/A"
                            }`}
                          </span>
                        </Tooltip>
                        <Tooltip
                          title={`${t(`${translationPath}property_name`)} : ${
                            item.property_name && item.property_name.name
                              ? item.property_name.name
                              : "N/A"
                          }`}
                        >
                          <span className="details-text property_name">
                            {/* <span className='details-icon mdi mdi-minus mdi-18px' /> */}
                            {t(`${translationPath}property_name`)}:
                            {` ${
                              item.property_name && item.property_name.name
                                ? item.property_name.name
                                : "N/A"
                            }`}
                          </span>
                        </Tooltip>
                        {item && item.unit_ref_number
                          ? item &&
                            item.unit_ref_number &&
                            item.unit_ref_number.name && (
                              <span className="details-text mt-1 hide-textarea ">
                                {t(`${translationPath}ref-no`)}:
                                <Tooltip title={t(`${translationPath}copy`)}>
                                  <CopyToClipboardComponents
                                    data={
                                      item &&
                                      item.unit_ref_number &&
                                      item.unit_ref_number.name
                                    }
                                    childrenData={
                                      item &&
                                      item.unit_ref_number &&
                                      item.unit_ref_number.name
                                    }
                                  />
                                </Tooltip>
                              </span>
                            )
                          : ""}
                        <span className="details-text mt-1">
                          {t(`${translationPath}unit-type`)}:
                          {` ${(item && item.unitType) || "N/A"} `}
                        </span>
                      </div>
                      {item.flatContent && item.flatContent.length > 0 && (
                        <div className="flat-contents-wrapper">
                          {item.flatContent
                            .filter(
                              (filterItem, filterIndex) =>
                                (!isExpanded && filterIndex < notExpandedMax) ||
                                isExpanded
                            )
                            .map((subItem, subIndex) => (
                              <div
                                key={`flatContentsItemRef${subIndex + 1}`}
                                className="flat-content-item"
                              >
                                <span
                                  className={`flat-content-icon ${subItem.iconClasses} mdi-18px`}
                                />
                                <span className="px-1">{subItem.value}</span>
                                <span>
                                  {subItem.title && (
                                    <span className="flat-content-text">
                                      {t(`${translationPath}${subItem.title}`)}
                                    </span>
                                  )}
                                </span>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="cards-progress-wrapper">
                    <ProgressComponet
                      value={item.progress}
                      progressText={`${item.progress}%`}
                      themeClasses="theme-gradient"
                    />
                  </div>
                  <div className="cards-footer-wrapper">
                    <ButtonBase
                      className="btns theme-transparent mx-0"
                      onClick={onFooterActionsClicked(
                        ActionsEnum.folder.key,
                        item,
                        index
                      )}
                      id="ActionsClickedfolder"
                    >
                      <span className="mdi mdi-folder-open-outline" />
                      <span className="px-1">{t("open-file")}</span>
                    </ButtonBase>
                    {(item &&
                      item.leadClass &&
                      item.leadClass.toLowerCase() ===
                        LeadsClassTypesEnum.buyer.value &&
                      item.status.lookupItemName ===
                        LeadsStatusEnum.Open.key && (
                        <ButtonBase
                          className="btns theme-transparent mx-0"
                          onClick={onFooterActionsClicked(
                            ActionsEnum.matching.key,
                            item,
                            index
                          )}
                        >
                          {" "}
                          <span className={ActionsEnum.matching.icon} />
                          <span className="px-2">
                            {" "}
                            {item.matchingUnitsNumber ||
                              t(`${translationPath}zero-matching`)}{" "}
                          </span>
                          <span>
                            {item.matchingUnitsNumber
                              ? t(ActionsEnum.matching.label)
                              : ""}
                          </span>
                        </ButtonBase>
                      )) || <div />}
                  </div>
                </a>
              </div>
            )}
          </>
        ))}
      {isOpen && (
        <CloneLeadView
          relodedata={() => {
            reloadDataAfterClone();
          }}
          isOpen={isOpen}
          ActiveIteam={isActiveIteam}
          isOwnerLeadClone={isOwnerLeadClone}
          reset={resetAllValues}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
};

LeadsCardsComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  activeCard: PropTypes.instanceOf(Object),
  selectedDetailsLeadItem: PropTypes.instanceOf(Object),
  isExpanded: PropTypes.bool.isRequired,
  withCheckbox: PropTypes.bool.isRequired,
  onCardClicked: PropTypes.func.isRequired,
  relodedata: PropTypes.func.isRequired,
  onFooterActionsClicked: PropTypes.func.isRequired,
  onActionClicked: PropTypes.func.isRequired,
  onCardCheckboxClick: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  notExpandedMax: PropTypes.number,
  isCheckBoxDisabled: PropTypes.bool,
  selectedCards: PropTypes.instanceOf(Array),
};
LeadsCardsComponent.defaultProps = {
  notExpandedMax: 5,
  selectedDetailsLeadItem: undefined,
  activeCard: undefined,
  isCheckBoxDisabled: false,
  selectedCards: undefined,
};
