import React, { createContext, useState, useEffect, useCallback } from "react";

// Create the context
export const ContactLayoutContext = createContext();

// Create the provider component
export const AdvancedSearchProvider = ({ children }) => {
  return (
    <ContactLayoutContext.Provider value={{}}>
      {children}
    </ContactLayoutContext.Provider>
  );
};
