import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Spinner } from '../../../Components';
import {
  BulkAssignActions,
  BulkSelectedPropertiesSection,
  NewValuesSection,
  PropertyFieldsSection,
} from './Sections';
import { GetAllFormFieldsByFormId } from '../../../Services';
import { FormsIdsEnum } from '../../../Enums';
import { useLocalStorage } from '../../../Hooks';
import {
  FormErrorsHandler,
  GlobalHistory,
  formItemsBuilderV3,
} from '../../../Helper';
const parentTranslationPath = 'BulkUpdateProperties';
const translationPath = '';

const relatedFormFields = {
  city: { parentFormFieldId: 'country' },
  district: { parentFormFieldId: 'city' },
  community: { parentFormFieldId: 'district' },
  sub_community: { parentFormFieldId: 'community' },
};
export const PropertiesBulkUpdateView = () => {
  const [bulkedProperties] = useLocalStorage('bulk-assign-ids');
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [formFieldIds, setFormFieldIds] = useState(formFields);
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState([]);
  const [propertyCards, setPropertyCards] = useState(bulkedProperties);
  const [propertyInitDetails, setPropertyInitDetails] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [propertyStatus, setPropertyStatus] = useState({
    failure: [],
    success: [],
  });
  const [formFieldsData, setFormFieldsData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (bulkedProperties && bulkedProperties.length < 2) GlobalHistory.goBack();
  }, [bulkedProperties]);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const defaultState = {
    propertyIds: [],
    fieldsName: [],
    updatedPropertyJson: {},
  };

  const [state, setState] = useReducer(reducer, defaultState);
  useEffect(() => {
    const properties = [];
    const fieldsNames = [];
    propertyInitDetails.map((item) => {
      if (!propertyDetails[item]) propertyDetails[item] = null;
    });

    properties.map((item, index) => {
      if (propertyStatus?.success.length > 0) properties.splice(index, 1);
    });

     formFieldIds.map((item, index) => {
       if (item.formFieldKey && item.formFieldKey === "property_owner")
         fieldsNames.push("master_developer");
       fieldsNames.push(item.formFieldName);
     });

     setState({ id: "updatedPropertyJson", value: propertyDetails });
     setState({ id: "fieldsName", value: fieldsNames });
    setState({
      id: 'propertyIds',
      value: propertyCards.map((property) => property.id),
    });
  }, [
    formFieldIds,
    propertyCards,
    propertyDetails,
    propertyInitDetails,
    propertyStatus,
  ]);
  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAllFormFieldsByFormId(FormsIdsEnum.properties.id);
    if (!(result?.status && result?.status !== 200)) {
      const listWithoutUpdateSameFields = result?.filter(
        (item) =>
          item.formFieldKey !== 'operation_type' &&
          item.formFieldKey !== 'owner' &&
          item.formFieldKey !== 'amount_due-to-developer' &&
          item.formFieldKey !== 'pricesqm' &&
          item.formFieldKey !== 'property_ref-no'
      );
      setFormFields(listWithoutUpdateSameFields || []);
    } else setFormFields([]);
    setIsLoading(false);
  }, []);

  const onPropertiesCardsChange = (value) => {
    setPropertyCards(value);
  };

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);

  useEffect(() => {
    setFormData(formItemsBuilderV3([], formFieldsData));
  }, [formFieldsData]);

  useEffect(() => {
    if (formData.length > 0)
      setErrors(FormErrorsHandler(formData, propertyDetails));
  }, [propertyDetails, formData]);

  return (
    <>
      <div className='px-5 units-bulk-assign-view-wrapper'>
        <Spinner isActive={isLoading} />
        <div className='bulk-section-wrapper'>
          <BulkSelectedPropertiesSection
            propertyCards={propertyCards}
            propertyStatus={propertyStatus}
            bulkedProperties={bulkedProperties}
            onPropertiesCardsChange={onPropertiesCardsChange}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>
        <span className='section-seperator mdi mdi-arrow-collapse-right c-gray' />
        <div className='bulk-section-wrapper'>
          <PropertyFieldsSection
            formFields={formFields.map((item) => ({
              ...item,
              parentFormFieldId:
                relatedFormFields[item.formFieldKey]?.parentFormFieldId,
            }))}
            setFormFieldsData={setFormFieldsData}
            propertyDetails={propertyDetails}
            setPropertyInitDetails={setPropertyInitDetails}
            formFieldIds={formFieldIds}
            setFormFieldIds={setFormFieldIds}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>
        <span className='section-seperator mdi mdi-arrow-collapse-right c-gray' />
        <div className='bulk-section-wrapper'>
          <NewValuesSection
            errors={errors}
            formData={formData}
            propertyDetails={propertyDetails}
            isSubmitted={isSubmitted}
            setFormData={setFormData}
            setPropertyDetails={setPropertyDetails}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            relatedFormFields={relatedFormFields}
          />
        </div>
      </div>

      <BulkAssignActions
        setIsSubmitted={setIsSubmitted}
        state={state}
        errors={errors}
        setIsLoading={setIsLoading}
        propertyStatus={propertyStatus}
        setPropertyStatus={setPropertyStatus}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </>
  );
};
