import React, { useEffect, useState, useReducer, useCallback } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError, showSuccess } from "../../../../Helper";
import { MicrosoftToken, lookupItemsGetId } from "../../../../Services";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import {
  AutocompleteComponent,
  Inputs,
  SelectComponet,
} from "../../../../Components";
import { ActionsButtonsEnum, MediaEnum, Status } from "../../../../Enums";
import { useTranslation } from "react-i18next";
import { OrganizationUserSearch } from "../../../../Services/userServices";
import { useSelector, useDispatch } from "react-redux";
import { GlobalOrderFilterActions } from "../../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import { ButtonBase } from "@material-ui/core";

const parentTranslationPath = "LeadsView";
const translationPath = "";

export const CeoDashboard = ({ dynamicComponentCEOProps }) => {
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);

  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();

  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [DateAppaer, setDateAppaer] = useState(false);
  const [Datetime, setDatetime] = useState(30);
  const [leadType, setLeadType] = useState();
  const [activeSelectedAction, setActiveSelectedAction] = useState(
    ActionsButtonsEnum[1].id
  );
  const [Leadtype, setLeadtype] = useState();
  const [Leadtypestatus, setLeadtypestatus] = useState(true);
  const [OperatorLeadType, setOperatorLeadType] = useState("All");

  const [LeadClass, setLeadClass] = useState();
  const [LeadClassstatus, setLeadClassstatus] = useState(true);
  const [OperatorLeadClass, setOperatorLeadClass] = useState("All");

  const [allReferred, setAllReferred] = useState([]);
  const [referred, setReferred] = useState({
    by: (orderFilter && orderFilter.ReferredByLeadTypeFilter) || null,
    to: (orderFilter && orderFilter.ReferredToLeadTypeFilter) || null,
    mediaDetails:
      (orderFilter && orderFilter.MediaDetailsLeadTypeFilter) || null,
  });

  const [refby, setrefby] = useState();
  const [refbystatus, setrefbystatus] = useState(true);
  const [Operatorrefby, setOperatorrefby] = useState("All");

  const [refto, setrefto] = useState();
  const [reftostatus, setreftostatus] = useState(true);
  const [Operatorrefto, setOperatorrefto] = useState("All");

  const [Leadstatus, setLeadstatus] = useState();
  const [Leadstatusstatus, setLeadstatusstatus] = useState(true);
  const [OperatorLeadstatus, setOperatorLeadstatus] = useState("All");

  const [Media, setMedia] = useState();
  const [Mediastatus, setMediastatus] = useState(true);
  const [OperatorMedia, setOperatorMedia] = useState("All");

  const [ContactName, setContactName] = useState();
  const [Namestatus, setNamestatus] = useState(true);
  const [OperatoName, setOperatoName] = useState("All");

  const [allMediaName, setAllMediaName] = useState([]);

  const [datefilters, setdatefilter] = useState(true);

  const [isLoading, setIsLoading] = useState({
    referred: false,
    mediaDetails: false,
  });
  const [monthsAgo, setMonthsAgo] = useState(1); 

const currentDate = new Date();
const countmonth = new Date(currentDate.setMonth(currentDate.getMonth() - monthsAgo));

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate() - 7));
  const count2 = new Date(new Date().setDate(today.getDate()));

  const datetimes = moment(count).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: startDate || null,
    endDate: endDate || null,
    key: "selection",
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes,
        toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes2,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const onClearedAllFiltersClick = useCallback(() => {
    setDateFilter(dateRangeDefault);
    setLeadtype("");
    setrefto("");
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        ReferredByLeadTypeFilter: {
          id: "",
          fullName: "",
        },

        ReferredToLeadTypeFilter: {
          id: "",
          fullName: "",
        },
        MediaDetailsLeadTypeFilter: {
          lookupItemId: "",
          lookupItemName: "",
        },
      })
    );
  }, []);

  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "LeadsView",
      column: datefilters ? "CreatedOn" : "LeadAssignDate",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  const FilterLeadType = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "LeadsView",
      column: "LeadsType",
    },
    operator: OperatorLeadType,
    values: [Leadtype],
    filterType: models.FilterType.BasicFilter,
  };
  const FilterName = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "LeadsView",
      column: "Contact Name",
    },
    operator: OperatoName,
    values: [ContactName],
    filterType: models.FilterType.BasicFilter,
  };
  const FilterRefby = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "LeadsView",
      column: "referredbyID",
    },
    operator: Operatorrefby,
    values: [refby],
    filterType: models.FilterType.BasicFilter,
  };
  const FilterRefto = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "LeadsView",
      column: "referredtoID",
    },
    operator: Operatorrefto,
    values: [refto],
    filterType: models.FilterType.BasicFilter,
  };
  const FilterLeadClass = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "LeadsView",
      column: "LeadClass",
    },
    operator: OperatorLeadClass,
    values: [LeadClass],
    filterType: models.FilterType.BasicFilter,
  };
  const FilterLeadStatus = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "LeadsView",
      column: "StatusId",
    },
    operator: OperatorLeadstatus,
    values: [Leadstatus],
    filterType: models.FilterType.BasicFilter,
  };
  const FilterMedia = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Lookups",
      column: "LookupItemName",
    },
    operator: OperatorMedia,
    values: [Media],
    filterType: models.FilterType.BasicFilter,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };

  const getAllReferred = useCallback(async (searchValue) => {
    setIsLoading((item) => ({ ...item, referred: true }));

    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue,
      userStatusId: Status.Active.value,
    });
    if (!(res && res.status && res.status !== 200)) {
      setAllReferred({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllReferred({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((item) => ({ ...item, referred: false }));
  }, []);

  const getAllMediaName = useCallback(async () => {
    // setIsLoading(true);
    setIsLoading((item) => ({ ...item, mediaDetails: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: MediaEnum.MediaDetailsId.lookupTypeId,
    });
    if (!(res && res.status && res.status !== 200)) setAllMediaName(res);
    else setAllMediaName([]);
    // setIsLoading(false);
    setIsLoading((item) => ({ ...item, mediaDetails: false }));
  }, []);

  useEffect(() => {
    Microsoft(false);
  }, []);
  useEffect(() => {
    if (Leadtypestatus) setOperatorLeadType("All");
    else setOperatorLeadType("In");
    if (LeadClassstatus) setOperatorLeadClass("All");
    else setOperatorLeadClass("In");
    if (refbystatus) setOperatorrefby("All");
    else setOperatorrefby("In");
    if (reftostatus) setOperatorrefto("All");
    else setOperatorrefto("In");
    if (Leadstatusstatus) setOperatorLeadstatus("All");
    else setOperatorLeadstatus("In");
    if (Mediastatus) setOperatorMedia("All");
    else setOperatorMedia("In");
    if (Namestatus) setOperatoName("All");
    else setOperatoName("In");
  }, [
    Leadtype,
    Leadtypestatus,
    LeadClassstatus,
    LeadClass,
    refbystatus,
    reftostatus,
    Leadstatusstatus,
    Mediastatus,
    Namestatus,
  ]);

    
  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
   
        console.log(true);
      })
      .catch((error) => {
        console.log(false);
      });
  }, []);

  useEffect(() => {
    getAllReferred();
    getAllMediaName();
  }, []);

  return (
    <div className="dashboardMain-PowerBIEmbed">
      <SpinnerDashboards isActive={!ShowReport} isAbsolute />

      {DateAppaer && (
        <div className="MainFiltersBi">
          <div className="selecterbi">
            <div>
              <SelectComponet
                data={[
                  { id: 1, name: "owner" },
                  { id: 2, name: "seeker" },
                ]}
                emptyItem={{
                  text: "select-category",
                  isDisabled: false,
                }}
                valueInput="id"
                textInput="name"
                onSelectChanged={(value) => {
                  if (value == undefined) {
                    setLeadtypestatus(true);
                  } else {
                    setLeadtype(value);
                    setLeadtypestatus(false);
                  }

                }}
                wrapperClasses="w-auto"
                themeClass="theme-transparent"
                idRef="Select_Category"
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
            <div>
              <SelectComponet
                data={[
                  { id: 457, name: "open" },
                  { id: 458, name: "closed" },
                ]}
                emptyItem={{ text: "select-status", isDisabled: false }}
                valueInput="id"
                textInput="name"
                onSelectChanged={(value) => {
                  if (value == undefined) {
                    setLeadstatusstatus(true);
                  } else {
                    setLeadstatus(value);
                    setLeadstatusstatus(false);
                  }
                }}
                wrapperClasses="w-auto"
                themeClass="theme-transparent"
                idRef="Lead_Status"
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
            <div>
              <SelectComponet
                data={[
                  { id: 1, name: "seller" },
                  { id: 2, name: "landlord" },
                  { id: 3, name: "buyer" },
                  { id: 4, name: "tenant" },
                ]}
                emptyItem={{
                  text: "select-lead-type",
                  isDisabled: false,
                }}
                valueInput="id"
                textInput="name"
                onSelectChanged={(value) => {
                  if (value == undefined) {
                    setLeadClassstatus(true);
                  } else {
                    setLeadClass(value);
                    setLeadClassstatus(false);
                  }

                }}
                wrapperClasses="w-auto"
                themeClass="theme-transparent"
                idRef="Lead_Type"
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
              {/* <div>
                      <Inputs
           
                        idRef='activitiesSearchRef'
                        labelClasses='mt-4'
                        onInputChanged={(e) => {
                          if (e.target.value == '') {
                            setNamestatus(true);
                          } else {
                            setContactName(e.target.value)
                            setNamestatus(false);
                          }
                          console.log(e.target.value)

                        }
                          
                          
                        
                        
                        }
                        inputPlaceholder={t(`${translationPath}name`)}
                      />
                    </div> */}
            </div>
          </div>
          <div className="selecterbi">
            <div>
              <AutocompleteComponent
                idRef="ReferredToRef"
                inputPlaceholder={t(`${translationPath}ReferredTo`)}
                data={(allReferred && allReferred.result) || []}
                selectedValues={referred.to}
                getOptionSelected={(option) =>
                  option.id ===
                    (orderFilter.ReferredToLeadTypeFilter &&
                      orderFilter.ReferredToLeadTypeFilter.id) || ""
                }
                onInputChange={(e) => {
                  if (e && e.target && e.target.value)
                    getAllReferred(e.target.value || "");
                  if (e && e.target && e.target.value == undefined) {
                    setreftostatus(true);
                  } else {
                    setreftostatus(false);
                  }
                }}
                multiple={false}
                displayLabel={(option) => (option && option.fullName) || ""}
                chipsLabel={(option) => (option && option.fullName) || ""}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  dispatch(
                    GlobalOrderFilterActions.globalOrderFilterRequest({
                      ...orderFilter,
                      ReferredToLeadTypeFilter:
                        {
                          id: (newValue && newValue.id) || "",
                          fullName: (newValue && newValue.fullName) || "",
                        } || "",
                    })
                  );
                  setReferred((item) => ({ ...item, to: newValue || null }));
                  setrefto(newValue.id);
                  setreftostatus(false);
                }}
              />
            </div>
            <div>
              <AutocompleteComponent
                idRef="ReferredByRef"
                inputPlaceholder={t(`${translationPath}ReferredBy`)}
                isLoading={isLoading.referred}
                selectedValues={referred.by}
                getOptionSelected={(option) =>
                  option.id ===
                    (orderFilter.ReferredByLeadTypeFilter &&
                      orderFilter.ReferredByLeadTypeFilter.id) || ""
                }
                data={(allReferred && allReferred.result) || []}
                onInputChange={(e) => {
                  if (e && e.target && e.target.value)
                    getAllReferred(e.target.value || "");

                  if (e && e.target && e.target.value == undefined) {
                    setrefbystatus(true);
                  } else {
                    setrefbystatus(false);
                  }
                }}
                multiple={false}
                displayLabel={(option) => (option && option.fullName) || ""}
                chipsLabel={(option) => (option && option.fullName) || ""}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  dispatch(
                    GlobalOrderFilterActions.globalOrderFilterRequest({
                      ...orderFilter,
                      ReferredByLeadTypeFilter:
                        {
                          id: (newValue && newValue.id) || "",
                          fullName: (newValue && newValue.fullName) || "",
                        } || "",
                    })
                  );
                  setReferred((item) => ({ ...item, by: newValue || "" }));
                  setrefby(newValue.id);
                  setrefbystatus(false);
                }}
              />
            </div>
            <div>
              <AutocompleteComponent
                idRef="MediaDetailsRef"
                isLoading={isLoading.mediaDetails}
                inputPlaceholder={t(`${translationPath}MediaDetails`)}
                selectedValues={referred.mediaDetails}
                getOptionSelected={(option) =>
                  option.lookupItemId ===
                    (orderFilter.MediaDetailsLeadTypeFilter &&
                      orderFilter.MediaDetailsLeadTypeFilter.lookupItemId) || ""
                }
                data={allMediaName || []}
                displayLabel={(option) =>
                  (option && option.lookupItemName) || ""
                }
                chipsLabel={(option) => (option && option.lookupItemName) || ""}
                onInputChange={(e) => {
                  if (e && e.target && e.target.value)
                    getAllReferred(e.target.value || "");
                  if (e && e.target && e.target.value == undefined) {
                    setMediastatus(true);
                  } else {
                    setMediastatus(false);
                  }
                }}
                multiple={false}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  dispatch(
                    GlobalOrderFilterActions.globalOrderFilterRequest({
                      ...orderFilter,
                      MediaDetailsLeadTypeFilter:
                        {
                          lookupItemId:
                            (newValue && newValue.lookupItemId) || "",
                          lookupItemName:
                            (newValue && newValue.lookupItemName) || "",
                        } || "",
                    })
                  );
                  setReferred((item) => ({
                    ...item,
                    mediaDetails: newValue || null,
                  }));

                  setMedia(newValue.lookupItemName);
                  setMediastatus(false);
                }}
              />
            </div>
          </div>
          <div className="selecterbi">
            <div>
              <SelectComponet
                data={[{ key: 1, value: `${translationPath}last-assign-date` }]}
                emptyItem={{
                  text: `${translationPath}created-date`,
                  isDisabled: false,
                }}
                valueInput="key"
                textInput="value"
                onSelectChanged={(value) => {
                  if (value == undefined) {
                    setdatefilter(true);
                  } else {
                    setLeadClass(value);
                    setdatefilter(false);
                  }

                }}
                themeClass="theme-transparent"
                wrapperClasses="w-auto"
                idRef="Date_Select"
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
            <div>
              <DateRangePickerComponent
                onClearClicked={() => {
                  setDateFilter({
                    startDate: startDate,
                    endDate: endDate,
                    key: "selection",
                  });
                }}
                ranges={[dateFilter]}
                onDateChanged={(selectedDate) => {
                  setDateFilter({
                    startDate:
                      selectedDate.selection &&
                      selectedDate.selection.startDate,
                    endDate: new Date(
                      moment(
                        selectedDate.selection && selectedDate.selection.endDate
                      ).endOf("day")
                    ),
                    key: "selection",
                  });
                }}
              />
            </div>
            <div >
            <ButtonBase
                onClick={handleRefreshClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-success clear-all-btn"
              >
                <span className="mdi mdi-chart-arc m-1" />
                Refresh
              </ButtonBase>
            </div>
          </div>
        </div>
      )}
      {render && (
        <div className="dashboardMain">
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: dynamicComponentCEOProps.config.reportId,
              embedUrl: dynamicComponentCEOProps.config.Url,
              accessToken: report,
              filters: [
                advancedFilter,
                FilterLeadType,
                FilterLeadClass,
                FilterRefby,
                FilterRefto,
                FilterLeadStatus,
                FilterMedia,
                // FilterName,
              ],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  (event) => {
                    setShowReport(true);
                    setDateAppaer(true);
                    // console.info("Report loaded");
                  },
                ],
                [
                  "dataSelected",

                  (event) => {
                    // console.log("dataSelected");
                    // console.log(event)
                  },
                ],

                [
                  "rendered",
                  (event) => {
                    // console.log(event.type)
                    // console.log(event)
                    showSuccess("Loaded Succssfuly");
                    // console.info("Report rendered");
                  },
                ],

                [
                  "error",
                  (event) => {
                    const error = event.detail;
                    if (
                      error.detailedMessage === "Get report failed" ||
                      error.detailedMessage ===
                        "Access token has expired, resubmit with a new access token" ||
                      error.detailedMessage ===
                        "Fail to initialize - Could not resolve cluster"
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport ? "report-style-class-Show" : "report-style-class-hide"
            }
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
      )}
    </div>
  );
};
