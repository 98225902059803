import React from "react";
import { AddCondationLeadForm } from "./AddCondationLeadForm/AddCondationLeadForm";

export const CreateLeadForm = ({
  LeadFormData,
  setLeadFormData,
  data,
  SelectedData,
}) => {
  return (
    <div className="FilterSectionView-view-wrapersAddCondationLeadForm">
      <div className="mian-FilterSectionView">
        <AddCondationLeadForm
          LeadFormData={LeadFormData}
          setLeadFormData={setLeadFormData}
          data={data}
          SelectedData={SelectedData}
        />
      </div>
    </div>
  );
};
