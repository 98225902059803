import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  PageTitle,
  CustomButton,
  SummaryCardList,
  SummaryCardListSkeleton,
  ContactProfile,
  ContactCardSkeleton,
  TableSkeleton,
  CustomSnackbar,
  ContactCard,
  BasicTable,
  CustomPagination,
  VIEW_MODES,
  CustomTableHeader,
  ContactsFilterBar,
  SaveFilterModal,
  TableActions,
  AddContactModal,
  ImportContactModal,
  ConfirmationDialog,
  NoDataFound,
  CustomPermission,
  AddContact,
  CustomDialog,
  DuplicatedContactCard,
} from "../../../Components";
import {
  useIsDesktop,
  useSelectedTheme,
  useTitle,
  useTranslate,
} from "../../../Hooks";
import { newContactsTableHeaderData } from "./NewContactsTableHeaderData";
import { FormsIdsEnum } from "../../../Enums";
import {
  GetAllFormFieldsByFormId,
  GetContactsDuplicationCriteria,
  PullContactOpportunity,
  GetAllSearchableFormFieldsByFormId,
  GetAdvanceSearchContacts,
} from "../../../Services";
import { GetAllFavoriteFolders } from "../../../Services/FavoriteFoldersServices";
import { flattenObject } from "../../../Helper";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import { QuickFilterSection } from "./UI";
import { ContactsMapper } from "../ContactsView";
import LoadingSpinner, {
  LoadingSpinnerSize,
  LoadingSpinnerStyle,
} from "../../../Components/V2/LoadingSpinner";
import { ContactsPermissions } from "../../../Permissions";
import { useNewLayoutShared } from "../../../Contexts/NewLayoutSharedContext";

// Icons
import { UploadIcon, PlusIcon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

function NewContactsView() {
  const styles = useStyles();
  const {
    setAlertBoxContent,
    setStickyBottomBox,
    mainLayoutRef,
    addNewContact,
    setAddNewContact,
    duplicatedContact,
    setDuplicatedContact,
    actionableItems,
    setActionableItems,
  } = useVerticalNav();

  const { isDesktop } = useIsDesktop();

  const dropPagination = useRef(null);
  const stickyPagination = useRef(null);
  const showMoreRef = useRef();

  const { translate } = useTranslate("NewContactsView");
  const { translate: sharedTranslate } = useTranslate("ContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const { isDarkMode } = useSelector((state) => state.theme);

  const {
    contactsData,
    pagination,
    setPagination,
    isLoading,
    filterModalData,
    savedFiltersCriteria,
    setContactsData,
    advancedSearchBody,
    setAdvancedSearchBody,
  } = useNewLayoutShared()

  const [inputValue, setInputValue] = useState(pagination.currentPage);

  const [filterItems, setFilterItems] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState(false);

  const [nextPage, setNextPage] = useState(pagination.currentPage);

  const flattenRows = (rows) => rows.map((row) => flattenObject(row));
  const flattenedRows = flattenRows([...contactsData?.result]);

  useTitle(translate("CONTACTS_PAGE_TITLE"));

  // Modify the fetchMoreContacts logic to append data instead of replacing it
  const fetchMoreContacts = async () => {
    setIsLoadMore(true);

    const totalItems = contactsData.totalCount;

    // Check if we've reached the end of data
    if (contactsData?.result?.length >= totalItems) {
      setIsLoadMore(false); // Stop loading if all items are fetched
      return;
    }

    if (!isLoadMore) {
      try {
        const newContactsData = await GetAdvanceSearchContacts(
          { pageIndex: nextPage, pageSize: pagination.itemsPerPage },
          advancedSearchBody
        );

        if (newContactsData && newContactsData.result.length > 0) {
          const mappedNewContacts = newContactsData.result
            .map((item) =>
              item.contactJson
                ? ContactsMapper(item, JSON.parse(item.contactJson).contact)
                : null
            )
            .filter(Boolean);

          // Append new data to existing contactsData
          setContactsData((prevData) => ({
            ...prevData,
            result: [...prevData.result, ...mappedNewContacts], // Append new contacts to the existing list
          }));

          // Manually increment the next page number
          setNextPage(nextPage + 1); // Increment for future API requests
        }
      } catch (error) {
        console.error("Error fetching contacts: ", error);
      } finally {
        setIsLoadMore(false);
      }
    }
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [activeItem, setActiveItem] = useState({});
  const [viewMode, setViewMode] = useState(VIEW_MODES.GRID);
  const [isBulkUpdateModal, setIsBulkUpdateModal] = useState(false);
  const [importContactModalOpened, setImportContactModalOpened] =
    useState(false);

  const [isFavoriteDialogOpen, setIsFavoriteDialogOpen] = useState(false);
  const [favoriteFolders, setFavoriteFolders] = useState([]);
  const [isFavoriteFoldersLoading, setIsFavoriteFoldersLoading] =
    useState(true);

  const onChangeViewMode = (value) => {
    setViewMode(value); // Update view mode

    if (viewMode !== value) {
      setActionableItems({
        selectedIds: [],
        action: null,
      });
    }
  };

  /////////////////////////////////////////////////////////

  const handleFilterConfirm = (
    params,
    title,
    inputValue,
    selectedValue,
    inputNameValue,
    selectedNameValue
  ) => {
    const field = params?.field || params?.colDef?.field;

    setAdvancedSearchBody((prevBody) => {
      const criteria = { ...prevBody.criteria };

      // Update criteria based on normal fields and special fields like createdBy/updatedBy
      criteria[field] = [{ searchType: selectedValue, value: inputValue }];

      const relatedField =
        title === "Creation Date"
          ? "createdBy"
          : title === "Updated Date"
          ? "updatedBy"
          : null;

      if (relatedField) {
        criteria[relatedField] = [
          { searchType: selectedNameValue, value: inputNameValue },
        ];
      }

      return { ...prevBody, criteria };
    });
  };

  const [allFormFields, setAllFormFields] = useState([]);
  const [tableColumns, setTableColumns] = useState(
    newContactsTableHeaderData(isDarkMode)
  );
  const [updateSelectedColumnItems, setUpdateSelectedColumnItems] =
    useState(tableColumns);
  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState(
    newContactsTableHeaderData(isDarkMode)
      .filter((item) => item.isDefaultFilterColumn)
      .map((column) => column.id)
  );

  const getAllSearchableFormFieldsByFormId = useCallback(
    async (filterFormType) => {
      const result = await GetAllSearchableFormFieldsByFormId(filterFormType);

      if (!((result && result.data && result.data.ErrorId) || !result)) {
        let list = [];

        const res =
          (Array.isArray(result) &&
            result.map((field) => ({
              ...field,
              renderHeader: (params) => (
                <CustomTableHeader
                  params={undefined}
                  title={
                    (field?.formFieldTitle &&
                      field?.formFieldTitle?.replace("*", "")) ||
                    ""
                  }
                />
              ),
            }))) ||
          [];

        return (list = [...list, ...res]);
      } else {
        return [];
      }
    },
    []
  );

  const getAllFormFieldsByFormId = useCallback(async () => {
    Promise.all([
      await GetAllFormFieldsByFormId(FormsIdsEnum.contactsIndividual.id),
      await GetAllFormFieldsByFormId(FormsIdsEnum.contactsCorporate.id),
      await getAllSearchableFormFieldsByFormId(
        FormsIdsEnum.contactsIndividual.id
      ),
      await getAllSearchableFormFieldsByFormId(
        FormsIdsEnum.contactsCorporate.id
      ),
    ])
      .then((result) => {
        const formFieldsIndividual = result[2];
        const formFieldsCorporate = result[3];

        if (Array.isArray(result[0]) && Array.isArray(result[1])) {
          const concantinateFields = result[0]
            .concat(result[1])
            .filter(
              (field, index, array) =>
                array?.findIndex(
                  (element) => element.formFieldKey === field.formFieldKey
                ) === index
            );
          const list = concantinateFields
            .filter(
              (e) =>
                e.formFieldName !== "company_logoimage" &&
                e.formFieldName !== "contact_image" &&
                e.formFieldName !== "contact_classifications" &&
                e.formFieldName !== "contact_preference" &&
                e.formFieldName !== "map" &&
                e.formFieldName !== "contacts_person"
            )
            .map((field) => ({
              ...field,
              id: field.formFieldId || null,
              key: field.formFieldKey || null,
              isDate: field.uiWidgetType === "alt-date" || false,
              disableColumnMenu: true,
              minWidth: 190,
              headerName:
                (field.formFieldTitle &&
                  field.formFieldTitle.replace("*", "")) ||
                "",
              field: field.displayPath || "",
              fieldType:
                field?.uiWidgetType === "alt-date"
                  ? "datePicker"
                  : field?.uiWidgetType,
              isNumber:
                (field.propertyJson &&
                  JSON.parse(field.propertyJson).schema &&
                  field.propertyJson &&
                  JSON.parse(field.propertyJson).schema.specialKey ===
                    "currency") ||
                (field.propertyJson &&
                  JSON.parse(field.propertyJson).schema &&
                  field.propertyJson &&
                  JSON.parse(field.propertyJson).schema.specialKey ===
                    "decimal") ||
                (field.propertyJson &&
                  JSON.parse(field.propertyJson).schema &&
                  field.propertyJson &&
                  JSON.parse(field.propertyJson).schema.specialKey === "size"),
              isSortable: true,
              searchableKey: field.searchableKey,
              renderHeader: (params) => (
                <CustomTableHeader
                  params={undefined}
                  title={
                    (field.formFieldTitle &&
                      field.formFieldTitle.replace("*", "")) ||
                    ""
                  }
                />
              ),
            }));

          const mergedFormFields = [
            ...list,
            ...formFieldsIndividual,
            ...formFieldsCorporate,
          ].reduce((acc, current) => {
            const exists = acc.find(
              (item) =>
                item.key === current.key || item.formFieldKey === current.key
            );
            if (!exists) {
              acc.push(current);
            }
            return acc;
          }, []);

          setAllFormFields(mergedFormFields);
        } else setAllFormFields([]);
      })
      .catch(() => {
        setAllFormFields([]);
      });
  }, []);

  // will be used for the duplicated contacts page in future
  const [duplicatedContactsCriteria, setDuplicatedContactsCriteria] = useState({
    result: [],
    totalCount: 0,
  });

  const APIGetAllDuplicatedContactsCriteria = useCallback(async () => {
    const result = await GetContactsDuplicationCriteria({
      pageSize: 25,
      pageIndex: 1,
    });

    if (!(result && result.status && result.status !== 200)) {
      setDuplicatedContactsCriteria(result);
    } else {
      setDuplicatedContactsCriteria({ result: [], totalCount: 0 });
    }
  });

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);

  useEffect(() => {
    setTableColumns([
      ...newContactsTableHeaderData(isDarkMode).filter(
        (item) =>
          selectedTableFilterColumns?.findIndex(
            (element) => element === item.id
          ) !== -1
      ),
      ...allFormFields
        .filter(
          (item) =>
            selectedTableFilterColumns?.findIndex(
              (element) => element === item.formFieldId
            ) !== -1
        )
        .map((field) => ({
          id: field.formFieldId || null,
          key: field.formFieldKey || null,
          isDate: field.uiWidgetType === "alt-date" || false,
          disableColumnMenu: true,
          minWidth: 190,
          headerName:
            (field.formFieldTitle && field.formFieldTitle.replace("*", "")) ||
            "",
          field: field.displayPath || "",
          isNumber:
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey ===
                "currency") ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === "decimal") ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === "size"),
          isSortable: true,
          searchableKey: field.searchableKey,
          renderHeader: (params) => (
            <CustomTableHeader
              params={undefined}
              title={
                (field.formFieldTitle &&
                  field.formFieldTitle.replace("*", "")) ||
                ""
              }
            />
          ),
        })),
    ]);
  }, [
    allFormFields,
    selectedTableFilterColumns,
    // contactTableFilter
  ]);

  useEffect(() => {
    if (!isLoading) {
      localStorage.setItem("bulk-assign-contacts-items", JSON.stringify([]));
      APIGetAllDuplicatedContactsCriteria();
      setIsFirstLoad(false); // Update the state after the first load
    }
  }, [isLoading]);

  const handleClose = () => {
    setActionableItems((prev) => ({
      ...prev,
      selectedIds: [],
      isConfirmationDialogOpen: false,
      isConfirmed: false,
    }));
  };

  const handleConfirm = async () => {
    try {
      await PullContactOpportunity(actionableItems?.selectedIds);

      setAlertBoxContent({
        display: true,
        variant: "success",
        title: sharedTranslate("contact-sent-successfully"),
        onClose: () => {
          setAlertBoxContent(null);
        },
      });

      setActionableItems((prev) => ({
        ...prev,
        selectedIds: [],
        isConfirmationDialogOpen: false,
        isConfirmed: false,
      }));
    } catch (error) {
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: sharedTranslate("error-message"),
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    }
  };

  const getAllFavoriteFolders = useCallback(async (favoriteFolderType) => {
    setIsFavoriteFoldersLoading(true);

    try {
      const response = await GetAllFavoriteFolders({
        favoriteFolderType,
        search: null,
      });

      setFavoriteFolders(response ?? []);
    } catch (error) {
      console.error("Failed to fetch favorite folders:", error);
      setFavoriteFolders([]);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to fetch comments!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setIsFavoriteFoldersLoading(false);
    }
  }, []);

  // Function to observe visibility of the dropPagination element
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            stickyPagination.current.style.display = "none";
          } else {
            stickyPagination.current.style.display = "block";
          }
        });
      },
      {
        root: null, // Default to viewport
        threshold: 1, // Adjust this threshold as needed
      }
    );

    // Start observing the dropPagination element
    if (dropPagination.current) {
      observer.observe(dropPagination.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (dropPagination.current) {
        observer.unobserve(dropPagination.current);
      }
    };
  }, []);

  useEffect(() => {
    setStickyBottomBox(
      <CustomPermission
        permissionsList={Object.values(ContactsPermissions)}
        permissionsId={ContactsPermissions.ViewAndSearchContacts.permissionsId}
      >
        <CustomPagination
          hideInMobile
          ref={stickyPagination}
          currentPage={pagination.currentPage}
          inputValue={inputValue}
          setInputValue={setInputValue}
          totalItems={contactsData.totalCount}
          itemsPerPage={pagination.itemsPerPage}
          onPageChange={(page) =>
            setPagination((prev) => ({ ...prev, currentPage: page }))
          }
          onItemsPerPageChange={(items) =>
            setPagination((prev) => ({ ...prev, itemsPerPage: items }))
          }
          isLoading={isLoading}
          isSticky
        />
      </CustomPermission>
    );

    return () => {
      setStickyBottomBox(null);
    };
  }, [isLoading]);

  useEffect(() => {
    // Create an IntersectionObserver to observe the `showMoreRef` element
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoadMore) {
          fetchMoreContacts(); // Trigger fetching when the observer comes into view
        }
      },
      {
        root: mainLayoutRef.current, // Observe within the main layout
        threshold: 1.0, // Trigger when 100% of the target is visible
      }
    );

    // Observe the `showMoreRef` element
    if (showMoreRef.current) observer.observe(showMoreRef.current);

    // Cleanup observer on component unmount or re-render
    return () => {
      if (showMoreRef.current) observer.unobserve(showMoreRef.current);
    };
  }, [mainLayoutRef, isLoadMore, isDesktop, viewMode]);

  useEffect(() => {
    getAllFavoriteFolders(3);
  }, []);

  const hasGridViewOnMobile = !isDesktop && viewMode === VIEW_MODES.GRID;

  return (
    <>
      <PageTitle
        hideDivider
        title={translate("CONTACTS_PAGE_TITLE")}
        subTitle={translate("CONTACTS_PAGE_SUBTITLE")}
      >
        <CustomPermission
          permissionsList={Object.values(ContactsPermissions)}
          permissionsId={ContactsPermissions.ImportContact.permissionsId}
        >
          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="outlined"
            color="secondary"
            onClick={() => {
              setImportContactModalOpened(true);
            }}
            startIcon={
              <UploadIcon
                width="20"
                height="20"
                fill={palette.button.secondary_fg}
              />
            }
          >
            {translate("IMPORT")}
          </CustomButton>
        </CustomPermission>

        <CustomPermission
          permissionsList={Object.values(ContactsPermissions)}
          permissionsId={ContactsPermissions.AddNewContact.permissionsId}
        >
          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="contained"
            color="primary"
            onClick={() => {
              setAddNewContact({
                addContactModalOpened: true,
                contact: {},
              });
            }}
            startIcon={
              <PlusIcon
                width="20"
                height="20"
                fill={palette.button.primary_fg}
              />
            }
          >
            {translate("ADD_NEW")}
          </CustomButton>
        </CustomPermission>
      </PageTitle>

      <Box style={{ marginTop: "32px" }}>
        {isLoading && isFirstLoad ? (
          <SummaryCardListSkeleton numberOfCards={5} />
        ) : (
          <SummaryCardList
            cardTitles={[
              translate("24H_NEW_CONTACTS"),
              translate("24H_NEW_LEADS"),
              translate("OPPORTUNITY_CONTACT"),
              translate("MY_OPPORTUNITY_CONTACT"),
              translate("PSI_OPPORTUNITY"),
            ]}
          />
        )}
      </Box>

      {actionableItems?.action === "pull" &&
        actionableItems?.isConfirmationDialogOpen && (
          <Box>
            <ConfirmationDialog
              onClose={handleClose}
              onConfirm={handleConfirm}
              content={sharedTranslate("confirmation-text")}
            />
          </Box>
        )}

      <CustomPermission
        permissionsList={Object.values(ContactsPermissions)}
        permissionsId={ContactsPermissions.ContactDuplications.permissionsId}
      >
        <CustomSnackbar
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          primaryMessage={translate("DUPLICATE_CONTACTS_TEXT", {
            number: duplicatedContactsCriteria?.totalCount,
          })}
          secondaryMessage={translate("PROCEED_ALERT_TEXT")}
        />
      </CustomPermission>

      <QuickFilterSection
        setIsBulkUpdateModal={setIsBulkUpdateModal}
        setUpdateSelectedColumnItems={setUpdateSelectedColumnItems}
        viewMode={viewMode}
        onChangeViewMode={onChangeViewMode}
        allFormFields={newContactsTableHeaderData(isDarkMode).concat(
          allFormFields.filter(
            (item) =>
              newContactsTableHeaderData(isDarkMode).findIndex(
                (element) =>
                  element?.fieldKey === item?.formFieldKey ||
                  element?.fieldKey === item?.displayPath
              ) === -1
          )
        )}
        tableColumns={updateSelectedColumnItems}
        setIsFavoriteDialogOpen={setIsFavoriteDialogOpen}
        isFavoriteDialogOpen={isFavoriteDialogOpen}
        favoriteFolders={favoriteFolders}
        setFavoriteFolders={setFavoriteFolders}
        isFavoriteFoldersLoading={isFavoriteFoldersLoading}
      />
      <CustomPermission
        permissionsList={Object.values(ContactsPermissions)}
        permissionsId={ContactsPermissions.ViewAndSearchContacts.permissionsId}
      >
        <ContactsFilterBar
          viewMode={viewMode}
          filterItems={filterItems}
          setFilterItems={setFilterItems}
          allTableColumns={newContactsTableHeaderData(isDarkMode).concat(
            allFormFields.filter(
              (item) =>
                newContactsTableHeaderData(isDarkMode).findIndex(
                  (element) =>
                    element?.fieldKey === item?.formFieldKey ||
                    element?.fieldKey === item?.displayPath
                ) === -1
            )
          )}
          data={flattenedRows || []}
          columnsDetails={updateSelectedColumnItems}
          favoriteFolders={favoriteFolders}
        />
      </CustomPermission>

      {viewMode === VIEW_MODES.TABLE && (
        <Box>
          <CustomPermission
            permissionsList={Object.values(ContactsPermissions)}
            permissionsId={
              ContactsPermissions.ViewAndSearchContacts.permissionsId
            }
          >
            {isLoading ? (
              <TableSkeleton rowsNum={12} />
            ) : contactsData?.result?.length > 0 ? (
              <BasicTable
                tableActions={({ anchorPosition }) => (
                  <TableActions anchorPosition={anchorPosition} />
                )}
                pageSize={pagination.itemsPerPage}
                rowsData={flattenedRows || []}
                setActiveItem={setActiveItem}
                columns={updateSelectedColumnItems?.map((column) => ({
                  ...column,
                  renderHeader: (params) => (
                    <CustomTableHeader
                      params={params}
                      title={column.headerName}
                      handleFilterConfirm={handleFilterConfirm}
                      isHiddenFilter={column.headerName === "Progress"}
                      criteriaFilter={advancedSearchBody.criteria}
                    />
                  ),
                }))}
                rowHeight={84}
                borderRadius="0 0 8px 8px"
              />
            ) : (
              <NoDataFound />
            )}
          </CustomPermission>
        </Box>
      )}

      {viewMode === VIEW_MODES.GRID && (
        <>
          <CustomPermission
            permissionsList={Object.values(ContactsPermissions)}
            permissionsId={
              ContactsPermissions.ViewAndSearchContacts.permissionsId
            }
          >
            <Box className={styles.gridContainer}>
              {isLoading && !isLoadMore ? (
                Array.from({ length: 25 }).map((_, index) => (
                  <ContactCardSkeleton index={index} />
                ))
              ) : contactsData?.result?.length > 0 ? (
                contactsData?.result?.map((item) => (
                  <ContactCard
                    item={item}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    setIsFavoriteDialogOpen={setIsFavoriteDialogOpen}
                  />
                ))
              ) : (
                <NoDataFound />
              )}
            </Box>
            {hasGridViewOnMobile && isLoadMore && (
              <Box className={styles.contactShowMoreSpinnerSection}>
                <LoadingSpinner
                  sizeVariant={LoadingSpinnerSize.small}
                  styleVariant={LoadingSpinnerStyle.primary}
                  loadingSpinnerContainer={
                    styles.showMoreloadingSpinnerContainer
                  }
                />
              </Box>
            )}
          </CustomPermission>
        </>
      )}

      <ContactProfile
        activeItem={activeItem}
        open={activeItem?.contactId || activeItem?.id ? true : false}
        onClose={() => setActiveItem({})}
      />

      <CustomPermission
        permissionsList={Object.values(ContactsPermissions)}
        permissionsId={ContactsPermissions.ViewAndSearchContacts.permissionsId}
      >
        <div ref={dropPagination}>
          <CustomPagination
            hideInMobile={viewMode === VIEW_MODES.GRID}
            totalItems={contactsData.totalCount}
            itemsPerPage={pagination.itemsPerPage}
            currentPage={pagination.currentPage}
            inputValue={inputValue}
            setInputValue={setInputValue}
            onPageChange={(page) =>
              setPagination((prev) => ({ ...prev, currentPage: page }))
            }
            onItemsPerPageChange={(items) =>
              setPagination((prev) => ({ ...prev, itemsPerPage: items }))
            }
            isLoading={isLoading}
          />
        </div>
      </CustomPermission>

      {(filterModalData?.isOpen || savedFiltersCriteria?.isOpen) && (
        <SaveFilterModal />
      )}

      {importContactModalOpened && (
        <ImportContactModal
          onClose={() => {
            setImportContactModalOpened(false);
          }}
        />
      )}

      {/* this div will trigger the observer when visible for infinite scroll */}
      {hasGridViewOnMobile && <div ref={showMoreRef} style={{ height: 1 }} />}
    </>
  );
}

export default NewContactsView;
