import React from "react";

function SaleIcon({ fill, ...restProps }) {
  return (
    <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
    >
      <g id="Sale-04">
        <path
          id="Icon"
          d="M2.5 8.80433C2.5 8.3257 2.5 8.08639 2.56169 7.866C2.61633 7.67077 2.70614 7.48716 2.82669 7.32417C2.96278 7.14017 3.15168 6.99324 3.52949 6.69939L9.18141 2.30345C9.47418 2.07574 9.62057 1.96189 9.78221 1.91812C9.92484 1.87951 10.0752 1.87951 10.2178 1.91812C10.3794 1.96189 10.5258 2.07574 10.8186 2.30345L16.4705 6.69939C16.8483 6.99324 17.0372 7.14017 17.1733 7.32417C17.2939 7.48716 17.3837 7.67077 17.4383 7.866C17.5 8.08639 17.5 8.3257 17.5 8.80433V14.8334C17.5 15.7669 17.5 16.2336 17.3183 16.5901C17.1586 16.9037 16.9036 17.1587 16.59 17.3185C16.2335 17.5001 15.7668 17.5001 14.8333 17.5001H5.16667C4.23325 17.5001 3.76654 17.5001 3.41002 17.3185C3.09641 17.1587 2.84144 16.9037 2.68166 16.5901C2.5 16.2336 2.5 15.7669 2.5 14.8334V8.80433Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Icon_2"
          d="M7.91699 12.3717C7.91699 13.1152 8.51969 13.7179 9.26315 13.7179H10.5131C11.3097 13.7179 11.9555 13.0721 11.9555 12.2756C11.9555 11.479 11.3097 10.8333 10.5131 10.8333H9.3593C8.56274 10.8333 7.91699 10.1875 7.91699 9.39094C7.91699 8.59438 8.56274 7.94864 9.3593 7.94864H10.6093C11.3528 7.94864 11.9555 8.55133 11.9555 9.29479M9.93622 7.08325V7.94864M9.93622 13.7179V14.5833"
          stroke="#667085"
          stroke-width="1.33"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

export default SaleIcon;