import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    cardWrapper: {
      padding: "16px",
      maxWidth: "310px",
      gap: "8px",
      flexDirection: "column",
      display: "flex",
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      alignItems: "center",
    },
    contactNameWrapper: {
      display: "flex",
      gap: "12px",
      alignSelf: "flex-start",
      cursor: "pointer",
    },
    contactName: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 600,
    },
    infoContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      alignItems: "center",
      paddingBottom: "8px",
      borderBottom: `1px solid ${theme.palette.background.secondary}`,
    },
    infoItem: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      width: "100%",
      justifyContent: "center",
    },
    infoContent: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.primary,
    },
    truncateString: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    fw400: {
      fontWeight: 400,
    },
    createdOn: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
    defaultAvatarStyle: {
      backgroundColor: "#F2F4F7",
      border: `1px solid ${theme.palette.border.secondary}`,
      height: "40px",
      width: "40px",
      "& img": {
        height: "24px",
        width: "24px",
      },
    },
    avatarStyle: {
      height: "40px",
      width: "40px",
      border: `1px solid ${theme.palette.border.secondary}`,
    },
    flexCol: {
      display: "flex",
      flexDirection: "column",
    },
    createdAt: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      alignSelf: "center",
    },
  };
});
