export const ActionsButtonsEnum = {
  1: {
    id: 'add',
    classes: 'bg-secondary',
    label: 'actions',
    buttonLabel: 'add',
  },
  2: {
    id: 'merge',
    classes: 'bg-secondary',
    label: 'merge',
    buttonLabel: 'merge',
  },
  3: {
    id: 'import',
    classes: 'bg-secondary',
    label: 'import',
    buttonLabel: 'import',
  },
  4: {
    id: 'bulk-update',
    classes: 'bg-secondary',
    label: 'bulk-update',
    buttonLabel: 'bulk-update',
  },
  5: {
    id: 'close-leads',
    classes: 'bg-secondary',
    label: 'close-leads',
    buttonLabel: 'close-leads',
  },
  6: {
    id: 'reassign-seeker-leads',
    classes: 'bg-secondary',
    label: 'reassign-seeker-leads',
    buttonLabel: 'reassign-seeker-leads',
  },
  7: {
    id: 'clone-seeker-leads',
    classes: 'bg-secondary',
    label: 'clone-seeker-leads',
    buttonLabel: 'clone-seeker-leads',
  },
  8: {
    id: 'send-to-rotation',
    classes: 'bg-secondary',
    label: 'send-to-seeker-rotation',
    buttonLabel: 'send-to-seeker-rotation',
  },
  9: {
    id: 'assign',
    classes: 'bg-secondary',
    label: 'assign',
    buttonLabel: 'assign',
  },
  10: {
    id: 'unAssign',
    classes: 'bg-secondary',
    label: 'unAssign',
    buttonLabel: 'unAssign',
  },
  11: {
    id: 'select',
    classes: 'bg-secondary',
    label: 'select',
    buttonLabel: 'select',
  },
  12: {
    id: 'qualify-lead',
    classes: 'bg-secondary',
    label: 'qualify-lead',
    buttonLabel: 'qualify-lead',
  },
  13:
  {
    id: 'unqualified-leads',
    classes: 'bg-secondary',
    label: 'unqualified-leads',
    buttonLabel: 'unqualified-leads',
  },
  14: {
    id: 'reassign-owner-leads',
    classes: 'bg-secondary',
    label: 'reassign-owner-leads',
    buttonLabel: 'reassign-owner-leads',
  },
  15: {
    id: 'make-transaction',
    classes: 'bg-secondary',
    label: 'make-transaction',
    buttonLabel: 'make-transaction',
  },
  16: {
    id: 'send-Proposal',
    classes: 'bg-secondary',
    label: 'send-Proposal',
    buttonLabel: 'send-Proposal',
  },
  17: {
    id: 'mark-as-available',
    classes: 'bg-secondary',
    label: 'mark-as-available',
    buttonLabel: 'mark-as-available',
  },
  18: {
    id: 'move-to-draft',
    classes: 'bg-secondary',
    label: 'move-to-draft',
    buttonLabel: 'move-to-draft',
  },
  19: {
    id: 'publish-to-website',
    classes: 'bg-secondary',
    label: 'publish-to-website',
    buttonLabel: 'publish-to-website',
  },
  20: {
    id: 'unpublish',
    classes: 'bg-secondary',
    label: 'unpublish',
    buttonLabel: 'unpublish',
  },
  21: {
    id: 'delete',
    classes: 'bg-secondary',
    label: 'delete',
    buttonLabel: 'delete',
  },
  22: {
    id: 'send-to-leads-pool',
    classes: 'bg-secondary',
    label: 'send-to-leads-pool',
    buttonLabel: 'send-to-leads-pool',
  },
  23 :{
    id: 'send-to-owner-rotation',
    classes: 'bg-secondary',
    label: 'send-to-owner-rotation',
    buttonLabel: 'send-to-owner-rotation',
  },
  24:{
    id: 'assign-to-me',
    classes: 'bg-secondary',
    label: 'assign-to-me',
    buttonLabel: 'assign-to-me',
  },
  25:{
    id: 'share-unit',
    classes: 'bg-secondary',
    label: 'share-unit',
    buttonLabel: 'share-unit',
  },
  26:{
    id: 'add-to-favourite',
    classes: 'bg-secondary',
    label: 'add-to-favourite',
    buttonLabel: 'add-to-favourite',
  },
  27:{
    id: 'archived',
    classes: 'bg-secondary',
    label: 'archived',
    buttonLabel: 'archived',
  },
  28:{
    id: 'un-archived',
    classes: 'bg-secondary',
    label: 'un-archived',
    buttonLabel: 'un-archived',
  },
  29:{
    id: 'pull-to-contact-opportunity',
    classes: 'bg-secondary',
    label: 'pull-to-contact-opportunity',
    buttonLabel: 'pull-to-contact-opportunity',
  },
  30:{
    id: 'consent-management',
    classes: 'bg-secondary',
    label: 'consent-management',
    buttonLabel: 'consent-management',
  },
  31:{
    id: 'dncr',
    classes: 'bg-secondary',
    label: 'dncr',
    buttonLabel: 'dncr',
  },
  32: {
    id: 'send-to-leads-pool-assets',
    classes: 'bg-secondary',
    label: 'send-to-leads-pool-assets',
    buttonLabel: 'send-to-leads-pool-assets',
  },
  33: {
    id: 'pull-to-hubspot',
    classes: 'bg-secondary',
    label: 'pull-to-hubspot',
    buttonLabel: 'pull-to-hubspot',
  },
  34: {
    id: 'clone-owner-leads',
    classes: 'bg-secondary',
    label: 'clone-owner-leads',
    buttonLabel: 'clone-owner-leads',
  },
};
