import { config } from '../config';
import { HttpServices } from '../Helper';

const getUnits = async ({
  pageSize,
  pageIndex,
  search,
  excludeLeadOwnerInUnits,
  propertyId,
  operationType,
}) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  if (search) queryList.push(`search=${search}`);
  if (propertyId) queryList.push(`propertyId=${propertyId}`);
  if (operationType) queryList.push(`operationType=${operationType}`);
  if (excludeLeadOwnerInUnits)
    queryList.push(`excludeLeadOwnerInUnits=${excludeLeadOwnerInUnits}`);
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetAllUnits?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllUnits = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Unit/GetAllUnits`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

const GetAllUnitsForLeadOwner = async ({
  pageSize,
  pageIndex,
  search,
  isSaleUnitType,
  propertyId,
}) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  if (search) queryList.push(`search=${search}`);

  queryList.push(`isSaleUnitType=${isSaleUnitType}`);

  if (propertyId) queryList.push(`propertyId=${propertyId}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllUnitsForLeadOwner?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAdvanceSearchUnits = async ({ pageSize, pageIndex }, body) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/AdvanceSearch?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAdvanceSearchSaleUnits = async ({ pageSize, pageIndex }, body) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllSaleUnitsAdvanceSearch?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const UnitsAdvanceSearchTest = async (
  { pageSize, pageIndex, isForLog },
  body
) => {
  const queryList = [];
  if (isForLog) queryList.push(`isForLog=${isForLog}`);
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/Unit/UnitsAdvanceSearchTest?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetUnitDetails = async (unitId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetAllUnitLeadOwners?unitId=${unitId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAdvanceSearchLeaseUnits = async ({ pageSize, pageIndex }, body) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllUnitLeaseAdvanceSearch?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAdvanceSearchLeaseUnitsPropertyManagement = async (
  { pageSize, pageIndex },
  body
) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllUnitPropertyManagementAdvanceSearch?${queryList.join(
      '&'
    )}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllLeaseTransactions = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/GetAllLeaseTransactions`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllSaleTransactions = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/GetAllSaleTransactions`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllUnitPaymentPlanByUnitId = async ({
  unitId,
  pageIndex,
  pageSize,
}) => {
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/UnitPaymentPlan/GetAllUnitPaymentPlanByUnitId/${unitId}?pageIndex=${
      pageIndex + 1
    }&pageSize=${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetLatestSaleOrReservedSaleTransactionByUnitId = async (unitId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetLatestSaleOrReservedSaleTransactionByUnitId/${unitId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetSaleReservationClient = async ({ id, isForAccountTab }) => {
  const queryList = [];
  if (isForAccountTab) queryList.push(`isForAccountTab=${isForAccountTab}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetSaleReservationClient/${id}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetLeaseReservationTenants = async ({ id, isForAccountTab }) => {
  const queryList = [];
  if (isForAccountTab) queryList.push(`isForAccountTab=${isForAccountTab}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetLeaseReservationTenants/${id}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetSaleUnitTransactionDetails = async (unitTransactionId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetSaleUnitTransactionDetails(/${unitTransactionId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllChequeRequestsUnitTransactionId = async (
  unitTransactionId,
  { pageSize, pageIndex }
) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  if (unitTransactionId) queryList.push(`transactionId=${unitTransactionId}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllTransactionChequeRequests?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllAmountReceivedUnitTransactionId = async (
  unitTransactionId
) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetAllAmountReceivedUnitTransactionId?unitTransactionId=${unitTransactionId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetSaleTransactionDetails = async (unitTransactionId) => {
  const queryList = [];
  if (unitTransactionId) queryList.push(`transactionId=${unitTransactionId}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/account/GetSaleTransactionDetails?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetLeasingTransactionDetails = async (unitTransactionId) => {
  const queryList = [];
  if (unitTransactionId) queryList.push(`transactionId=${unitTransactionId}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/account/GetLeasingTransactionDetails?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetChequeRequestTransactionInfo = async (unitTransactionId) => {
  const queryList = [];
  if (unitTransactionId) queryList.push(`transactionId=${unitTransactionId}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/account/GetChequeRequestTransactionInfo?${queryList.join(
      '&'
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetLeasingChequeRequestTransactionInfo = async (
  unitTransactionId
) => {
  const queryList = [];
  if (unitTransactionId) queryList.push(`transactionId=${unitTransactionId}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/account/GetLeasingChequeRequestTransactionInfo?${queryList.join(
      '&'
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetEarningByUnitTransactionId = async (unitTransactionId) => {
  const queryList = [];
  if (unitTransactionId) queryList.push(`transactionId=${unitTransactionId}`);
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/account/GetEarning?${queryList.join(
      '&'
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetLeasingEarningByUnitTransactionId = async (
  unitTransactionId
) => {
  const queryList = [];
  if (unitTransactionId) queryList.push(`transactionId=${unitTransactionId}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/account/GetLeaseEarning?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetSalesTransactionReferenceDetailsByUnitTransactionId = async (
  unitTransactionId
) => {
  const queryList = [];
  if (unitTransactionId) queryList.push(`transactionId=${unitTransactionId}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/account/GetReferralsForReferenceDetail?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetLeaseTransactionReferenceDetailsByUnitTransactionId = async (
  unitTransactionId
) => {
  const queryList = [];
  if (unitTransactionId) queryList.push(`transactionId=${unitTransactionId}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/account/GetLeasingReferralsForReferenceDetail?${queryList.join(
      '&'
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetUnitTransactionDocuments = async (
  id,
  { pageIndex, pageSize }
) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/PropertyManagementDocument/GetAllDocumentsByUnitTransaction/${id}?${queryList.join(
      '&'
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const CreateUnitTransactionDocument = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/PropertyManagementDocument`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const CreateOrUpdateUnitTransactionReferenceDetail = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/account/CreateOrUpdateUnitTransactionReferenceDetail`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const CreateOrUpdateUnitLeasingTransactionReferenceDetail = async (
  body
) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/account/CreateOrUpdateUnitLeasingTransactionReferenceDetail`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const UpdateUnitTransactionDocument = async (documentId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/PropertyManagementDocument/UpdateDocument/${documentId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const CreateUnitTransactionChequeRequest = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/CreateTransactionChequeRequest`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const UpdateUnitTransactionChequeRequest = async (
  chequeRequestId,
  body
) => {
  const queryList = [];
  if (chequeRequestId)
    queryList.push(`transChequeRequestId=${chequeRequestId}`);
  const result = await HttpServices.put(
    `${
      config.server_address
    }/CrmDfm/Unit/UpdateTransactionChequeRequest?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetLatestLeaseOrReservedLeaseTransactionByUnitId = async (unitId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetLatestLeaseOrReservedLeaseTransactionByUnitId/${unitId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetUnitTransactionsHistory = async (unitId, { pageIndex, pageSize }) => {
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetUnitTransactionsHistory/${unitId}?pageIndex=${
      pageIndex + 1
    }&pageSize=${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const CreateUnitPaymentPlan = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/UnitPaymentPlan/CreateUnitPaymentPlan`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const BulkUpdateUnits = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/BulkUpdateUnits`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const UpdateUnitPaymentPlan = async (unitPaymentPlanId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitPaymentPlan/UpdateUnitPaymentPlan/${unitPaymentPlanId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const DeleteUnitPaymentPlan = async (unitPaymentPlanId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitPaymentPlan/DeleteUnitPaymentPlan/${unitPaymentPlanId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const DeleteChequeRequest = async (chequeRequestId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/Unit/DeleteChequeRequest/${chequeRequestId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const UpdateUnitReferenceDetails = async (unitId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/UpdateUnitReferenceDetails/${unitId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetUnitReferenceDetailsById = async (unitId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetUnitReferenceDetailsById/${unitId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetUnitPaymentPlanById = async (unitPaymentPlanId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitPaymentPlan/GetUnitPaymentPlanById/${unitPaymentPlanId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const unitDetailsGet = async ({ id, isForLog }) => {
  const queryList = [];
  if (isForLog) queryList.push(`isForLog=${isForLog}`);
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetUnitById/${id}?${queryList.join(
      '&'
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllUnitLeadOwners = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetAllUnitLeadOwners?unitId=${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetUnitAvailableStatus = async (unitId, type) => {
  const queryList = [];
  if (type) queryList.push(`type=${type}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetUnitAvailableStatus/${unitId}/type?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const CanSetUnitAsReservedOrLeasedOrSale = async (unitId, status) => {
  const queryList = [];
  if (unitId) queryList.push(`unitId=${unitId}`);
  if (status) queryList.push(`status=${status}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/CanSetUnitAsReservedOrLeasedOrSale?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllUnitsSaleByOwnerId = async ({ pageIndex, pageSize }, contactId) => {
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllUnitsSaleByOwnerId/${contactId}?pageIndex=${
      pageIndex + 1
    }&pageSize=${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllUnitsLeaseByOwnerId = async ({ pageIndex, pageSize }, ownerId) => {
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllUnitsLeaseByOwnerId/${ownerId}?pageIndex=${
      pageIndex + 1
    }&pageSize=${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAllUnitsByOwnerId = async ({ pageIndex, pageSize }, contactId) => {
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllUnitsByOwnerId/${contactId}?pageIndex=${
      pageIndex + 1
    }&pageSize=${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllUnitsByOwnerLeadId = async ({ pageIndex, pageSize }, contactId) => {
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllUnitsByOwnerLeadId/${contactId}?pageIndex=${
      pageIndex + 1
    }&pageSize=${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAllContactUnitTransactions = async (
  { pageIndex, pageSize },
  contactId
) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllContactUnitTransactions/${contactId}?${queryList.join(
      '&'
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAllLeadUnitTransactions = async ({ pageIndex, pageSize }, leadId) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllLeadUnitTransactions/${leadId}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const unitDetailsPut = async ({
  id,
  body,
  referredById,
  skipSensitiveDataCheck,
}) => {
  const queryList = [];
  if (referredById) queryList.push(`referredById=${referredById}`);
  if (skipSensitiveDataCheck)
    queryList.push(`skipSensitiveDataCheck=${skipSensitiveDataCheck}`);

  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/UpdateUnit/${id}?${queryList.join(
      '&'
    )}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const SetUnitAsAvailableOrDraft = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/SetUnitAsAvailableOrDraft`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const SetUnitAsReserveOrSale = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/SetUnitAsReserveOrSale`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const SetUnitAsReserveOrLease = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/SetUnitAsReserveOrLease`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
// const UpdateUnitStatus = async ({ id, status, body }) => {
//   const result = await HttpServices.put(
//     `${config.server_address}/CrmDfm/Unit/UpdateUnitStatus/${id}/${status}`,
//     body
//   )
//     .then((data) => data)
//     .catch((error) => error.response);
//   return result;
// };
const unitPost = async (body, referredById) => {
  const queryList = [];
  if (referredById) queryList.push(`?referredById=${referredById}`);
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit${queryList.join('&')}`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};
const GetLatitudeAndLongitudeByUnit = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetUnitMapByUnitId/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
// old version
const unitPut = async (payload) =>
  // const { body, token } = payload;
  // await base('put', 'v1/units/', token, body);
  ({});
const unitFieldsGet = async (payload) =>
  // const { searchableValue } = payload;
  // await base('get', `v1/units/fields/${searchableValue}`, '', null);
  ({});
const unitFilesGet = async (payload) =>
  //  const { unitId, token } = payload;
  // await base('get', `v1/units/files/${unitId}`, token, null);
  ({});
const unitPropertiesGet = async (payload) =>
  // const { propertyId, token } = payload;
  // await base('get', `v1/units/propertyId/${propertyId}`, token, null);
  ({});
const mergeUnitPut = async (payload) =>
  // const { token, id, body } = payload;
  // await base('put', `v1/units/mergeUnits/${id}`, token, body.data);
  ({});
const archiveUnitsPut = async (unitId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/ArchiveUnit/${unitId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetUnitImage = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetUnitImageByUnitId/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetUnitDocument = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetUnitDocumentByUnitId/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetReservationInvoices = async (unitTransactionId) => {
  // const queryList = [];
  // if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  // if (pageIndex || pageIndex === 0) queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetReservationInvoices/${unitTransactionId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetLastSaleTransByUnitId = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetLastSaleTransByUnitId/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetLastLeaseTransByUnitId = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetLastLeaseTransByUnitId/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetReservedUnitTransactionDataForSaleByUnitId = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetReservedUnitTransactionDataForSaleByUnitId/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetReservedUnitTransactionDataForLeaseByUnitId = async (id, isForLeaseJourney = false) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetReservedUnitTransactionDataForLeaseByUnitId/${id}?isForLeaseJourney=${isForLeaseJourney}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetUnitLeaseDetails = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetUnitLeaseDetails/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetUnitSaleDetails = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetUnitSaleDetails/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetUnitOpenHouseById = async (unitOpenHouseId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/UnitOpenHouse/GetUnitOpenHouseById/${unitOpenHouseId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateUnitSaleDetails = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/UpdateUnitSaleDetails`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllUnitOpenHouses = async (Id, pageIndex, pageSize) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/UnitOpenHouse/GetAllUnitOpenHousesBUnityId/${Id}?pageIndex=${pageIndex}&pageSize=${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const DeleteOpenHouse = async (unitOpenHouseId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/UnitOpenHouse/${unitOpenHouseId}`
  )
    .then((data) => data)
    .catch((error) => error.response);

  return result;
};

const AddOpenHouse = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/UnitOpenHouse/`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);

  return result;
};

const UpdateUnitOpenHouse = async (unitOpenHouseId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitOpenHouse/UpdateUnitOpenHouse/${unitOpenHouseId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UnitRemark = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/UnitRemark/`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllUnitRemarksByUnitId = async (Id, pageIndex, pageSize, isAgent) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/UnitRemark/GetAllUnitRemarksByUnitId/${Id}?pageIndex=${pageIndex}&pageSize=${pageSize}&isAgent=${isAgent}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateUnitLeaseDetails = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/UpdateUnitLeaseDetails`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);

  return result;
};

const DeleteUnitRemark = async (unitRemarkId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/UnitRemark/${unitRemarkId}`
  )
    .then((data) => data)
    .catch((error) => error.response);

  return result;
};

const UpdateUnitRemark = async (unitRemarkId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitRemark/UpdateUnitRemark/${unitRemarkId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetBuyerSummary = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetBuyerSummary/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllMatchingUnitsByLeadId = async (leadId, pageIndex, pageSize) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetAllMatchingUnitsByLeadId/${leadId}?pageIndex=${pageIndex}&pageSize=${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateBuyerSummary = async (unitId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/UpdateBuyerSummary/${unitId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetShareUnitById = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetShareUnitById/${id}`,
    { headers: { ApiKey: config.ApiKey } }
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

const GetLeaseTransactionDetails = async (unitTransactionId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/account/GetAccountLeaseTransactionDetails?transactionId=${unitTransactionId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const UpdateLeaseUnitTransaction = async (unitTransactionId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/UpdateLeaseUnitTransaction/${unitTransactionId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateSaleUnitTransaction = async (unitTransactionId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/UpdateSaleUnitTransaction/${unitTransactionId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetLeaseDetails = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetLeaseTransactionDetails/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllUnitsByPropertyId = async ({
  propertyId,
  pageIndex,
  pageSize,
  orderBy,
  filterBy,
}) => {
  // eslint-disable-next-line prefer-const
  let queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  if (orderBy) queryList.push(`orderBy=${orderBy}`);
  if (filterBy) queryList.push(`filterBy=${filterBy}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllUnitsByPropertyId/${propertyId}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAllOwnersByUnitId = async (unitId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/UnitOwner/GetAllOwnersByUnitId?unitId=${unitId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const UnitOwner = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/UnitOwner`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const UpdateUnitOwner = async (query) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitOwner/UpdateUnitOwner?unitId=${query.unitId}&oldOwnerId=${query.oldOwnerId}&newOwnerId=${query.newOwnerId}&IsLeadOwner=${query.isLeadOwner}&IsLeaseLeadOwner=${query.isLeaseLeadOwner}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const DeleteUnitOwner = async ({ unitId, ownerId }) => {
  const queryList = [];
  if (unitId) queryList.push(`unitId=${unitId}`);
  if (ownerId) queryList.push(`ownerId=${ownerId}`);

  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/UnitOwner/DeleteUnitOwner?${queryList.join(
      '&'
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllUnitTransactionReasons = async (
  currentStatus,
  destinationStatus
) => {
  const queryList = [];
  if (currentStatus) queryList.push(`currentStatus=${currentStatus}`);
  if (destinationStatus)
    queryList.push(`destinationStatus=${destinationStatus}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Unit/GetAllUnitTransactionReasons?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const ValidateContactForTransaction = async (id) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/ValidateContactForTransaction?leadId=${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const SendTemplateByEmail = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Template/SendTemplateByEmail`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const SendProjectsProposal = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Template/SendProjectsProposal`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllSendTemplateEmailLogs = async ({ pageIndex, pageSize }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Template/GetAllSendTemplateEmailLogs/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateContactDetailsForTransaction = async (body, contactId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Contacts/UpdateContactDetailsForTransaction/${contactId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetUserIdByApplicationUserId = async (userId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetUserIdByApplicationUserId?applicationUserId=${userId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetTransactionUnitDetails = async (unitId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ExtendedUnitTransaction/GetUnitDetails/${unitId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const ValidateTransaction = async (unitId) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ExtendedUnitTransaction/ValidateTransaction/${unitId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const SetUnitAsReserveOrSaleExtended = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ExtendedUnitTransaction/SetUnitAsReservedOrSale`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const SentTemplateWithSignature = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Template/SentTemplateWithSignature`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CreateLeadForUnitLeadOwner = async (unitId) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ExtendedUnitTransaction/CreateLeadForUnitLeadOwner/${unitId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetListingAgentDetails = async (userId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Unit/GetListingAgentDetails/${userId}`
    );
    return result;
  } catch (error) {
    return error.message;
  }
};

export const GetContactDetailsFromIdCardDocument = async ({ fileId, file }) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/contacts/GetContactDetailsFromIdCardDocument?fileId=${fileId}`,
      { file }
    );
    return result;
  } catch (error) {
    return error.message;
  }
};

export const GetUnitsDuplicationCriteria = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Unit/GetUnitsDuplicationCriteria`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetDuplicatedCriteriaUnits = async (unitDuplicationCriteriaId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Unit/GetDuplicateCriteriaUnits/${unitDuplicationCriteriaId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const BulkUpdateUnitsAsAvailable = async (body) => {
  try {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/Unit/BulkUpdateUnitsAsAvailable`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const BulkUpdateUnitMarketingFieldsData = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Unit/BulkUpdateUnitMarketingFieldsData`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const BulkSetUnitsAsAvailableOrDraft = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Unit/BulkSetUnitsAsAvailableOrDraft`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const BulkUpdateUnitsMoveToDraft = async (body) => {
  try {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/Unit/BulkUpdateUnitsMoveToDraft`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const BulkUpdateUnitPublishedStatus = async (body) => {
  try {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/UnitMarketing/BulkUpdateUnitPublishedStatus`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const SaveShareUnits = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ShareUnit/SaveShareUnits`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const ShareUnitsSearchAPI = async ({ pageSize, pageIndex }, body) => {
  const queryList = [];
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/ShareEntity/ShareUnit/ShareUnitsSearch?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateUnitPricesFroBulkUnit = async (body) => {
  try {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/UnitMarketing/UpdateUnitPricesFroBulkUnit`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const SendShareUnitProposalToLeads = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/ShareEntity/ShareUnitProposal/SendShareUnitProposalToLeads`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const CreateBulkUnitRequestToPublishOrUnPublish = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/UnitApproval/CreateBulkUnitRequestToPublishOrUnPublish`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const UpdateBulkUnitPublishStatus = async (body) => {
  try {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/UnitMarketing/UpdateBulkUnitPublishStatus`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

const MyShareUnitsSearchAPI = async ({ pageSize, pageIndex }, body) => {
  const queryList = [];
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/ShareEntity/ShareUnit/MyShareUnitsSearch?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetShareUnitDetails = async (id, unitOperationSource, isAdmin) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/ShareEntity/ShareUnit/GetShareUnitDetails?id=${id}&unitOperationSource=${unitOperationSource}&isAdmin=${isAdmin}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetAllPaymentPlans = async ({ pageIndex, pageSize }) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/PropertyPaymentPlan/GetAllPaymentPlans/${pageIndex}/${pageSize}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const CreateInvoicesFromPaymentPlanInstallment = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Invoice/CreateInvoicesFromPaymentPlanInstallment`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const VerifyDraftToAvailableRequirements = async (params) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/UnitApproval/VerifyDraftToAvailableRequirements`,
      { params }
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetUnitsRefNumbers = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Unit/GetUnitsRefNumbers`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const PauseShareUnits = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/ShareUnit/PauseShareUnits`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const PauseMultiShareUnits = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/ShareUnit/PauseMultiShareUnits`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const ListShareUnitAccesseses = async ({
  unitId,
  unitOperationSource,
}) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/ShareEntity/ShareUnit/ListShareUnitAccesseses?unitId=${unitId}&unitOperationSource=${unitOperationSource}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetReservedTransactionStepsInfo = async (unitTransactionId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/UnitTransaction/GetReservedTransactionStepsInfo/${unitTransactionId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const CheckForDuplicateUnit = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Unit/CheckForDuplicateUnit`,
      body
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};

const ClaimUnit = async (unitId, listingAgentId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/ClaimUnit/${unitId}/${listingAgentId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetMpiGaugeChartDataAPI = async ({ unitId, operationType }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetMpiGaugeChartData/${unitId}/${operationType}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const MarkUnitAsAvailable = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Unit/MarkUnitAsAvailable`,
      body
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};
export const GetUnitQuickPublishInfo = async (unitId, operationType) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Unit/GetUnitQuickPublishInfo?unitId=${unitId}&operationType=${operationType}`
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};
export const QuickPublishUnit = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Unit/QuickPublishUnit`,
      body
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};

export const CanMakeQuickActions = async (data) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Unit/CanMakeQuickActions`,
      { params: data }
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};

export {
  ShareUnitsSearchAPI,
  MyShareUnitsSearchAPI,
  GetShareUnitDetails,
  GetShareUnitById,
  GetAllUnitsByPropertyId,
  GetAllUnitLeadOwners,
  DeleteChequeRequest,
  GetAllSaleTransactions,
  GetAllLeaseTransactions,
  CanSetUnitAsReservedOrLeasedOrSale,
  GetReservationInvoices,
  UpdateUnitPaymentPlan,
  DeleteUnitPaymentPlan,
  GetUnitPaymentPlanById,
  CreateUnitPaymentPlan,
  GetAllUnitPaymentPlanByUnitId,
  GetLatestSaleOrReservedSaleTransactionByUnitId,
  GetLatestLeaseOrReservedLeaseTransactionByUnitId,
  GetReservedUnitTransactionDataForSaleByUnitId,
  GetReservedUnitTransactionDataForLeaseByUnitId,
  GetAllLeadUnitTransactions,
  GetAllContactUnitTransactions,
  UpdateUnitLeaseDetails,
  GetUnitLeaseDetails,
  GetBuyerSummary,
  UpdateUnitSaleDetails,
  GetUnitSaleDetails,
  GetAllMatchingUnitsByLeadId,
  GetUnitAvailableStatus,
  SetUnitAsReserveOrSale,
  SetUnitAsReserveOrLease,
  SetUnitAsAvailableOrDraft,
  GetAllUnitsByOwnerLeadId,
  GetAllUnitsByOwnerId,
  GetAllUnitsSaleByOwnerId,
  GetAllUnitsLeaseByOwnerId,
  getUnits,
  GetAdvanceSearchUnits,
  unitDetailsGet,
  unitDetailsPut,
  unitPost,
  unitPut,
  unitFieldsGet,
  unitFilesGet,
  unitPropertiesGet,
  mergeUnitPut,
  archiveUnitsPut,
  BulkUpdateUnits,
  GetLatitudeAndLongitudeByUnit,
  GetUnitImage,
  GetUnitDocument,
  GetUnitOpenHouseById,
  GetAllUnitOpenHouses,
  AddOpenHouse,
  DeleteOpenHouse,
  UpdateUnitOpenHouse,
  UnitRemark,
  GetUnitTransactionsHistory,
  GetAllUnitRemarksByUnitId,
  DeleteUnitRemark,
  UpdateUnitRemark,
  UpdateBuyerSummary,
  GetAdvanceSearchSaleUnits,
  UnitsAdvanceSearchTest,
  GetAdvanceSearchLeaseUnits,
  UpdateUnitReferenceDetails,
  GetUnitReferenceDetailsById,
  GetAdvanceSearchLeaseUnitsPropertyManagement,
  GetLeaseTransactionDetails,
  GetLastSaleTransByUnitId,
  GetLastLeaseTransByUnitId,
  GetLeaseDetails,
  GetAllOwnersByUnitId,
  GetAllUnitsForLeadOwner,
  UnitOwner,
  UpdateUnitOwner,
  DeleteUnitOwner,
  GetAllUnitTransactionReasons,
  UpdateLeaseUnitTransaction,
  UpdateSaleUnitTransaction,
  ValidateContactForTransaction,
  SendTemplateByEmail,
  SendProjectsProposal,
  SentTemplateWithSignature,
  GetAllSendTemplateEmailLogs,
  UpdateContactDetailsForTransaction,
  GetUserIdByApplicationUserId,
  GetTransactionUnitDetails,
  ValidateTransaction,
  SetUnitAsReserveOrSaleExtended,
  CreateLeadForUnitLeadOwner,
  GetUnitDetails,
  GetListingAgentDetails,
  ClaimUnit,
};
