import React from "react";
import PropTypes from "prop-types";
import * as moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { FBFieldWrapper } from "../FBFieldWrapper";

import { useLocalStorage } from "../../../../Hooks";
import { FBLabelPosition } from "../Constant";

// Styles
import useStyles from "./styles";

/**
 * FBDatePicker is a reusable date picker component built with Material-UI's KeyboardDatePicker.
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} The date picker component.
 */

function FBDatePicker(props) {
  const {
    label,
    helperInfo,
    labelPosition,
    required,
    locale,
    value,
    onDateChanged,
    error,
    errorMessage,
    disabled,
    format,
    placeholder,
    autoOk,
    clearable,
    disablePast,
    disableFuture,
    variant,
    ...restProps
  } = props;

  const styles = useStyles();

  const [language] = useLocalStorage("localization", {
    currentLanguage: "en",
    isRtl: false,
  });

  const handleDateChange = (date) => {
    if (onDateChanged) onDateChanged(date);
  };

  return (
    <FBFieldWrapper
      label={label}
      helperInfo={helperInfo}
      labelPosition={labelPosition}
      required={required}
    >
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={locale || language.currentLanguage}
      >
        <KeyboardDatePicker
          value={value}
          onChange={handleDateChange}
          required={required}
          error={error}
          helperText={error ? errorMessage : ""}
          format={format}
          placeholder={placeholder}
          autoOk={autoOk}
          disablePast={disablePast}
          disableFuture={disableFuture}
          disabled={disabled}
          clearable={clearable}
          inputVariant="outlined"
          variant={variant}
          InputProps={{
            startAdornment: <span className={styles.startAdornmentIcon} />,
            endAdornment: <span className={styles.endAdornmentIcon} />,
          }}
          className={styles.datePickerInput}
          DialogProps={{
            className: styles.dialogClass,
          }}
          elevetion={0}
          {...restProps}
        />
      </MuiPickersUtilsProvider>
    </FBFieldWrapper>
  );
}

FBDatePicker.propTypes = {
  label: PropTypes.string,
  helperInfo: PropTypes.string,
  labelPosition: PropTypes.oneOf([FBLabelPosition.ABOVE, FBLabelPosition.SIDE]),
  required: PropTypes.bool,
  locale: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
    PropTypes.object,
  ]),
  onDateChanged: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  autoOk: PropTypes.bool,
  clearable: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  variant: PropTypes.oneOf(["dialog", "inline", "static"]),
};

FBDatePicker.defaultProps = {
  label: "",
  helperInfo: "",
  labelPosition: FBLabelPosition.SIDE,
  required: false,
  format: "DD/MM/YYYY",
  variant: "inline",
};

export default FBDatePicker;
