import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";

import {
  GetAdvanceSearchContacts,
  PropertiesAdvanceSearchTest,
  GetAllUserSearchCriteria,
} from "../Services";
import { PropertiesMapper } from "../Views/Home/PropertiesView/PropertiesUtilities/PropertiesMapper";
import { ContactsMapper } from "../Views/Home/ContactsView/ContactsUtilities";
import { SearchCriteriaEnum } from "../Enums";

// Create the context
const NewLayoutSharedContext = createContext();

// Provider Component
export const NewLayoutSharedProvider = ({ children }) => {
  const [advancedSearchBody, setAdvancedSearchBody] = useState({
    criteria: {},
    filterBy: "createdOn",
    orderBy: 2,
    map: null,
    LeadClasses: [],
    contactClasses: [],
  });

  const [contactsData, setContactsData] = useState({
    result: [],
    totalCount: 0,
  });

  const [kycHistoryActions, setKycHistoryActions] = useState({
    activeItem: null,
    isShareOpen: false,
    isRiskRatingClicked: false,
    isViewOpen: false,
  });

  const [isAddTaskDialogOpen, setIsAddTaskDialogOpen] = useState(false);
  const [addActivity, setAddActivity] = useState(false);
  const [propertiesData, setPropertiesData] = useState({
    result: [],
    totalCount: 0,
  });

  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 25,
  });

  const [isLoading, setIsLoading] = useState(true);
  const isContactPage = window.location.hostname.includes("Contacts-CRM");
  const [savedFiltersCriteria, setSavedFiltersCriteria] = useState({
    isOpen: false,
    selectedValue: undefined,
    selectedId: undefined,
    data: [],
    filterDataToAdd: {},
    type: isContactPage ? "contact" : "property",
  });
  const [isCriteriaLoading, setIsCriteriaLoading] = useState([]);
  const [filterModalData, setFilterModalData] = useState({
    isOpen: false,
    selectedValue: undefined,
    selectedId: undefined,
    data: [],
    selectedColumns: [],
  });

  const fetchProperties = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await PropertiesAdvanceSearchTest(
        {
          pageIndex: pagination.currentPage - 1,
          pageSize: pagination.itemsPerPage,
          search: "",
        },
        advancedSearchBody
      );

      if (!(response && response.status && response.status !== 200)) {
        setPropertiesData({
          result: ((response && response.result) || []).map(
            (item) =>
              item?.propertyJson &&
              PropertiesMapper(item, JSON.parse(item?.propertyJson)?.property)
          ),
          totalCount: (response && response.totalCount) || 0,
        });
      } else {
        setPropertiesData({
          result: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    } finally {
      setIsLoading(false);
    }
  }, [pagination, advancedSearchBody]);

  useEffect(() => {
    fetchProperties();
  }, [pagination, advancedSearchBody]);

  ////////////////////////////////

  const fetchContacts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await GetAdvanceSearchContacts(
        {
          pageIndex: pagination.currentPage - 1,
          pageSize: pagination.itemsPerPage,
        },
        advancedSearchBody
      );

      if (!(response && response.status && response.status !== 200)) {
        setContactsData({
          result: ((response && response.result) || []).map(
            (item) =>
              item.contactJson &&
              ContactsMapper(item, JSON.parse(item.contactJson).contact)
          ),
          totalCount: (response && response.totalCount) || 0,
        });
      } else {
        setContactsData({
          result: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    } finally {
      setIsLoading(false);
    }
  }, [pagination, advancedSearchBody]);

  const getSearchCriteria = useCallback(async () => {
    setIsCriteriaLoading(true);

    try {
      const res = await GetAllUserSearchCriteria(
        !isContactPage
          ? SearchCriteriaEnum.Property.typeId
          : SearchCriteriaEnum.Contact.typeId
      );

      if (!(res && res.status && res.status !== 200)) {
        setSavedFiltersCriteria((prevData) => ({
          ...prevData,
          data: res,
        }));
      } else {
        setSavedFiltersCriteria((prevData) => ({
          ...prevData,
          data: [],
        }));
      }
    } catch (error) {
      console.error("Failed to fetch search criteria:", error);
    } finally {
      setIsCriteriaLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchContacts();
  }, [pagination, advancedSearchBody]);

  useEffect(() => {
    getSearchCriteria();
  }, []);

  return (
    <NewLayoutSharedContext.Provider
      value={{
        advancedSearchBody,
        setAdvancedSearchBody,
        propertiesData,
        pagination,
        setPagination,
        isLoading,
        savedFiltersCriteria,
        setSavedFiltersCriteria,
        isCriteriaLoading,
        filterModalData,
        setFilterModalData,
        isAddTaskDialogOpen,
        setIsAddTaskDialogOpen,
        addActivity,
        setAddActivity,
        setPropertiesData,
        kycHistoryActions,
        setKycHistoryActions,
        contactsData,
      }}
    >
      {children}
    </NewLayoutSharedContext.Provider>
  );
};

// Custom hook to use the context
export const useNewLayoutShared = () => useContext(NewLayoutSharedContext);
