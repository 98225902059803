import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SelectComponet, PermissionsComponent, CheckboxesComponent } from '../../../../../../../Components';
import { getErrorByName } from '../../../../../../../Helper';
import { ClientsSegmentationPermissions } from '../../../../../../../Permissions';
import { FormCategotyType, FieldName, FilterTypes, FieldValue } from '.';
import { useEdges } from 'react-flow-renderer';

export const QeuriesComponents = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  schema,
  setIfHaveAnyErrors,
  ifHaveAnyErrors,
  fields,
  setFields,
  searchHandler,
  setIsOpenSaveDialog,
  SelectedData,
  LeadFormData,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  return (
    <div>
      {state.conditions &&
        state.conditions.map((item, index) => (
          <div className='client-segmentation-item-wrapper' key={`segmentation${index + 1}`}>
            <div className='client-segmentation-section'>
              <div className='d-flex w-100'>
                <SelectComponet
                  labelValue={!index ? t(`${translationPath}select_type`) : ''}
                  emptyItem={{ value: null, text: t(`${translationPath}select_type`), isHiddenOnOpen: true }}
                  idRef={`selectForm${index + 1}`}
                  data={
                    (() => {
                      let data = [];
                      let Contact = { key: 'contacts', value: 'Contact' };
                      let Lead = { key: 'leads', value: 'Lead' }

                      if (SelectedData) {
                        if (SelectedData.formName === "MainLeadFrom-lead") {
                          if (SelectedData.actionType !== "Action") {
                            data = [
                              Contact,
                              Lead
                            ];
                          } else {
                            data = [
                              Lead
                            ];
                          }
                        } else if (SelectedData.formName === "MainContactForm-contact" && SelectedData.actionType === "Action") {
                          data = [
                            Contact
                          ];
                        }
                      }
                      return data;
                    })()
                  }
                  value={item.category}
                  valueInput='key'
                  textInput='value'
                  onSelectChanged={(value) => {
                    let updatelist = state.conditions;
                    updatelist[index] = {
                      ...updatelist[index],
                      category: value,
                      categoryType: null,
                      displayPath: null,
                      searchKey: null,
                      fieldName: null,
                      value: null,
                      operator: SelectedData.actionType !== "Condition" ? 1 : null,
                    }
                    setState({ id: 'conditions', value: updatelist });
                  }}
                  isWithError
                  error={getErrorByName(schema, `conditions.${index}`, 'state.userNotSet'
                  ).error}
                  translationPathForData={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </div>
            </div>
            {item.category && (
              <div className='client-segmentation-section'>
                <div className='d-flex w-100'>
                  <FormCategotyType
                    item={item}
                    index={index}
                    state={state}
                    SelectedData={SelectedData}
                    setState={setState}
                    translationPathForData={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    schema={schema}
                  />
                </div>
              </div>
            )}
            {item.categoryType && (
              <div className='client-segmentation-section'>
                <div className='d-flex w-100'>
                  <FieldName
                    item={item}
                    index={index}
                    state={state}
                    setState={setState}
                    SelectedData={SelectedData}
                    translationPathForData={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    schema={schema}
                    fields={fields}
                    setFields={setFields}
                  />
                </div>
              </div>
            )}
            {SelectedData.actionType === "Condition" && item.fieldName && (
              <div className='client-segmentation-section'>
                <div className='d-flex w-100'>
                  <FilterTypes
                    item={item}
                    index={index}
                    SelectedData={SelectedData}
                    state={state}
                    setState={setState}
                    translationPathForData={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    schema={schema}
                  />
                </div>
              </div>
            )}
            {SelectedData.actionType === "Condition" && item.operator ? (
              <div className='client-segmentation-section'>
                <div className='d-flex w-100'>
                  <FieldValue
                    item={item}
                    index={index}
                    SelectedData={SelectedData}
                    state={state}
                    setState={setState}
                    translationPathForData={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    schema={schema}
                    setIfHaveAnyErrors={setIfHaveAnyErrors}
                  />
                </div>
              </div>
            ) : (
              <div className='client-segmentation-section'>
                <div className='d-flex w-100'>
                  <FieldValue
                    item={item}
                    index={index}
                    SelectedData={SelectedData}
                    state={state}
                    setState={setState}
                    translationPathForData={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    schema={schema}
                    setIfHaveAnyErrors={setIfHaveAnyErrors}
                  />
                </div>
              </div>
            )}

            {(index + 1 !== state.conditions.length) && (
              <div className='client-segmentation-section'>
                <div className='d-flex w-100'>
                  <SelectComponet
                    labelValue={!index ? t(`${translationPath}Operator`) : ''}
                    idRef={`operatorRef${index + 1}`}
                    value={item.operand}
                    data={[{ key: 'AND', value: 'AND' }, { key: 'OR', value: 'OR' }]}
                    emptyItem={{ value: null, text: t(`${translationPath}select-operator`), isHiddenOnOpen: true }}
                    valueInput='key'
                    textInput='value'
                    onSelectChanged={(value) => {
                      let updatelist = state.conditions;
                      updatelist[index] = { ...updatelist[index], operand: value }
                      setState({ id: 'conditions', value: updatelist });
                    }}
                    isDisabled={index !== 0}
                    translationPathForData={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>
              </div>
            )}

            <div className='client-segmentation-operator-section'>
              {(index + 1) === state.conditions.length && (
                <div>
                  <ButtonBase
                    className='btns-icon theme-solid bg-secondary-light mt-1 mx-2'
                    onClick={() => {
                      let queryList = state.conditions;
                      queryList[index] = { ...queryList[index], operand: state.conditions[0].operand }
                      queryList.push({
                        category: null,
                        categoryType: null,
                        searchKey: null,
                        fieldName: null,
                        displayPath: null,
                        value: null,
                        operator: SelectedData.actionType !== "Condition" ? 1 : null,
                        operand: state.conditions[0].operand || 'AND'
                      });
                      setState({ id: 'conditions', value: queryList });
                    }}
                    disabled={!item.category || !item.categoryType || !item.fieldName ||
                      (item.value === null || item.value === undefined || item.value === 0) || !item.operator || ifHaveAnyErrors}
                  >
                    <span className='mdi mdi-plus c-black-light' />
                  </ButtonBase>
                </div>
              )}
              <ButtonBase
                className='btns-icon theme-solid bg-secondary-light mt-1 mx-2'
                disabled={(index + 1 === state.conditions.length && index === 0)}
                onClick={() => {
                  let queryList = state.conditions;
                  queryList.splice(index, 1);
                  queryList[state.conditions.length - 1] = { ...queryList[state.conditions.length - 1] };
                  setState({ id: 'conditions', value: queryList });
                }}
              >
                <span className='mdi mdi-minus c-black-light' />
              </ButtonBase>
            </div>
          </div>
        ))}
    </div>
  );
}

QeuriesComponents.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  setIfHaveAnyErrors: PropTypes.func.isRequired,
  ifHaveAnyErrors: PropTypes.bool.isRequired,
  fields: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  setFields: PropTypes.func.isRequired,
};
