import React, { useState } from 'react';
import './UnitStatusBreadcrumb.scss';
import { UnitStatusDraftDialog } from '../../UnitsSalesView/UnitsSalesUtilities/CardDetailsComponent/Dialogs';
import { UnitsOperationTypeEnum, UnitsStatusEnum } from '../../../../Enums';
import { useHistory } from 'react-router-dom';
import MarkUnitAvailableDialog from '../UnitsUtilities/Dialogs/MarkUnitAvailableDialog';
import PublishToWebsiteDialog from '../UnitsUtilities/Dialogs/PublishToWebsiteDialog';
import { CanMakeQuickActions } from '../../../../Services';
import { getIsAllowedPermission, showError } from '../../../../Helper';
import {
  UnitsLeasePermissions,
  UnitsSalesPermissions,
} from '../../../../Permissions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TransactionUnitSelectionDialog } from '../../LeaseTransactionJourney/TransactionSteps';

const steps = (isForLease) => [
  {
    label: 'move-to-draft',
    key: 'draft',
    permissionId: isForLease
      ? UnitsLeasePermissions.MakeTransactionOnUnitLeaseToBeDraft.permissionsId
      : UnitsSalesPermissions.MakeTransactionOnUnitSaleToBeDraft.permissionsId,
  },
  {
    label: 'mark-as-available',
    key: 'available',
    permissionId: isForLease
      ? UnitsLeasePermissions.MakeTransactionOnUnitLeaseToBeAvailable
          .permissionsId
      : UnitsSalesPermissions.MakeTransactionOnUnitSaleToBeAvailable
          .permissionsId,
  },
  {
    label: 'publish-to-website',
    key: 'publish',
    permissionId: isForLease
      ? UnitsLeasePermissions.EditPublishedToWebsiteMarketing.permissionsId
      : UnitsSalesPermissions.EditPublishedToWebsiteMarketing.permissionsId,
  },
  {
    label: 'make-transaction',
    key: 'transaction',
    permissionId: isForLease
      ? UnitsLeasePermissions.MakeTransaction.permissionsId
      : UnitsSalesPermissions.AgentTransactionButton.permissionsId,
  },
];

const dialogComponents = {
  draft: UnitStatusDraftDialog,
  available: MarkUnitAvailableDialog,
  publish: PublishToWebsiteDialog,
};

function UnitStatusBreadcrumb({
  unitData,
  reloadData,
  translationPath,
  parentTranslationPath,
  isForLease,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [activeDialog, setActiveDialog] = useState(null);
  const [isTransactionDialogOpen, setIsTransactionDialogOpen] = useState(false);
  const history = useHistory();
  const loginResponse = useSelector((state) => state.login.loginResponse);

  const clickHandler = async (key, permissions) => {
    const result = await CanMakeQuickActions({
      operationTypeId: isForLease
        ? UnitsOperationTypeEnum.rent.key
        : UnitsOperationTypeEnum.sale.key,
      unitId: unitData?.id,
    });
    const isPermitted = getIsAllowedPermission(
      Object.values(isForLease ? UnitsLeasePermissions : UnitsSalesPermissions),
      loginResponse,
      permissions
    );
    if (!(isPermitted || result)) {
      showError(t('action_not_allowed'));
      return;
    }
    if (key === 'transaction') {
      if (isForLease) {
        setIsTransactionDialogOpen(true);
      } else {
        history.push(
          `/home/units-sales/unit-transaction-parameters?unitRefNo=${unitData?.unit_ref_no}`
        );
      }
    } else {
      setActiveDialog((prev) => (prev === key ? null : key));
    }
  };

  const ActiveDialogComponent = activeDialog
    ? dialogComponents[activeDialog]
    : null;

  return (
    <>
      <ul className='status-breadcrumb'>
        {steps(isForLease).map(({ label, key, permissionId }) => {
          let isDisabled = false;
          const statusKeyToCheck = isForLease
            ? unitData?.leaseUnitStatusDetails?.key
            : unitData?.unitStatusDetails?.key;
          if (key === 'draft') {
            if (isForLease) {
              isDisabled = ![
                UnitsStatusEnum.Leased.key,
                UnitsStatusEnum.Available.key,
                UnitsStatusEnum.ReservedLeased.key,
              ].includes(statusKeyToCheck);
            } else {
              isDisabled = ![
                UnitsStatusEnum.Available.key,
                UnitsStatusEnum.Sale.key,
                UnitsStatusEnum.ReservedSale.key,
              ].includes(statusKeyToCheck);
            }
          } else if (key === 'available') {
            if (isForLease) {
              isDisabled = ![
                UnitsStatusEnum.Draft.key,
                UnitsStatusEnum.Leased.key,
                UnitsStatusEnum.ReservedLeased.key,
              ].includes(statusKeyToCheck);
            } else {
              isDisabled = ![
                UnitsStatusEnum.Draft.key,
                UnitsStatusEnum.Sale.key,
                UnitsStatusEnum.ReservedSale.key,
              ].includes(statusKeyToCheck);
            }
          } else if (key === 'transaction') {
            const leaseStatus =
              unitData?.leaseUnitStatusDetails?.name?.toLowerCase();
            const unitStatus = unitData?.unitStatusDetails?.name?.toLowerCase();
            if (isForLease) {
              isDisabled = leaseStatus !== 'available';
            } else {
              isDisabled = !['available', 'draft'].includes(unitStatus);
            }
          } else if (key === 'publish') {
            isDisabled =
              unitData?.isPublishUnitSale === true ||
              unitData?.isPublishUnitLease === true ||
              ![
                UnitsStatusEnum.Available.key,
                UnitsStatusEnum.Draft.key,
              ].includes(statusKeyToCheck);
          }
          return (
            <li
              className={`breadcrumb-contact ${
                isDisabled ? 'breadcrumb-contact-disabled' : ''
              } `}
              key={key}
            >
              <div
                className={`breadcrumb-value ${
                  isDisabled ? 'breadcrumb-disabled' : ''
                } `}
                onClick={() => clickHandler(key, permissionId)}
              >
                <span className='breadcrumb-text'>{t(`${label}`)}</span>
              </div>
            </li>
          );
        })}
      </ul>

      {ActiveDialogComponent && (
        <ActiveDialogComponent
          activeItem={unitData}
          isOpen={ActiveDialogComponent}
          isOpenChanged={() => setActiveDialog(null)}
          breadcrumbStatus={true}
          reloadData={() => {
            setActiveDialog(null);
            if (reloadData) reloadData();
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isForLease={isForLease}
        />
      )}

      {isTransactionDialogOpen && (
        <TransactionUnitSelectionDialog
          isDialogOpen={isTransactionDialogOpen}
          onClose={() => {
            setIsTransactionDialogOpen(false);
          }}
          activeUnitId={unitData?.id}
        />
      )}
    </>
  );
}

export default UnitStatusBreadcrumb;
