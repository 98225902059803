import React from "react";
import { CustomDialog } from "../../../../CustomDialog";
import { useTranslate } from "../../../../../../Hooks";
import { Box } from "@material-ui/core";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import { CustomCopyToClipboard } from "../../../../CustomCopyToClipboard";

//styles
import useStyles from "./styles";

const DuplicatePropertyAlertDialog = ({ onOpen, onClose }) => {
  const styles = useStyles();
  const { translate } = useTranslate("HeaderView");
  const {
    duplicatedProprety,
  } = useVerticalNav();
  return (
    <CustomDialog
      open={onOpen}
      onClose={() => {
        onClose();
      }}
      title={translate("Duplicate-property-alert")}
      subtitle={translate(
        "An-exact-match-for-this-property-already-exists-in-the-system-Please-verify-before-proceeding-to-avoid-duplicates"
      )}
      cancelText={translate("Close")}
      width={"688px"}
      content={
        <Box className={styles.containerDuplicatePropertyAlertDialog}>
          {duplicatedProprety.matchingProperties.length > 0 &&
            duplicatedProprety.matchingProperties.map(
              (duplicatedPropretyItem) => (
                <Box className={styles.containerCardDuplicated}>
                  <Box className={styles.wapperPropretyInfo}>
                    <CustomCopyToClipboard
                      data={duplicatedPropretyItem?.propertyId} // copy
                      childrenData={"#" + duplicatedPropretyItem?.propertyId} // render
                      copyDoneShowTime={1000}
                    />
                    <Box className={styles.propretyTitle}>
                      {duplicatedPropretyItem?.propertyName}
                    </Box>
                    <Box className={styles.propretyAddress}>
                      {[
                        duplicatedPropretyItem?.countryId,
                        duplicatedPropretyItem?.cityId,
                        duplicatedPropretyItem?.districtId,
                        duplicatedPropretyItem?.communityId,
                        duplicatedPropretyItem?.subCommunityId,
                      ]
                        .filter(Boolean)
                        .join(" ,")}
                    </Box>
                  </Box>
                </Box>
              )
            )}
        </Box>
      }
    />
  );
};

export default DuplicatePropertyAlertDialog;
