import React, { useCallback, useEffect, useReducer } from "react";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import { CustomDialog } from "../CustomDialog";
import { Box, Typography, Divider } from "@material-ui/core";
import { CustomAutocomplete } from "../CustomAutocomplete";
import { useTranslate } from "../../../Hooks";
import {
  CityTypeIdEnum,
  CommunityTypeIdEnum,
  ContactClassIdEnum,
  DistrictTypeIdEnum,
  LanguageEnum,
  NationalityEnum,
  SalutationEnum,
} from "../../../Enums";
import { lookupItemsGetId, QuickAddContact } from "../../../Services";
import { CustomButton } from "../CustomButton";

// Styles
import useStyles from "./styles";

const AddNewContactDialog = ({ onClose, onOpen }) => {
  const { addNewContact, setAlertBoxContent } = useVerticalNav();
  const styles = useStyles();
  const { translate } = useTranslate("HeaderView");
  const reducerAddContact = useCallback((state, action) => {
    if (action.id !== "edit") {
      return { ...state, [action.id]: action.value };
    } else {
      return action.value;
    }
  }, []);
  const [selected, setSelected] = useReducer(reducerAddContact, {
    Title: null,
    AssignedTo: null,
    Nationality: null,
    Country: null,
    City: null,
    District: null,
    Community: null,
    Language: null,
    ContactClass: null,
  });
  const [data, setData] = useReducer(reducerAddContact, {
    nationalities: [],
    languages: [],
    contactClasses: [],
    countries: [],
    cities: [],
    discrities: [],
    community: [0],
    salutation: [],
  });
  const [saveDto, setSaveDto] = useReducer(reducerAddContact, {
    contactsTypeId: 1,
    firstName: addNewContact?.contact?.firstName,
    lastName: addNewContact?.contact?.lastName,
    salutationId: "",
    emailAddress: [addNewContact?.contact?.email],
    phoneNumber: [addNewContact?.contact?.phone],
    nationalityId: "",
    countryId: 0,
    cityId: 0,
    districtId: 0,
    communityId: 0,
    postalZipCode: "",
    data_completed: 16,
    contactClassId: null,
    languageId: null,
  });

  const GetNationalities = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: NationalityEnum.lookupTypeId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "nationalities",
        value: res.map((item) => ({
          id: item.lookupItemId,
          name: item.lookupItemName,
          code: item.lookupItemCode,
        })),
      });
    } else
      setData({
        id: "nationalities",
        value: [],
      });
  }, []);

  const getAllLanguages = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: LanguageEnum.lookupTypeId,
    });

    if (!(res && res.status && res.status !== 200))
      setData({
        id: "languages",
        value: res.map((item) => ({
          id: item?.lookupItemId,
          name: item?.lookupItemName,
          code: item?.lookupItemCode,
        })),
      });
    else
      setData({
        id: "languages",
        value: [],
      });
  }, []);

  const getAllContactClass = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: ContactClassIdEnum.lookupTypeId,
    });

    if (!(res && res.status && res.status !== 200))
      setData({
        id: "contactClasses",
        value: res.map((item) => ({
          id: item?.lookupItemId,
          name: item?.lookupItemName,
          code: item?.lookupItemCode,
        })),
      });
    else
      setData({
        id: "contactClasses",
        value: [],
      });
  }, []);

  const GetCountries = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: NationalityEnum.lookupTypeId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "countries",
        value: res.map((item) => ({
          id: item.lookupItemId,
          name: item.lookupItemName,
          code: item.lookupItemCode,
        })),
      });
    } else
      setData({
        id: "countries",
        value: [],
      });
  }, []);

  const GetCities = useCallback(async (countryId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: CityTypeIdEnum.lookupTypeId,
      lookupParentId: countryId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "cities",
        value: res.map((item) => ({
          id: item.lookupItemId,
          name: item.lookupItemName,
          code: item.lookupItemCode,
        })),
      });
    } else
      setData({
        id: "cities",
        value: [],
      });
  }, []);

  const GetDistrict = useCallback(async (cityId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: DistrictTypeIdEnum.lookupTypeId,
      lookupParentId: cityId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "discrities",
        value: res.map((item) => ({
          id: item.lookupItemId,
          name: item.lookupItemName,
          code: item.lookupItemCode,
        })),
      });
    } else
      setData({
        id: "discrities",
        value: [],
      });
  }, []);

  const GetCommunity = useCallback(async (districtId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: CommunityTypeIdEnum.lookupTypeId,
      lookupParentId: districtId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "community",
        value: res.map((item) => ({
          id: item.lookupItemId,
          name: item.lookupItemName,
          code: item.lookupItemCode,
        })),
      });
    } else
      setData({
        id: "community",
        value: [],
      });
  }, []);

  const GetSalutations = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: SalutationEnum.lookupTypeId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "salutation",
        value: res.map((item) => ({
          id: item.lookupItemId,
          name: item.lookupItemName,
        })),
      });
    } else
      setData({
        id: "salutation",
        value: [],
      });
  }, []);

  console.log("state :", saveDto);
  console.log("selected :", selected);
  console.log("data :", data);

  const restCounryRelatedChange = () => {
    setSelected({
      id: "City",
      value: null,
    });
    setSelected({
      id: "District",
      value: null,
    });
    setSelected({
      id: "Community",
      value: null,
    });
    setSaveDto({
      id: "cityId",
      value: null,
    });
    setSaveDto({
      id: "districtId",
      value: null,
    });
    setSaveDto({
      id: "communityId",
      value: null,
    });
  };

  const restCityRelatedChange = () => {
    setSelected({
      id: "District",
      value: null,
    });
    setSelected({
      id: "Community",
      value: null,
    });
    setSaveDto({
      id: "districtId",
      value: null,
    });
    setSaveDto({
      id: "communityId",
      value: null,
    });
  };

  const restDistrictRelatedChange = () => {
    setSelected({
      id: "Community",
      value: null,
    });
    setSaveDto({
      id: "communityId",
      value: null,
    });
  };
  const saveAndCompleteHandler = () => {};
  const AddContactHandler = async () => {
    try {
      const result = await QuickAddContact({
        ...saveDto,
        phoneNumber: [addNewContact?.contact?.phone],
        emailAddress: [addNewContact?.contact?.email],
      });
      if (!(result && result.data)) {
        setAlertBoxContent({
          display: true,
          variant: "success",
          title: "Comment added successfully!",
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      } else {
        setAlertBoxContent({
          display: true,
          variant: "error",
          title: "Something went wrong!",
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    } catch (error) {
      console.error("some thing wrong !", error);
    }
  };

  useEffect(() => {
    GetNationalities("");
    getAllLanguages();
    getAllContactClass();
    GetCountries();
    GetSalutations();
  }, [
    GetNationalities,
    getAllLanguages,
    getAllContactClass,
    GetCountries,
    GetSalutations,
  ]);

  console.log("addNewContact:", addNewContact);
  return (
    <>
      <CustomDialog
        open={onOpen}
        onClose={onClose}
        title={translate("Add-new-contacts")}
        subtitle={translate(
          "Create-a-new-contact-entry-specifying-whether-it-is-an-individual-or-corporate-entity-to-categorize-and-manage-contacts-effectively"
        )}
        width={"1024px"}
        confirmText={translate("Add-contact")}
        onConfirm={() => {
          AddContactHandler();
        }}
        contentDialogClass={styles.containerContentDialog}
        isDisabled={
          saveDto.nationalityId === "" ||
          !saveDto.nationalityId ||
          !saveDto.languageId ||
          !saveDto.contactClassId ||
          saveDto.salutationId === "" ||
          !saveDto.salutationId
        }
        content={
          <Box className={styles.wrapperAddNewContactDialog}>
            <Box className={styles.containerContactInfo}>
              <Box className={styles.containerInfoItem}>
                <Typography className={styles.InfoTitle}>
                  {translate("First-name")}
                </Typography>
                <Typography className={styles.InfoValue}>
                  {addNewContact?.contact?.firstName}
                </Typography>
              </Box>
              <Box className={styles.containerInfoItem}>
                <Typography className={styles.InfoTitle}>
                  {translate("Last-name")}
                </Typography>
                <Typography className={styles.InfoValue}>
                  {addNewContact?.contact?.lastName}
                </Typography>
              </Box>
              <Box className={styles.containerInfoItem}>
                <Typography className={styles.InfoTitle}>
                  {translate("Phone-number")}
                </Typography>
                <Typography className={styles.InfoValue}>
                  {addNewContact?.contact?.phone}
                </Typography>
              </Box>
              <Box className={styles.containerInfoItem}>
                <Typography className={styles.InfoTitle}>
                  {translate("Email")}
                </Typography>
                <Typography className={styles.InfoValue}>
                  {addNewContact?.contact?.email}
                </Typography>
              </Box>
            </Box>
            <Box className={styles.containerContactFiled}>
              <Box className={styles.continerFiledItem}>
                <Typography className={styles.TitleFiledItem}>
                  {translate("Salutation")}*
                </Typography>
                <CustomAutocomplete
                  options={
                    data.salutation && data.salutation.length > 0
                      ? data.salutation
                      : []
                  }
                  value={selected?.Title || null}
                  placeholder={`Select value`}
                  onChange={(e, value) => {
                    console.log("value :", value);
                    setSelected({
                      id: "Title",
                      value: value,
                    });
                    setSaveDto({
                      id: "salutationId",
                      value: value?.id,
                    });
                  }}
                  getOptionLabel={(option) => (option && option.name) || ""}
                  getOptionValue={(option) => (option && option.id) || ""}
                />
              </Box>
              <Box className={styles.continerFiledItem}>
                <Typography className={styles.TitleFiledItem}>
                  {translate("Nationality")}*
                </Typography>
                <CustomAutocomplete
                  options={
                    data.nationalities && data.nationalities.length > 0
                      ? data.nationalities
                      : []
                  }
                  value={selected?.Nationality || null}
                  placeholder={`Select value`}
                  onChange={(e, value) => {
                    console.log("value :", value);
                    setSelected({
                      id: "Nationality",
                      value: value,
                    });
                    setSaveDto({
                      id: "nationalityId",
                      value: value?.id,
                    });
                  }}
                  getOptionLabel={(option) => (option && option.name) || ""}
                  getOptionValue={(option) => (option && option.id) || ""}
                />
              </Box>
              <Divider className={styles.divider}></Divider>
              <Box className={styles.continerFiledItem}>
                <Typography className={styles.TitleFiledItem}>
                  {translate("Languages")}*
                </Typography>
                <CustomAutocomplete
                  options={
                    data.languages && data.languages.length > 0
                      ? data.languages
                      : []
                  }
                  value={selected?.Language || null}
                  placeholder={`Select value`}
                  onChange={(e, value) => {
                    console.log("value :", value);
                    setSelected({
                      id: "Language",
                      value: value,
                    });
                    setSaveDto({
                      id: "languageId",
                      value: value?.id,
                    });
                  }}
                  getOptionLabel={(option) => (option && option.name) || ""}
                  getOptionValue={(option) => (option && option.id) || ""}
                />
              </Box>
              <Divider className={styles.divider}></Divider>
              <Box className={styles.continerFiledItem}>
                <Typography className={styles.TitleFiledItem}>
                  {translate("Contact-class")}*
                </Typography>
                <CustomAutocomplete
                  options={
                    data.contactClasses && data.contactClasses.length > 0
                      ? data.contactClasses
                      : []
                  }
                  placeholder={`Select value`}
                  value={selected?.ContactClass || null}
                  onChange={(e, value) => {
                    console.log("value :", value);
                    setSelected({
                      id: "ContactClass",
                      value: value,
                    });
                    setSaveDto({
                      id: "contactClassId",
                      value: value?.id,
                    });
                  }}
                  getOptionLabel={(option) => (option && option?.name) || ""}
                  getOptionValue={(option) => (option && option?.id) || ""}
                />
              </Box>
              <Divider className={styles.divider}></Divider>
              <Box className={styles.continerFiledItem}>
                <Typography className={styles.TitleFiledItem}>
                  {translate("Country")}
                </Typography>
                <CustomAutocomplete
                  options={
                    data?.countries && data.countries.length > 0
                      ? data?.countries
                      : []
                  }
                  value={selected?.Country || null}
                  placeholder={`Select value`}
                  onChange={(e, value) => {
                    console.log("value :", value);
                    setSelected({
                      id: "Country",
                      value: value,
                    });
                    setSaveDto({
                      id: "countryId",
                      value: value?.id,
                    });
                    restCounryRelatedChange();

                    if (value) GetCities(value?.id);
                    else {
                      setData({
                        id: "cities",
                        value: [],
                      });
                      restCounryRelatedChange();
                    }
                  }}
                  getOptionLabel={(option) => (option && option?.name) || ""}
                  getOptionValue={(option) => (option && option?.id) || ""}
                />
              </Box>
              <Divider className={styles.divider}></Divider>
              <Box className={styles.continerFiledItem}>
                <Typography className={styles.TitleFiledItem}>
                  {translate("City")}
                </Typography>
                <CustomAutocomplete
                  options={
                    data.cities && data.cities.length > 0 ? data.cities : []
                  }
                  value={selected?.City || null}
                  placeholder={`Select value`}
                  onChange={(e, value) => {
                    console.log("value :", value);
                    setSelected({
                      id: "City",
                      value: value,
                    });
                    setSaveDto({
                      id: "cityId",
                      value: value?.id,
                    });
                    restCityRelatedChange();
                    if (value) GetDistrict(value?.id);
                    else {
                      setData({
                        id: "discrities",
                        value: [],
                      });
                      restCityRelatedChange();
                    }
                  }}
                  getOptionLabel={(option) => (option && option.name) || ""}
                  getOptionValue={(option) => (option && option.id) || ""}
                />
              </Box>
              <Divider className={styles.divider}></Divider>
              <Box className={styles.continerFiledItem}>
                <Typography className={styles.TitleFiledItem}>
                  {translate("District")}
                </Typography>
                <CustomAutocomplete
                  options={
                    data.discrities && data.discrities.length > 0
                      ? data.discrities
                      : []
                  }
                  value={selected?.District || null}
                  placeholder={`Select value`}
                  onChange={(e, value) => {
                    console.log("value :", value);

                    setSelected({
                      id: "District",
                      value: value,
                    });
                    setSaveDto({
                      id: "districtId",
                      value: value?.id,
                    });
                    restDistrictRelatedChange();
                    if (value) GetCommunity(value?.id);
                    else {
                      setData({
                        id: "community",
                        value: [],
                      });
                      restDistrictRelatedChange();
                    }
                  }}
                  getOptionLabel={(option) => (option && option.name) || ""}
                  getOptionValue={(option) => (option && option.id) || ""}
                />
              </Box>
              <Divider className={styles.divider}></Divider>
              <Box className={styles.continerFiledItem}>
                <Typography className={styles.TitleFiledItem}>
                  {translate("Community")}
                </Typography>
                <CustomAutocomplete
                  options={
                    data.community && data.community.length > 0
                      ? data.community
                      : []
                  }
                  value={selected?.Community || null}
                  placeholder={`Select value`}
                  onChange={(e, value) => {
                    console.log("value :", value);
                    setSelected({
                      id: "Community",
                      value: value,
                    });
                    setSaveDto({
                      id: "communityId",
                      value: value?.id,
                    });
                  }}
                  getOptionLabel={(option) => (option && option.name) || ""}
                  getOptionValue={(option) => (option && option.id) || ""}
                />
              </Box>
            </Box>
          </Box>
        }
        moreAction={
          <CustomButton
            boxShadow="none"
            size="lg"
            variant="text"
            color="tertiaryGray"
            onClick={() => {
              saveAndCompleteHandler();
            }}
            style={{ marginInlineEnd: "auto" }}
          >
            Save & Complete Details
          </CustomButton>
        }
      />
    </>
  );
};
export default AddNewContactDialog;
