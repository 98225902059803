import React from "react";
import { useTranslation } from "react-i18next";
import { NumberWithCommas } from "../../../../../../Helper";

export const GaugeChart = ({
  minPrice,
  maxPrice,
  medianPrice,
  psiPrice,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const rawHTML = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <title>Gauge Chart</title>
    <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
    <style>
  body {
    font-family: Arial, sans-serif;
    text-align: center;
    margin-bottom: 2rem
  }

  .chart-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: .5rem;
    max-width: 220px;
    max-height: 220px
  }

  .chart-item {
    width: 220px
  }

  h3 {
    margin-bottom: 15px
  }

  .gauge-container {
    max-width: 220px;
    max-height: 220px
  }

  .info-line {
    display: flex, flex-diraction: column, 
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 3rem  ,
    .info {
      margin-bottom: 3rem  
        }

      }

  .avg {
    font-family: Arial, sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px
  }

  .psi-price {
    margin-top: 10px;
    font-family: Arial, sans-serif;
    color: #2153b1;
    font-weight: 700;
    font-size: 14;
    text-align: center
  }

  .price {
    margin: 0 auto;
    max-width: 200px;
    max-height: 200px
    }
   </style>
  </head>

     <body>
         <h3> 
          ${t(`${translationPath}gauge-chart`)}
         </h3>
         <div id="psi-price" class="psi-price">
         </div>
        <span class="mdi mdi-home-currency-usd" />
        <div class="chart-container">
         <div class="chart-item">
            <canvas id="chart1"></canvas>
          </div>
         </div>
        <div id="avg" class="avg"></div>
        <div class="info-line" id="info-line">
        <div id="min-value" class="info"></div>
        <div id="max-value" class="info"></div>
        </div>

  <script>

    function createGaugeChart(canvasId, psiValue, minValue, maxValue, avgValue) {

    const ctx = document.getElementById(canvasId).getContext("2d");
    const priceValuesColors = () => {
      let chartColorsAndValues = null;
      if (psiValue > avgValue) {
        chartColorsAndValues = {
          data: [maxValue, 0],
          backgroundColor: ['#ef0433', '#E0E0E0'],
          hoverBackgroundColor: ['#ef0433', '#E0E0E0'],
        };
        return chartColorsAndValues;
      } else if (psiValue === 0) {
        chartColorsAndValues = {
          data: [0, maxValue],
          backgroundColor: ['#E0E0E0', '#E0E0E0'],
          hoverBackgroundColor: ['#E0E0E0', '#E0E0E0'],
        };
        return chartColorsAndValues;
      } else if (psiValue !== minValue && psiValue === avgValue) {
        chartColorsAndValues = {
          data: [psiValue - minValue, maxValue - psiValue],
          backgroundColor: ['#bedffe', '#E0E0E0'],
          hoverBackgroundColor: ['#bedffe', '#E0E0E0'],
        };
        return chartColorsAndValues;
      } else if (psiValue < minValue && minValue === maxValue) {
        chartColorsAndValues = {
          data: [0, maxValue],
          backgroundColor: ['#E0E0E0', '#E0E0E0'],
          hoverBackgroundColor: ['#E0E0E0', '#E0E0E0'],
        };
        return chartColorsAndValues;
      } else if (psiValue === avgValue && minValue === maxValue) {
        chartColorsAndValues = {
          data: [psiValue / 2, psiValue / 2],
          backgroundColor: ['#bedffe', '#E0E0E0'],
          hoverBackgroundColor: ['#bedffe', '#E0E0E0'],
        };
        return chartColorsAndValues;
      } else if (minValue === maxValue) {
        chartColorsAndValues = {
          data: [maxValue, 0],
          backgroundColor: ['#E0E0E0', '#E0E0E0'],
          hoverBackgroundColor: ['#E0E0E0', '#E0E0E0'],
        };
        return chartColorsAndValues;
      } else if (psiValue === minValue && psiValue < avgValue) {
        chartColorsAndValues = {
          data: [0, maxValue],
          backgroundColor: ['#E0E0E0', '#E0E0E0'],
          hoverBackgroundColor: ['#E0E0E0', '#E0E0E0'],
        };
        return chartColorsAndValues;
      } else if (psiValue === minValue && psiValue === avgValue) {
        chartColorsAndValues = {
          data: [psiValue, maxValue - psiValue],
          backgroundColor: ['#E0E0E0', '#E0E0E0'],
          hoverBackgroundColor: ['#E0E0E0', '#E0E0E0'],
        };
        return chartColorsAndValues;
      } else if (psiValue < minValue && psiValue < avgValue) {
        chartColorsAndValues = {
          data: [0, maxValue],
          backgroundColor: ['#E0E0E0', '#E0E0E0'],
          hoverBackgroundColor: ['#E0E0E0', '#E0E0E0']
        };
        return chartColorsAndValues;
      } else if (psiValue === minValue && psiValue < avgValue) {
        chartColorsAndValues = {
          data: [0, maxValue],
          backgroundColor: ['#E0E0E0', '#E0E0E0'],
          hoverBackgroundColor: ['#E0E0E0', '#E0E0E0']
        };
        return chartColorsAndValues;
      } else {
        chartColorsAndValues = {
          data: [psiValue - minValue, maxValue - psiValue],
          backgroundColor: ['#59cc0e', '#E0E0E0'],
          hoverBackgroundColor: ['#59cc0e', '#E0E0E0']
        };
      }
      return chartColorsAndValues;
    };

    const dataOfUnit = priceValuesColors();

    const data = {
      labels: [],
      datasets: [{
        data: dataOfUnit?.data ? dataOfUnit?.data : [maxValue, 0],
        backgroundColor: dataOfUnit?.backgroundColor,
        hoverBackgroundColor: dataOfUnit?.hoverBackgroundColor,
        borderWidth: [0],
      }],
    };

    const config = {
      type: "doughnut",
      data: data,
      options: {
        rotation: -90,
        circumference: 180,
        cutout: "70%",
        responsive: true,
        plugins: {
          tooltip: {
            enabled: false
          },
          legend: {
            display: false
          },
        },
        elements: {
          arc: {
            borderWidth: 0
          }
        }
      },

      plugins: [{
        afterDatasetDraw(chart, args, options) {
          const {
            ctx,
            chartArea
          } = chart;
          const {
            width,
            height
          } = chartArea;
          const centerX = width / 2 + chartArea.left;
          const centerY = height - 50 + chartArea.top;
          const radius = chart.getDatasetMeta(0).data[0].outerRadius;

          function getCoordinates(value) {
           
            let angle = 0;
            if (maxValue === minValue) angle = Math.PI / 2;
            else if (value > maxValue) angle = Math.PI - 1200;
            else if (value === minValue) angle = Math.PI - 600;
            else if (value < minValue) angle = Math.PI - 600;
            else if (value === maxValue) angle = Math.PI + 1200;
            else angle = Math.PI * (value - minValue) / (maxValue - minValue);
            return {
              x: centerX + radius * Math.cos(angle - Math.PI),
              y: centerY + radius * Math.sin(angle - Math.PI),
            };
          }
          // Draw Average Pointer (Yellow)
          const avgCoords = getCoordinates(avgValue);
          ctx.beginPath();
          ctx.moveTo(centerX, centerY);
          ctx.lineTo(avgCoords.x, avgCoords.y);
          ctx.lineWidth = 4;
          ctx.strokeStyle = 'yellow';
          ctx.stroke();
          ctx.beginPath();
          ctx.arc(avgCoords.x, avgCoords.y, 5, 0, 2 * Math.PI);
          ctx.fillStyle = 'yellow';
          ctx.fill();
          ctx.font = "bold 14px Arial";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.font = "bold 14px Arial";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
        }
      }]
    };
    new Chart(ctx, config);
  } 

   // Create Chart with Given Values
  createGaugeChart("chart1", ${psiPrice},${minPrice}, ${maxPrice}, ${medianPrice});

  document.getElementById('psi-price').textContent = "${t(
    `${translationPath}psi-price`
  )} : ${psiPrice && NumberWithCommas(psiPrice)} AED 💰";
  document.getElementById('avg').textContent = "${t(
    `${translationPath}median-price`
  )} : ${NumberWithCommas(medianPrice)}";
  document.getElementById('min-value').textContent = "${t(
    `${translationPath}min-price`
  )} : ${NumberWithCommas(minPrice)}";
  document.getElementById('max-value').textContent = "${t(
    `${translationPath}max-price`
  )}  : ${NumberWithCommas(maxPrice)} ";

  </script>
  </body>
  </html>
  `;

  return (
    <iframe
      className="mb-4"
      title="Gauge Chart"
      srcDoc={rawHTML}
      style={{ width: "100%", height: "350px", border: "none" }}
    />
  );
};

export default GaugeChart;
