import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    containerNumbersSelector :{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        width:'100%'
    },containerValue:{
        border:`1px solid ${theme.palette.border.primary}`,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        padding:'10px 14px',
        borderRadius:'8px',
        maxWidth:'45px',
        minWidth:'45px',
        width:'45px',
        background:theme.palette.background.paper,
        color:theme.palette.text.placeholder,
        cursor: "pointer",
    },selectedValueStyle:{
        color:theme.palette.text.white,
        background:theme.palette.background.brand_solid,
        border:'none',
    }
  }
})