import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    radioRoot: {
        margin: 0,
        padding: 0,
    },
    radioIcon: {
      borderRadius: "180px",
      border: `2px solid ${theme.palette.border.border_disabled}`,
      width: 16,
      height: 16,
      backgroundColor: theme.palette.background.paper,
      "input:hover ~ &": {
        border: `2px solid ${theme.palette.background.brand_solid}`,
      },
      "input:disabled ~ &": {
        border: `2px solid ${theme.palette.border.border_disabled}`,
        backgroundColor: theme.palette.background.disabled,
      },
    },
    radioCheckedIcon: {
      borderRadius: "180px",
      border: `2px solid ${theme.palette.background.brand_solid}`,
      backgroundColor: theme.palette.background.brand_solid,
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 8,
        height: 8,
        borderRadius: "180px",
        backgroundColor: theme.palette.background.paper,
      },
    },
    radioLabel: {
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      marginRight: "16px",
      verticalAlign: "middle",
      WebkitTapHighlightColor: "transparent",
    },
    containerFiledIconTitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between", // Pushes radio icon to the right
      border: `1px solid ${theme.palette.border.primary}`,
      width: "100%",
      borderRadius: "12px",
      padding: "12px 16px",
      marginLeft: "0px",
      marginRight: "0px",
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
  };
});
