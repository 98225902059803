import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useTranslate } from "../../../../../../Hooks";
import PropTypes from "prop-types";
import { Box, CircularProgress } from "@material-ui/core";
import {
  GetAllDFMTransactionForContact,
  GetAllFormFieldsByFormId,
} from "../../../../../../Services";
import ContactHistoryTimeLine from "../UI/ContactHistoryTimeLine/ContactHistoryTimeLine";
import CustomPaginationHistorySideMenu from "../UI/CustomPaginationHistorySideMenu/CustomPaginationHistorySideMenu";
import { NoDataFound } from "../../../../NoDataFound";

//styles
import useStyles from "./styles";


const CustomContactHistoryComponent = ({
  typeId,
  formType,
}) => {
  const styles = useStyles();
  const query = useQuery();
  const { translate } = useTranslate("NewContactsView");
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [transactions, setTransactions] = useState({
    result: [],
    totalCount: 0,
  });
  const [formsContent, setFormsContent] = useState([]);
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const pathName = window.location.pathname
    .split("/home/")[1]
    .split("/view")[0]
    .split("/")[0];

  const formTypeId = query.get("formType");
  const contactId = query.get("id");
  const GetAllFormByFormId = useCallback(async () => {
    let formId = formTypeId || 1;

    const result = await GetAllFormFieldsByFormId(formId);
    setFormsContent(
      result.map((item) => ({
        type: item.uiWidgetType,
        el: item.formFieldKey
          .replace("-", "_")
          .replace("-", "_")
          .replace("-", "_"),
      }))
    );
  }, [activeItem, pathName]);

  const getAllDFMTransaction = useCallback(async () => {
    setIsLoading(true);
    let result = await GetAllDFMTransactionForContact(pagination, contactId);
    if (!(result && result.status && result.status !== 200)) {
      setTransactions({
        result: result && result.result,
        totalCount: result && result.totalCount,
      });
    } else setTransactions({ result: [], totalCount: 0 });
    setIsLoading(false);
  }, [pagination, pathName]);

  useEffect(() => {
    GetAllFormByFormId();
    getAllDFMTransaction();
  }, [GetAllFormByFormId, getAllDFMTransaction]);

  return (
    <Box className={styles.containerHistoryTimeLinePlsPaggination}>
      <Box className={styles.containerContactHistoryTimeLine}>
        {isLoading ? (
          <Box className={styles.loaderContainer}>
            <CircularProgress />
          </Box>
        ) : transactions?.result?.length > 0 ? (
          <ContactHistoryTimeLine
            timelineItems={transactions.result}
            formsContent={formsContent}
            typeId={typeId}
            formType={formType}
            pathName={pathName}
          />
        ) : (
          <NoDataFound title={translate("History")} />
        )}
      </Box>
      <CustomPaginationHistorySideMenu
        totalItems={transactions.totalCount}
        itemsPerPage={pagination?.pageSize}
        currentPage={pagination?.pageIndex}
        onPageChange={(page) =>
          setPagination((prev) => ({ ...prev, pageIndex: page }))
        }
        onItemsPerPageChange={(items) =>
          setPagination((prev) => ({ ...prev, pageSize: items }))
        }
       />
    </Box>
  );
};
export default CustomContactHistoryComponent;

CustomContactHistoryComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
};
