import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  TabsComponent,
  RadiosGroupComponent,
  CompletedDataComponent,
  LeadsStagesBreadcrumb,
  DialogComponent,
} from "../../../../Components";
import { PermissionDeniedLayout } from "../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout";
import {
  LeadsAdvanceSearchTest,
  GetSensitiveFieldsForUser,
} from "../../../../Services";

import { GetParams, GlobalHistory } from "../../../../Helper";
import { LeadTypeIdEnum, FormsIdsEnum } from "../../../../Enums";
import { LeadsVerticalTabsData } from "../../Common/OpenFileView/OpenFileUtilities/OpenFileData";
import { useLocation } from "react-router-dom";
const parentTranslationPath = "LeadsProfileManagementView";
const translationPath = "";

export const LeadsProfileManagementView = () => {
  const [pathName, setPathName] = useState("");
  const leadId = +GetParams("id");
  const location = useLocation();

  const [list, setList] = useState([]);
  const [isPropertyManagementView, setIsPropertyManagementView] =
    useState(false);
  const [contactType, setContactType] = useState(null);
  const [leadWithPolicy, setLeadWithPolicy] = useState(null);
  const [sensitiveFieldsForUser, setSensitiveFieldsForUser] = useState([]);
  const [leadsPoolSource, setLeadsPoolSource] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [SensitiveLoading, setSensitiveLoading] = useState(false);
  const [leadEmptyFields, setLeadEmptyFields] = useState(null);
  const [
    displayDetailsToUserDependsOnPolicy,
    setDisplayDetailsToUserDependsOnPolicy,
  ] = useState(null);
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const [activeTab, setActiveTab] = useState(0);
  const localActiveItem = localStorage.getItem("activeItem");
  const [contactLead, setContactLead] = useState(false);
  const [viewType, setViewType] = useState(1);
  const [filter, setFilterBy] = useState({
    id: null,
    formType: null,
  });
  const [isSensitiveLoading, setIsSensitiveLoading] = useState(0);
  const [targetedStageId, setTargetedStageId] = useState(null);

  const userHasPolicyToDisplayData = useCallback(async (leadId) => {
    setIsLoading(true);
    const isForLog = true;

    let body = {
      criteria: {
        Ids: [{ searchType: 1, value: leadId }],
      },
      filterBy: "createdOn",
      orderBy: 2,
    };

    const res = await LeadsAdvanceSearchTest(
      { pageIndex: 0, pageSize: 2, isForLog },
      body
    );
    if (!(res && res.status && res.status !== 200)) {
      if (res && res.totalCount > 0) {
        if (res && res.result && res.result.length && res.result[0].leadJson) {
          setLeadsPoolSource(res?.result[0]?.leadsPoolSource);
          const LeadJson = JSON.parse(
            res && res.result && res.result.length && res.result[0].leadJson
          );
          setContactType(LeadJson?.lead?.contact_name?.type);
          setLeadEmptyFields(
            JSON.parse(
              res && res.result && res.result[0] && res.result[0].leadJson
            )
          );
          const lead = {
            ...LeadJson.lead,
            bedrooms:
              (LeadJson.lead &&
                LeadJson.lead.bedrooms &&
                LeadJson.lead.bedrooms.length === 0) ||
              !(LeadJson && LeadJson.lead && LeadJson.lead.bedrooms)
                ? ["Any"]
                : LeadJson.lead.bedrooms,
            bathrooms:
              (LeadJson.lead &&
                LeadJson.lead.bathrooms &&
                LeadJson.lead.bathrooms.length === 0) ||
              !(LeadJson && LeadJson.lead && LeadJson.lead.bathrooms)
                ? ["Any"]
                : LeadJson.lead.bathrooms,
          };
          setLeadWithPolicy({ ...LeadJson, lead: lead });
          localStorage.setItem(
            "leadStatus",
            JSON.stringify(LeadJson && LeadJson.lead && LeadJson.lead.status)
          );
        }
        setDisplayDetailsToUserDependsOnPolicy("allowed");
      } else {
        setLeadWithPolicy(null);
        setLeadEmptyFields(null);
        setDisplayDetailsToUserDependsOnPolicy("notAllowed");
      }
    } else {
      setDisplayDetailsToUserDependsOnPolicy("notAllowed");
      setLeadEmptyFields(null);
      setLeadWithPolicy(null);
    }
    setIsLoading(false);
  });

  const GetSensitiveFieldsForUserAPI = useCallback(async () => {
    setSensitiveLoading(true);
    const allSensitiveFields = await GetSensitiveFieldsForUser(
      filter && +filter.formType === LeadTypeIdEnum.Owner.leadTypeId
        ? FormsIdsEnum.leadsOwner.id
        : FormsIdsEnum.leadsSeeker.id
    );

    if (
      !(
        allSensitiveFields &&
        allSensitiveFields.status &&
        allSensitiveFields.status !== 200
      )
    ) {
      setSensitiveFieldsForUser(allSensitiveFields);
      setIsSensitiveLoading(1);
    } else {
      setSensitiveFieldsForUser([]);
      setIsSensitiveLoading(2);
    }

    setSensitiveLoading(false);
  }, [leadWithPolicy, filter]);

  const appendLeadStatusToURL = (leadStatus) => {
    const newQueryParams = {
      formType: GetParams("formType"),
      id: GetParams("id"),
      leadClass: GetParams("leadClass"),
      leadStatus,
    };
    const strParams = new URLSearchParams(newQueryParams).toString();
    const urlWithStatus = `${location.pathname}?${strParams}`;
    GlobalHistory.replace(urlWithStatus);
  };

  useEffect(() => {
    const pathName = window.location.pathname
      .split("/home/")[1]
      .split("/view")[0];
    const matchingParam = GetParams("matching");

    const leadStatus = leadWithPolicy?.lead?.status?.lookupItemName;
    if (leadStatus) appendLeadStatusToURL(leadStatus);

    if (pathName.includes("Contacts-CRM")) {
      setContactLead(true);
      setActiveTab(0);
    } else if (pathName === "leads/lead-profile-edit" && !matchingParam) {
      setActiveTab(0);
    } else if (pathName === "leads/lead-profile-edit" && matchingParam) {
      setTimeout(() => {
        setActiveTab(2 || 1);
      }, 500);
    }
  }, [leadWithPolicy]);

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };
  useEffect(() => {
    setIsPropertyManagementView(
      pathName === "properties" ||
        pathName === "properties/property-profile-edit"
    );
  }, [pathName]);

  useEffect(() => {
    if (isPropertyManagementView && !contactLead) {
      setList([...LeadsVerticalTabsData.LeadsProprtyManagement]);
    } else if (contactLead && !isPropertyManagementView) {
      if (GetParams("formType") == LeadTypeIdEnum.Owner.leadTypeId)
        setList([
          ...(LeadsVerticalTabsData.leadFromContact &&
            LeadsVerticalTabsData.leadFromContact.filter(
              (tab) =>
                tab.label !== "matching"
            )),
        ]);
      else setList([...LeadsVerticalTabsData.leadFromContact]);
    } else if (!isPropertyManagementView && !contactLead) {
      if (GetParams("formType") == LeadTypeIdEnum.Owner.leadTypeId)
        setList([
          ...(LeadsVerticalTabsData.callCenter &&
            LeadsVerticalTabsData.callCenter.filter(
              (tab) =>
                tab.label !== "matching"
            )),
        ]);
      else setList([...LeadsVerticalTabsData.callCenter]);
    }
  }, [isPropertyManagementView, contactLead, pathName]);

  useEffect(() => {
    setFilterBy({
      formType: GetParams("formType"),
      id: +GetParams("id"),
    });
    setPathName(window.location.pathname.split("/home/")[1].split("/view")[0]);
  }, [GetParams("id")]);

  const reloadData = () => {
    userHasPolicyToDisplayData(filter.id);
    GetSensitiveFieldsForUserAPI();
  };
  useEffect(() => {
    const leadId = filter.id;
    if (leadId && activeTab === 0) userHasPolicyToDisplayData(leadId);
  }, [activeTab, filter]);

  useEffect(() => {
    if (
      filter &&
      filter.id &&
      displayDetailsToUserDependsOnPolicy === "allowed" &&
      activeTab === 0
    )
      GetSensitiveFieldsForUserAPI();
  }, [activeTab, displayDetailsToUserDependsOnPolicy, activeTab, filter]);

  return (
    <div className="leads-profile-wrapper view-wrapper">
      {displayDetailsToUserDependsOnPolicy === "allowed" &&
        activeTab === 0 &&
        list &&
        list.length &&
        list[0].label === "lead-information" && (
          <div className="d-flex">
            <RadiosGroupComponent
              idRef="viewDataRef"
              data={[
                {
                  key: 1,
                  value: "all-data",
                },
                {
                  key: 2,
                  value: "missing-data",
                },
              ]}
              value={viewType}
              labelValue="view"
              labelInput="value"
              valueInput="key"
              themeClass="theme-line"
              parentTranslationPath={parentTranslationPath}
              translationPathForData={translationPath}
              translationPath={translationPath}
              onSelectedRadioChanged={onViewTypeChangedHandler}
            />
            <CompletedDataComponent
              completedData={
                activeItem && activeItem.progress
                  ? activeItem.progress
                  : JSON.parse(localActiveItem) &&
                    JSON.parse(localActiveItem).progress
              }
              typeId="lead"
              formType={filter.formType}
              operationType={
                !isPropertyManagementView && !contactLead
                  ? "callCenter"
                  : isPropertyManagementView && !contactLead
                  ? "leadPropertyManagment"
                  : "leadFromContact"
              }
              activeItem={activeItem}
              leadsPoolSource={leadsPoolSource}
            />
          </div>
        )}

      {displayDetailsToUserDependsOnPolicy === "allowed" && (
        <>
          {" "}
          <LeadsStagesBreadcrumb
            currentStage={leadWithPolicy?.lead?.lead_stage?.lookupItemId}
            setActiveTab={setActiveTab}
            reload={reloadData}
            leadId={+GetParams("id")}
            updateStageId={targetedStageId}
            clearUpdateStageId={() => setTargetedStageId(null)}
            contactId={leadWithPolicy?.lead?.contact_name?.id}
            contactType={contactType}
            leadStatus={leadWithPolicy?.lead?.status?.lookupItemName}
          />
          <div className="m3">
            <TabsComponent
              data={list}
              labelInput="label"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              themeClasses="theme-solid"
              currentTab={activeTab}
              onTabChanged={onTabChanged}
              dynamicComponentProps={{
                viewType: viewType || 1,
                parentTranslationPath,
                translationPath,
                sensitiveFieldsForUser,
                leadWithPolicy,
                leadDataIsLoading: isLoading,
                isSensitiveLoading,
                isLoading,
                leadEmptyFields,
                reloadData,
                taskRelatedTo: "Lead",
                formType: "contactCenterLead",
                setTargetedStageId,
              }}
            />
          </div>{" "}
        </>
      )}
      {displayDetailsToUserDependsOnPolicy === "allowed" && (
        <div className="tabs-content-wrapper" />
      )}
      {displayDetailsToUserDependsOnPolicy === "notAllowed" && (
        <PermissionDeniedLayout />
      )}
    </div>
  );
};
