import React from "react";

function PlatinumIcon({ fill, ...restProps }) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <g clip-path="url(#clip0_6304_2198)">
        <path
          d="M17.5591 10.0855L11.5654 16.683C10.4279 17.9342 8.57289 17.9342 7.43539 16.683L1.44163 10.0855C0.767884 9.34174 0.549136 7.99424 0.951636 7.07549L1.65164 5.50049H17.3491L18.0491 7.07549C18.4516 7.99424 18.2329 9.34174 17.5591 10.0855Z"
          fill="#98A2B3"
        />
        <path
          opacity="0.4"
          d="M17.3498 5.50059H1.65234L3.19234 2.03559C3.60359 1.12559 4.25109 0.381836 5.73859 0.381836H13.2636C14.7511 0.381836 15.3986 1.12559 15.8098 2.03559L17.3498 5.50059Z"
          fill="#98A2B3"
        />
      </g>
      <defs>
        <clipPath id="clip0_6304_2198">
          <rect
            width="17.5"
            height="17.5"
            fill="white"
            transform="translate(0.75 0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PlatinumIcon;
