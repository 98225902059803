import React from "react";
import clsx from "clsx";
import {
  ArrowNarrowLeft,
  CloseXIcon,
} from "../../../assets/icons";
import { Box, Paper, Typography } from "@material-ui/core";
import { useSelectedTheme } from "../../../Hooks";
import { CustomIconButton } from "../CustomIconButton";

// styles
import useStyles from "./styles";

function CustomHistorySideMenu(props) {
  const { title, open, onClose, content, showBackIcon, goBackHandle } = props;
  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <Paper className={clsx(styles.historySideMenu, { [styles.hidden]: !open })}>
      <Box className={styles.header}>
        <Box className={styles.containerTitlePlusBackIcon}>
          {showBackIcon && (
            <CustomIconButton
              variant="text"
              size="none"
              boxShadow="none"
              color="tertiaryColor"
              hideHoverBg
              onClick={() => {
                goBackHandle();
              }}
            >
              <ArrowNarrowLeft
                width="20"
                height="20"
                fill={palette.button.tertiary_fg}
              />
            </CustomIconButton>
          )}
          <Typography variant="h6" className={styles.title}>
            {title}
          </Typography>
        </Box>

        <CustomIconButton
          variant="text"
          size="none"
          boxShadow="none"
          color="tertiaryColor"
          hideHoverBg
          onClick={onClose}
        >
          <CloseXIcon
            width="20"
            height="20"
            fill={palette.button.tertiaryColor_fg}
          />
        </CustomIconButton>
      </Box>
      <Box className={styles.contentSideMneu}>{content}</Box>
    </Paper>
  );
}
export default CustomHistorySideMenu;
