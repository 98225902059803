import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import {
  DialogComponent,
  PhonesComponent,
  Spinner,
} from "../../../../../../../../../../../Components";
import {
  showError,
  getErrorByName,
  GlobalTranslate,
  showSuccess,
} from "../../../../../../../../../../../Helper";
import {
  AddBranchMainNumberAPI,
  UpdateBranchMainNumberAPI,
} from "../../../../../../../../../../../Services";

export const AddAndEditMainNumbersDialog = ({
  parentTranslationPath,
  translationPath,
  mainNumberId,
  isOpen,
  onClose,
  onSave,
  branchId,
  activeItem,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [helperText, setHelperText] = useState({
    mainNumber: "",
  });

  const [mainNumberInfo, setMainNumberInfo] = useState({
    mainNumberValue: null,
  });
  const schema = Joi.object({
    mainNumberValue: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}main-number-is-required`),
        "string.empty": t(`${translationPath}main-number-is-required`),
        "string.required": t(`${translationPath}main-number-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(mainNumberInfo);

  const GetMainNumberInfoAPI = useCallback(async () => {
    setIsSubmitted(true);
    if (schema.error) {
      showError(t("Shared:fill-required-fields"));
      return;
    }
    setIsLoading(true);

    setIsLoading(false);
  }, [mainNumberId]);

  const saveHandler = async () => {
    setIsLoading(true);
    const res = !activeItem
      ? await AddBranchMainNumberAPI(branchId, mainNumberInfo.mainNumberValue)
      : await UpdateBranchMainNumberAPI(
          mainNumberInfo?.mainNumberId,
          mainNumberInfo.mainNumberValue
        );
        setIsLoading(false);

     if (res && !res.Message) {
       showSuccess(!activeItem ? t(`${translationPath}add-main-number-succssfuly`): t(`${translationPath}edit-main-number-succssfuly`) );
      onSave(res);
    } else  if (res && res.Message) {
      const messageOne =  (res.Message  && res.Message && res.Message.includes("Duplicate_Extension_Number") &&  'duplicate-main-number') ||  null  
      showError(
        !activeItem
          ? (messageOne && t(`${translationPath}${messageOne}`)) ||
              t(`${translationPath}add-main-number-faild`)
          : (messageOne && t(`${translationPath}${messageOne}`)) ||
              t(`${translationPath}edit-main-number-faild`)
      );
      setIsLoading(false);
    }
  };

  const convoloNumberValidation = (mainNumber) => {
    if (mainNumber.length < 11) {
      setHelperText({
        ...helperText,
        mainNumber: t(`${translationPath}enter-main-number-at-least-11-digits`),
      });
      return true;
    } else if (mainNumber.length > 14) {
      setHelperText({
        ...helperText,
        mainNumber: t(
          `${translationPath}enter-the-main-number-no-more-than-14-digits`
        ),
      });
      return true;
    } else {
      setHelperText({
        ...helperText,
        mainNumber: "",
      });
      return false;
    }
  };

  useEffect(() => {
    if (mainNumberId && branchId) GetMainNumberInfoAPI();
  }, [mainNumberId, branchId]);

  useEffect(() => {
    if (activeItem)
      setMainNumberInfo((item) => ({
        ...item,
        mainNumberValue: activeItem?.mainNumber,
        mainNumberId: activeItem?.mainNumberId,
      }));
  }, [activeItem]);

  useEffect(() => {
    if (!isOpen)
      setMainNumberInfo((item) => ({ ...item, mainNumberValue: null }));
  }, [isOpen]);

  return (
    <>
      <DialogComponent
        saveText={GlobalTranslate.t("Shared:confirm")}
        disableBackdropClick
        titleText={
          !activeItem
            ? t(`${translationPath}add-main-number`)
            : t(`${translationPath}edit-main-number`)
        }
        saveClasses={"btns theme-solid bg-primary"}
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <Spinner isActive={isLoading} isAbsolute />
            <div className="w-100 mr-1-reversed mt-3">
              <PhonesComponent
                idRef="mainRef"
                labelClasses="Requierd-Color"
                labelValue="main-number"
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                value={mainNumberInfo?.mainNumberValue || ""}
                helperText={
                  (helperText?.mainNumber !== "" &&
                    t(helperText?.mainNumber)) ||
                  ""
                }
                error={helperText?.mainNumber !== ""}
                inputPlaceholder="enter-valid-main-number"
                isSubmitted={isSubmitted}
                onInputChanged={(value) => {
                  let newValue = value;
                  newValue = newValue.replaceAll(" ", "");
                  convoloNumberValidation(newValue);
                  setMainNumberInfo((item) => ({
                    ...item,
                    mainNumberValue: value,
                  }));
                }}
              />
            </div>
          </div>
        }
        isOpen={isOpen}
        saveIsDisabled={
          isLoading ||
          schema.error ||
          mainNumberInfo.mainNumberValue === null ||
          helperText?.mainNumber !== ""
        }
        onSaveClicked={() => saveHandler()}
        onCancelClicked={onClose}
        translationPath={translationPath.confirm}
      />
    </>
  );
};
