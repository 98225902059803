import React, { useRef, useState , useCallback  , useEffect } from "react";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  PhonesComponent,
  SwitchComponent,
  Inputs 
} from "../../../../../../../../../../Components";
import {
    showError,
    showSuccess,
  } from "../../../../../../../../../../Helper";
  import {
    BulkUpdateExtensionStatusAPI
  } from "../../../../../../../../../../Services";
  import { MessageConfirmDialog } from "../../../../../../../../../../SharedComponents";

export const ConvoloExtensionsFilters = ({
  parentTranslationPath,
  translationPath,
  setFilter,
  filter,
  checkedDetailed  ,
  setIsLoading ,
  isLoading  ,
  setCheckedDetailed ,
  setReloadAllExtensions , 
  reloadAllExtensions 

}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [isMissingInfoMSGOpen, setIsMissingInfoMSGOpen] = useState(false);
  const [helperText, setHelperText] = useState({
    number: "",
  });

  const [filterValues, setFilterValues] = useState({
    number: null , 
    name : null  ,
  });

  const saveHandler = useCallback(
    async (status) => {
      if (checkedDetailed.length > 0) {
        setIsLoading((item) => ({ ...item, bulkUpdateExtensionStatus: true }));
        const checkedDetailedIds =
          (checkedDetailed &&
            checkedDetailed.length &&
            checkedDetailed.map((e) => e?.extensionNumberId)) ||
          [];

        const res = await BulkUpdateExtensionStatusAPI(
          status,
          checkedDetailedIds
        );

        if (!(res && res.status && res.status !== 200)) {
          showSuccess(
            t(`${translationPath}update-extensions-status-succssfuly`)
          );
          setIsMissingInfoMSGOpen(false);
          setIsLoading((item) => ({
            ...item,
            bulkUpdateExtensionStatus: false,
          }));
          setCheckedDetailed([]);
          setReloadAllExtensions(!reloadAllExtensions);
        } else {
          showError(t(`${translationPath}update-extensions-status-faild`));
          setIsLoading((item) => ({
            ...item,
            bulkUpdateExtensionStatus: false,
          }));
        }
      } else showError(t(`${translationPath}please-select-extension-status`));
    },
    [checkedDetailed]
  );

  const openMissingInfoMSG = useCallback(async () => {
    if (checkedDetailed && checkedDetailed?.length > 0)
      setIsMissingInfoMSGOpen(true);
    else
      showError(
        t(`${translationPath}please-select-extensions-at-least-one-extension`)
      );
  }, [checkedDetailed]);

  useEffect(() => {
    setCheckedDetailed([]) ; 
  }, [filter.isActive]);

  return (
    <div className="d-flex-column">
      <div className="w-100 px-2 d-flex fj-end my-2">
        <div className="w-50" />
        <div className="d-flex fj-end">
          <div className="">
            <PhonesComponent
              labelValue={"search-main-number-or-extension"}
              idRef={`serachMainNumberextentionR3ef`}
              value={(filterValues?.number) || ''}
              onInputChanged={(value) => {
                let newValue = value;
                if (
                  (newValue !== "" && newValue.length < 11) ||
                  (newValue !== "" && newValue.length > 14)
                ) {
                  setHelperText((item) => ({
                    ...item,
                    number: t(
                      "please-enter-vaild-main-number-or-extension-number"
                    ),
                  }));
                  setFilterValues((item) => ({
                    ...item,
                    number: newValue,
                  }));
                } else {
                  setHelperText((item) => ({ ...item, number: "" }));
                  setFilterValues((item) => ({
                    ...item,
                    number: newValue,
                  }));
                }
              }}
              onKeyUp={(e) => {
                let { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  setFilter((item) => ({
                    ...item,
                    number: (value) || null,
                  }));
                }, 700);
              }}
              onKeyDown={() => {
                if (searchTimer) clearTimeout(searchTimer.current);
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>
          <div className="px-2  d-flex-inline">
            <Inputs
              idRef="agent-name"
              labelValue="agentName"
              value={filterValues.name || ""}
              onInputChanged={(event) => {
                const newValue = event.target.value;
                setFilterValues((item) => ({
                  ...item,
                  name: newValue,
                }));
              }}
              onKeyUp={(e) => {
                let { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  setFilter((item) => ({
                    ...item,
                    name: value || null,
                  }));
                }, 700);
              }}
              onKeyDown={() => {
                if (searchTimer) clearTimeout(searchTimer.current);
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>
        </div>
      </div>
      <span className="d-flex fj-end px-2">
        <ButtonBase
          className="btn btns theme-solid bg-green-dark"
          onClick={() => {
            setFilter((item) => ({
              ...item,
              name: null,
              number: null,
              isActive: true,
            }));
            setFilterValues((item) => ({
              ...item,
              name: null,
              number: null,
            }));
          }}
        >
          <span className="d-flex-center">
            <span className="mdi mdi-filter-remove">
              {t(`${translationPath}clear`)}
            </span>
          </span>
        </ButtonBase>
      </span>
      <div className="w-100 px-2 d-flex fj-end my-2"></div>
      <div className="w-100 px-2 d-flex fj-end my-2">
        <div className="px-2">
          <SwitchComponent
            idRef="statusRef"
            isChecked={filter.isActive}
            themeClass="theme-line"
            labelValue={(filter.isActive && "active") || "inactive"}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChangeHandler={(event, isChecked) =>
              setFilter((item) => ({
                ...item,
                isActive: isChecked,
              }))
            }
          />
        </div>
        {filter?.isActive === false && (
          <ButtonBase
            className="btn  btns theme-solid"
            style={{ "background-color": "#368750e3" }}
            onClick={() => openMissingInfoMSG()}
          >
            <span className="mdi mdi-arrow" />
            <span className="px-1">
              {t(`${translationPath}active-extensions`)}
            </span>
          </ButtonBase>
        )}

        {filter?.isActive && (
          <ButtonBase
            className="btn btns theme-solid bg-danger"
            onClick={() => openMissingInfoMSG()}
          >
            <span className="mdi mdi-arrow" />
            <span className="px-1">
              {t(`${translationPath}inactive-extensions`)}
            </span>
          </ButtonBase>
        )}
      </div>
      {isMissingInfoMSGOpen && checkedDetailed.length > 0 && (
        <MessageConfirmDialog
          isDialogOpen={isMissingInfoMSGOpen}
          theme="warning"
          content={{
            heading: "modify-extensions-status",
            body: t(
              `${translationPath}are-you-sure-to-update-all-selected-extensions-status-to-${
                filter.isActive ? "inactive" : "active"
              }`
            ),
          }}
          confirmButton={{
            label: "confirm",
            handler: () => saveHandler(filter.isActive ? false : true),
          }}
          cancelButton={{
            label: "discard",
            handler: () => {
              setIsMissingInfoMSGOpen(false);
            },
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};
