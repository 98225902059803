import React from "react";
import { Box } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  ProgressBar,
  CustomIconButton,
  CustomCopyToClipboard,
  PropertyRating,
  CustomTableHeader,
} from "../../../Components";
import { getDownloadableLink, truncateString } from "../../../Helper";
import { ArrowUpRight, EmptyUnitGalleryIcon } from "../../../assets/icons";

import useStyles from "./styles";

const ContactName = ({ item }) => {
  const { isDarkMode } = useSelector((state) => state.theme);
  const styles = useStyles();
  const history = useHistory();

  const handleClick = (item) => {
    if (history) {
    }
  };

  const getFullName = () => {
    return item?.row?.name || "N/A";
  };

  const displayName = getFullName();
  const truncatedName =
    displayName?.length >= 18 ? truncateString(displayName, 35) : displayName;

  return (
    <Box className={styles.container}>
      {item?.row?.allpropertyImages?.[0]?.fileId ? (
        <Box
          className={styles.galleryImg}
          style={{
            backgroundImage: `url(${getDownloadableLink(
              item?.row?.allpropertyImages?.[0]?.fileId
            )})`,
          }}
        />
      ) : (
        <EmptyUnitGalleryIcon
          width="40"
          height="40"
          fill={!isDarkMode ? "#98A2B3" : "#85888E"}
        />
      )}

      <Box className={styles.detailsColumn}>
        <Box className={styles.nameRow} onClick={() => handleClick(item)}>
          <span className={styles.nameText}>{truncatedName}</span>
          <CustomIconButton
            variant="text"
            size="xs"
            boxShadow="none"
            color="secondary"
          >
            <ArrowUpRight
              width="20"
              height="20"
              fill={!isDarkMode ? "#344054" : "#CECFD2"}
            />
          </CustomIconButton>
        </Box>

        <p className={styles.communityText}>
          {item?.row?.["community.lookupItemName"] +
            (item?.row?.["sub_community.lookupItemName"]
              ? ", " + item?.row?.["sub_community.lookupItemName"]
              : "")}
        </p>
      </Box>
    </Box>
  );
};

export const PropertyTableHeaderData = (isDarkMode = false) => [
  {
    id: 1,
    formFieldId: 1,
    minWidth: 300,
    headerName: "Property name",
    field: "property_name",
    isHiddenFilter: false,
    sortable: true,
    filterable: true,
    fieldType: "text",
    displayPath: "property_name",
    isDefaultFilterColumn: true,
    fieldKey: "property_name",
    formFieldTitle: "property_name",
    disableColumnMenu: true,
    searchKey: "property_name",
    renderHeader: (params) => (
      <CustomTableHeader
        params={params}
        title="Property name"
        isHiddenFilter={true}
      />
    ),
    renderCell: (item) => {
      return <ContactName item={item} />;
    },
  },
  {
    disableColumnMenu: true,
    displayPath: "id",
    field: "id",
    fieldKey: "id",
    fieldType: "text",
    filterable: true,
    formFieldId: 2,
    formFieldTitle: "id",
    headerName: "Property id",
    headerName: "Property id",
    id: 2,
    isDate: false,
    isDefaultFilterColumn: false,
    isHiddenFilter: false,
    isNumber: false,
    isSortable: true,
    key: "id",
    minWidth: 190,
    searchKey: "id",
    searchableKey: "id",
    sortable: true,
    title: "Property id",
    renderCell: (item) => {
      return (
        <CustomCopyToClipboard
          data={item?.row?.id} // copy
          childrenData={"#" + item?.row?.id} // render
          copyDoneShowTime={1000}
          fontSize="14px"
        />
      );
    },
  },
  {
    id: 3,
    formFieldId: 3,
    minWidth: 200,
    headerName: "Property type",
    field: "property_type.lookupItemName",
    fieldType: "text",
    sortable: true,
    filterable: true,
    displayPath: "property_type.lookupItemName",
    isDefaultFilterColumn: true,
    disableColumnMenu: true,
    fieldKey: "property_type.lookupItemName",
    formFieldTitle: "Property type",

    renderCell: (item) => {
      return (
        item?.row?.property_type?.lookupItemName && (
          <label style={{ fontSize: "14px", fontWeight: 500 }}>
            {item?.row?.property_type.lookupItemName}
          </label>
        )
      );
    },
  },
  {
    id: 4,
    formFieldId: 4,
    headerName: "Property rating",
    width: 240,
    field: "propertyRating",
    fieldKey: "propertyRating",
    fieldType: "text",
    disableColumnMenu: true,
    displayPath: "propertyRating",
    sortable: true,
    filterable: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Property rating",
    renderCell: (item) => {
      return <PropertyRating rating={item?.row?.propertyRating} />;
    },
  },
  {
    id: 5,
    formFieldId: 5,
    minWidth: 190,
    headerName: "Creation date",
    field: "createdOn",
    isHiddenFilter: false,
    filterable: true,
    disableColumnMenu: true,
    fieldType: "datePicker",
    displayPath: "createdOn",
    isDate: true,
    sortable: true,
    isDefaultFilterColumn: true,
    fieldKey: "createdOn",
    formFieldTitle: "Creation date",
    renderCell: (item) => {
      return (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          {item?.row?.createdOn && (
            <label style={{ lineHeight: "20px", fontWeight: 500 }}>
              {moment(item.row.createdOn).format("DD MMM YYYY")}
            </label>
          )}
          {item?.row?.createdBy && (
            <span
              style={{
                lineHeight: "20px",
                fontWeight: 400,
                color: !isDarkMode ? "#475467" : "#94969C",
              }}
            >
              {item.row.createdBy}
            </span>
          )}
        </Box>
      );
    },
  },
  {
    disableColumnMenu: true,
    displayPath: "updatedBy",
    field: "updatedBy",
    fieldKey: "updatedBy",
    fieldType: "text",
    filterable: true,
    formFieldId: 6,
    formFieldTitle: "updatedBy",
    headerName: "Updated by",
    headerName: "Updated by",
    id: 6,
    isDate: false,
    isDefaultFilterColumn: false,
    isHiddenFilter: false,
    isNumber: false,
    isSortable: true,
    key: "updatedBy",
    minWidth: 190,
    searchKey: "updatedBy",
    searchableKey: "updatedBy",
    sortable: true,
    title: "Updated by",
    renderCell: (item) => {
      return (
        item?.row?.updatedBy && (
          <label style={{ fontSize: "14px", fontWeight: 400 }}>
            {item?.row?.updatedBy}
          </label>
        )
      );
    },
  },
  {
    disableColumnMenu: true,
    displayPath: "property_owner",
    field: "property_owner",
    fieldKey: "property_owner",
    fieldType: "text",
    filterable: true,
    formFieldId: 7,
    formFieldTitle: "property_owner",
    headerName: "Owner",
    headerName: "Owner",
    id: 7,
    isDate: false,
    isDefaultFilterColumn: false,
    isHiddenFilter: false,
    isNumber: false,
    isSortable: true,
    key: "property_owner",
    minWidth: 190,
    searchKey: "property_owner",
    searchableKey: "property_owner",
    sortable: true,
    title: "Owner ",
    renderCell: (item) => {
      return (
        item?.row?.property_owner && (
          <label style={{ fontSize: "14px", fontWeight: 400 }}>
            {item?.row?.property_owner}
          </label>
        )
      );
    },
  },
  {
    id: 8,
    formFieldId: 8,
    minWidth: 190,
    headerName: "Progress",
    field: "data_completed",
    displayPath: "data_completed",
    fieldKey: "data_completed",
    filterable: false,
    textInputType: "number",
    disableColumnMenu: true,
    textInputMax: 100,
    textInputMin: 0,
    isHiddenFilter: true,
    fieldType: "number",
    isNumber: true,
    sortable: false,
    isDefaultFilterColumn: true,
    formFieldTitle: "Progress",
    renderCell: (item) => {
      return (
        <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <Box style={{ width: "100px", display: "flex" }}>
            <ProgressBar staticProgress={item?.row?.progress || 0} />
          </Box>
          <label style={{ fontSize: "14px", fontWeight: 500 }}>
            {item?.row?.progressWithPercentage}
          </label>
        </Box>
      );
    },
  },
  {
    minWidth: 170,
    id: 9,
    formFieldId: 9,
    headerName: "isBulkUpload",
    field: "isBulkUpload",
    displayPath: "isBulkUpload",
    fieldKey: "isBulkUpload",
    isHiddenFilter: false,
    disableColumnMenu: true,
    filterable: true,
    sortable: true,
    data: { enum: ["Yes", "No"] },
    fieldType: "select",
    isDefaultFilterColumn: true,
    formFieldTitle: "isBulkUpload",
    renderCell: (item) => <div>{!!item?.row?.isBulkUpload ? "Yes" : "No"}</div>,
  },
  {
    minWidth: 170,
    id: 10,
    formFieldId: 10,
    field: "webPublished",
    displayPath: "webPublished",
    fieldKey: "webPublished",
    headerName: "IsPublished",
    isHiddenFilter: false,
    disableColumnMenu: true,
    filterable: true,
    sortable: true,
    data: { enum: ["Yes", "No"] },
    fieldType: "select",
    isDefaultFilterColumn: true,
    formFieldTitle: "IsPublished",
    renderCell: (item) => <div>{!!item?.row?.IsPublished ? "Yes" : "No"}</div>,
  },
  {
    disableColumnMenu: true,
    displayPath: "webPublishedDate",
    field: "webPublishedDate",
    fieldKey: "webPublishedDate",
    fieldType: "text",
    filterable: true,
    formFieldId: 11,
    formFieldTitle: "webPublishedDate",
    headerName: "Published date",
    headerName: "Published date",
    id: 11,
    isDate: false,
    isDefaultFilterColumn: false,
    isHiddenFilter: false,
    isNumber: false,
    isSortable: true,
    key: "webPublishedDate",
    minWidth: 190,
    searchKey: "webPublishedDate",
    searchableKey: "webPublishedDate",
    sortable: true,
    title: "Published date",
    renderCell: (item) => {
      return (
        item?.row?.webPublishedDate && (
          <label style={{ fontSize: "14px", fontWeight: 400 }}>
            {item?.row?.webPublishedDate}
          </label>
        )
      );
    },
  },
];
