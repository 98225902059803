import React from "react";

function HeartIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M6.847 2.044c-2.448.331-4.594 2.138-5.458 4.596-.423 1.201-.502 2.646-.208 3.811.328 1.3.84 2.23 1.904 3.452 1.111 1.277 4.438 4.471 7.306 7.014.767.68.839.732 1.174.845.468.159.963.051 1.415-.31.298-.237 2.215-1.967 3.399-3.067 2.775-2.577 4.577-4.427 5.285-5.427 1.475-2.083 1.743-4.632.726-6.894-.875-1.948-2.712-3.47-4.715-3.907-1.85-.404-3.873.101-5.429 1.354l-.253.204-.227-.185A7.257 7.257 0 0 0 9.4 2.273c-.747-.222-1.864-.322-2.553-.229m1.392 2.018c1.031.167 1.869.615 2.744 1.466.266.259.549.498.629.531.209.088.592.078.799-.019.096-.045.333-.247.549-.467.497-.507.908-.807 1.498-1.095 1.478-.72 3.069-.592 4.402.353 1.217.863 1.964 2.137 2.104 3.586.128 1.316-.258 2.553-1.145 3.664-.889 1.114-3.092 3.296-6 5.943-1.168 1.063-1.794 1.616-1.829 1.616-.061 0-2.468-2.189-4.029-3.665-1.285-1.215-2.985-2.934-3.497-3.535-.914-1.073-1.311-1.926-1.432-3.07a5.27 5.27 0 0 1 .112-1.63 5.188 5.188 0 0 1 2.681-3.335c.73-.364 1.559-.482 2.414-.343"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default HeartIcon;
