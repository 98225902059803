import { GetLocationByAddress } from "../Services/map";
import { lookupItemsGetId } from "../Services/LookupsServices";
import { showError, showWarn } from "../Helper";

// import { reRender } from '../Helper';
export async function getCurrentLocation(hideWarn) {
  const data = await GetLocationByAddress(
    localStorage.getItem("CurrentAddress")
  );
  try {
    const location = data?.results[0]?.geometry?.location;
    return location
      ? { latitude: location.lat, longitude: location.lng }
      : null;
  } catch (e) {
    !hideWarn && showWarn("Not found location");
    return null;
  }
}

function resetAllValue(id, setData, item, itemList, formType) {
  try {
    const i = itemList.indexOf(
      itemList.find(
        (f) => f.field.id.toLowerCase() === item.data.hasEffectOn.toLowerCase()
      )
    );

    if (i !== -1) {
      // itemList[i].data.enum = [];
      resetAllValue(
        itemList[i].field.id,
        setData,
        itemList[i],
        itemList,
        formType
      );
    }
    if (formType === 1) setData(itemList[i].field.id, {});
    else if (formType === 2) setData(null, 0, itemList[i].field.id);
  } catch (e) {
    if (id !== -1) {
      if (formType === 1) setData(id, {});
      else if (formType === 2) setData(null, 0, id);
    }
  }
}

export async function OnchangeCountryRule(
  id,
  setData,
  item,
  itemList,
  v,
  formType
) {
  if (v) localStorage.setItem("CurrentAddress", `${v.lookupItemName}`);
  else localStorage.removeItem("CurrentAddress");

  if (!item.data.specialKey) return;

  if (item.data.specialKey !== "country") return;

  resetAllValue(id, setData, item, itemList, formType);
  const mapIndex = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === "map")
  );
  if (mapIndex !== -1 && (!v || v?.lookupItemName == "Unknown")) {
    setData(itemList[mapIndex].field.id, undefined);
    setTimeout(() => {
      setData(itemList[mapIndex].field.id, null);
    }, 100);
  }

  const currentIndex = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === id)
  );
  const i1 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() ===
        itemList[currentIndex].data.hasEffectOn.toLowerCase()
    )
  );
  if (!itemList[i1]) {
    // eslint-disable-next-line no-console
    return;
  }
  const result = await lookupItemsGetId({
    lookupTypeId: itemList[i1].data.lookup,
    lookupParentId: v && v.lookupItemId,
  });
  itemList[i1].data.enum = [];
  if (!(result && result.status && result.status !== 200))
    result.map((items) => itemList[i1].data.enum.push(items));
  if (!itemList[i1].data.effectOnMap) {
    setData(itemList[i1].field.id, null);
    return;
  }
  const i2 = itemList.indexOf(
    itemList.find(
      (f) => f.field.id.toLowerCase() === itemList[i1].data.effectOnMap
    )
  );
  const data = v ? await getCurrentLocation() : null;
  setData(itemList[i2].field.id, undefined);

  setTimeout(() => {
    if (formType === 1) setData(itemList[i2].field.id, data || " ");
  }, 100);
}

export async function OnchangeCityRule(
  id,
  v,
  itemList,
  setData,
  item,
  formType,
  country
) {
  if (!item.data.specialKey) return;
  if (item.data.specialKey !== "city") return;
  if (v)
    localStorage.setItem(
      "CurrentAddress",
      `${v.parentLookupItemName} , ${v.lookupItemName}`
    );
  else {
    localStorage.setItem("CurrentAddress", country?.lookupItemName);
    const data = await getCurrentLocation();
    setTimeout(() => {
      setData("map", data);
    }, 100);
  }

  const currentIndex = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === id)
  );
  resetAllValue(id, setData, itemList[currentIndex], itemList, formType);
  if (!v) return;
  const i1 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() ===
        itemList[currentIndex].data.hasEffectOn.toLowerCase()
    )
  );
  if (!itemList[i1]) return;
  const result = await lookupItemsGetId({
    lookupTypeId: itemList[i1].data.lookup,
    lookupParentId: v && v.lookupItemId,
  });
  itemList[i1].data.enum = [];
  if (!(result && result.status && result.status !== 200))
    result.map((items) => itemList[i1].data.enum.push(items));

  const i1countryIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "country"
  );
  const Lookup = {
    lookupItemId: v && v.parentLookupItemId,
    parentLookupTypeName: v && v.LookupType,
    lookupItemName: v && v.parentLookupItemName,
  };
  if (i1countryIndex !== -1) {
    const isExist =
      itemList[i1countryIndex].data.enum &&
      itemList[i1countryIndex].data.enum.find(
        (c) => (c && c.lookupItemId) === (Lookup && Lookup.lookupItemId)
      );
    if (!isExist) itemList[i1countryIndex].data.enum.push(Lookup);

    setTimeout(() => {
      setData(itemList[i1countryIndex].field.id, Lookup);
    }, 100);
  }

  if (!itemList[currentIndex].data.effectOnMap) {
    if (formType === 1) setData(itemList[i1].field.id, null);
    return;
  }
  if (!itemList[currentIndex].data.effectOnMap) return;
  const i2 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() === itemList[currentIndex].data.effectOnMap
    )
  );
  const data = await getCurrentLocation();
  setData(itemList[i2].field.id, undefined);

  setTimeout(() => {
    setData(itemList[i2].field.id, data);
  }, 100);
}

export async function OnchangeDistrictRule(
  id,
  setData,
  item,
  itemList,
  v,
  formType,
  city
) {
  if (!item.data.specialKey) return;
  if (item.data.specialKey !== "district") return;

  if (v)
    localStorage.setItem(
      "CurrentAddress",
      `${v.parentLookupItemName || v.lookupItemName} ,, ${v.lookupItemName}`
    );
  else {
    localStorage.setItem(
      "CurrentAddress",
      `${city?.parentLookupItemName || city?.lookupItemName} , ${
        city?.lookupItemName
      }`
    );
    const data = await getCurrentLocation();
    setTimeout(() => {
      setData("map", data);
    }, 100);
  }
  const currentIndex = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === id)
  );
  resetAllValue(id, setData, itemList[currentIndex], itemList, formType);
  if (!v) return;

  const i1 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() ===
        itemList[currentIndex].data.hasEffectOn.toLowerCase()
    )
  );

  if (!itemList[i1]) return;
  const result = await lookupItemsGetId({
    lookupTypeId: itemList[i1].data.lookup,
    lookupParentId: v && v.lookupItemId,
  });
  itemList[i1].data.enum = [];
  if (!(result && result.status && result.status !== 200))
    result.map((items) => itemList[i1].data.enum.push(items));
  if (v) v.addressType = item.data.addressType;

  const cityIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "city"
  );
  const countryIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "country"
  );
  const parentsItems = JSON.parse(v && v.lookupItemParents);
  const cityLookup = {
    lookupType: parentsItems && parentsItems[0] && parentsItems[0].LookupType,
    lookupItemId:
      parentsItems && parentsItems[0] && parentsItems[0].LookupItemId,
    lookupItemName:
      parentsItems && parentsItems[0] && parentsItems[0].LookupItemName,
    parentLookupItemName:
      parentsItems && parentsItems[1] && parentsItems[1].LookupItemName,
  };
  const countryLookup = {
    lookupType: parentsItems && parentsItems[1] && parentsItems[1].LookupType,
    lookupItemId:
      parentsItems && parentsItems[1] && parentsItems[1].LookupItemId,
    lookupItemName:
      parentsItems && parentsItems[1] && parentsItems[1].LookupItemName,
    lookupItemCode:
      parentsItems && parentsItems[1] && parentsItems[1].LookupItemCode,
  };

  if (countryIndex !== -1) {
    const isExist =
      itemList[countryIndex].data.enum &&
      itemList[countryIndex].data.enum.find(
        (c) =>
          (c && c.lookupItemId) ===
          (countryLookup && countryLookup.lookupItemId)
      );
    if (!isExist && countryLookup)
      itemList[countryIndex].data.enum.push(countryLookup);

    setData(itemList[countryIndex].field.id, countryLookup || {});
  }

  if (cityIndex !== -1) {
    const isExist =
      itemList[cityIndex].data.enum &&
      itemList[cityIndex].data.enum.find(
        (c) => (c && c.lookupItemId) === (cityLookup && cityLookup.lookupItemId)
      );
    if (!isExist && cityLookup) itemList[cityIndex].data.enum.push(cityLookup);

    setData(itemList[cityIndex].field.id, cityLookup || {});
  }

  if (!itemList[currentIndex].data.effectOnMap) return;
  const i2 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() === itemList[currentIndex].data.effectOnMap
    )
  );

  const data = await getCurrentLocation();
  setData(itemList[i2].field.id, undefined);
  setTimeout(() => {
    setData(itemList[i2].field.id, data);
  }, 100);
}

export async function OnchangeCommunityRule(
  id,
  setData,
  item,
  itemList,
  v,
  formType,
  district
) {
  if (!item.data.specialKey) return;
  if (item.data.specialKey !== "community") return;

  if (v)
    localStorage.setItem(
      "CurrentAddress",
      `${v.parentLookupItemName || v.lookupItemName} ,,, ${v.lookupItemName}`
    );
  else {
    localStorage.setItem(
      "CurrentAddress",
      `${district?.parentLookupItemName || district?.lookupItemName} ,, ${
        district?.lookupItemName
      }`
    );
    const data = await getCurrentLocation();
    setTimeout(() => {
      setData("map", data);
    }, 100);
  }
  const currentIndex = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === id)
  );
  resetAllValue(id, setData, itemList[currentIndex], itemList, formType);
  if (!v) return;

  const i1 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() ===
        itemList[currentIndex].data.hasEffectOn.toLowerCase()
    )
  );
  if (!itemList[i1] && item.data.description !== "Contacts & Address") return;

  if (i1 !== -1) {
    const result = await lookupItemsGetId({
      lookupTypeId: itemList[i1].data.lookup,
      lookupParentId: v && v.lookupItemId,
    });
    itemList[i1].data.enum = [];
    if (!(result && result.status && result.status !== 200))
      result.map((items) => itemList[i1].data.enum.push(items));
  }

  if (v) v.addressType = item.data.addressType;

  const countryIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "country"
  );
  const cityIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "city"
  );
  const districtIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "district"
  );
  const parentsItems = JSON.parse(v && v.lookupItemParents);

  const districtLookup = {
    lookupType: parentsItems && parentsItems[0].LookupType,
    lookupItemId: parentsItems && parentsItems[0].LookupItemId,
    lookupItemName: parentsItems && parentsItems[0].LookupItemName,
    parentLookupItemName:
      parentsItems && parentsItems[1] && parentsItems[1].LookupItemName,
  };
  const cityLookup = {
    lookupType: parentsItems && parentsItems[1].LookupType,
    lookupItemId: parentsItems && parentsItems[1].LookupItemId,
    lookupItemName: parentsItems && parentsItems[1].LookupItemName,
    parentLookupItemName:
      parentsItems && parentsItems[2] && parentsItems[2].LookupItemName,
  };
  const countryLookup = {
    lookupType: parentsItems && parentsItems[2].LookupType,
    lookupItemId: parentsItems && parentsItems[2].LookupItemId,
    lookupItemName: parentsItems && parentsItems[2].LookupItemName,
    lookupItemCode:
      parentsItems && parentsItems[2] && parentsItems[2].LookupItemCode,
  };

  if (countryIndex !== -1) {
    const isExist =
      itemList[countryIndex].data.enum &&
      itemList[countryIndex].data.enum.find(
        (c) =>
          (c && c.lookupItemId) ===
          (countryLookup && countryLookup.lookupItemId)
      );
    if (!isExist && countryLookup)
      itemList[countryIndex].data.enum.push(countryLookup);

    setData(itemList[countryIndex].field.id, countryLookup || {});
  }

  if (cityIndex !== -1) {
    const isExist =
      itemList[cityIndex].data.enum &&
      itemList[cityIndex].data.enum.find(
        (c) => (c && c.lookupItemId) === (cityLookup && cityLookup.lookupItemId)
      );
    if (!isExist && cityLookup) itemList[cityIndex].data.enum.push(cityLookup);

    setData(itemList[cityIndex].field.id, cityLookup || {});
  }

  if (districtIndex !== -1) {
    const isExist =
      itemList[districtIndex].data.enum &&
      itemList[districtIndex].data.enum.find(
        (c) =>
          (c && c.lookupItemId) ===
          (districtLookup && districtLookup.lookupItemId)
      );
    if (!isExist && districtLookup)
      itemList[districtIndex].data.enum.push(districtLookup);

    setData(itemList[districtIndex].field.id, districtLookup);
  }

  if (!itemList[currentIndex].data.effectOnMap) return;
  const i2 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() === itemList[currentIndex].data.effectOnMap
    )
  );

  const data = await getCurrentLocation();
  setData(itemList[i2].field.id, undefined);
  setTimeout(() => {
    setData(itemList[i2].field.id, data);
  }, 100);
}

export async function OnchangeSubCommunityRule(
  id,
  setData,
  item,
  itemList,
  v,
  formType,
  community
) {
  if (id !== "sub_community") return;

  if (v)
    localStorage.setItem(
      "CurrentAddress",
      `${v.parentLookupItemName || v.lookupItemName} ,,,, ${v.lookupItemName}`
    );
  else {
    localStorage.setItem(
      "CurrentAddress",
      `${community.parentLookupItemName || community?.lookupItemName} ,,, ${
        community?.lookupItemName
      }`
    );
    const data = await getCurrentLocation();
    setTimeout(() => {
      setData("map", data);
    }, 100);
  }
  const currentIndex = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === id)
  );
  resetAllValue(id, setData, itemList[currentIndex], itemList, formType);
  if (!v) return;
  const countryIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "country"
  );
  const cityIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "city"
  );
  const districtIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "district"
  );
  const communityIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "community"
  );
  const parentsItems = JSON.parse(v && v.lookupItemParents);
  const districtLookup = {
    lookupType: parentsItems && parentsItems[1].LookupType,
    lookupItemId: parentsItems && parentsItems[1].LookupItemId,
    lookupItemName: parentsItems && parentsItems[1].LookupItemName,
    parentLookupItemName:
      parentsItems && parentsItems[2] && parentsItems[2].LookupItemName,
  };
  const cityLookup = {
    lookupType: parentsItems && parentsItems[2].LookupType,
    lookupItemId: parentsItems && parentsItems[2].LookupItemId,
    lookupItemName: parentsItems && parentsItems[2].LookupItemName,
    parentLookupItemName:
      parentsItems && parentsItems[3] && parentsItems[3].LookupItemName,
  };
  const countryLookup = {
    lookupType: parentsItems && parentsItems[3] && parentsItems[3].LookupType,
    lookupItemId: parentsItems && parentsItems[3].LookupItemId,
    lookupItemName: parentsItems && parentsItems[3].LookupItemName,
    lookupItemCode:
      parentsItems && parentsItems[3] && parentsItems[3].LookupItemCode,
  };
  const communityLookup = {
    lookupType: parentsItems && parentsItems[0].LookupType,
    lookupItemId: parentsItems && parentsItems[0].LookupItemId,
    lookupItemName: parentsItems && parentsItems[0].LookupItemName,
    parentLookupItemName:
      parentsItems && parentsItems[1] && parentsItems[1].LookupItemName,
  };

  if (countryIndex !== -1) {
    const isExist =
      itemList[countryIndex].data.enum &&
      itemList[countryIndex].data.enum.find(
        (c) =>
          (c && c.lookupItemId) ===
          (countryLookup && countryLookup.lookupItemId)
      );
    if (!isExist && countryLookup)
      itemList[countryIndex].data.enum.push(countryLookup);

    setData(itemList[countryIndex].field.id, countryLookup || {});
  }

  if (cityIndex !== -1) {
    const isExist =
      itemList[cityIndex].data.enum &&
      itemList[cityIndex].data.enum.find(
        (c) => (c && c.lookupItemId) === (cityLookup && cityLookup.lookupItemId)
      );
    if (!isExist && cityLookup) itemList[cityIndex].data.enum.push(cityLookup);

    setData(itemList[cityIndex].field.id, cityLookup || {});
  }

  if (districtIndex !== -1) {
    const isExist =
      itemList[districtIndex].data.enum &&
      itemList[districtIndex].data.enum.find(
        (c) =>
          (c && c.lookupItemId) ===
          (districtLookup && districtLookup.lookupItemId)
      );
    if (!isExist && districtLookup)
      itemList[districtIndex].data.enum.push(districtLookup);

    setData(itemList[districtIndex].field.id, districtLookup);
  }

  if (communityIndex !== -1) {
    const isExist =
      itemList[communityIndex].data.enum &&
      itemList[communityIndex].data.enum.find(
        (c) =>
          (c && c.lookupItemId) ===
          (communityLookup && communityLookup.lookupItemId)
      );
    if (!isExist && communityLookup)
      itemList[communityIndex].data.enum.push(communityLookup);
    setData(itemList[communityIndex].field.id, communityLookup);
  }

  if (!itemList[currentIndex].data.effectOnMap) return;
  const i2 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() === itemList[currentIndex].data.effectOnMap
    )
  );

  const data = await getCurrentLocation();
  setData(itemList[i2].field.id, undefined);
  setTimeout(() => {
    setData(itemList[i2].field.id, data);
  }, 100);
}

export async function OnchangeCountryRule2(id, setData, item, itemList, v) {
  if (!item.data.specialKey) return;

  if (item.data.specialKey !== "country") return;

  if (v) localStorage.setItem("CurrentAddress", `${v.lookupItemName}`);
  else if (!v) localStorage.removeItem("CurrentAddress");

  resetAllValue(id, setData, item, itemList, 2);

  const currentIndex = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === id)
  );
  const i1 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() ===
        itemList[currentIndex].data.hasEffectOn.toLowerCase()
    )
  );
  if (!itemList[i1]) {
    // eslint-disable-next-line no-console
    return;
  }
  if (!itemList[i1].data.effectOnMap && i1 !== -1) {
    setData(null, 0, itemList[i1].field.id);
    return;
  }
  const i2 = itemList.indexOf(
    itemList.find(
      (f) => f.field.id.toLowerCase() === itemList[i1].data.effectOnMap
    )
  );
  if (!v || v?.lookupItemName === "Unknown") {
    setData(null, 0, itemList[i2].field.id);
    return;
  }
  const data = v ? await getCurrentLocation() : null;
  setData(null, 0, itemList[i2].field.id);
  setTimeout(() => {
    setData(data, 0, itemList[i2].field.id);
  }, 100);
}

export async function OnchangeCityRule2(
  id,
  v,
  itemList,
  setData,
  item,
  country
) {
  if (!item.data.specialKey) return;
  if (item.data.specialKey !== "city") return;

  if (v)
    localStorage.setItem(
      "CurrentAddress",
      `${v.parentLookupItemName} , ${v.lookupItemName}`
    );
  else {
    localStorage.setItem("CurrentAddress", country?.lookupItemName);
    const data = await getCurrentLocation();
    setTimeout(() => {
      setData(data, 0, "map");
    }, 100);
  }

  const currentIndex = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === id)
  );
  resetAllValue(id, setData, itemList[currentIndex], itemList, 2);
  if (!v) return;
  const i1 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() ===
        itemList[currentIndex].data.hasEffectOn.toLowerCase()
    )
  );
  const i1countryIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "country"
  );
  const Lookup = {
    lookupItemId: v && v.parentLookupItemId,
    parentLookupTypeName: v && v.LookupType,
    lookupItemName: v && v.parentLookupItemName,
    lookupItemCode: v && v.parentLookupItemCode,
  };
  if (i1countryIndex !== -1) {
    setTimeout(() => {
      setData(Lookup, 0, "country");
    }, 100);
  }

  if (!itemList[currentIndex].data.effectOnMap && i1 !== -1) {
    setData(null, 0, itemList[i1].field.id);
    return;
  }
  if (!itemList[currentIndex].data.effectOnMap) return;
  const i2 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() === itemList[currentIndex].data.effectOnMap
    )
  );
  const data = await getCurrentLocation();
  setTimeout(() => {
    setData(data, 0, itemList[i2].field.id);
  }, 100);
}

export async function OnchangeDistrictRule2(
  id,
  setData,
  item,
  itemList,
  v,
  city
) {
  if (!item.data.specialKey) return;
  if (item.data.specialKey !== "district") return;

  if (v)
    localStorage.setItem(
      "CurrentAddress",
      `${v.parentLookupItemName || v.lookupItemName} ,, ${v.lookupItemName}`
    );
  else {
    localStorage.setItem(
      "CurrentAddress",
      `${city?.parentLookupItemName || city?.lookupItemName} , ${
        city?.lookupItemName
      }`
    );
    const data = await getCurrentLocation();
    setTimeout(() => {
      setData(data, 0, "map");
    }, 100);
  }
  const currentIndex = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === id)
  );

  resetAllValue(id, setData, itemList[currentIndex], itemList, 2);
  if (!v) return;

  const i1 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() ===
        itemList[currentIndex].data.hasEffectOn.toLowerCase()
    )
  );
  if (!itemList[i1]) return;
  if (v) v.addressType = item.data.addressType;

  const cityIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "city"
  );
  const countryIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "country"
  );
  const parentsItems = JSON.parse(v && v.lookupItemParents);
  const cityLookup = {
    lookupType: parentsItems && parentsItems[0] && parentsItems[0].LookupType,
    lookupItemId:
      parentsItems && parentsItems[0] && parentsItems[0].LookupItemId,
    lookupItemName:
      parentsItems && parentsItems[0] && parentsItems[0].LookupItemName,
    parentLookupItemName:
      parentsItems && parentsItems[1] && parentsItems[1].LookupItemName,
  };
  const countryLookup = {
    lookupType: parentsItems && parentsItems[1] && parentsItems[1].LookupType,
    lookupItemId:
      parentsItems && parentsItems[1] && parentsItems[1].LookupItemId,
    lookupItemName:
      parentsItems && parentsItems[1] && parentsItems[1].LookupItemName,
    lookupItemCode:
      parentsItems && parentsItems[1] && parentsItems[1].LookupItemCode,
  };

  if (countryIndex !== -1) setData(countryLookup, 0, "country");

  if (cityIndex !== -1) setData(cityLookup, 0, itemList[cityIndex].field.id);

  if (!itemList[currentIndex].data.effectOnMap) return;
  const i2 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() === itemList[currentIndex].data.effectOnMap
    )
  );

  const data = await getCurrentLocation();

  setData(null, 0, itemList[i2].field.id);
  setTimeout(() => {
    setData(data, 0, itemList[i2].field.id);
  }, 100);
}

export async function OnchangeCommunityRule2(
  id,
  setData,
  item,
  itemList,
  v,
  district
) {
  if (!item.data.specialKey) return;
  if (item.data.specialKey !== "community") return;

  if (v)
    localStorage.setItem(
      "CurrentAddress",
      `${v.parentLookupItemName || v.lookupItemName} ,,, ${v.lookupItemName}`
    );
  else {
    localStorage.setItem(
      "CurrentAddress",
      `${district?.parentLookupItemName || district?.lookupItemName} ,, ${
        district?.lookupItemName
      }`
    );
    const data = await getCurrentLocation();
    setTimeout(() => {
      setData(data, 0, "map");
    }, 100);
  }
  const currentIndex = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === id)
  );
  resetAllValue(id, setData, itemList[currentIndex], itemList, 2);
  if (!v) return;

  const i1 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() ===
        itemList[currentIndex].data.hasEffectOn.toLowerCase()
    )
  );
  if (!itemList[i1] && item.data.description !== "Contacts & Address") return;
  if (v) v.addressType = item.data.addressType;

  const countryIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "country"
  );
  const cityIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "city"
  );
  const districtIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "district"
  );
  const parentsItems = JSON.parse(v && v.lookupItemParents);

  const districtLookup = {
    lookupType: parentsItems && parentsItems[0].LookupType,
    lookupItemId: parentsItems && parentsItems[0].LookupItemId,
    lookupItemName: parentsItems && parentsItems[0].LookupItemName,
    parentLookupItemName:
      parentsItems && parentsItems[1] && parentsItems[1].LookupItemName,
  };
  const cityLookup = {
    lookupType: parentsItems && parentsItems[1].LookupType,
    lookupItemId: parentsItems && parentsItems[1].LookupItemId,
    lookupItemName: parentsItems && parentsItems[1].LookupItemName,
    parentLookupItemName:
      parentsItems && parentsItems[2] && parentsItems[2].LookupItemName,
  };
  const countryLookup = {
    lookupType: parentsItems && parentsItems[2].LookupType,
    lookupItemId: parentsItems && parentsItems[2].LookupItemId,
    lookupItemName: parentsItems && parentsItems[2].LookupItemName,
    lookupItemCode:
      parentsItems && parentsItems[2] && parentsItems[2].LookupItemCode,
  };

  if (countryIndex !== -1)
    setData(countryLookup, 0, itemList[countryIndex].field.id);

  if (cityIndex !== -1) setData(cityLookup, 0, itemList[cityIndex].field.id);

  if (districtIndex !== -1)
    setData(districtLookup, 0, itemList[districtIndex].field.id);

  if (!itemList[currentIndex].data.effectOnMap) return;
  const i2 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() === itemList[currentIndex].data.effectOnMap
    )
  );

  const data = await getCurrentLocation();
  setData(null, 0, itemList[i2].field.id);
  setTimeout(() => {
    setData(data, 0, itemList[i2].field.id);
  }, 100);
}


export async function OnchangeSubCommunityRule2(
  id,
  setData,
  item,
  itemList,
  v,
  community
) {
  if (id !== "sub_community") return;

  if (v) {
    localStorage.setItem(
      "CurrentAddress",
      `${v.parentLookupItemName || v.lookupItemName} ,,,, ${v.lookupItemName}`
    );
  } else {
    localStorage.setItem(
      "CurrentAddress",
      `${community.parentLookupItemName || community?.lookupItemName} ,,, ${
        community?.lookupItemName
      }`
    );

    const data = await getCurrentLocation();
    setTimeout(() => {
      setData(data, 0, "map");
    }, 100);
  }
  const currentIndex = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === id)
  );
  resetAllValue(id, setData, itemList[currentIndex], itemList, 2);

  if (!v) return;
  const countryIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "country"
  );
  const cityIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "city"
  );
  const districtIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "district"
  );
  const communityIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "community"
  );
  const parentsItems = JSON.parse(v && v.lookupItemParents);

  const getCountryLookUp = () => {
    if (countryIndex !== -1) {
      const newLookUp = parentsItems.filter((item) => {
        return item.LookupType.toLowerCase() === "country";
      });

      return {
        lookupType: newLookUp[0]?.LookupType,
        lookupItemId: newLookUp[0]?.LookupItemId,
        lookupItemName: newLookUp[0]?.LookupItemName,
        parentLookupItemName: newLookUp[0]?.LookupItemName,
      };
    } else return null;
  };
  const getCityLookUp = () => {
    if (cityIndex !== -1) {
      const newLookUp = parentsItems.filter((item) => {
        return item.LookupType.toLowerCase() === "city";
      });

      return {
        lookupType: newLookUp[0]?.LookupType,
        lookupItemId: newLookUp[0]?.LookupItemId,
        lookupItemName: newLookUp[0]?.LookupItemName,
        parentLookupItemName: newLookUp[0]?.LookupItemName,
      };
    } else return null;
  };

  const getDistrictLookUp = () => {
    if (cityIndex !== -1) {
      const newLookUp = parentsItems.filter((item) => {
        return item.LookupType.toLowerCase() === "district";
      });

      return {
        lookupType: newLookUp[0]?.LookupType,
        lookupItemId: newLookUp[0]?.LookupItemId,
        lookupItemName: newLookUp[0]?.LookupItemName,
        parentLookupItemName: newLookUp[0]?.LookupItemName,
      };
    } else return null;
  };

  const getCommunityLookUp = () => {
    if (cityIndex !== -1) {
      const newLookUp = parentsItems.filter((item) => {
        return item.LookupType.toLowerCase() === "community";
      });

      return {
        lookupType: newLookUp[0]?.LookupType,
        lookupItemId: newLookUp[0]?.LookupItemId,
        lookupItemName: newLookUp[0]?.LookupItemName,
        parentLookupItemName: newLookUp[0]?.LookupItemName,
      };
    } else return null;
  };

  if (countryIndex !== -1) {
    setData(getCountryLookUp(), 0, itemList[countryIndex].field.id);
  }

  if (cityIndex !== -1) {
    setData(getCityLookUp(), 0, itemList[cityIndex].field.id);
  }

  if (districtIndex !== -1) {
    setData(getDistrictLookUp(), 0, itemList[districtIndex].field.id);
  }

  if (communityIndex !== -1) {
    setData(getCommunityLookUp(), 0, itemList[communityIndex].field.id);
  }

  if (!itemList[currentIndex].data.effectOnMap) return;
  const i2 = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id.toLowerCase() === itemList[currentIndex].data.effectOnMap
    )
  );
}
