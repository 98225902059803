import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Tabs, Tab } from "@material-ui/core";
import {
  GetAllActivities,
  GetMainEntitiesComments,
} from "../../../../../Services";
import {
  DetailsTab,
  ContactProfileActivity,
  ContactProfileSchedule,
  ContactProfileComments,
} from "..";
import { useTranslate } from "../../../../../Hooks";
import { useVerticalNav } from "../../../../../Contexts/VerticalNavContext";

// styles
import useStyles from "./styles";

const a11yProps = (index) => ({
  "aria-controls": `simple-tabpanel-${index}`,
  id: `Property-tab-${index}`,
});

const TabPanel = ({ children, index, value, ...other }) => {
  return (
    <div
      aria-labelledby={`Property-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      style={{ overflowY: "auto", maxHeight: "calc(100vh - 578px)" }}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

function PropertySideMenuTabs({ handleTabChange, tabValue, activeItem }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");

  return (
    <>
      <Tabs
        TabIndicatorProps={{ style: { display: "none" } }}
        aria-label="PropertyDetailsSideMenuTabs"
        className={styles.tabs}
        onChange={handleTabChange}
        scrollButtons={"off"}
        value={tabValue}
        variant="standard"
        classes={{ flexContainer: styles.flexContainer }}
      >
        <Tab
          className={styles.tabButton}
          disableRipple
          classes={{ selected: styles.tab }}
          label={
            <div className={styles.tabButtonContainer}>
              <span className={clsx(styles.tabLabel, styles.font14)}>
                {translate("DETAILS")}
              </span>
            </div>
          }
          {...a11yProps(0)}
        />
        <Tab
          className={styles.tabButton}
          disableRipple
          classes={{ selected: styles.tab }}
          label={
            <div className={styles.tabButtonContainer}>
              <span className={clsx(styles.tabLabel, styles.font14)}>
                {translate("Unit modals")}
              </span>
            </div>
          }
          {...a11yProps(0)}
        />
      </Tabs>
      <TabPanel index={0} value={tabValue}>
        <DetailsTab activeItem={activeItem} />
      </TabPanel>

      <TabPanel index={1} value={tabValue}>
        <></>
      </TabPanel>
    </>
  );
}

PropertySideMenuTabs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  activeItem: PropTypes.objectOf(PropTypes.any),
  handleTabChange: PropTypes.func,
  tabValue: PropTypes.number,
};

PropertySideMenuTabs.defaultProps = {
  handleTabChange: () => {},
  tabValue: 0,
  activeItem: {},
};

export default PropertySideMenuTabs;
