import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Typography, Divider } from "@material-ui/core";
import { CustomCard } from "../../../../../../../Components";
import { useSelectedTheme, useTranslate } from "../../../../../../../Hooks";
import { GetAllScopeDocuments } from "../../../../../../../Services";
import { ScopeDocumentEnum } from "../../../../../../../Enums";

// Styles
import { identityDetailsStyles } from "./styles";

// Icons
import { Attachment } from "../../../../../../../assets/icons";

function IdentityDetails({ details, contactId }) {
  const styles = identityDetailsStyles();

  const { translate } = useTranslate("NewContactsView");

  const [documents, setDocuments] = useState({
    result: [],
    totalCount: 0,
  });

  const getAllScopeDocuments = async () => {
    const body = {
      scopeId: contactId,
      pageSize: 9999999,
      pageIndex: 0,
      scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
    };

    try {
      const res = await GetAllScopeDocuments(body);

      if (!(res?.status && res.status !== 200))
        setDocuments({
          result: res.result,
          totalCount: res.totalCount,
        });
      else setDocuments({ result: [], totalCount: 0 });
    } catch (error) {
      console.error("Failed to fetch documents:", error);
    }
  };

  const {
    theme: { palette },
  } = useSelectedTheme();

  useEffect(() => {
    if (contactId) {
      getAllScopeDocuments();
    }
  }, [contactId]);

  const idAttachedDocumentsCount = documents.result.filter((doc) => doc.categoryName === "ID")?.length ?? 0;
  const passportDocumentsCount = documents.result.filter((doc) => doc.categoryName === "Passport")?.length ?? 0;

  return (
    <CustomCard
      borderRadius="xl"
      boxShadow="xs"
      borderColor="secondary"
      classes={styles.container}
    >
      <Box className={styles.mainSideWrapper}>
        <Typography className={styles.heading} variant="h3">
        {translate('id-card-details')}
        </Typography>
        <Divider className={styles.divider} />
        <Box className={styles.infoContainer}>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('id-Card-no')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.id_card_no ?? "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('id-card-expiry-date')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.id_card_expiry_date
                ? moment(details?.id_card_expiry_date).format("DD/MM/YYYY")
                : "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('attache')}:
            </Typography>
            <Box className={styles.attachmentWrapper}>
              <Typography className={styles.infoValue} variant="body1">
                {idAttachedDocumentsCount}
              </Typography>
              <Attachment
                width="20" 
                height="20" 
                fill={palette.foreground.quarterary}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography className={styles.heading} variant="h3">
        {translate('passport-details')}
        </Typography>
        <Divider className={styles.divider} />
        <Box className={styles.infoContainer}>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('passport-details')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.passport_no ?? "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('passport-Issue-date')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.passport_issue_date
                ? moment(details?.passport_issue_date).format("DD/MM/YYYY")
                : "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('passport-expiry-date')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.passport_expiry_date
                ? moment(details?.passport_expiry_date).format("DD/MM/YYYY")
                : "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('attache')}:
            </Typography>
            <Box className={styles.attachmentWrapper}>
              <Typography className={styles.infoValue} variant="body1">
                {passportDocumentsCount}
              </Typography>
              <Attachment
                width="20" 
                height="20" 
                fill={palette.foreground.quarterary}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
}

export default IdentityDetails;
