import React, { useState } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import { Box } from "@material-ui/core";
import i18next from "i18next";

import { useSelectedTheme } from "../../../Hooks";
import { DropdownIcon } from "../../../assets/icons";

// // Styles
import useStyles from "./styles";

const PhoneNumberInput = ({
  isValid,
  country,
  onInputChanged,
  idRef,
  isRequired,
  isDisabled,
  value,
  wrapperClasses,
  inputClasses,
  labelClasses,
  labelValue,
  inputPlaceholder,
  onInputBlur,
  error,
  helperText,
  isSubmitted,
  themeClass,
  onKeyDown,
  onKeyUp,
  onPaste,
  // errorClasses,
  // buttonOptions,
  // isLoading,
  // afterIconClasses,
}) => {
  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();
  const [isFocused, setIsFocused] = useState(false);

  const [isBlurOrChanged, setIsBlurOrChanged] = useState(false);
  const CustomizationMasks = {
    ci: "(...) ...-...-..-....",
    mx: "(...) ...-...-..",
    ar: "(..) ...-....-..",
    jo: "(..) ...-....",
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <Box
      style={{
        position: "relative",
      }}
      className={`${wrapperClasses} ${themeClass}`}
    >
      <Box className={styles.errorMessageContainer}>
        <PhoneInput
          onFocus={handleFocus}
          className={styles.phoneInputContainer} // Pass custom styles here
          specialLabel={false}
          isValid={isValid}
          country={country}
          masks={CustomizationMasks}
          excludeCountries={["JE", "je"]}
          enableLongNumbers
          inputProps={{
            required: isRequired,
            id: idRef || labelValue || inputClasses || labelClasses || "",
            autoComplete: "new-password",
            onKeyDown,
            onKeyUp,
            onPaste,
          }}
          containerClass={`${
            (helperText &&
              error &&
              (isSubmitted || isBlurOrChanged) &&
              " phone-error") ||
            ""
          }`}
          disabled={isDisabled}
          value={value}
          inputClass={`${styles.phoneInput} ${inputClasses} ${
            error ? styles.inputContainerError : ""
          } ${!error && isFocused ? styles.inputContainerFocused : ""}`}
          onBlur={(event) => {
            setIsFocused(false);
            setIsBlurOrChanged(true);
            if (onInputBlur) onInputBlur(event);
          }}
          onChange={(newValue, newCountry, event) => {
            setIsBlurOrChanged(true);
            if (newValue && newCountry?.dialCode?.includes(newValue)) {
              if (onInputChanged) onInputChanged(newValue, newCountry, event);
              return;
            } else if (newValue && !newValue.startsWith(newCountry.dialCode)) {
              if (onInputChanged)
                onInputChanged(
                  newCountry.dialCode + newValue,
                  newCountry,
                  event
                );
              return;
            }
            if (onInputChanged) onInputChanged(newValue, newCountry, event);
          }}
          enableTerritories
          enableSearch
          searchPlaceholder="Search"
          localization={i18next.language}
          placeholder={inputPlaceholder}
        />

        {error && <Box className={styles.errorMessage}>{error}</Box>}
      </Box>

      <DropdownIcon
        className={styles.arrowStyle}
        width="20"
        height="20"
        fill={palette.foreground.quarterary}
      />
    </Box>
  );
};

export default PhoneNumberInput;

PhoneNumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.any]),
  onInputChanged: PropTypes.func,
  isValid: PropTypes.func,
  idRef: PropTypes.string.isRequired,
  wrapperClasses: PropTypes.string,
  className: PropTypes.string,
  inputClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  labelValue: PropTypes.string,
  country: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errorClasses: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  onInputBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isSubmitted: PropTypes.bool,
  isLoading: PropTypes.bool,
  themeClass: PropTypes.oneOf(["theme-default"]),
  afterIconClasses: PropTypes.string,
  buttonOptions: PropTypes.shape({
    className: PropTypes.string,
    iconClasses: PropTypes.string,
    onActionClicked: PropTypes.func,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
};
PhoneNumberInput.defaultProps = {
  className: "",
  value: undefined,
  onInputChanged: undefined,
  isValid: undefined,
  country: "ae",
  isRequired: false,
  isDisabled: false,
  translationPath: "",
  parentTranslationPath: "",
  labelValue: null,
  buttonOptions: null,
  wrapperClasses: "",
  inputClasses: "",
  labelClasses: "",
  errorClasses: "",
  themeClass: "theme-default",
  inputPlaceholder: undefined,
  onInputBlur: undefined,
  error: false,
  helperText: undefined,
  isSubmitted: false,
  isLoading: false,
  afterIconClasses: "",
};
