import { makeStyles } from "@material-ui/core/styles";
import { wrap } from "lodash";

export default makeStyles((theme) => {
  return {
    wrapperDuplicatedUnits: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "20px",
      alignItems: "stretch",
      justifyContent:'center'
    },
    containerCardDuplicatedUnit: {
      width: "300px",
      maxWidth: "300px",
      minWidth: "300px",
      border: `1px solid ${theme.palette.border.secondary}`,
      borderRadius: "12px",
      padding: "16px",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      height: "100%", 
      minHeight: "211px",
      cursor: "pointer",
    },
    containerTopSection: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      alignItems:'flex-start',
      justifyContent:'space-between'
    },
    containerImages: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      gap: "8px",
    },
    ImageContainer: {
      width: "130px",
      height: "76px",
      border: `1px solid ${theme.palette.border.secondary}`,
      borderRadius: "12px",
    },
    containerStatusTitleId: {
      display: "flex",
      flexDirection: "column",
      justifyContent:'space-between'
    },
    containerTitleArrowUp: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "6px",
    },
    containerIdWithTitle: {
      display: "flex",
      flexDirection: "row",
      gap: "12px",
      alignItems: "center",
    },
    containerTitleIds: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    },
    containerStatus: {
      display: "flex",
      flexDirection: "row",
      gap: "12px",
      alignItems: "center",
    },
    detailsUnits: {
      display: "flex",
      flexDirection: "row",
      gap: "12px",
    },
    containerUnitSize: {
      display: "flex",
      flexDirection: "row",
      gap: "4px",
    },
    containerBedNumber: {
      display: "flex",
      flexDirection: "row",
      gap: "4px",
    },
    containerBathRoomNumber: {
      display: "flex",
      flexDirection: "row",
      gap: "4px",
    },
    containerBottomSection: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    containerLeftSide: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      alignItems: "center",
    },
    containerRightSide: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
    },
    containerIcon: {
      background: theme.palette.background.gray_200,
      border: `1px solid ${theme.palette.background.gray_200}`,
      borderRadius: "24px",
      padding: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: `0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
    },
    containerName: {
      display: "flex",
      flexDirection: "column",
    },
    propertyNameTitle: {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "20px",
      color: theme.palette.text.secondary,
    },
    containerSelectedCard: {
      border: `2px solid ${theme.palette.border.brandSolid}`,
    },
    warrperImage: {
      width: "130px",
      height: "76px",
      borderRadius: "8px",
    },matchingUnitItemCity :{
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        color: theme.palette.text.secondary,
    },matchingUnitItemOperationType:{
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        color: theme.palette.text.secondary,
    },listingAgentName:{
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        color: theme.palette.text.secondary,
    },listingAgentNameTitle:{
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        color: theme.palette.text.secondary,
    },matchingUnitItemDetails :{
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        color: theme.palette.text.secondary,
    },containerUnitDetailsAndDetails:{
      display:'flex',
      flexDirection:'column',
      gap:'16px'
    }
  };
});
