import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    containerHistoryTimeLinePlsPaggination: {
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-between',
      minHeight:'90vh'
    },
    containerContactHistoryTimeLine: {
      minHeight: "80vh",
      boxSizing: "border-box",
      padding: "0px 32px",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.primary.main,
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: theme.palette.primary.dark,
      },
    },
    loaderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "80vh",
    },
  };
});
