import { Button, IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PaymentPlanManagementDialog from './components/PaymentPlanManagementDialog';
import { PaymentPlansLocalePath } from './i18n/PaymentPlansLocale';
import { useTranslation } from 'react-i18next';
import {
  DeletePaymentPlan,
  GetAllPaymentPlans,
} from '../../../Services/PaymentPlanServices';
import { showError, showSuccess } from '../../../Helper';
import { TableActions } from '../../../Enums';
import { formatPaymentPlansForTableFields } from './models';
import DeleteConfirmationDialog from './components/DeleteConfirmationDialog';
import {
  paymentPlanHeaderData,
  paymentPlansFilterData,
  propertyPaymentPlanFilterData,
  propertyPaymentPlanHeaderData,
} from './headerData';
import PaymentPlansTable from './components/PaymentPlansTable';
import { PermissionsComponent } from '../../../Components';
import { PaymentPlanPermissions } from './Permissions';
import { useSelector } from 'react-redux';

const initialFilterWithOperator = {
  downPayment: { operator: null, value: '' },
  handoverInstallment: { operator: null, value: '' },
};

const initialFilter = (propertyId) => ({
  paymentPlanName: null,
  propertyPlanStatus: null,
  developerName: null,
  propertyId,
  isForSpecificProperty: !!propertyId,
});

function PaymentPlansView({ propertyId, children, onDelete }) {
  const { t } = useTranslation(PaymentPlansLocalePath);
  const { permissions: userPermissions } = useSelector(
    (state) => state.login.loginResponse
  );
  const [isManagementDialogOpen, setIsManagementDialogOpen] = useState(false);
  const [data, setData] = useState({ result: [], totalCount: 0 });
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageIndex: 1, pageSize: 25 });
  const [activeItem, setActiveItem] = useState(null);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);
  const [filterWithOperator, setFilterWithOperator] = useState(
    initialFilterWithOperator
  );
  const [filter, setFilter] = useState(initialFilter(propertyId));

  const permissions = useMemo(
    () => (propertyId ? PaymentPlanPermissions : {}),
    [propertyId]
  );
  const actions = useMemo(() => {
    const baseActions = [];
    if (
      userPermissions.find(
        (permission) =>
          permission.permissionsId ===
          permissions.EditPropertyPaymentPlan?.permissionsId
      ) || !permissions.EditPropertyPaymentPlan?.permissionsId
    ) {
      baseActions.push({ enum: TableActions.edit.key });
    }
    if (
      userPermissions.find(
        (permission) =>
          permission.permissionsId ===
          permissions.DeletePropertyPaymentPlan?.permissionsId
      ) || !permissions.DeletePropertyPaymentPlan?.permissionsId
    ) {
      baseActions.push({ enum: TableActions.delete.key });
    }
    return baseActions;
  }, [permissions, userPermissions]);
  const getAllPaymentPlans = useCallback(async () => {
    try {
      setLoading(true);
      const advanceSearch = {};
      if (filterWithOperator.downPayment.value) {
        advanceSearch.downPayment = filterWithOperator.downPayment.value / 100;
        advanceSearch.searchType = filterWithOperator.downPayment.operator;
      }
      if (filterWithOperator.handoverInstallment.value) {
        advanceSearch.handoverInstallment =
          filterWithOperator.handoverInstallment.value / 100;
        advanceSearch.searchType =
          filterWithOperator.handoverInstallment.operator;
      }
      const body = { ...pagination, ...filter, ...advanceSearch };
      const res = await GetAllPaymentPlans(body);
      res.result = formatPaymentPlansForTableFields(res.result);
      setData(res);
    } catch (error) {
      console.error("🚀 ~ getAllPaymentPlans ~ error:", error)
      showError(t('error_occurred'));
    } finally {
      setLoading(false);
    }
  }, [
    pagination,
    filter.developerName,
    filter.paymentPlanName,
    filter.propertyPlanStatus,
    filterWithOperator.downPayment.value,
    filterWithOperator.handoverInstallment.value,
    filterWithOperator.downPayment.operator,
    filterWithOperator.handoverInstallment.operator,
  ]);
  const onActionClicked = (enumKey, item) => {
    if (TableActions.edit.key === enumKey) {
      setActiveItem(item);
      setIsManagementDialogOpen(true);
    } else if (TableActions.delete.key === enumKey) {
      setActiveItem(item);
      setDeleteConfirmationDialogOpen(true);
    }
  };

  const deletePaymentPlanHandler = async () => {
    try {
      await DeletePaymentPlan(activeItem.propertyPaymentPlanId);
      setDeleteConfirmationDialogOpen(false);
      await getAllPaymentPlans();
      if (onDelete) {
        onDelete();
      }
      showSuccess(t('deleted_successfully'));
    } catch (error) {
      showError(t('error_occurred'));
    }
  };
  const onClearHandler = () => {
    setFilter(initialFilter(propertyId));
    setFilterWithOperator(initialFilterWithOperator);
    setIsClearFiltersClicked(true);
  };

  const onFilterValuesChangeHandler = (data) => {
    if (data) {
      const item = Object.values(data)
        .filter((item) => item.searchableKey)
        .pop();
      if (item) {
        if (filterWithOperator[item?.searchableKey]) {
          if (+item.value) {
            const currentFilter = { ...initialFilterWithOperator };
            let keyToReset = '';
            const valueToReset = Object.entries(currentFilter)
              .find(([key]) => {
                if (key !== item.searchableKey) {
                  keyToReset = key;
                  return true;
                }
              })
              .pop();
            setFilterWithOperator({
              [keyToReset]: valueToReset,
              [item.searchableKey]: {
                value: +item.value,
                operator: item.operator,
              },
            });
          } else {
            setFilterWithOperator(initialFilterWithOperator);
          }
        } else if (Object.hasOwn(filter, item.searchableKey)) {
          setFilter((prev) => ({
            ...prev,
            [item.searchableKey]: item.value,
          }));
        }
      } else {
        setFilter(initialFilter(propertyId));
        setIsClearFiltersClicked(true);
      }
    }
  };
  useEffect(() => {
    getAllPaymentPlans();
  }, [getAllPaymentPlans]);

  return (
    <div className='p-3 payment-plan-view'>
      <div className='flex fj-between '>
        <div className='w-75'>
          <h3 className='bold capitalize payment_plan_title'>
            {t(
              propertyId ? 'payment_plan_title' : 'payment_plan_configuration'
            )}
          </h3>
          <p className='payment_plan_text'>
            {t(
              propertyId
                ? 'payment_plan_text'
                : 'payment_plan_configuration_text'
            )}
          </p>
        </div>
        <PermissionsComponent
          permissionsList={Object.values(permissions)}
          permissionsId={permissions.AddPropertyPaymentPlan?.permissionsId}
          allowEmptyRoles
        >
          <div>
            <Button
              variant='contained'
              className='add-btn capitalize'
              onClick={() => setIsManagementDialogOpen(true)}
            >
              <Add />
              <span>
                {t(propertyId ? 'add_custom' : 'add_new_payment_plan')}
              </span>
            </Button>
          </div>
        </PermissionsComponent>
      </div>
      {children}
      <div className='flex fj-end w-100'>
        <div className='p-3'>
          <IconButton
            className='btns theme-solid bg-danger clear-all-btn'
            onClick={onClearHandler}
          >
            <span className='mdi mdi-filter-remove m-1' />
          </IconButton>
        </div>
      </div>
      <PaymentPlansTable
        isLoading={loading}
        headerData={
          propertyId ? propertyPaymentPlanHeaderData : paymentPlanHeaderData
        }
        data={data}
        pagination={pagination}
        onPageIndexChanged={(index) =>
          setPagination((prev) => ({ ...prev, pageIndex: index + 1 }))
        }
        onPageSizeChanged={(pageSize) =>
          setPagination((prev) => ({ ...prev, pageSize }))
        }
        actions={actions}
        onActionClicked={onActionClicked}
        filterData={
          propertyId ? propertyPaymentPlanFilterData : paymentPlansFilterData
        }
        onFilterValuesChanged={onFilterValuesChangeHandler}
        isClearFiltersClicked={isClearFiltersClicked}
        setIsClearFiltersClicked={setIsClearFiltersClicked}
        filterValues={filterWithOperator}
      />
      <PaymentPlanManagementDialog
        open={isManagementDialogOpen}
        onClose={() => {
          setIsManagementDialogOpen(false);
          setActiveItem(null);
        }}
        activeItem={activeItem}
        reload={getAllPaymentPlans}
        forProperty={!!propertyId}
        propertyId={propertyId}
      />
      <DeleteConfirmationDialog
        onClose={() => {
          setDeleteConfirmationDialogOpen(false);
          setActiveItem(null);
        }}
        onConfirm={deletePaymentPlanHandler}
        isOpen={deleteConfirmationDialogOpen}
      />
    </div>
  );
}

export default PaymentPlansView;
