import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import { FBLabelWrapper } from "../FBLabelWrapper";
import { FBLabelPosition } from "../Constant";

// Styles
import useStyles from "./styles";

/**
 * A reusable wrapper for form fields that manages label positioning.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.label - The label text.
 * @param {boolean} props.required - Whether the field is required.
 * @param {string} props.helperInfo - Additional helper information.
 * @param {"above" | "side"} [props.labelPosition="side"] - The position of the label relative to the input field.
 *        Use "above" for a vertical layout or "side" for a horizontal layout.
 * @param {React.ReactNode} props.children - The form field component (e.g., FBTextField, FBAutocomplete).
 *
 * @returns {JSX.Element}
 */

function FBFieldWrapper({ label, required, helperInfo, labelPosition, children }) {
  const styles = useStyles();

  const isLabelSidePosition = labelPosition === FBLabelPosition.SIDE;

  if (!label) {
    return children;
  }

  return (
    <Box
      display="flex"
      flexDirection={isLabelSidePosition ? "row" : "column"}
      alignItems={isLabelSidePosition ? "center" : "flex-start"}
      className={clsx({
        [styles.wrapperSide]: isLabelSidePosition,
      })}
    >
      <FBLabelWrapper
        label={label}
        required={required}
        helperInfo={helperInfo}
        isLabelSidePosition={isLabelSidePosition}
      />
      {children}
    </Box>
  );
}

FBFieldWrapper.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  helperInfo: PropTypes.string,
  labelPosition: PropTypes.oneOf(["above", "side"]),
  children: PropTypes.node.isRequired,
};

FBFieldWrapper.defaultProps = {
  label: "",
  required: false,
  helperInfo: "",
  labelPosition: FBLabelPosition.SIDE,
};

export default FBFieldWrapper;
