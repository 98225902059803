import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    preferenceDialogWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    rowItem: {
      display: 'flex',
      gap: '16px 32px',
    },
    rowLabel: {
      display: 'flex',
      width: '160px',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },

    defaultAvatar: {
      background: theme.palette.background.secondary_hover,
      border: ` 0.333px solid ${theme.palette.border.avatarContrast}`,
      height: '16px',
      width: '16px',
      borderRadius: '6666px',
      '& img': {
        width: '10.667px',
        height: '10.667px',
      },
    },
    rowField: {
      width: '448px',
    },
    CCContainer: {
      width: '448px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '16px',
    },
    inputFullWidth: {
      width: '100% !important',
      maxWidth: '100% !important',
      [theme.breakpoints.down('426')]: {
        width: '100% !important',
      },
    },
    uploadedFilesContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      marginTop: '12px',
    },
    uploadedFileCard: {
      display: 'flex',
      flexDirection: 'row',
      padding: '16px',
      gap: '12px',
    },
    uploadedFileDetailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    fileHeaderContainer: {
      position: 'relative',
    },
    fileNameText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '330px',
      display: 'inline-block',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      color: theme.palette.text.secondary,
    },
    DeleteIcon: {
      cursor: 'pointer',
      position: 'absolute',
      right: '0px',
      top: '0px',
    },
    fileSizeText: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: theme.palette.text.tertiary,
    },
    customChipInputWrapper: {
      minHeight: '44px',
      display: 'flex',
      width: '100%',
      flexShrink: 0,
      padding: '9px 14px',
      alignItems: 'center',
      gap: '8px',
      alignSelf: 'stretch',
      borderRadius: '8px',
      background: theme.palette.background.paper,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      border: `1px solid ${theme.palette.border.primary}`,
      height: '44px',
    },
    customChipInput: {
      width: '100%',
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
      fontSize: '16px',
      width: '100%',
      backgroundColor: 'transparent',
      lineHeight: '20px',
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
    removeBeforeAfter: {
      '& ::before, ::after': {
        display: 'none',
      },
    },
    inputWrapper: {
      padding: '0px !important',
      '&::placeholder': {
        color: theme.palette.text.placeholder,
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        opacity: '1',
      },
    },
    chipWrapper: {
      height: '100%',
      margin: '0px',
      minHeight: '0',
    },

    toolbar: {
      '& .ql-toolbar.ql-snow': {
        border: 'none',
      },
      '& .ql-stroke': {
        stroke: theme.palette.foreground.quinary,
      },
      '& .ql-fill': {
        fill: theme.palette.foreground.quinary,
      },
      '& .ql-container.ql-snow': {
        borderRadius: '8px',
        border: '1px solid #D0D5DD',
        background: '#FFF',
        borderTop: '1px solid #D0D5DD !important',
        height: '154px',
      },
      '& .ql-editor.ql-blank::before ': {
        color: theme.palette.text.text_quarterary,
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
      },
    },

    customChipWrapper: {
      borderRadius: '6px',
      border: `1px solid ${theme.palette.border.primary}`,
      background: theme.palette.background.paper,
      display: 'flex',
      padding: '2px 4px 2px 5px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '3px',
      height: '24px',
      color: theme.palette.text.secondary,
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
    },
    customChipContent: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
    closeXIcon: {
      cursor: 'pointer',
    },
    inputWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
      width: '100%',
    },
    inputContainerError: {
      border: ` 1px solid ${theme.palette.border.error}`,
      boxShadow: theme.shadows[1],
    },
    errorMessage: {
      color: theme.palette.text.error_primary,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    inputContainerFocused: {
      boxShadow: theme.shadows[8],
      border: `1px solid ${theme.palette.border.brand}`,
    },
  };
});
