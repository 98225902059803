import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Tables } from '../../../../../Components';
import { useTranslation } from 'react-i18next';

const AgentPerformanceDetails = ({
  agentPerformance,
  details,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {agentPerformance}
      </AccordionSummary>
      <AccordionDetails>
        <Tables
          parentTranslationPath={'Agents'}
          headerData={[
            {
              label: 'criteria',
              input: 'criteria',
              component: (row) => t(row.criteria),
            },
            {
                label: 'achieved-value',
                input: 'value',
            },
            {
              label: 'percentage',
              input: 'percentage',
            },
          ]}
          data={details}
          itemsPerPage={details.length}
        />
      </AccordionDetails>
    </Accordion>
  );
};
export default AgentPerformanceDetails;
