import React from "react";
import { SwitchRoute } from "../../../Components/Route/SwitchRoute";
import { NewPropertiesCrmRoutes } from "../../../routes/HomeRoutes/NewPropertiesCrmRoutes";
import { AdvancedSearchProvider } from "./PropertiesLayoutContext";

export const NewPropertiesCrmLayout = () => {
  return (
    <AdvancedSearchProvider>
      <SwitchRoute routes={NewPropertiesCrmRoutes} />
    </AdvancedSearchProvider>
  );
};

