import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Box, Typography, Divider } from "@material-ui/core";
import { CustomDialog } from "../../../../CustomDialog";
import { useTranslate } from "../../../../../../Hooks";
import { CustomAutocomplete } from "../../../../CustomAutocomplete";
import { CustomInput } from "../../../../CustomInput";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import {
  CityTypeIdEnum,
  CommunityTypeIdEnum,
  DistrictTypeIdEnum,
  NationalityEnum,
  PropertyPlanEnum,
  SubCommunityTypeIdEnum,
} from "../../../../../../Enums";
import { lookupItemsGetId, propertyPost } from "../../../../../../Services";
import { StaticLookupsIds } from "../../../../../../assets/json/StaticLookupsIds";
import { propertyInitValue } from "../../../../../../Views/Home/Common/HeaderView/Sections/QuickAddPopoverComponent/Dialogs/PropertQuickAddDialog/PropertyQuickAddInitialValue";

//styles
import useStyles from "./styles";

const PropretySucsessValidateDialog = ({ onOpen, onClose }) => {
  const styles = useStyles();
  const { translate } = useTranslate("HeaderView");

  const { addNewProprety, setDuplicatedProprety, setAlertBoxContent } =
    useVerticalNav();

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [propertyDetails, setPropertyDetails] = useState(
    JSON.parse(JSON.stringify(propertyInitValue))
  );
  const [isOnSubmit, setIsOnSubmit] = useState(false);
  const [selected, setSelected] = useReducer(reducer, {
    propretyPlane: null,
    Propertyname: null,
    propretyType: null,
    country: null,
    city: null,
    district: null,
    coummunity: null,
    subCoummunity: null,
  });

  const [saveDto, setSaveDto] = useReducer(reducer, {
    propretyPlane: null,
    Propertyname: null,
    propretyType: null,
    country: null,
    city: null,
    district: null,
    coummunity: null,
    subCoummunity: null,
  });

  const [data, setData] = useReducer(reducer, {
    propretyPlane: [],
    propretyType: [],
    country: [],
    city: [],
    district: [],
    coummunity: [],
    subCoummunity: [],
  });

  const GetAllPropertyPlan = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: PropertyPlanEnum.lookupTypeId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "propretyPlane",
        value: res || [],
      });
    } else
      setData({
        id: "propretyPlane",
        value: [],
      });
  }, []);

  const GetAllPropertyType = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.PropertyType,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "propretyType",
        value: res || [],
      });
    } else
      setData({
        id: "propretyType",
        value: [],
      });
  }, []);

  const GetCountries = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: NationalityEnum.lookupTypeId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "country",
        value: res || [],
      });
    } else
      setData({
        id: "country",
        value: [],
      });
  }, []);

  const getCities = useCallback(async (countryId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: CityTypeIdEnum.lookupTypeId,
      lookupParentId: countryId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "city",
        value: res || [],
      });
    } else
      setData({
        id: "city",
        value: [],
      });
  }, []);

  const GetDistrict = useCallback(async (cityId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: DistrictTypeIdEnum.lookupTypeId,
      lookupParentId: cityId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "district",
        value: res || [],
      });
    } else
      setData({
        id: "district",
        value: [],
      });
  }, []);

  const GetCommunity = useCallback(async (districtId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: CommunityTypeIdEnum.lookupTypeId,
      lookupParentId: districtId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "coummunity",
        value: res || [],
      });
    } else
      setData({
        id: "coummunity",
        value: [],
      });
  }, []);

  const getSubCommunity = useCallback(async (coumunityId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: SubCommunityTypeIdEnum.lookupTypeId,
      lookupParentId: coumunityId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "subCoummunity",
        value: res || [],
      });
    } else
      setData({
        id: "subCoummunity",
        value: [],
      });
  }, []);

  const AddPropretyHandler = async () => {
    setIsOnSubmit(true);
    try {
      const newPropertyDetails = {
        ...propertyDetails,
        property: {
          ...propertyDetails.property,
          country: selected?.country || null,
          city: selected?.city || null,
          district: selected?.district || null,
          community: selected?.coummunity || null,
          sub_community: selected?.subCoummunity || null,
          property_type_id: saveDto?.propretyType || null,
          property_name: selected?.Propertyname || null,
          property_type: selected?.propretyType,
          property_plan: selected?.propretyPlane,
        },
      };
      const postResponse = await propertyPost({
        propertyJson: newPropertyDetails,
      });

      if (
        !(postResponse && postResponse.status && postResponse.status !== 200)
      ) {
        setAlertBoxContent({
          display: true,
          variant: "success",
          title: `Proprety ${postResponse?.propertyName} with id ${postResponse?.propertyId} added Successfully!`,
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
        setDuplicatedProprety({
          PropretySucsessValidateDialog: false,
        });
      } else {
        const errorMsg =
          postResponse &&
          postResponse.data &&
          postResponse.data.Message &&
          postResponse.data.Message.split(":") &&
          postResponse.data.Message.split(":").pop();

        setAlertBoxContent({
          display: true,
          variant: "error",
          title: `${errorMsg.substring(
            0,
            errorMsg.lastIndexOf(" ")
          )} ${errorMsg.substring(errorMsg.lastIndexOf(" "), errorMsg.length)}`,
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    } catch (error) {
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to add the Proprety.",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
      console.log("some thing wrong !", error);
    } finally {
      setIsOnSubmit(false);
    }
  };

  useEffect(() => {
    if (!addNewProprety?.Proprety) return;

    setSelected({
      id: "edit",
      value: {
        propretyPlane: addNewProprety?.Proprety?.propretyPlane || null,
        Propertyname: addNewProprety?.Proprety?.Propertyname || null,
        propretyType: addNewProprety?.Proprety?.propretyType || null,
        country: addNewProprety?.Proprety?.country || null,
        city: addNewProprety?.Proprety?.city || null,
        district: addNewProprety?.Proprety?.district || null,
        coummunity: addNewProprety?.Proprety?.coummunity || null,
        subCoummunity: addNewProprety?.Proprety?.subCoummunity || null,
      },
    });

    setSaveDto({
      id: "edit",
      value: {
        propretyPlane: addNewProprety?.Proprety?.propretyPlane || null,
        Propertyname: addNewProprety?.Proprety?.Propertyname || null,
        propretyType:
          addNewProprety?.Proprety?.propretyType?.lookupItemId || null,
        country: addNewProprety?.Proprety?.country?.lookupItemId || null,
        city: addNewProprety?.Proprety?.city?.lookupItemId || null,
        district: addNewProprety?.Proprety?.district?.lookupItemName || null,
        coummunity: addNewProprety?.Proprety?.coummunity?.lookupItemId || null,
        subCoummunity:
          addNewProprety?.Proprety?.subCoummunity?.lookupItemId || null,
      },
    });
  }, [addNewProprety]);

  useEffect(() => {
    GetAllPropertyPlan();
    GetAllPropertyType();
    GetCountries();
  }, [GetAllPropertyPlan, GetAllPropertyType, GetCountries]);

  return (
    <CustomDialog
      onClose={() => {
        onClose();
      }}
      open={onOpen}
      subtitle={translate(
        "Fill-in-the-details-below-to-add-a-new-property-to-your-list-Ensure-all-required-fields-are-completed-before-saving"
      )}
      onConfirm={() => {
        AddPropretyHandler();
      }}
      isDisabled={isOnSubmit}
      title={translate("Add-new-property")}
      confirmText={translate("Add-Proprety")}
      width={"688px"}
      content={
        <Box className={styles.wrapperaddNewProretycheckDuplicate}>
          <Box className={styles.containerFiledItem}>
            <Typography className={styles.titleFiled}>
              {translate("proprety-Plane")}*
            </Typography>
            <CustomAutocomplete
              placeholder={translate("Select")}
              options={data?.propretyPlane || []}
              onChange={(e, value) => {
                setSelected({
                  id: "propretyPlane",
                  value: value,
                });
                setSaveDto({
                  id: "propretyPlane",
                  value: value?.lookupItemId,
                });
              }}
              value={selected?.propretyPlane || null}
              getOptionLabel={(option) => option?.lookupItemName || ""}
              getOptionValue={(option) => option?.lookupItemId || ""}
              renderOption={(option) => option?.lookupItemName || ""}
            />
          </Box>
          <Divider />

          <Box className={styles.containerFiledItem}>
            <Typography className={styles.titleFiled}>
              {translate("Property-name")}*
            </Typography>
            <CustomInput
              disabled={true}
              backgroundColorDisabled={styles.backgroundColorTextInputFiled}
              colorDisabled={styles.colorTextInputFiled}
              inputContainerOverrideStyles={styles.inputFullWidth}
              hasSearchIcon={false}
              placeholder={translate("Enter")}
              type="text"
              value={selected?.Propertyname}
              onChange={(e) => {
                setSelected({
                  id: "Propertyname",
                  value: e?.target?.value,
                });
                setSaveDto({
                  id: "Propertyname",
                  value: e?.target?.value,
                });
              }}
            />
          </Box>
          <Divider />

          <Box className={styles.containerFiledItem}>
            <Typography className={styles.titleFiled}>
              {translate("Property-type")}*
            </Typography>
            <CustomAutocomplete
              isDisabled={true}
              placeholder={translate("Select")}
              options={data?.propretyType || []}
              onChange={(e, value) => {
                setSelected({
                  id: "propretyType",
                  value: value,
                });
                setSaveDto({
                  id: "propretyType",
                  value: value?.lookupItemId,
                });
              }}
              value={selected?.propretyType || null}
              getOptionLabel={(option) => option?.lookupItemName || ""}
              getOptionValue={(option) => option?.lookupItemId || ""}
              renderOption={(option) => option?.lookupItemName || ""}
            />
          </Box>
          <Divider />

          <Box className={styles.containerFiledItem}>
            <Typography className={styles.titleFiled}>
              {translate("Country")}*
            </Typography>
            <CustomAutocomplete
              isDisabled={true}
              placeholder={translate("Select")}
              options={data?.country || []}
              onChange={(e, value) => {
                setSelected({
                  id: "country",
                  value: value,
                });
                setSaveDto({
                  id: "country",
                  value: value?.lookupItemId,
                });

                if (value) {
                  getCities(value?.lookupItemId);
                }
              }}
              value={selected?.country || null}
              getOptionLabel={(option) => option?.lookupItemName || ""}
              getOptionValue={(option) => option?.lookupItemId || ""}
              renderOption={(option) => option?.lookupItemName || ""}
            />
          </Box>
          <Divider />

          <Box className={styles.containerFiledItem}>
            <Typography className={styles.titleFiled}>
              {translate("City")}*
            </Typography>
            <CustomAutocomplete
              isDisabled={true}
              placeholder={translate("Select")}
              options={data?.city || []}
              onChange={(e, value) => {
                setSelected({
                  id: "city",
                  value: value,
                });
                setSaveDto({
                  id: "city",
                  value: value?.lookupItemId,
                });

                if (value) {
                  GetDistrict(value?.lookupItemId);
                }
              }}
              value={selected?.city || null}
              getOptionLabel={(option) => option?.lookupItemName || ""}
              getOptionValue={(option) => option?.lookupItemId || ""}
              renderOption={(option) => option?.lookupItemName || ""}
            />
          </Box>
          <Divider />

          <Box className={styles.containerFiledItem}>
            <Typography className={styles.titleFiled}>
              {translate("District")}
            </Typography>
            <CustomAutocomplete
              isDisabled={true}
              placeholder={translate("Select")}
              options={data?.district || []}
              onChange={(e, value) => {
                setSelected({
                  id: "district",
                  value: value,
                });
                setSaveDto({
                  id: "district",
                  value: value?.lookupItemId,
                });

                if (value) {
                  GetCommunity(value?.lookupItemId);
                }
              }}
              value={selected?.district || null}
              getOptionLabel={(option) => option?.lookupItemName || ""}
              getOptionValue={(option) => option?.lookupItemId || ""}
              renderOption={(option) => option?.lookupItemName || ""}
            />
          </Box>
          <Divider />

          <Box className={styles.containerFiledItem}>
            <Typography className={styles.titleFiled}>
              {translate("Community")}
            </Typography>
            <CustomAutocomplete
              isDisabled={true}
              placeholder={translate("Select")}
              options={data?.coummunity || []}
              onChange={(e, value) => {
                setSelected({
                  id: "coummunity",
                  value: value,
                });
                setSaveDto({
                  id: "coummunity",
                  value: value?.lookupItemId,
                });
                if (value) {
                  getSubCommunity(value?.lookupItemId);
                }
              }}
              value={selected?.coummunity || null}
              getOptionLabel={(option) => option?.lookupItemName || ""}
              getOptionValue={(option) => option?.lookupItemId || ""}
              renderOption={(option) => option?.lookupItemName || ""}
            />
          </Box>
          <Divider />

          <Box className={styles.containerFiledItem}>
            <Typography className={styles.titleFiled}>
              {translate("Sub-community")}
            </Typography>
            <CustomAutocomplete
              isDisabled={true}
              placeholder={translate("Select")}
              options={data?.subCoummunity || []}
              onChange={(e, value) => {
                setSelected({
                  id: "subCoummunity",
                  value: value,
                });
                setSaveDto({
                  id: "subCoummunity",
                  value: value?.lookupItemId,
                });
              }}
              value={selected?.subCoummunity || null}
              getOptionLabel={(option) => option?.lookupItemName || ""}
              getOptionValue={(option) => option?.lookupItemId || ""}
              renderOption={(option) => option?.lookupItemName || ""}
            />
          </Box>
        </Box>
      }
    />
  );
};
export default PropretySucsessValidateDialog;
