import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { Spinner } from "../../../../../../Components";

import { showSuccess, showError, GetParams } from "../../../../../../Helper";
import {
  UpdateAgreementDetailsAPI,
  getProperties,
  GetLookupItemsByLookupTypeName,
  GetAllBranches,
} from "../../../../../../Services";
import { ApplicationUserSearch } from "../../../../../../Services/userServices";
import {
  AgreementDetailtsFooterDialog,
  AgreementDetailsContentDialog,
} from "../AgreementDetailsManagement";
import {FooterWrapperComponent  } from '../../../DevelopersUtilities/Components/FooterWrapperComponent' ;

export const AgreementDetailsManagementDialog = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
  agreementDetailsValues,
  contactReletedWithDeveloperId 
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [isLoading, setIsLoading] = useState({
    agreementsTypes: false,
    addAgreementDetails: false,
    branches: false,
    users: false,
    info: [],
    properties: false,
    editAgreementDetails : false
  });
  const searchTimer = useRef(null);

  const [items, setItems] = useState({
    agreementsTypes: [],
    branches: [],
    users: [],
    properties: [],
    allAgreementDetails: {
      result: [],
      totalCount: 0,
    },
  });
  const [defaultState, setDefaultState] = useState({
    developerContactId: null,
    agreementDetailsId: null,
    agreementTypeId: null,
    agreementStartDate: null,
    agreementDuration: null,
    agreementEndDate: null,
    isMarketingBudgetAllocation: false,
    marketingBudgetAmount: null,
    agreementDetailsProjects: [],
    agreementDetailsSigningBranches: [],
    agreementDetailsAuthorizedSignatories: [],
    agreementStatusId : null,
  });

  const [defaultSelected, setDefaultSelected] = useState({
    agreementType: null,
    marketingBudgetAllocation: null,
    agreementDetailsProjects: [],
    agreementDetailsSigningBranches: [],
    agreementDetailsAuthorizedSignatories: [],
    agreementsStatus : null
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const SaveUpdateAgreementDetailsHandler = useCallback(async () => {
    setIsSubmitted(true);
    setIsLoading((item) => ({ ...item, editAgreementDetails: true }));
    const res = await UpdateAgreementDetailsAPI(state.agreementDetailId, state);
    setIsLoading((item) => ({ ...item, editAgreementDetails: false }));
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}agreement-details-update-successfully`);
      onSave(true);
    } else showError(t`${translationPath}agreement-details-update-failed`);
  }, [state]);

  useEffect(() => {
    if (agreementDetailsValues) {
      const updateState = {
        agreementDetailId: agreementDetailsValues?.agreementDetailId,
        developerContactId: agreementDetailsValues?.developerContactId,
        agreementTypeId: agreementDetailsValues?.agreementTypeId,
        agreementStatusId : agreementDetailsValues?.agreementStatusId,
        agreementStartDate: agreementDetailsValues?.agreementStartDate,
        agreementDuration: agreementDetailsValues?.agreementDuration,
        agreementEndDate: agreementDetailsValues?.agreementEndDate,
        isMarketingBudgetAllocation:
          agreementDetailsValues?.isMarketingBudgetAllocation,
        marketingBudgetAmount: agreementDetailsValues?.marketingBudgetAmount,
        agreementDetailsProjects:
          agreementDetailsValues?.agreementDetailsProjects,
        agreementDetailsSigningBranches:
          agreementDetailsValues?.agreementDetailsSigningBranches,
        agreementDetailsAuthorizedSignatories:
          agreementDetailsValues?.agreementDetailsAuthorizedSignatories,
      };
      const updateSelected = {
        marketingBudgetAllocation:
          agreementDetailsValues?.isMarketingBudgetAllocation
            ? { key: true, name: "yes" }
            : { key: false, name: "no" },
        agreementType: agreementDetailsValues?.agreementType
          ? {
              lookupItemId: agreementDetailsValues?.agreementTypeId,
              lookupItemName: agreementDetailsValues?.agreementType,
            }
          : null,
        agreementDetailsProjects:
          agreementDetailsValues?.agreementDetailsProjects,
        agreementDetailsSigningBranches:
          agreementDetailsValues?.agreementDetailsSigningBranches,
        agreementDetailsAuthorizedSignatories:
          agreementDetailsValues?.agreementDetailsAuthorizedSignatories,
        createdBy: agreementDetailsValues?.createdBy,
        createdOn: agreementDetailsValues?.createdOn,
        agreementsStatus : agreementDetailsValues?.agreementsStatus ?? {
          lookupItemId: agreementDetailsValues?.agreementStatusId,
              lookupItemName: agreementDetailsValues?.agreementStatus,
        }
      };
      setState({ id: "edit", value: updateState });
      setSelected({ id: "edit", value: updateSelected });
    }
  }, [agreementDetailsValues]);

  return (
    <div>
      <Spinner isActive={saveIsLoading} isAbsolute />
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        className="activities-management-dialog-wrapper"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}edit-agreement-details`)}</span>
        </DialogTitle>
        <DialogContent>
          <AgreementDetailsContentDialog
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            state={state}
            setState={setState}
            selected={selected}
            setSelected={setSelected}
            isSubmitted={isSubmitted}
            contactReletedWithDeveloperId={contactReletedWithDeveloperId}
          />
        </DialogContent>
        <DialogActions>
        <FooterWrapperComponent
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onClose={onClose}
            onSave={SaveUpdateAgreementDetailsHandler}
            disabled={!selected.agreementType || isLoading.editAgreementDetails}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

AgreementDetailsManagementDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editPortalConfiguration: PropTypes.instanceOf(Object),
  isLoading: PropTypes.instanceOf(Object),
};
AgreementDetailsManagementDialog.defaultProps = {
  editPortalConfiguration: undefined,
  isLoading: undefined,
};
