import React, { useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { CustomTimeline } from "../../../../../CustomTimeline";
import { DropdownIcon, EyeIcon } from "../../../../../../../assets/icons";
import { useSelectedTheme, useTranslate } from "../../../../../../../Hooks";
import moment from "moment";
import { CustomIconButton } from "../../../../../CustomIconButton";
import { CustomCopyToClipboard } from "../../../../../CustomCopyToClipboard";
import ContactHistoryOfMergedComponent from "../ContactHistoryOfMergedComponent/ContactHistoryOfMergedComponent";
import { getDownloadableLink } from "../../../../../../../Helper";

// styles 
import useStyles from "./styles";

function ContactMergingHistoryTimeLine({
  timelineItems,
  typeId,
  formType,
  pathName,
  handleComponentSwitch,
  componentSwitch,
}) {
  const styles = useStyles();
  const [activeItem, setActiveItem] = useState(null);
  const { translate } = useTranslate("NewContactsView");
  const handleHistoryOfMergedView = (item) => {
    setActiveItem(item);
    handleComponentSwitch({
      IsshowBackIcon: true,
      newTitle: `${translate("History-Of-Merged")} ${item.mergedBy}`,
      isComponentSwitch: true,
    });
  };
  return (
    <>
      {componentSwitch ? (
        <ContactHistoryOfMergedComponent activeItem={activeItem} />
      ) : (
        <CustomTimeline
          timelineData={timelineItems}
          component={TimelineItemContent}
          componentProps={{
            typeId,
            formType,
            pathName,
            handleHistoryOfMergedView,
          }}
          timelinePrefixComponent={TimelinePrefixContent}
          timelineDotTopMargin="15px"
        />
      )}
    </>
  );
}

export default ContactMergingHistoryTimeLine;

function TimelineItemContent({ item, handleHistoryOfMergedView }) {
  const {
    theme: { palette },
  } = useSelectedTheme();
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenTransactionDetailsToogle = () => {
    setIsOpen(!isOpen);
  };

  const getAvatarSrc = (item) => {
    const orginalContactDataPrased = item?.orginalContactData
      ? JSON.parse(item?.orginalContactData)
      : null;
    const uuid =
      orginalContactDataPrased?.contact?.contact_image?.["Image Upload"]?.[0]
        ?.uuid || null;
    if (uuid) {
      return getDownloadableLink(uuid);
    } else {
      const gender = orginalContactDataPrased?.contact?.gender?.lookupItemName;
      return gender === "Female"
        ? "/images/contacts/FemaleAvatar.svg"
        : "/images/contacts/MaleAvatar.svg";
    }
  };
  const ids = item.absoluteContactIds.split(",");
  return (
    <Box className={styles.wrapperHistoryMergedTimelineItemContent}>
      <Box className={styles.HistoryTimelineItemContent}>
        <Box className={styles.containerHistoryInformation}>
          <Box className={styles.containerHeaderHistory}>
            <Box className={styles.containerImage}>
              <img className={styles.imageWrapper} src={getAvatarSrc(item)} />
            </Box>
            <Typography className={styles.createdByTitle}>
              {item?.mergedBy}
            </Typography>
          </Box>
        </Box>
        <CustomIconButton
          variant="text"
          size="none"
          boxShadow="none"
          color="tertiaryColor"
          hideHoverBg
          onClick={() => {
            handleOpenTransactionDetailsToogle();
          }}
        >
          <DropdownIcon
            width="20"
            height="20"
            fill={palette.foreground.navigation["nav-item-button-icon-fg"]}
            style={{
              transform: isOpen === true ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          />
        </CustomIconButton>
      </Box>
      {isOpen && (
        <Box className={styles.containerTableContactMergingHistory}>
          <Box className={styles.headerTable}>
            <Box className={styles.tableCellTitle}>{translate("Final")}</Box>
            <Box className={styles.tableCellTitle}>{translate("Original")}</Box>
            <Box className={styles.tableCellTitle}>{translate("Absolute")}</Box>
            <Box className={styles.tableCellAction}>{translate("Action")}</Box>
          </Box>
          <Box className={styles.bodyTable}>
            <Box className={styles.tableCellValue}>
              <CustomCopyToClipboard
                data={item?.finalId} // copy
                childrenData={"#" + item?.finalId} // render
                copyDoneShowTime={1000}
                customContainerClasses={styles.copyToClipboard}
              />
            </Box>
            <Box className={styles.tableCellValue}>
              <CustomCopyToClipboard
                data={item?.finalId} // copy
                childrenData={"#" + item?.finalId} // render
                copyDoneShowTime={1000}
                customContainerClasses={styles.copyToClipboard}
              />
            </Box>
            <Box className={styles.tableCellValue}>
              {item?.absoluteContactIds && (
                <>
                  <div className={styles.copyToClipboardRow}>
                    <CustomCopyToClipboard
                      data={ids[0]}
                      childrenData={"#" + ids[0]}
                      copyDoneShowTime={1000}
                      customContainerClasses={styles.copyToClipboard}
                    />
                  </div>
                  {ids.length > 1 && (
                    <Tooltip
                      title={ids
                        .slice(1)
                        .map((id) => `#${id}`)
                        .join(", ")}
                      arrow
                    >
                      <span className={styles.moreItemsIndicator}>
                        +{ids.length - 1}
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
            </Box>
            <Box className={styles.tableCellAction}>
              <IconButton
                aria-label="Preview"
                className={styles.button}
                onClick={() => {
                  handleHistoryOfMergedView(item);
                }}
              >
                <EyeIcon
                  width="20"
                  height="20"
                  fill={palette.button.tertiaryGray_fg}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

function TimelinePrefixContent({ item }) {
  const styles = useStyles();
  const formatedDateTime = (dateTime) => {
    if (!dateTime) return { day: null, time: null, year: null };
    const day = moment(dateTime).locale("en").format("D MMM");
    const year = moment(dateTime).locale("en").format("YYYY");
    const time = moment(dateTime).locale("en").format("h:mm a");

    return { day, time, year };
  };
  return (
    <Box className={styles.containerTimelinePrefixContent}>
      <Box className={styles.dayTitle}>
        {formatedDateTime(item.mergedDate).day}
      </Box>
      <Box className={styles.yearTitle}>
        {formatedDateTime(item.mergedDate).year}
      </Box>
      <Box className={styles.timeTitle}>
        {formatedDateTime(item.mergedDate).time}
      </Box>
    </Box>
  );
}
