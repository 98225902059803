import React, { useState, useEffect, useContext } from "react";
import { Box } from "@material-ui/core";

import { useTranslate, useSelectedTheme } from "../../../Hooks";
import { CustomSelect } from "../..";
import { UpdateKycRiskRating } from "../../../Services";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import { RiskRatingEnum } from "../../../Enums";
import { useNewLayoutShared } from "../../../Contexts/NewLayoutSharedContext";

import { ArrowNarrowRight } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

const RiskRatingModal = ({ isRatingConfirmed, setIsRatingDisabled }) => {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();
  const [fieldValue, setFieldValue] = useState("");
  const [newRiskRatingStatus, setNewRiskRatingStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { setAlertBoxContent } = useVerticalNav();

  const { kycHistoryActions, setKycHistoryActions } =
  useNewLayoutShared()

  const handleSelectTypeChange = async (value) => {
    setNewRiskRatingStatus(value);
  };
  const updateKycRiskRating = async () => {
    setIsLoading(true);
    const body = {
      sharedKycDocumentId:
        kycHistoryActions?.activeItem.sharedKycDocmentHistoryId || null,
      newRiskRatingStatus: newRiskRatingStatus,
      reason: fieldValue,
    };
    const res = await UpdateKycRiskRating(body);

    if (!(res && res.status && res.status !== 200)) {
      setAlertBoxContent({
        display: true,
        variant: "success",
        title: "Changes saved successfully!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } else {
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Changes save failed..",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    }
    setIsLoading(false);

    setKycHistoryActions((prev) => ({
      ...prev,
      isRiskRatingClicked: false,
    }));
  };

  useEffect(() => {
    if (isRatingConfirmed && fieldValue && newRiskRatingStatus) {
      updateKycRiskRating();
    }
  }, [isRatingConfirmed]);

  useEffect(() => {
    if (fieldValue && newRiskRatingStatus) {
      setIsRatingDisabled(false);
    } else {
      setIsRatingDisabled(true);
    }
  }, [fieldValue, newRiskRatingStatus]);

  return (
    <Box>
      <Box className={styles.fieldContainer}>
        <label>{translate("Change_rating")}</label>

        <Box className={styles.flex}>
          <CustomSelect
            options={RiskRatingEnum}
            variant="dot"
            disabled={true}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            defaultValue={
              kycHistoryActions?.activeItem?.riskRatingDurationTypeInfo
                ?.enumName
            }
            customSelectClasses={styles.customSelect}
          />

          <ArrowNarrowRight
            width="20"
            height="20"
            fill={palette.foreground.navigation["nav-item-button-icon-fg"]}
          />

          <CustomSelect
            options={RiskRatingEnum}
            disabled={isLoading}
            onValueChange={handleSelectTypeChange}
            variant="dot"
            placeholder={"To"}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            customSelectClasses={styles.customSelect}
          />
        </Box>
      </Box>

      <Box className={styles.fieldContainer}>
        <label>{translate("REASON_LABEL")}</label>
        <textarea
          className={styles.textAreaInput}
          placeholder={translate("Enter_reason")}
          value={fieldValue}
          onChange={(e) => setFieldValue(e.target.value)}
        />
      </Box>
    </Box>
  );
};

export default RiskRatingModal;
