import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  AutocompleteComponent,
  DialogComponent,
  Inputs,
  Spinner,
} from '../../../../../Components';
import {
  GetAllUnitImagesCategoryByUnitId,
  GetUnitMarketingByUnitId,
  MarkUnitAsAvailable,
} from '../../../../../Services';
import { Button } from '@material-ui/core';
import { UnitsSalesProfileManagementDialog } from '../../../UnitsSalesView/UnitsSalesProfileManagement/Common/UnitsSalesProfileManagementDialog/UnitsSalesProfileManagementDialog';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';
import {
  AgentRoleEnum,
  UnitProfileImagesCardActionEnum,
  UnitsOperationTypeEnum,
} from '../../../../../Enums';
import { ImagesGalleryAlbumsCardComponent } from '../../../ImagesGallery/Sections/ImagesGalleryAlbumsComponent/Sections';
import '../../../UnitsView/UnitStatusBreadcrumb/UnitStatusBreadcrumb.scss';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import { ApplicationUserSearch } from '../../../../../Services/userServices';
import { getErrorByName, showError } from '../../../../../Helper';

function MarkUnitAvailableDialog({
  isOpen,
  isOpenChanged,
  activeItem,
  translationPath,
  parentTranslationPath,
  publishActiveItem,
  UpdatePublishData,
  reloadData,
  isForLease,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const defaultState = {
    unitId: publishActiveItem?.unitId || activeItem?.id,
    title: null,
    marketingDescription: null,
    listingAgentId: publishActiveItem
      ? publishActiveItem?.listingAgentId
      : isForLease
      ? activeItem?.rent_listing_agent?.id
      : activeItem?.listing_agent?.id || null,
    images: [],
    operationType: isForLease
      ? UnitsOperationTypeEnum.rent.key
      : UnitsOperationTypeEnum.sale.key,
    sellingPrice: !isForLease
      ? +activeItem?.price || +publishActiveItem?.sellingPrice || null
      : null,
    rentPerYearPrice: isForLease
      ? +activeItem?.price || +publishActiveItem?.rentPerYearPrice || null
      : null,
    titleEn: publishActiveItem?.titleEn || null,
    titleAr: publishActiveItem?.titleAr || null,
    descriptionEn: publishActiveItem?.descriptionEn || null,
    descriptionAr: publishActiveItem?.descriptionAr || null,
    listingAgentName: publishActiveItem
      ? publishActiveItem?.listingAgentName
      : isForLease
      ? activeItem?.rent_listing_agent?.fullName
      : activeItem?.listing_agent?.fullName || null,
    isPublishUnitSale: !isForLease ? true : false,
    isPublishUnitLease: isForLease ? true : false,
  };
  const [state, setState] = useReducer(reducer, defaultState);
  const [loadingStates, setLoadingStates] = useReducer(reducer, {
    isMarketingLoading: false,
    isListingAgentLoading: false,
    isImagesDataLoading: false,
    savedDataLoading: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [imageCategoryData, setImageCategoryData] = useState();
  const [isUploadImageDialogOpen, setUploadImageDialogOpen] = useState(false);
  const [hideIcon, setHideIcon] = useState(false);
  const [marketingImages, setMarketingImages] = useState([]);
  const [listingAgentsData, setListingAgentData] = useState([]);
  const listingAgent = {
    id: publishActiveItem?.listingAgentId,
    fullName: publishActiveItem?.listingAgentName,
  };

  const hasListingAgent = Object.values(listingAgent).some(
    (value) => value !== undefined
  );

  const [selectedAgent, setSelectedAgent] = useState(
    hasListingAgent
      ? listingAgent
      : isForLease
      ? activeItem?.rent_listing_agent
      : activeItem?.listing_agent || {}
  );

  const schema = Joi.object({
    [publishActiveItem ? 'titleEn' : 'title']: Joi.string()
      .required()
      .messages({
        'string.base': t(`marketing-title-is-required`),
        'string.empty': t(`marketing-title-is-required`),
      }),
    [publishActiveItem ? 'descriptionEn' : 'marketingDescription']: Joi.string()
      .required()
      .messages({
        'string.base': t(`marketing-description-is-required`),
        'string.empty': t(`marketing-description-is-required`),
      }),
    listingAgentId: Joi.string()
      .required()
      .messages({
        'string.base': t(`listing-agent-is-required`),
        'string.empty': t(`listing-agent-is-required`),
        'any.required': t(`listing-agent-is-required`),
      }),
    [isForLease ? 'rentPerYearPrice' : 'sellingPrice']: Joi.number()
      .min(1)
      .required()
      .messages({
        'number.base': isForLease
          ? t(`rent-price-is-required`)
          : t(`selling-price-is-required`),
        'number.empty': isForLease
          ? t(`rent-price-is-required`)
          : t(`selling-price-is-required`),
        'number.min': isForLease
          ? t(`rent-price-is-required`)
          : t(`selling-price-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const getUnitMarketingData = async () => {
    setLoadingStates({ id: 'isMarketingLoading', value: true });
    const res = await GetUnitMarketingByUnitId(
      activeItem?.id,
      isForLease ? true : false
    );
    if (!(res && res.status && res.status !== 200)) {
      setState({
        id: 'edit',
        value: {
          ...state,
          title: res?.titleEn || res?.titleAr || '',
          marketingDescription: res?.descriptionEn || res?.descriptionAr || '',
        },
      });
    }
    setLoadingStates({ id: 'isMarketingLoading', value: false });
  };
  const handleImageUpdateMarketing = (updatedImages) => {
    setMarketingImages((prev) => [...prev, updatedImages]);
  };

  useEffect(() => {
    if (!imageCategoryData || imageCategoryData.length === 0) return;
    const formattedImages = imageCategoryData.map((category) => ({
      unitId: activeItem?.id,
      categoryId: category.categoryId,
      files: category?.images?.result.map((image) => ({
        fileId: image.fileId,
        fileName: image.fileName,
        isPublished: marketingImages.find((img) => img.fileId === image?.fileId)
          ?.isPublished,
        isMarketing: marketingImages.find((img) => img.fileId === image?.fileId)
          ?.isMarketing,
      })),
    }));
    setState({ id: 'images', value: formattedImages });
  }, [imageCategoryData, marketingImages]);

  const getAllImageCategory = useCallback(async () => {
    setLoadingStates({ id: 'isImagesDataLoading', value: true });
    const unitId = publishActiveItem
      ? publishActiveItem?.unitId
      : activeItem?.id;
    const response = await GetAllUnitImagesCategoryByUnitId(unitId);
    if (!(response && response.status && response.status !== 200))
      setImageCategoryData(response);
    else setImageCategoryData([]);
    setHideIcon(true);
    setLoadingStates({ id: 'isImagesDataLoading', value: false });
  }, []);

  const getAllListingAgents = async (name) => {
    const body = {
      pageIndex: 1,
      pageSize: 50,
      name,
      userTypeId: isForLease
        ? AgentRoleEnum?.LeaseListingAgent?.value
        : AgentRoleEnum?.SaleListingAgent?.value,
    };
    const res = await ApplicationUserSearch(body);
    if (!(res && res.status && res.status !== 200)) {
      const localValue = res?.result || [];
      if (localValue.length > 0) {
        setListingAgentData(localValue);
      } else setListingAgentData([]);
    }
  };
  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    setLoadingStates({ id: 'savedDataLoading', value: true });
    try {
      const allImages = state?.images?.flatMap((img) => img?.files || []);

      if (allImages.length < 3) {
        showError(t('at-least-three-images-are-required.'));
        return;
      }
      if (schema.error) {
        showError(t('fill_all_required_fields'));
        return;
      }
      if (publishActiveItem) {
        if (UpdatePublishData) UpdatePublishData(state);
        isOpenChanged();
        return;
      }
      await MarkUnitAsAvailable(state);
      reloadData();
      isOpenChanged();
    } catch (error) {
      showError(t('an-error-occurred-while-processing-your-request.'));
    } finally {
      setLoadingStates({ id: 'savedDataLoading', value: false });
    }
  };

  useEffect(() => {
    if (activeItem || publishActiveItem) {
      getUnitMarketingData();
      getAllImageCategory();
    }
  }, [activeItem, publishActiveItem]);
  return (
    <>
      <DialogComponent
        titleText={
          publishActiveItem ? 'publish-to-websites' : 'mark-as-available'
        }
        saveText={t('confirm')}
        maxWidth='sm'
        isOpen={isOpen}
        onCancelClicked={isOpenChanged}
        onSubmit={saveHandler}
        saveIsDisabled={loadingStates.savedDataLoading}
        parentTranslationPath={parentTranslationPath}
        dialogContent={
          <>
            <Spinner isActive={loadingStates.isMarketingLoading} />
            <div className='mb-3'>
              <Inputs
                idRef='priceRef'
                labelValue={isForLease ? 'rent-price' : 'selling-price'}
                value={isForLease ? state.rentPerYearPrice : state.sellingPrice}
                onInputChanged={(event) =>
                  setState({
                    id: isForLease ? 'rentPerYearPrice' : 'sellingPrice',
                    value: +event?.target?.value,
                  })
                }
                isWithError
                helperText={
                  getErrorByName(
                    schema,
                    isForLease ? 'rentPerYearPrice' : 'sellingPrice'
                  ).message
                }
                error={
                  getErrorByName(
                    schema,
                    isForLease ? 'rentPerYearPrice' : 'sellingPrice'
                  ).error
                }
                isSubmitted={isSubmitted}
                labelClasses='Requierd-Color'
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className='mb-3'>
              <Inputs
                idRef='marketingTitleRef'
                labelValue={
                  publishActiveItem
                    ? 'marketing-title-in-english'
                    : 'marketing-title'
                }
                value={publishActiveItem ? state.titleEn : state.title}
                onInputChanged={(event) =>
                  setState({
                    id: publishActiveItem ? 'titleEn' : 'title',
                    value: event?.target?.value,
                  })
                }
                isWithError
                helperText={
                  getErrorByName(
                    schema,
                    publishActiveItem ? 'titleEn' : 'title'
                  ).message
                }
                error={
                  getErrorByName(
                    schema,
                    publishActiveItem ? 'titleEn' : 'title'
                  ).error
                }
                isSubmitted={isSubmitted}
                labelClasses='Requierd-Color'
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            {publishActiveItem && (
              <div className='mb-3'>
                <Inputs
                  idRef='marketingTitleARRef'
                  labelValue='marketing-title-ar'
                  value={state?.titleAr}
                  onInputChanged={(event) =>
                    setState({
                      id: 'titleAr',
                      value: event?.target?.value,
                    })
                  }
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            )}
            <div className='mb-3'>
              <Inputs
                idRef='descriptionRef'
                labelValue={
                  publishActiveItem
                    ? 'marketing-description-en'
                    : 'marketing-description'
                }
                value={
                  publishActiveItem
                    ? state.descriptionEn
                    : state.marketingDescription
                }
                multiline
                rows={4}
                onInputChanged={(event) =>
                  setState({
                    id: publishActiveItem
                      ? 'descriptionEn'
                      : 'marketingDescription',
                    value: event?.target?.value,
                  })
                }
                isWithError
                helperText={
                  getErrorByName(
                    schema,
                    publishActiveItem ? 'descriptionEn' : 'description'
                  ).message
                }
                error={
                  getErrorByName(
                    schema,
                    publishActiveItem ? 'descriptionEn' : 'description'
                  ).error
                }
                isSubmitted={isSubmitted}
                labelClasses='Requierd-Color'
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            {publishActiveItem && (
              <div className='mb-3'>
                <Inputs
                  idRef='descriptionARRef'
                  labelValue='marketing-description-ar'
                  value={state.descriptionAr}
                  multiline
                  rows={4}
                  onInputChanged={(event) =>
                    setState({
                      id: 'descriptionAr',
                      value: event?.target?.value,
                    })
                  }
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            )}

            <div className='mb-3'>
              <AutocompleteComponent
                idRef='ListingAgentRef'
                wrapperClasses='w-100 mb-2'
                labelValue='listing-agent'
                multiple={false}
                withoutSearchButton
                isWithError
                helperText={getErrorByName(schema, 'listingAgentId').message}
                error={getErrorByName(schema, 'listingAgentId').error}
                isSubmitted={isSubmitted}
                displayLabel={(option) =>
                  option?.fullName || option?.name || ''
                }
                chipsLabel={(option) => option?.fullName || option?.name || ''}
                selectedValues={selectedAgent || {}}
                data={listingAgentsData || []}
                onChange={(e, newValue) => {
                  setSelectedAgent(newValue);
                  setState({
                    id: 'listingAgentId',
                    value: newValue?.usersId,
                  });
                  setState({
                    id: 'listingAgentName',
                    value: newValue?.fullName,
                  });
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllListingAgents(value);
                  }, 700);
                }}
                labelClasses='Requierd-Color'
                parentTranslationPath={parentTranslationPath}
                isLoading={loadingStates.isListingAgentLoading}
                withLoader
              />
            </div>

            <div className='marketing-stages-wrapper'>
              <span className='upload-image-text'>{t('marketing-stages')}</span>
              <div className=' upload-image-btn-wrapper'>
                <Button
                  className='btns theme-solid upload-image-btn'
                  onClick={() => setUploadImageDialogOpen(true)}
                >
                  <span className='mdi mdi-plus' />
                  <span>{t('upload-images')}</span>
                </Button>
              </div>
              {imageCategoryData?.length !== 0 ? (
                <>
                  <Spinner isActive={loadingStates.isImagesDataLoading} />
                  <ImagesGalleryAlbumsCardComponent
                    ImageCategoryLookup={StaticLookupsIds.UnitImageCategory}
                    hideIcon={hideIcon}
                    hidePhotosTooltip
                    fromUnit={true}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    data={imageCategoryData}
                    WithUnitDetails
                    updateData={getAllImageCategory}
                    setdata={setImageCategoryData}
                    Display={UnitProfileImagesCardActionEnum.Show}
                    handleImageUpdateMarketing={handleImageUpdateMarketing}
                  />
                </>
              ) : (
                <div>{t('no-available-documents')}</div>
              )}
            </div>
          </>
        }
      />

      {isUploadImageDialogOpen && (
        <UnitsSalesProfileManagementDialog
          ImageCategoryLookup={StaticLookupsIds.UnitImageCategory}
          updateData={getAllImageCategory}
          data={imageCategoryData}
          unit={UnitProfileImagesCardActionEnum.Show}
          isOpen={isUploadImageDialogOpen}
          isOpenChanged={() => {
            setUploadImageDialogOpen(false);
          }}
          onSave={() => {
            setUploadImageDialogOpen(false);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          setMarketingImages={setMarketingImages}
        />
      )}
    </>
  );
}

export default MarkUnitAvailableDialog;
