import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  phoneInputContainer: {
    width: "100%",
    "& .react-tel-input": {
      width: "100%",
      border: "1px solid #ccc",
      borderRadius: "4px",
      padding: theme.spacing(1),
      fontSize: "16px",
    },

    "& .arrow": {
      opacity: "0 !important",
    },

    "& .country-list": {
      overflowX: "hidden",
      fontFamily: "Inter !important",
      fontWeight: 500,
      fontSize: "16px !important",
      backgroundColor: `${theme.palette.background.paper} !important`,
      color: `${theme.palette.text.primary} !important`,

      "& .search": {
        backgroundColor: `${theme.palette.background.paper} !important`,
      },
    },

    "& .dial-code": {
      color: `${theme.palette.text.tertiary} !important`,
    },

    "& .search-box": {
      minHeight: "44px",
      width: "100%",
      padding: "9px 14px !important",
      borderRadius: "8px !important",
      background: theme.palette.background.paper,
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)  !important",
      border: `1px solid ${theme.palette.border.primary} !important`,
      fontWeight: 500,
      fontSize: "16px",
      backgroundColor: `${theme.palette.background.paper} !important`,

      "&::placeholder": {
        color: theme.palette.text.placeholder,
        fontWeight: 400,
      },

      "&:hover": {
        border: `1px solid ${theme.palette.border.primary} !important`,
      },
    },

    "& .flag-dropdown": {
      backgroundColor: `transparent !important`,
      borderRadius: "4px",
    },
    "& input": {
      padding: theme.spacing(1),
      fontSize: "16px",
    },
  },
  arrowStyle: {
    position: "absolute",
    top: "13px",
    left: "35px",
  },
  phoneInput: {
    fontFamily: "Inter !important",
    width: "100% !important",
    outline: "none  !important",
    fontSize: "16px  !important",
    lineHeight: "20px  !important",
    backgroundColor: `transparent !important`,
    color: `${theme.palette.text.primary} !important`,

    fontWeight: 500,

    minHeight: "44px",

    padding: "9px 14px",
    alignItems: "center",
    borderRadius: "8px !important",
    background: theme.palette.background.paper,
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important",
    border: `1px solid ${theme.palette.border.primary} !important`,

    "&::placeholder": {
      color: theme.palette.text.placeholder,
      fontWeight: 400,
    },

    "&:disabled": {
      backgroundColor: `${theme.palette.background.disabled_subtle} !important`,
      cursor: "not-allowed",
    },
  },
  errorMessageContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "100%",
    direction: "ltr",
  },
  errorMessage: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    color: theme.palette.text.error_primary,
  },
  inputContainerError: {
    border: ` 1px solid ${theme.palette.border.error} !important`,
    boxShadow: `${theme.shadows[1]} !important`,
  },
  inputContainerFocused: {
    border: ` 1px solid ${theme.palette.border.brand} !important`,
    boxShadow: `${theme.shadows[8]} !important`,
  },
}));
