import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    wrapperAbsolouteHistories: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      marginBottom:'40px'
    },
    containerBoardItemAbsoloute: {
      display: "flex",
      flexDirection: "column",
      borderRadius: "12px 12px 0px 0px",
      border: `1px solid ${theme.palette.background.gray_200}`,
    },
    containerHeaderofAbsoloute: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "12px 20px 12px 16px",
      borderBottom: `1px solid ${theme.palette.background.gray_200}`,
      "&:last-child": {
        borderBottom: "none",
      },
    },
    containerLeftHeader: {
      display: "flex",
      flexDirection: "row",
      gap: "12px",
      alignItems: "center",
    },
    containerImgTitle: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
    },
    containerTitle: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
    },
    imageWrapper: {
      height: "24px",
      width: "24px",
      borderRadius: "99999px",
    },
    containerImage: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `1px solid rgba(0, 0, 0, 0.08)`,
      borderRadius: "99999px",
    },
    containerInfoAbsoloute: {
      display: "flex",
      flexDirection: "column",
      gap: "6px",
    },
    containerInformationSction: {
      borderBottom: `1px solid ${theme.palette.background.gray_200}`,
      padding: "12px 20px 12px 16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "&:last-child": {
        borderBottom: "none",
      },
    },
    valueInfo: {
      color: theme.palette.text.primary,
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
    },
    titleInfo: {
      color: theme.palette.text.tertiary,
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
    },
  };
});
