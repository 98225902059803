import React, { useCallback, useEffect, useReducer, useState } from "react";
import { CustomDialog } from "../CustomDialog";
import { Box, Typography, Divider } from "@material-ui/core";
import { CustomAutocomplete } from "../CustomAutocomplete";
import { useTranslate } from "../../../Hooks";
import { CustomInput } from "../CustomInput";
import {
  CheckPropertyForDuplicates,
  lookupItemsGetId,
} from "../../../Services";
import {
  CityTypeIdEnum,
  CommunityTypeIdEnum,
  DistrictTypeIdEnum,
  NationalityEnum,
  SubCommunityTypeIdEnum,
} from "../../../Enums";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import { StaticLookupsIds } from "../../../assets/json/StaticLookupsIds";

//styles
import useStyles from "./styles";


const AddPropretyCheckDuplicatedDialog = ({ onOpen, onClose }) => {
  const styles = useStyles();
  const { translate } = useTranslate("HeaderView");

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const { setAddNewProprety, setDuplicatedProprety, setAlertBoxContent } =
    useVerticalNav();
  const [isOnSubmit, setIsOnSummit] = useState(false);

  const [selected, setSelected] = useReducer(reducer, {
    propretyPlane: null,
    Propertyname: null,
    propretyType: null,
    country: null,
    city: null,
    district: null,
    coummunity: null,
    subCoummunity: null,
  });

  const [saveDto, setSaveDto] = useReducer(reducer, {
    propretyPlane: null,
    Propertyname: null,
    propretyType: null,
    country: null,
    city: null,
    district: null,
    coummunity: null,
    subCoummunity: null,
  });

  const [data, setData] = useReducer(reducer, {
    propretyPlane: [],
    propretyType: [],
    country: [],
    city: [],
    district: [],
    coummunity: [],
    subCoummunity: [],
  });

  const GetAllPropertyType = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.PropertyType,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "propretyType",
        value: res || [],
      });
    } else
      setData({
        id: "propretyType",
        value: [],
      });
  }, []);

  const GetCountries = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: NationalityEnum.lookupTypeId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "country",
        value: res || [],
      });
    } else
      setData({
        id: "country",
        value: [],
      });
  }, []);

  const getCities = useCallback(async (countryId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: CityTypeIdEnum.lookupTypeId,
      lookupParentId: countryId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "city",
        value: res || [],
      });
    } else
      setData({
        id: "city",
        value: [],
      });
  }, []);

  const GetDistrict = useCallback(async (cityId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: DistrictTypeIdEnum.lookupTypeId,
      lookupParentId: cityId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "district",
        value: res || [],
      });
    } else
      setData({
        id: "district",
        value: [],
      });
  }, []);

  const GetCommunity = useCallback(async (districtId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: CommunityTypeIdEnum.lookupTypeId,
      lookupParentId: districtId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "coummunity",
        value: res || [],
      });
    } else
      setData({
        id: "coummunity",
        value: [],
      });
  }, []);

  const getSubCommunity = useCallback(async (coumunityId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: SubCommunityTypeIdEnum.lookupTypeId,
      lookupParentId: coumunityId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "subCoummunity",
        value: res || [],
      });
    } else
      setData({
        id: "subCoummunity",
        value: [],
      });
  }, []);

  const restFiledRelatedCountryChange = () => {
    setSelected({
      id: "city",
      value: null,
    });
    setSaveDto({
      id: "city",
      value: null,
    });
    setSelected({
      id: "district",
      value: null,
    });
    setSaveDto({
      id: "district",
      value: null,
    });
    setSelected({
      id: "coummunity",
      value: null,
    });
    setSaveDto({
      id: "coummunity",
      value: null,
    });
    setSelected({
      id: "subCoummunity",
      value: null,
    });
    setSaveDto({
      id: "subCoummunity",
      value: null,
    });
  };

  const restFiledRelatedCityChange = () => {
    setSelected({
      id: "district",
      value: null,
    });
    setSaveDto({
      id: "district",
      value: null,
    });
    setSelected({
      id: "coummunity",
      value: null,
    });
    setSaveDto({
      id: "coummunity",
      value: null,
    });
    setSelected({
      id: "subCoummunity",
      value: null,
    });
    setSaveDto({
      id: "subCoummunity",
      value: null,
    });
  };

  const restFiledRelatedDistrictChange = () => {
    setSelected({
      id: "coummunity",
      value: null,
    });
    setSaveDto({
      id: "coummunity",
      value: null,
    });
    setSelected({
      id: "subCoummunity",
      value: null,
    });
    setSaveDto({
      id: "subCoummunity",
      value: null,
    });
  };

  const restFiledRelatedCommunityChange = () => {
    setSelected({
      id: "subCoummunity",
      value: null,
    });
    setSaveDto({
      id: "subCoummunity",
      value: null,
    });
  };

  const restFiled = () => {
    setSelected({
      id: "edit",
      value: {
        propretyPlane: null,
        Propertyname: null,
        propretyType: null,
        country: null,
        city: null,
        district: null,
        coummunity: null,
        subCoummunity: null,
      },
    });
    setSaveDto({
      id: "edit",
      value: {
        propretyPlane: null,
        Propertyname: null,
        propretyType: null,
        country: null,
        city: null,
        district: null,
        coummunity: null,
        subCoummunity: null,
      },
    });
  };
  const CheckDuplicataedPropretyHandler = async (selectedValues) => {
    setIsOnSummit(true);
    try {
      const res = await CheckPropertyForDuplicates({
        propertyName: saveDto?.Propertyname,
        propertyTypeId: saveDto?.propretyType,
        subCommunityId: saveDto?.subCoummunity,
      });

      setDuplicatedProprety((prevState) => ({
        ...prevState,
        isExactMatch: res.isExactMatch,
        isFuzzyMatch: res.isFuzzyMatch,
        matchingProperties: res.matchingProperties || [],
        fuzzyMatchingProperties: res.fuzzyMatchingProperties || [],
      }));

      if (res.isExactMatch) {
        setDuplicatedProprety((prevState) => ({
          ...prevState,
          PropretyMatchingPropertiesDialogOpened: true,
        }));
      } else if (res.isFuzzyMatch) {
        setDuplicatedProprety((prevState) => ({
          ...prevState,
          PropretyFuzzyMatchingPropertiesDialogOpened: true,
        }));
      } else {
        setDuplicatedProprety((prevState) => ({
          ...prevState,
          PropretySucsessValidateDialog: true,
        }));
      }
      setAddNewProprety((prevState) => ({
        ...prevState,
        addPropretyModalOpened: false,
        Proprety: selectedValues,
      }));
      restFiled();
      onClose();
    } catch (error) {
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to add the proprety.",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
      console.log("something wrong !", error);
    } finally {
      setIsOnSummit(false);
    }
  };

  useEffect(() => {
    GetAllPropertyType();
    GetCountries();
  }, [GetAllPropertyType, GetCountries]);

  return (
    <>
      <CustomDialog
        onClose={() => {
          restFiled();
          onClose();
        }}
        open={onOpen}
        subtitle={translate(
          "Fill-in-the-details-below-to-add-a-new-property-to-your-list-Ensure-all-required-fields-are-completed-before-saving"
        )}
        onConfirm={() => {
          CheckDuplicataedPropretyHandler(selected);
        }}
        title={translate("Add-new-property")}
        confirmText={translate("Add-Proprety")}
        width={"688px"}
        isDisabled={
          !saveDto.propretyType ||
          !saveDto.country ||
          !saveDto.Propertyname ||
          !saveDto.city ||
          isOnSubmit
        }
        content={
          <Box className={styles.wrapperaddNewProretycheckDuplicate}>
            <Box className={styles.containerFiledItem}>
              <Typography className={styles.titleFiled}>
                {translate("Property-name")}*
              </Typography>
              <CustomInput
                inputContainerOverrideStyles={styles.inputFullWidth}
                hasSearchIcon={false}
                placeholder={translate("Enter")}
                type="text"
                value={selected?.Propertyname}
                onChange={(e) => {
                  setSelected({
                    id: "Propertyname",
                    value: e?.target?.value,
                  });
                  setSaveDto({
                    id: "Propertyname",
                    value: e?.target?.value,
                  });
                }}
              />
            </Box>
            <Divider />

            <Box className={styles.containerFiledItem}>
              <Typography className={styles.titleFiled}>
                {translate("Property-type")}*
              </Typography>
              <CustomAutocomplete
                placeholder={translate("Select")}
                options={data?.propretyType || []}
                onChange={(e, value) => {
                  setSelected({
                    id: "propretyType",
                    value: value,
                  });
                  setSaveDto({
                    id: "propretyType",
                    value: value?.lookupItemId,
                  });
                }}
                value={selected?.propretyType || null}
                getOptionLabel={(option) => option?.lookupItemName || null}
                getOptionValue={(option) => option?.lookupItemId || null}
                renderOption={(option) => option?.lookupItemName || ""}
              />
            </Box>
            <Divider />

            <Box className={styles.containerFiledItem}>
              <Typography className={styles.titleFiled}>
                {translate("Country")}*
              </Typography>
              <CustomAutocomplete
                placeholder={translate("Select")}
                options={data?.country || []}
                onChange={(e, value) => {
                  setSelected({
                    id: "country",
                    value: value,
                  });
                  setSaveDto({
                    id: "country",
                    value: value?.lookupItemId,
                  });
                  restFiledRelatedCountryChange();
                  if (value) {
                    getCities(value?.lookupItemId);
                  } else {
                    setData({
                      id: "city",
                      value: [],
                    });
                    restFiledRelatedCountryChange();
                  }
                }}
                value={selected?.country || null}
                getOptionLabel={(option) => option?.lookupItemName || ""}
                getOptionValue={(option) => option?.lookupItemId || ""}
                renderOption={(option) => option?.lookupItemName || ""}
              />
            </Box>
            <Divider />

            <Box className={styles.containerFiledItem}>
              <Typography className={styles.titleFiled}>
                {translate("City")}*
              </Typography>
              <CustomAutocomplete
                placeholder={translate("Select")}
                options={data?.city || []}
                onChange={(e, value) => {
                  setSelected({
                    id: "city",
                    value: value,
                  });
                  setSaveDto({
                    id: "city",
                    value: value?.lookupItemId,
                  });
                  restFiledRelatedCityChange();
                  if (value) {
                    GetDistrict(value?.lookupItemId);
                  } else {
                    setData({
                      id: "district",
                      value: [],
                    });
                    restFiledRelatedCityChange();
                  }
                }}
                value={selected?.city || null}
                getOptionLabel={(option) => option?.lookupItemName || ""}
                getOptionValue={(option) => option?.lookupItemId || ""}
                renderOption={(option) => option?.lookupItemName || ""}
              />
            </Box>
            <Divider />

            <Box className={styles.containerFiledItem}>
              <Typography className={styles.titleFiled}>
                {translate("District")}
              </Typography>
              <CustomAutocomplete
                placeholder={translate("Select")}
                options={data?.district || []}
                onChange={(e, value) => {
                  setSelected({
                    id: "district",
                    value: value,
                  });
                  setSaveDto({
                    id: "district",
                    value: value?.lookupItemId,
                  });
                  restFiledRelatedDistrictChange();
                  if (value) {
                    GetCommunity(value?.lookupItemId);
                  } else {
                    setData({
                      id: "district",
                      value: [],
                    });
                    restFiledRelatedDistrictChange();
                  }
                }}
                value={selected?.district || null}
                getOptionLabel={(option) => option?.lookupItemName || ""}
                getOptionValue={(option) => option?.lookupItemId || ""}
                renderOption={(option) => option?.lookupItemName || ""}
              />
            </Box>
            <Divider />

            <Box className={styles.containerFiledItem}>
              <Typography className={styles.titleFiled}>
                {translate("Community")}
              </Typography>
              <CustomAutocomplete
                placeholder={translate("Select")}
                options={data?.coummunity || []}
                onChange={(e, value) => {
                  setSelected({
                    id: "coummunity",
                    value: value,
                  });
                  setSaveDto({
                    id: "coummunity",
                    value: value.lookupItemId,
                  });
                  restFiledRelatedCommunityChange();
                  if (value) {
                    getSubCommunity(value?.lookupItemId);
                  } else {
                    setData({
                      id: "subCoummunity",
                      value: [],
                    });
                    restFiledRelatedCommunityChange();
                  }
                }}
                value={selected?.coummunity || null}
                getOptionLabel={(option) => option?.lookupItemName || ""}
                getOptionValue={(option) => option?.lookupItemId || ""}
                renderOption={(option) => option?.lookupItemName || ""}
              />
            </Box>
            <Divider />

            <Box className={styles.containerFiledItem}>
              <Typography className={styles.titleFiled}>
                {translate("Sub-community")}
              </Typography>
              <CustomAutocomplete
                placeholder={translate("Select")}
                options={data?.subCoummunity || []}
                onChange={(e, value) => {
                  setSelected({
                    id: "subCoummunity",
                    value: value,
                  });
                  setSaveDto({
                    id: "subCoummunity",
                    value: value?.lookupItemId,
                  });
                }}
                value={selected?.subCoummunity || null}
                getOptionLabel={(option) => option?.lookupItemName || ""}
                getOptionValue={(option) => option?.lookupItemId || ""}
                renderOption={(option) => option?.lookupItemName || ""}
              />
            </Box>
          </Box>
        }
      />
    </>
  );
};
export default AddPropretyCheckDuplicatedDialog;
