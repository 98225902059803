import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { bottomBoxComponentUpdate } from "../../../../../../../../Helper";
import {
  NoContentComponent,
  Spinner,
  Tables,
} from "../../../../../../../../Components";
import { GetLeaseReservationTenants } from "../../../../../../../../Services";

export const AssignedAgentsDetails = ({
  parentTranslationPath,
  translationPath,
  unitId,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [isLoading, setIsLoading] = useState(false);
  const [leaseReservations, setLeaseReservations] = useState({
    assignedTenantsAgents: [],
    assignedLandlordsAgents :[],
    listingAgents:  [],
    agents: [],
  });
  const [filter] = useState({
    pageSize: 9999999,
    pageIndex: 0,
    search: "",
  });
  
  const repeated = (list, filed) =>
    list &&
    list.map((item, index) =>
      !item[filed] ? (
        <span key={index}>
          <span> </span>
        </span>
      ) : (
        <span key={index}>
          {" "}
          {item[filed]} {list.length - 1 != index && <span> , </span>}
        </span>
      )
    );
  const getLeaseReservationClient = useCallback(async () => {
    setIsLoading(true);
    const leaseTransactionDetailsId = localStorage.getItem(
      "leaseTransactionDetailsId"
    );
    const res = await GetLeaseReservationTenants({
      id: leaseTransactionDetailsId,
      isForAccountTab: true,
    });
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      let landLordsList = [];
      let tenantsList = [];
      let  listingAgents = res?.listingAgents || [] ;
      let agents = res?.agents  || []; 


      res?.tenants &&
        res.tenants.map((t) => {
          if (
            t.transactionTenantDetails &&
            t.transactionTenantDetails?.transactionReferralDetails
          ) {
            t.transactionTenantDetails.transactionReferralDetails.map((s) => {
              tenantsList.push({ ...s });
            });
          }
        });

      res?.landLords &&
        res.landLords.map((t) => {
          if (
            t.transactionLandlordDetails &&
            t.transactionLandlordDetails?.transactionReferralDetails
          ) {
            t.transactionLandlordDetails.transactionReferralDetails.map((s) => {
              landLordsList.push({ ...s });
            });
          }
        });
      setLeaseReservations({
        assignedTenantsAgents : tenantsList, 
        assignedLandlordsAgents : landLordsList,
        listingAgents  ,
        agents  


      });
    } else {
      setLeaseReservations({
        assignedTenantsAgents : [], 
        assignedLandlordsAgents : [],
        listingAgents :[] , 
        agents   :[]
      });
    }
    setIsLoading(false);
  }, [unitId]);

  useEffect(() => {
    if (unitId) getLeaseReservationClient();
  }, [getLeaseReservationClient, unitId]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );
  return (
        <div className="contact-details-wrapper childs-wrapper">
          <Spinner isActive={isLoading} isAbsolute />
            <div className="w-100 px-3">
              {leaseReservations.agents?.length === 0 &&
              leaseReservations.listingAgents?.length === 0 ? (
                <NoContentComponent />
              ) : (
                !isLoading && (
                  <>
                    <div className="seller-table-wrapper presentational-wrapper mb-4 mt-4">
                      <div className="title-section mb-2">
                        <span>{t(`${translationPath}landlords-agents`)}</span>
                      </div>
                      <Tables
                      data={leaseReservations?.listingAgents || []}
                      headerData={[
                        {
                          id: 1,
                          isSortable: false,
                          label: t(`${translationPath}lease-listing-agent`),
                          input: "listingAgentName",
                        },
                        {
                          id: 2,
                          isSortable: false,
                          label: "agent-branch",
                          input: "agentBranch",
                        },
                        {
                          id: 3,
                          isSortable: false,
                          label: "team-name",
                          component: (item) =>
                            repeated(item.teamLeads
                              , "teamName"),
                        },
                        {
                          id: 4,
                          isSortable: false,
                          label: "team-lead-name",
                          component: (item) =>
                            repeated(item.teamLeads
                              , "teamLeadName"),
                        },
                      
                        {
                          id: 5,
                          isSortable: false,
                          label: "agent-team-manager",
                          component: (item) =>
                            repeated(item.teamLeads
                              , "teamManager"),
                        },
                        
                      
                      ]}
                      defaultActions={[]}
                      activePage={1}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                      totalItems={leaseReservations?.listingAgents?.length}
                     /> 
                    </div>
                    <div className="seller-table-wrapper presentational-wrapper mb-4 mt-4">
                      <div className="title-section mb-2">
                        <span>{t(`${translationPath}tenants-agents`)}</span>
                      </div>
                      <Tables
                      data={leaseReservations?.agents || []}
                      headerData={[
                        {
                          id: 1,
                          isSortable: false,
                          label: t(`${translationPath}lease-agent`),
                          input: "agentName",
                        },
                        {
                          id: 2,
                          isSortable: false,
                          label: "agent-branch",
                          input: "agentBranch",
                        },
                        {
                          id: 3,
                          isSortable: false,
                          label: "team-name",
                          component: (item) =>
                            repeated(item.teamLeads
                              , "teamName"),
                        },
                        {
                          id: 4,
                          isSortable: false,
                          label: "team-lead-name",
                          component: (item) =>
                            repeated(item.teamLeads
                              , "teamLeadName"),
                        },
                      
                        {
                          id: 5,
                          isSortable: false,
                          label: "agent-team-manager",
                          component: (item) =>
                            repeated(item.teamLeads
                              , "teamManager"),
                        }, 
                        
                      ]}
                      defaultActions={[]}
                      activePage={1}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                      totalItems={leaseReservations?.agents?.length}
                     /> 
                    </div>
                    </>
                )
              )}
            </div>
        </div>
  );
};

AssignedAgentsDetails.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
AssignedAgentsDetails.defaultProps = {};
