import React from "react";

function RentIcon({ fill, ...restProps }) {
  return (
    <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
    >
      <g id="Rent - 02">
        <path
          id="Vector"
          d="M9.61558 6.68265C9.53751 6.69306 9.45943 6.70346 9.38136 6.72428C8.50173 6.92728 7.79386 7.62994 7.57526 8.50436C7.32542 9.52452 7.71059 10.5551 8.53817 11.1432V13.5687C8.53817 13.8134 8.63185 14.0424 8.80362 14.2141L9.32411 14.7346C9.49587 14.9064 9.72488 15.0001 9.96951 15.0001C10.2141 15.0001 10.4432 14.9064 10.6149 14.7346L11.1354 14.2141C11.3072 14.0424 11.4009 13.8134 11.4009 13.5687V11.1432C12.0463 10.68 12.4418 9.93051 12.4418 9.12895C12.4418 8.36903 12.0983 7.66117 11.505 7.19273C11.1823 6.93769 10.8075 6.77113 10.4067 6.70346C10.0913 6.68265 9.89536 6.64533 9.61558 6.68265ZM11.6611 9.12375C11.6611 9.72751 11.3384 10.2896 10.8127 10.5915C10.693 10.6592 10.6201 10.7893 10.6201 10.9298V13.5687C10.6201 13.6052 10.6045 13.6364 10.5837 13.6624L10.0632 14.1829C9.99554 14.2506 9.94349 14.2506 9.88103 14.1829L9.36054 13.6624C9.36054 13.6624 9.32411 13.6052 9.32411 13.5687V10.9298C9.32411 10.7893 9.25124 10.6592 9.13152 10.5915C8.4705 10.2116 8.153 9.44645 8.34038 8.68653C8.48612 8.09317 8.97017 7.61953 9.56353 7.479C9.69886 7.44777 9.83419 7.43215 9.96951 7.43215C10.3547 7.43215 10.7242 7.55707 11.0261 7.79649C11.4321 8.1192 11.6663 8.60325 11.6663 9.12375H11.6611Z"
          fill="#667085"
          stroke="#667085"
          stroke-width="0.44"
        />
        <path
          id="Icon"
          d="M2.5 8.80433C2.5 8.3257 2.5 8.08639 2.56169 7.866C2.61633 7.67077 2.70614 7.48716 2.82669 7.32417C2.96278 7.14017 3.15168 6.99324 3.52949 6.69939L9.18141 2.30345C9.47418 2.07574 9.62057 1.96189 9.78221 1.91812C9.92484 1.87951 10.0752 1.87951 10.2178 1.91812C10.3794 1.96189 10.5258 2.07574 10.8186 2.30345L16.4705 6.69939C16.8483 6.99324 17.0372 7.14017 17.1733 7.32417C17.2939 7.48716 17.3837 7.67077 17.4383 7.866C17.5 8.08639 17.5 8.3257 17.5 8.80433V14.8334C17.5 15.7669 17.5 16.2336 17.3183 16.5901C17.1586 16.9037 16.9036 17.1587 16.59 17.3185C16.2335 17.5001 15.7668 17.5001 14.8333 17.5001H5.16667C4.23325 17.5001 3.76654 17.5001 3.41002 17.3185C3.09641 17.1587 2.84144 16.9037 2.68166 16.5901C2.5 16.2336 2.5 15.7669 2.5 14.8334V8.80433Z"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

export default RentIcon;
