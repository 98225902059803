import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GetExtensionsByMainNumberAPI } from "../../../../../../../../../../Services";
import { ConvoloExtensionsTable } from "./ConvoloExtensionsTable";

export const ConvoloExtensionsView = ({
  parentTranslationPath,
  translationPath,
  reloadExtensionsNumbersByMainId,
  setIsLoading,
  isLoading,
  branchId,
  reloadAllExtensions,
  mainNumbersByBranchIdInfo,
  setFilter,
  filter,
  checkedDetailed,
  setCheckedDetailed,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [allExtensionsByMainId, setAllExtensionsByMainId] = useState({
    results: [],
    totalCount: 0,
  });

  const GetAllExtensionsNumbersByMainId = useCallback(async () => {
    setIsLoading((item) => ({ ...item, allExtensionsNumbersByMainId: true }));
    const res = await GetExtensionsByMainNumberAPI(
      reloadExtensionsNumbersByMainId?.mainNumberId ||
        mainNumbersByBranchIdInfo,
      filter
    );
    if (res)
      setAllExtensionsByMainId({
        results: res,
        totalCount: res?.length,
      });
    setIsLoading((item) => ({ ...item, allExtensionsNumbersByMainId: false }));
  }, [
    reloadExtensionsNumbersByMainId,
    reloadAllExtensions,
    mainNumbersByBranchIdInfo,
    filter,
  ]);

  useEffect(() => {
    if (
      reloadExtensionsNumbersByMainId?.mainNumberId ||
      mainNumbersByBranchIdInfo
    )
      GetAllExtensionsNumbersByMainId();
  }, [
    reloadExtensionsNumbersByMainId,
    reloadAllExtensions,
    mainNumbersByBranchIdInfo,
    filter,
  ]);

  return (
    <div className="b-bottom mt-2 mb-2">
      <ConvoloExtensionsTable
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        extensions={allExtensionsByMainId}
        branchId={branchId}
        onAddOrEditExtensions={() => {
          GetAllExtensionsNumbersByMainId();
        }}
        reloadExtensionsNumbersByMainId={reloadExtensionsNumbersByMainId}
        checkedDetailed={checkedDetailed}
        setCheckedDetailed={setCheckedDetailed}
        isLoading={isLoading}
      />
    </div>
  );
};
