import React from "react";
import { Box, Radio } from "@material-ui/core";
import clsx from "clsx";

// styles
import useStyles from "./styles";

const CustomRadioButtonGroupItem = ({
  checked,
  onChange,
  label,
  icon,
  value,
  ...props
}) => {
  const styles = useStyles();
  return (
    <Box className={clsx(styles.containerFiledIconTitle)}>
      <Box className={styles.labelContainer}>
        {icon && <Box className={styles.labelIcon}>{icon}</Box>}
        <Box className={styles.labelText}>{label}</Box>
      </Box>
      <Radio
        value={value}
        onChange={onChange}
        checked={checked}
        className={styles.radioRoot}
        disableRipple
        color="default"
        checkedIcon={
          <span className={clsx(styles.radioIcon, styles.radioCheckedIcon)} />
        }
        icon={<span className={styles.radioIcon} />}
        {...props}
      />
    </Box>
  );
};
export default CustomRadioButtonGroupItem;
