import React, { useEffect, useState, useReducer, useCallback } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError, showSuccess } from "../../../../Helper";
import { MicrosoftToken, lookupItemsGetId } from "../../../../Services";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import { Button, ButtonBase, ButtonGroup } from "@material-ui/core";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";
import { PowerBiEnum } from "../../../../Enums";

export const Aml = () => {
  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [ShowReport2, setShowReport2] = useState(false);

  const [datefilters, setdatefilter] = useState(true);
  const [datepicker, setdatepicker] = useState();
  const [monthsAgo, setMonthsAgo] = useState(1);

  const currentDate = new Date();
  const countmonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - monthsAgo)
  );

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate() - 7));
  const count2 = new Date(new Date().setDate(today.getDate()));

  const datetimes = moment(count).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: startDate || null,
    endDate: endDate || null,
    key: "selection",
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes2,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "Aml_Bi",
      column: "AmlCreatedOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  const [bookmarks, setBookmarks] = useState([]);
  const [error, setError] = useState(null);

  const loadBookmarks = async () => {
    const report = window.report;

    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        const fetchedBookmarks = await bookmarksManager.getBookmarks();
        setBookmarks(fetchedBookmarks);
        // 
      } catch (error) {
        console.error("Error loading bookmarks:", error);
        setError(error);
      }
    }
  };
  const applyBookmark = async (bookmarkName) => {
    const report = window.report;
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        bookmarksManager.apply(bookmarkName);
      } catch (error) {
        console.error("Error applying bookmark:", error);
        setError(error);
      }
    }
  };

  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
        setShowReport2(false);
        console.log(true);
      })
      .catch((error) => {
        console.log(false);
      });
  }, []);

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };

  useEffect(() => {
    Microsoft(false);
  }, []);

  return (
    <div className="dashboardMain-PowerBIEmbed">
      <CellSpinnerBi isActive={!ShowReport} isAbsolute blue />
      {ShowReport ? (
        <div className="MainFiltersBi">
          <CellSpinnerBi isActive={!ShowReport2} isAbsolute blue />
          <div className="selecterbi">
            <div>
              <DateRangePickerComponent
                onClearClicked={() => {
         
                  setDateFilter({
                    startDate: startDate,
                    endDate: endDate,
                    key: "selection",
                  });
                }}
                ranges={[dateFilter]}
                onDateChanged={(selectedDate) => {
                  const newStartDate =
                    selectedDate.selection && selectedDate.selection.startDate;
                  const newEndDate = new Date(
                    moment(
                      selectedDate.selection && selectedDate.selection.endDate
                    ).endOf("day")
                  );

                  if (
                    newStartDate.getTime() !== dateFilter.startDate.getTime() ||
                    newEndDate.getTime() !== dateFilter.endDate.getTime()
                  ) {
                    setShowReport2(false);
                  } else {
                    setShowReport2(true);
                  }

                  setDateFilter({
                    startDate: newStartDate,
                    endDate: newEndDate,
                    key: "selection",
                  });
                }}
              />
            </div>

            <div className="DashboardFilter">
              <ButtonBase
                onClick={handleRefreshClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
              >
                <span className="mdi mdi-refresh m-1" />
              </ButtonBase>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <div className="dashboardMain">
        <PowerBIEmbed
          embedConfig={{
            type: "report",
            id: PowerBiEnum.AML.reportid,
            embedUrl: PowerBiEnum.AML.url,
            accessToken: report,
            pageView: "fitToWidth",
            filters: [advancedFilter],
            settings: {
              customLayout: {
                displayOption: models.DisplayOption.FitToWidth,
              },
              filterPaneEnabled: false,
              navContentPaneEnabled: false,
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                (event) => {
                  setShowReport(true);
                },
              ],
              [
                "dataSelected",

                (event) => {
                  setShowReport2(false);
                },
              ],

              [
                "rendered",
                (event) => {
                  setShowReport2(true);
                  setRender(true);
                  loadBookmarks();
                },
              ],

              [
                "error",
                (event, embed) => {
                  const error = event.detail;

                  if (
                    error.detailedMessage === "Get report failed" ||
                    error.detailedMessage ===
                      "Access token has expired, resubmit with a new access token" ||
                    error.detailedMessage ===
                      "Fail to initialize - Could not resolve cluster"
                  ) {
                    Microsoft(true);
                    setRender(false);
                  } else console.log(error.detailedMessage);
                  showError(error.detailedMessage);
                },
              ],
            ])
          }
          cssClassName={
            ShowReport ? "report-style-class-Show" : "report-style-class-hide"
          }
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      </div>
    </div>
  );
};
