import React, { createContext } from "react";

// Create the context
export const PropertiesLayoutContext = createContext();

// Create the provider component
export const AdvancedSearchProvider = ({ children }) => {
  return (
    <PropertiesLayoutContext.Provider value={{}}>
      {children}
    </PropertiesLayoutContext.Provider>
  );
};
