import React, { useState, useEffect, useCallback } from "react";
import "./UnitMPIFilterStyle.scss";
import { ButtonBase } from "@material-ui/core";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { StaticLookupsIds } from "../../../assets/json/StaticLookupsIds";
import { GetLookupItemsByLookupTypeName } from "../../../Services";

export const UnitMPIFilter = ({
  parentTranslationPath,
  onButtonClicked,
  translationPath,
  defaultValueis,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [SelectedIndex, setSelectedIndex] = useState(defaultValueis || null);
  const [response, setResponse] = useState([]);

  const MPITypes = useCallback(async () => {
    setIsLoading(true);
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "MPI",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      const mappedNoteTypes =
        res?.result &&
        res.result.map((item) => ({
          lookupItemId: item.lookupItemId,
          lookupItemName: item.lookupItemName,
          title:
            item.lookupItemName === "Above Market Price Index"
              ? "above-market"
              : item.lookupItemName.includes("Within Market")
              ? "within-market"
              : item.lookupItemName.includes("Less than Market")
              ? "less-than-market"
              : null,
          value:
            item.lookupItemName === "Above Market Price Index"
              ? "Above"
              : item.lookupItemName.includes("Within Market")
              ? "Equal"
              : item.lookupItemName.includes("Less than Market")
              ? "Less"
              : null,
        }));
      setResponse(mappedNoteTypes);
    } else setResponse([]);

    setIsLoading(false);
  }, []);


  const onClickChanged = (index, item) => {
    if (SelectedIndex !== index && item !== null) {
      onButtonClicked({ index, item });
      setSelectedIndex(index);
    } else {
      setSelectedIndex(null);
      onButtonClicked(null);
    }
  };

  const ReturnClassColor = (item) => {
    const itemName = item.lookupItemName;
    const itemClassName = itemName ? itemName.replace(/\s/g, "") : "";
    return itemClassName;
  };

  useEffect(() => {
    MPITypes();
  }, []);

  useEffect(() => {
    if (defaultValueis === null) setSelectedIndex(null);
  }, [defaultValueis]);

  return (
    <div className="px-2 UnitMPIFilter-wrapper">
      {response
        ? response.map((item, index) => (
            <ButtonBase
              index={index}
              className={`btns c-black-light history-button ${
                SelectedIndex === index ? "Activeitem" : ""
              } ${ReturnClassColor(item)}`}
              onClick={() => {
                onClickChanged(index, item);
              }}
            >
              <div className="fw-b">
                {t(`${translationPath}${(item?.title) || ""}`)}
              </div>
            </ButtonBase>
          ))
        : null}
    </div>
  );
};
