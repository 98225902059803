import React, { useState } from "react";
import { CustomDialog } from "../../../../CustomDialog";
import { useSelectedTheme, useTranslate } from "../../../../../../Hooks";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import { Box, Divider, Typography, Avatar } from "@material-ui/core";
import {
  ArrowUpRight,
  BathIcon,
  BedIcon,
  PhoneIcon,
  SMSIcon,
  UnitSizeIcon,
} from "../../../../../../assets/icons";
import { CustomBadge } from "../../../../CustomBadge";
import { CustomCopyToClipboard } from "../../../../CustomCopyToClipboard";
import {
  getDownloadableLink,
  getFirstLastNameLetters,
} from "../../../../../../Helper";
import clsx from "clsx";
import { ClaimUnit } from "../../../../../../Services";

//styles
import useStyles from "./styles";

const DuplicatedUnitsDialog = ({ open, onClose }) => {
  const styles = useStyles();
  const { translate } = useTranslate("HeaderView");
  const { setAlertBoxContent, duplicatedUnit, setDuplicatedUnit } =
    useVerticalNav();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const userId = JSON.parse(localStorage.getItem("session")).userId;
  const [selectedDuplicatedUnitId, setSelectedDuplicatedUnitId] = useState();
  const [onSubmit, setOnSubmit] = useState(false);

  const onCardClick = (unit) => {
    if (!unit?.unitId) return;
    setSelectedDuplicatedUnitId(unit.unitId);
    setDuplicatedUnit((prev) => ({
      ...prev,
      selectedDuplicatedUnit: unit,
    }));
  };

  const onClaimUnitHandler = async (claimUnitSelected) => {
    const unitId = claimUnitSelected?.unitId || null;
    const listingAgentId = userId;
    setOnSubmit(true);
    try {
      const res = await ClaimUnit(unitId, listingAgentId);

      if (!(res && res.status && res.status !== 200)) {
        if (res) {
          setAlertBoxContent({
            display: true,

            variant: "success",

            title: translate("unit-claimed-Successfully"),

            onClose: () => {
              setAlertBoxContent(null);
            },
          });

          setDuplicatedUnit((prev) => ({
            ...prev,

            isDuplicate: false,

            isOpenDuplicatedUnitsDialog: false,
          }));

          onClose();
        } else {
          setAlertBoxContent({
            display: true,

            variant: "error",

            title: translate("couldn't-claim-unit"),

            onClose: () => {
              setAlertBoxContent(null);
            },
          });
        }
      } else {
        setAlertBoxContent({
          display: true,

          variant: "error",

          title: translate("an-error-occurred-while-claiming-unit"),

          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    } catch (error) {
      console.log("something wrong !", error);
      setAlertBoxContent({
        display: true,

        variant: "error",

        title: translate("something wrong !"),

        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setOnSubmit(false);
    }
  };
  return (
    <CustomDialog
      open={open}
      width="688px"
      title={translate("Duplicated-units")}
      subtitle={translate(
        "One-or-more-units-matching-the-details-already-exist-in-the-system-You-can-review-unit-details-claim-an-unassigned-unit-or-close-this-window-Select-a-unit-to-proceed"
      )}
      confirmText={translate("Claim-unit")}
      cancelText={translate("cancel")}
      onClose={() => onClose()}
      isDisabled={
        Object.keys(duplicatedUnit?.selectedDuplicatedUnit).length === 0 ||
        duplicatedUnit?.selectedDuplicatedUnit?.hasListingAgent ||
        onSubmit
      }
      onConfirm={() => {
        onClaimUnitHandler(duplicatedUnit?.selectedDuplicatedUnit);
      }}
      content={
        <Box className={styles.wrapperDuplicatedUnits}>
          {duplicatedUnit?.matchingUnits?.length > 0 &&
            duplicatedUnit?.matchingUnits?.map((matchingUnitItem, index) => (
              <Box
                className={clsx(styles.containerCardDuplicatedUnit, {
                  [styles.containerSelectedCard]:
                    selectedDuplicatedUnitId === matchingUnitItem?.unitId,
                })}
                key={index}
                onClick={() => {
                  onCardClick(matchingUnitItem);
                }}
              >
                <Box className={styles.containerTopSection}>
                  <Box className={styles.containerStatusTitleId}>
                    <Box className={styles.containerTitleIds}>
                      <Box className={styles.containerTitleArrowUp}>
                        <Typography className={styles.propertyNameTitle}>
                          {matchingUnitItem?.propertyName || ""}
                        </Typography>
                        <ArrowUpRight
                          width="20"
                          height="20"
                          fill={palette.button.secondaryGray_fg}
                        />
                      </Box>
                      <Box className={styles.containerIdWithTitle}>
                        <Typography className={styles.matchingUnitItemCity}>
                          {matchingUnitItem?.city || ""}
                        </Typography>
                        {matchingUnitItem?.unitRefNumber && (
                          <CustomCopyToClipboard
                            data={matchingUnitItem?.unitRefNumber}
                            childrenData={"#" + matchingUnitItem?.unitRefNumber}
                            copyDoneShowTime={1000}
                            fontSize="14px"
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box className={styles.containerUnitDetailsAndDetails}>
                  <Box className={styles.containerStatus}>
                      <CustomBadge
                        Style={{
                          padding: "2px 10px",
                        }}
                        label={matchingUnitItem?.unitStatus}
                        SizeVariant="large"
                        BackgroundColor={palette.utility.gray_blue_50}
                        BorderColor={palette.utility.gray_blue_200}
                        Color={palette.text.secondary}
                      />
                      {matchingUnitItem?.unitOperationType && (
                        <Typography
                          className={styles.matchingUnitItemOperationType}
                        >
                          {matchingUnitItem?.unitOperationType || ""}
                        </Typography>
                      )}
                    </Box>
                    <Box className={styles.detailsUnits}>
                    <Box className={styles.containerUnitSize}>
                      <UnitSizeIcon
                        width="20"
                        height="20"
                        fill={palette.foreground.quinary}
                      />
                      <Typography
                        className={styles.matchingUnitItemDetails}
                      >{`${matchingUnitItem?.size} sq.ft`}</Typography>
                    </Box>
                    <Box className={styles.containerBedNumber}>
                      <BedIcon
                        width="20"
                        height="20"
                        fill={palette.foreground.quinary}
                      />
                      <Typography
                        className={styles.matchingUnitItemDetails}
                      >{`${matchingUnitItem?.bedrooms || ""}`}</Typography>
                    </Box>
                    <Box className={styles.containerBathRoomNumber || ""}>
                      <BathIcon
                        width="20"
                        height="20"
                        fill={palette.foreground.quinary}
                      />
                      <Typography
                        className={styles.matchingUnitItemDetails}
                      >{`${matchingUnitItem?.bathrooms}`}</Typography>
                    </Box>
                  </Box>
                  </Box>
                </Box>

                {matchingUnitItem?.hasListingAgent  && (
                    <>
                      <Divider />
                      <Box className={styles.containerBottomSection}>
                        <Box className={styles.containerLeftSide}>
                          <Box className={styles.containerBadgLable}>
                            <Avatar
                              className="Avatar"
                              src={
                                matchingUnitItem?.listingAgentImage
                                  ? getDownloadableLink(
                                      matchingUnitItem?.listingAgentImage
                                    )
                                  : ""
                              }
                            >
                              {getFirstLastNameLetters(
                                matchingUnitItem?.listingAgentName || ""
                              )}
                            </Avatar>
                          </Box>
                          <Box className={styles.containerName}>
                            <Typography className={styles.listingAgentName}>
                              {matchingUnitItem?.listingAgentName || ""}
                            </Typography>
                            <Typography
                              className={styles.listingAgentNameTitle}
                            >
                              {translate("Listing-agent")}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className={styles.containerRightSide}>
                          <Box className={styles.containerIcon}>
                            <PhoneIcon
                              width="20"
                              height="20"
                              fill={palette.button.lightGray_fg}
                            />
                          </Box>
                          <Box className={styles.containerIcon}>
                            <SMSIcon
                              width="20"
                              height="20"
                              fill={palette.button.lightGray_fg}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
              </Box>
            ))}
        </Box>
      }
    />
  );
};

export default DuplicatedUnitsDialog;
