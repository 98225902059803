import { ButtonBase } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Inputs,
  PaginationComponent,
  PermissionsComponent,
  Spinner,
  Tables,
} from "../../../Components";
import { TableActions, TableFilterTypesEnum } from "../../../Enums";
import { useTitle } from "../../../Hooks";
import { GetAllExternalLinks } from "../../../Services";

import {
  bottomBoxComponentUpdate,
  returnPropsByPermissions,
} from "../../../Helper";
import { PermissionDeniedLayout } from "../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout";
import { AdminExternalLinksPermissions } from "../../../Permissions";

import { DeleteDialog, ExternalLinksManangementDialog } from "./Dialogs";

export const AdminExternalLinksView = () => {
  const parentTranslationPath = "ExternalLinksView";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}admin-external-links`));

  const expression =
    /\bhttps?:\/\/(?:(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)(?:\/[+~%\/.\w_-]*\??(?:[-+=&;%@.\w_]*)#?[.!\/\\\w]*)?/g;
  const urlRegex = new RegExp(expression);
  const [allExternalLinks, setAllExternalLinks] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const searchTimer = useRef(null);
  const [havePermissionsToDisplayData, setHavePermissionsToDisplayData] =
    useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    url: null,
    externalLinkName: null,
    description: null,
  });

  const tableHeaderData = [
    {
      id: 1,
      label: "Id",
      input: "externalLinkId",
    },
    {
      id: 2,
      label: "Name",
      input: "externalLinkName",
      searchableKey: "externalLinkName",
      filterType: TableFilterTypesEnum.textInput.key,
      isHiddenFilter: false,
    },
    {
      id: 3,
      label: "URL",
      input: "url",
      searchableKey: "url",
      filterType: TableFilterTypesEnum.textInput.key,
      isHiddenFilter: false,
      component: (item) => (
        <span>
          {item && item.url && item.url.match(urlRegex) ? (
            <a href={item.url} target="_blank" rel="noreferrer">
              {item.url}{" "}
            </a>
          ) : (
            item && item.url
          )}
        </span>
      ),
    },
    {
      id: 4,
      label: "Description",
      input: "description",
      searchableKey: "description",
      filterType: TableFilterTypesEnum.textInput.key,
      isHiddenFilter: false,
    },
  ];

  const onFilterValuesChanged = (e) => {
    if (e && Object.values(e).length > 0) {
      const { value, searchableKey } = Object.values(e)[0];

      setFilter((prevFilter) => ({
        ...prevFilter,
        [searchableKey]: value,
      }));
    } else {
    }
  };

  const addNewHandler = () => {
    setIsDialogOpen(true);
  };

  const getAllExternalLinks = async () => {
    setIsLoading(true);
    if (
      returnPropsByPermissions(
        AdminExternalLinksPermissions.ViewExternalLinks.permissionsId
      )
    ) {
      setHavePermissionsToDisplayData("allowed");

      const isAdmin = true;
      const body = {
        ...filter,
        orderBy: 1,
        pageIndex: filter.pageIndex + 1,
      };
      const response = await GetAllExternalLinks(body, isAdmin);
      if (!(response && response.status && response.status !== 200))
        setAllExternalLinks(response || {});
      else setAllExternalLinks({});
    } else setHavePermissionsToDisplayData("notAllowed");

    setIsLoading(false);
  };

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.deleteText.key) {
        setIsDeleteDialogOpen(true);
        setActiveItem(item);
      } else if (actionEnum === TableActions.editText.key) {
        setIsDialogOpen(true);
        setActiveItem(item);
      }
    },
    []
  );

  const reloadData = () => {
    getAllExternalLinks();
  };

  const getDefaultActionsWithPermissions = () => {
    const list = [];
    if (
      returnPropsByPermissions(
        AdminExternalLinksPermissions.EditExternalLink.permissionsId
      )
    ) {
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      });
    }
    if (
      returnPropsByPermissions(
        AdminExternalLinksPermissions.DeleteExternalLink.permissionsId
      )
    ) {
      list.push({
        enum: TableActions.deleteText.key,
        isDisabled: false,
        externalComponent: null,
      });
    }
    return list;
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  useEffect(() => {
    getAllExternalLinks();
  }, [filter]);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={(allExternalLinks && allExternalLinks.totalCount) || 0}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  return (
    <div className="m-3">
      <Spinner isActive={isLoading} />
      <div className="filter-section-wrapper my-3">
        <div className="mx-3">
          <PermissionsComponent
            permissionsList={Object.values(AdminExternalLinksPermissions)}
            permissionsId={
              AdminExternalLinksPermissions.AddNewExternalLink.permissionsId
            }
          >
            <ButtonBase
              className="btns theme-solid px-3"
              onClick={addNewHandler}
            >
              <span className="mdi mdi-plus" />
              {t(`${translationPath}add-new`)}
            </ButtonBase>
          </PermissionsComponent>
        </div>
      </div>

      {havePermissionsToDisplayData === "notAllowed" && (
        <PermissionDeniedLayout />
      )}
      {havePermissionsToDisplayData === "allowed" && (
        <Tables
          data={allExternalLinks.result || []}
          headerData={tableHeaderData || []}
          defaultActions={getDefaultActionsWithPermissions()}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          isWithFilter
          FilterDisabledButton
          filterData={tableHeaderData.map((item) => ({
            key: item.label,
            id: item.id,
            filterType: item.filterType,
            searchableKey: item.searchableKey,
            isHiddenFilter: item.isHiddenFilter,
          }))}
          onFilterValuesChanged={onFilterValuesChanged}
          totalItems={allExternalLinks ? allExternalLinks.totalCount : 0}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}

      {isDialogOpen && (
        <ExternalLinksManangementDialog
          isDialogOpen={isDialogOpen}
          activeItem={activeItem}
          onSave={() => {
            reloadData();
            setIsDialogOpen(false);
            setActiveItem(null);
          }}
          onClose={() => {
            setActiveItem(null);
            setIsDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteDialog
          activeItem={activeItem}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          reloadData={reloadData}
          onCloseClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};
