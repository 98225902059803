import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    containerMergingHistoryComponent:{
      display: "flex",
      flexDirection: "column",
      marginBottom:'10px',
      paddingRight:'10px',
      minHeight:'90vh',
      gap:'32px',
      padding:'0px 32px'
    },loaderContainer:{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "80vh",
    },containerMergingHistoryPlsPaggination:{
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-between',
      minHeight:'90vh'
    }
   
  }
})