import { Box, Button, Tabs, Tab, Divider } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useCallback, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";

import useStyles from "./styles";
import Ellipse from "../../../assets/images/defaults/Ellipse-gold.svg";
import { GlobalTranslate } from "../../../Helper";
import { GetMyNotifications, MarkNotificationAsRead } from "../../../Services";
import { GetMyImportProcess } from "../../../Services/File";
import { useIsAr, useIsDesktop } from "../../../Hooks";
import LoadingSpinner, {
  LoadingSpinnerSize,
  LoadingSpinnerStyle,
} from "../../V2/LoadingSpinner/index";

import "./NotificationTabs.scss";

const a11yProps = (index) => ({
  "aria-controls": `simple-tabpanel-${index}`,
  id: `notification-tab-${index}`,
});

const TabPanel = (props) => {
  const { children, index, value, isDesktop, height, ...other } = props;
  return (
    <div
      aria-labelledby={`notification-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            padding: "0",
            maxHeight: !isDesktop ? `calc(${height - 371}px)` : "580px",
            overflow: "hidden",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

const NotificationTabs = ({
  actionItems,
  alertItems,
  handleChange,
  importantItems,
  onClose,
  setActionItems,
  setAlertItems,
  setImportantItems,
  tabValue,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation("HeaderView");
  const history = useHistory();
  const translationPath = "notificationsView.";
  const theme = useSelector((store) => store.theme);
  const { isAr } = useIsAr(theme.themeDirection);
  const { isDesktop } = useIsDesktop();
  const { innerHeight: height } = window ?? {};

  useEffect(() => {
    try {
      fetchDataNotification();
    } catch (error) {
      console.error("error", error);
    }
  }, []);

  const styles = useStyles();

  const getProccessName = (importProcceseType) => {
    if (importProcceseType === 1) return `${translationPath}contacts`;
    if (importProcceseType === 7) return `${translationPath}leads`;
    if (importProcceseType === 4) return `${translationPath}properties`;
    if (importProcceseType === 5) return `${translationPath}units`;
    if (importProcceseType === 6) return `${translationPath}companies`;
    return "N/A";
  };

  const getAction = (actionItem) => {
    const object = {
      id: actionItem.importProcceseId,
      processName: null,
      createdOn: null,
      type: null,
      header: null,
      text: null,
      withProgress: false,
    };

    const getHeaderName = (importProcceseStatus) => {
      if (importProcceseStatus === 1) return `${translationPath}reading-file`;
      if (importProcceseStatus === 2)
        return `${translationPath}reading-file-failed`;
      if (importProcceseStatus === 3)
        return `${translationPath}finish-reading-and-start-importing`;
      if (importProcceseStatus === 4)
        return `${translationPath}imported-successfully`;
      return "N/A";
    };

    object.processName = GlobalTranslate.t(
      `HeaderView:${getProccessName(actionItem.importProcceseType)}`
    );
    object.header = GlobalTranslate.t(
      `HeaderView:${getHeaderName(actionItem.importProcceseStatus)}`
    );
    if (actionItem.importProcceseStatus === 1) {
      object.text = `${object.header} ${actionItem.fileName}`;
      object.type = 4;
      object.withProgress = true;
      object.createdOn = actionItem.createdOn;
    } else if (actionItem.importProcceseStatus === 2) {
      object.text =
        GlobalTranslate.t(
          `HeaderView:${translationPath}error-while-reading-file`
        ) + actionItem.fileName;
      object.type = 3;
      object.createdOn = actionItem.createdOn;
    } else if (actionItem.importProcceseStatus === 3) {
      object.text = `${object.header} ${object.processName}`;
      object.type = 4;
      object.createdOn = actionItem.createdOn;
      object.withProgress = true;
    } else if (actionItem.importProcceseStatus === 4) {
      object.text = `${object.processName} ${object.header}`;
      object.type = 2;
      object.createdOn = actionItem.createdOn;
    }
    return object;
  };

  const actionsHandler = useCallback((data) => {
    data.map((item) => {
      setActionItems((elements) => {
        const localActions = elements;
        const itemIndex = elements.findIndex(
          (element) => element.id === item.importProcceseId
        );
        if (itemIndex === -1) return [{ ...getAction(item) }, ...elements];
        localActions[itemIndex] = getAction(item);
        return [...localActions];
      });
      return null;
    });
  }, []);

  const fetchDataNotification = async () => {
    const importantResponse = await GetMyNotifications({
      pageIndex: 1,
      pageSize: 10,
      isImportant: true, // important Notification
    });

    const alertsResponse = await GetMyNotifications({
      pageIndex: 1,
      pageSize: 10,
      isImportant: false, // alerts Notification
    });

    setImportantItems(importantResponse?.notificationItems?.result || []);
    setAlertItems(alertsResponse?.notificationItems?.result || []);

    GetMyImportProcess(1, 10).then((data) => {
      if (data) {
        actionsHandler(data.result);
        setIsLoading(false);
      } else {
        setActionItems([]);
        setIsLoading(false);
      }
    });
  };

  const saveHandlerMarkNotificationAsRead = useCallback(
    async (notificationId, isRead) => {
      const res = await MarkNotificationAsRead(notificationId, isRead);
      if (res?.status === 200) {
        fetchDataNotification();
      }
    },
    []
  );

  const RenderNotifications = (items) => {
    return (
      <div
        style={{
          maxHeight: !isDesktop ? `calc(${height - 371}px)` : "585px",
          overflowY: "auto",
        }}
      >
        {items?.map((element) => (
          <div
            key={element?.notificationHistoryId || element?.id}
            className={clsx(
              "notification-body",
              styles.trinityColor,
              !element?.isRead ? styles.notificationBody : ""
            )}
            style={{
              cursor:
                (tabValue === 1 && element?.id) ||
                (element?.formTypeId && element?.data?.leadId)
                  ? "pointer"
                  : "default",
            }}
            onClick={async () => {
              const formTypeId = element?.formTypeId;
              const leadId = element?.data?.leadId;

              if (leadId != null) {
                await saveHandlerMarkNotificationAsRead(
                  element?.notificationHistoryId,
                  true
                );

                history.push(
                  `/home/leads/lead-profile-edit?formType=${formTypeId}&id=${leadId}`
                );
              } else if (tabValue === 1 && element?.id) {
                history.push(`/home/import-details?id=${element?.id}`);
              }
            }}
          >
            <label>{ReactHtmlParser(element?.body || element?.text)}</label>

            <div
              className={styles.textContainer}
              style={{ flexDirection: isAr ? "row-reverse" : "unset" }}
            >
              <span style={{ direction: "ltr" }}>
                {(element?.createdOn &&
                  moment(element?.createdOn).format("DD MMM YYYY  h:mm a")) ||
                  "N/A"}
              </span>
              <span className={styles.title}>{element?.title}</span>
            </div>

            {!element.isRead && <img className="dot" alt="dot" src={Ellipse} />}
          </div>
        ))}
      </div>
    );
  };

  return !isLoading ? (
    <>
      <Box
        className={styles.menuContainer}
        style={{ width: isDesktop ? "448px" : "100%" }}
      >
        <Box>
          <Tabs
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
            aria-label="basic tabs example"
            className={styles.tabs}
            onChange={handleChange}
            scrollButtons={'off'}
            value={tabValue}
            variant="standard"
          >
            <Tab
              className={styles.tabButton}
              classes={{ selected: styles.tab }}
              label={
                <div className={styles.tabButtonContainer}>
                  <span className={clsx(styles.tabLabel, styles.font14)}>
                    {t(`${translationPath}important`)}
                  </span>

                  {importantItems?.filter((item) => !item?.isRead)?.length >
                    0 && (
                    <span
                      className={
                        tabValue === 0
                          ? styles.notificationCounter
                          : styles.inactiveNotificationCounter
                      }
                    >
                      {importantItems?.filter((item) => !item?.isRead)?.length}
                    </span>
                  )}
                </div>
              }
              style={{ textTransform: "none" }}
              {...a11yProps(0)}
            />
            <Tab
              className={styles.tabButton}
              classes={{ selected: styles.tab }}
              label={
                <div className={styles.tabButtonContainer}>
                  <span className={clsx(styles.tabLabel, styles.font14)}>
                    {t(`${translationPath}actions`)}
                  </span>
                  {actionItems?.length > 0 && (
                    <span
                      className={
                        tabValue === 1
                          ? styles.notificationCounter
                          : styles.inactiveNotificationCounter
                      }
                    >
                      {actionItems?.length}
                    </span>
                  )}
                </div>
              }
              style={{ textTransform: "none" }}
              {...a11yProps(1)}
            />
            <Tab
              className={styles.tabButton}
              classes={{ selected: styles.tab }}
              label={
                <div className={styles.tabButtonContainer}>
                  <span className={clsx(styles.tabLabel, styles.font14)}>
                    {t(`${translationPath}alerts`)}
                  </span>

                  {alertItems?.filter((item) => !item?.isRead)?.length > 0 && (
                    <span
                      className={
                        tabValue === 2
                          ? styles.notificationCounter
                          : styles.inactiveNotificationCounter
                      }
                    >
                      {alertItems?.filter((item) => !item?.isRead)?.length}
                    </span>
                  )}
                </div>
              }
              style={{ textTransform: "none" }}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel
          index={0}
          value={tabValue}
          isDesktop={isDesktop}
          height={height}
        >
          {RenderNotifications(importantItems)}
        </TabPanel>
        <TabPanel
          index={1}
          value={tabValue}
          isDesktop={isDesktop}
          height={height}
        >
          {RenderNotifications(actionItems)}{" "}
        </TabPanel>
        <TabPanel
          index={2}
          value={tabValue}
          isDesktop={isDesktop}
          height={height}
        >
          {RenderNotifications(alertItems)}{" "}
        </TabPanel>
      </Box>

      <Divider className={styles.divider} />

      <Box>
        <Box className={styles.actions}>
          <Button
            className={clsx(styles.buttonStyle)}
            variant="contained"
            color="primary"
            onClick={() => {
              switch (tabValue) {
                case 0:
                  history.push("/home/user-notification-details?isImportant=1"); // important
                  break;
                case 1:
                  history.push("/home/import-details-actions"); // actions
                  break;
                default:
                  // case 2:
                  history.push("/home/user-notification-details"); // alert
                  break;
              }
            }}
          >
            {t(`${translationPath}view-all`)}
          </Button>
          <Button
            className={clsx(styles.buttonStyle, styles.secondaryButtonStyle)}
            variant="outlined"
            onClick={onClose}
          >
            {t(`cancel`)}
          </Button>
        </Box>
      </Box>
    </>
  ) : (
    <LoadingSpinner
      sizeVariant={LoadingSpinnerSize.medium}
      styleVariant={LoadingSpinnerStyle.primary}
    />
  );
};

export default NotificationTabs;

NotificationTabs.propTypes = {
  actionItems: PropTypes.instanceOf(Array),
  alertItems: PropTypes.instanceOf(Array),
  handleChange: PropTypes.func,
  importantItems: PropTypes.instanceOf(Array),
  onClose: PropTypes.func,
  setActionItems: PropTypes.func,
  setAlertItems: PropTypes.func,
  setImportantItems: PropTypes.func,
  tabValue: PropTypes.number,
};
NotificationTabs.defaultProps = {
  actionItems: [],
  alertItems: [],
  importantItems: [],
  handleChange: () => {},
  onClose: () => {},
  setActionItems: () => {},
  setAlertItems: () => {},
  setImportantItems: () => {},
  tabValue: 0,
};
