import React, { useEffect, useMemo, useState } from 'react';
import { DialogComponent, Spinner } from '../../../../../Components';
import '../../../UnitsView/UnitStatusBreadcrumb/UnitStatusBreadcrumb.scss';
import { Avatar, Button } from '@material-ui/core';
import {
  getDownloadableLink,
  getFirstLastNameLetters,
  showError,
} from '../../../../../Helper';
import MarkUnitAvailableDialog from './MarkUnitAvailableDialog';
import {
  GetUnitQuickPublishInfo,
  QuickPublishUnit,
} from '../../../../../Services';
import { useTranslation } from 'react-i18next';
import { UnitsOperationTypeEnum } from '../../../../../Enums';

function PublishToWebsiteDialog({
  isOpen: isDialogOpen,
  isOpenChanged: toggleDialog,
  translationPath,
  parentTranslationPath,
  reloadData,
  isForLease,
  activeItem,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [isMarkDialogOpen, setIsMarkDialogOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [publishData, setPublishData] = useState(null);

  const unitImgURLs = useMemo(() => convertImageIdsToURLs(), [publishData]);

  function convertImageIdsToURLs() {
    return publishData?.images
      ?.flatMap((item) =>
        item?.files?.map((img) =>
          img.fileId
            ? getDownloadableLink(img.fileId)
            : getDownloadableLink(item)
        )
      )
      .filter(Boolean);
  }

  const handleEditPublish = () => {
    setIsMarkDialogOpen(true);
  };
  const UpdatePublishData = (updatedData) => {
    setPublishData(updatedData);
  };

  const handleQuickPublishUnit = async (event) => {
    event.preventDefault();
    const updatedPublishData = {
      ...publishData,
      isPublishUnitLease: isForLease ? true : false,
      isPublishUnitSale: !isForLease ? true : false,
    };
    try {
      await QuickPublishUnit(updatedPublishData);
      reloadData();
      toggleDialog();
    } catch {
      showError(t('an-error-occurred-while-processing-your-request.'));
    }
  };

  useEffect(() => {
    const totalFilesCount = publishData?.images?.flatMap(
      (image) => image.files
    ).length;
    const hasRequiredFiles = totalFilesCount > 2;

    setIsDisabled(
      !publishData?.titleEn ||
        !publishData?.descriptionEn ||
        !publishData?.listingAgentId ||
        !hasRequiredFiles
    );
  }, [publishData]);

  const getUnitPublishInfo = async () => {
    setIsLoading(true);
    const unitId = activeItem?.id;
    const operationType = isForLease
      ? UnitsOperationTypeEnum.rent.key
      : UnitsOperationTypeEnum.sale.key;
    try {
      const res = await GetUnitQuickPublishInfo(unitId, operationType);
      setPublishData(res);
    } catch {
      showError(t('an-error-occurred-while-processing-your-request.'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeItem) {
      getUnitPublishInfo();
    }
  }, [activeItem]);

  if (!publishData) {
    return null;
  }
  return (
    <>
      <DialogComponent
        titleText={'publish-to-website'}
        maxWidth='sm'
        isOpen={isDialogOpen}
        parentTranslationPath={parentTranslationPath}
        dialogContent={
          <>
            <Spinner isActive={isLoading} />
            <div className='publish-dialog-wrapper'>
              <div className='publish-field'>
                <p className='publish-filed-label'>
                  {isForLease ? t('rent-price') : t('selling-price')}
                </p>
                <span className='publish-filed-text'>
                  {isForLease
                    ? publishData?.rentPerYearPrice
                    : publishData?.sellingPrice}
                </span>
              </div>
              <div className='publish-field'>
                <p className='publish-filed-label'>{t('marketing-title-en')}</p>
                <span className='publish-filed-text'>
                  {publishData?.titleEn}
                </span>
              </div>
              <div className='publish-field'>
                <p className='publish-filed-label'>{t('marketing-title-ar')}</p>
                <span className='publish-filed-text'>
                  {publishData?.titleAr}
                </span>
              </div>
              <div className='publish-field'>
                <p className='publish-filed-label'>
                  {t('marketing-description-en')}
                </p>
                <span className='publish-filed-text'>
                  {publishData?.descriptionEn}
                </span>
              </div>
              <div className='publish-field'>
                <p className='publish-filed-label'>
                  {t('marketing-description-ar')}
                </p>
                <span className='publish-filed-text'>
                  {publishData?.descriptionAr}
                </span>
              </div>
              <div className='d-flex-v-center'>
                <Avatar className='agent-avatar'>
                  {getFirstLastNameLetters(publishData?.listingAgentName || '')}
                </Avatar>
                <div className='agent-avatar-text'>
                  <span>{publishData.listingAgentName || '-'}</span>
                </div>
              </div>
              <div>
                <p className='publish-filed-label'>{t('marketing-images')}</p>
                <div className='publish-img-wrapper'>
                  <div className='publish-img'>
                    {unitImgURLs[0] && (
                      <img
                        src={unitImgURLs[0]}
                        alt='unit-image'
                        className='image-width'
                      />
                    )}
                  </div>
                  <div className='publish-img'>
                    {unitImgURLs[1] && (
                      <img
                        src={unitImgURLs[1]}
                        alt='unit-image'
                        className='image-width'
                      />
                    )}
                  </div>
                  <div className='publish-img'>
                    {unitImgURLs[2] && (
                      <img
                        src={unitImgURLs[2]}
                        alt='unit-image'
                        className='image-width'
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='publish-action-wrapper'>
                <Button
                  className='btns'
                  onClick={() => toggleDialog()}
                >
                  {t('cancel')}
                </Button>
                <div className='publish-action-left-side'>
                  <Button
                    className='btns edit-btn'
                    onClick={handleEditPublish}
                  >
                    {t('edit')}
                  </Button>
                  <Button
                    className='btns theme-solid confirm-btn'
                    onClick={handleQuickPublishUnit}
                    disabled={isDisabled}
                  >
                    {t('confirm')}
                  </Button>
                </div>
              </div>
            </div>
          </>
        }
      />

      {isMarkDialogOpen && (
        <MarkUnitAvailableDialog
          isOpen={isMarkDialogOpen}
          isOpenChanged={() => setIsMarkDialogOpen(false)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          publishActiveItem={publishData}
          UpdatePublishData={UpdatePublishData}
          isForLease={isForLease}
        />
      )}
    </>
  );
}

export default PublishToWebsiteDialog;
