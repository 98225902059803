import React from "react";

function ChevronDown({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M5.67 8.061c-.222.081-.42.248-.542.455-.092.156-.108.229-.107.484 0 .212.022.341.074.44.1.192 6.199 6.298 6.425 6.433.153.091.225.107.48.106.212 0 .341-.022.44-.074.192-.1 6.298-6.199 6.433-6.425.091-.152.107-.226.107-.48s-.017-.328-.108-.484a1.003 1.003 0 0 0-1.215-.451c-.179.06-.447.315-2.927 2.789L12 13.578l-2.73-2.724C6.79 8.38 6.522 8.125 6.343 8.065a1.015 1.015 0 0 0-.673-.004"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ChevronDown;
