import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  TextField,
  Box,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";

import { useSelectedTheme } from "../../../Hooks";
import { EmailIcon } from "../../../assets/icons";

import useStyles from "./styles";

const EmailInput = ({
  error,
  errorMessage,
  helperText,
  idRef,
  inputClasses,
  inputPlaceholder,
  isDisabled,
  isLoading,
  isRequired,
  labelClasses,
  labelValue,
  onInputBlur,
  onInputChanged,
  onKeyDown,
  onKeyUp,
  themeClass,
  value,
  wrapperClasses,
}) => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <Box
      style={{
        position: "relative",
      }}
      className={`${wrapperClasses} ${themeClass}`}
    >
      <Box className={styles.errorMessageContainer}>
        <TextField
          fullWidth
          id={idRef || ""}
          label={labelValue}
          placeholder={inputPlaceholder}
          required={isRequired}
          disabled={isDisabled}
          value={value || ""}
          error={Boolean(error)}
          helperText={helperText}
          variant="outlined"
          className={clsx(styles.textField, inputClasses, {
            [styles.inputContainerError]: error && errorMessage,
            [styles.inputContainerFocused]: !error && isFocused,
          })}
          InputLabelProps={{
            className: labelClasses,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ marginRight: "8px" }}>
                <EmailIcon
                  width="20"
                  height="20"
                  fill={palette.utility.utility_gray_500}
                />
              </InputAdornment>
            ),
            endAdornment: isLoading ? (
              <InputAdornment position="end" style={{ marginLeft: "8px" }}>
                <CircularProgress
                  size={20}
                  color={palette.button.brand_solid}
                />
              </InputAdornment>
            ) : null,
            autoComplete: "off",
            onKeyDown,
            onKeyUp,
          }}
          onFocus={handleFocus}
          onBlur={(event) => {
            setIsFocused(false);
            if (onInputBlur) onInputBlur(event);
          }}
          onChange={(e) => {
            const newValue = e.target.value;
            console.log("Email Input Changed:", newValue); // Debugging
            if (onInputChanged) onInputChanged(newValue);
          }}
          // style={{
          //   borderColor: isFocused && !error ? palette.primary.main : undefined,
          // }}
        />
        {error && errorMessage && (
          <span className={styles.errorMessage}>{errorMessage}</span>
        )}
      </Box>
    </Box>
  );
};

export default EmailInput;

EmailInput.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  idRef: PropTypes.string.isRequired,
  inputClasses: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelClasses: PropTypes.string,
  labelValue: PropTypes.string,
  onInputBlur: PropTypes.func,
  onInputChanged: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  themeClass: PropTypes.oneOf(["theme-default"]),
  value: PropTypes.string,
  wrapperClasses: PropTypes.string,
};

EmailInput.defaultProps = {
  error: false,
  errorMessage: "",
  helperText: "",
  inputClasses: "",
  inputPlaceholder: "",
  isDisabled: false,
  isLoading: false,
  isRequired: false,
  labelClasses: "",
  labelValue: "",
  onInputBlur: undefined,
  onInputChanged: undefined,
  onKeyDown: undefined,
  onKeyUp: undefined,
  themeClass: "theme-default",
  value: "",
  wrapperClasses: "",
};
