import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapperUnitsAddDialogsView: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  containerRowFiled: {
    display: "flex",
    flexDirection: "row",
    gap: "32px",
  },
  titleFiled: {
    maxWidth: "280px",
    minWidth: "160px",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
}));
