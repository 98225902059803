
import React, { useCallback, useState, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getErrorByName } from '../../../../../../../Helper';
import { SelectComponet } from '../../../../../../../Components';



export const FormCategotyType = ({
  parentTranslationPath, translationPath, state, setState, item, index, schema, SelectedData
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  const [allFormCategotyType, setAllFormCategotyType] = useState([]);

  useEffect(() => {
    if (index !== -1 && state.conditions && state.conditions[index] && state.conditions[index].category && state.conditions[index].category === 'contacts')
      setAllFormCategotyType([{ id: 1, name: 'Individual' }, { id: 2, name: 'Corporate' }]);

    else if (index !== -1 && state.conditions && state.conditions[index] && state.conditions[index].category && state.conditions[index].category === 'leads')
      setAllFormCategotyType([{ id: 6, name: 'Owner' }, { id: 7, name: 'Seeker' }]);
    else if (index !== -1 && state.conditions && state.conditions[index] && state.conditions[index].category && state.conditions[index].category === 'activities')
      setAllFormCategotyType([{ id: 8, name: 'Last Activity' }]);
    else
      setAllFormCategotyType([]);

  }, [state]);



  return (
    <div>
      <SelectComponet
        labelValue={!index ? t(`${translationPath}categoty-type`) : ''}
        idRef={`FormCategotyTypeRef${index + 1}`}
        emptyItem={{ value: null, text: t(`${translationPath}select-categoty`), isHiddenOnOpen: true }}
        data={allFormCategotyType || []}
        value={item.categoryType}
        valueInput='id'
        textInput='name'
        onSelectChanged={(value) => {
          let updatelist = state.conditions;
          updatelist[index] = {
            ...updatelist[index],
            categoryType: value,
            searchKey: null,
            fieldName: null,
            displayPath: null,
            value: null,
            operator: SelectedData.actionType !== "Condition" ? 1 : null,
          };
          setState({ id: 'conditions', value: updatelist });
        }}
        translationPathForData={translationPath}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        error={getErrorByName(schema, 'categoryType').error}
      />
    </div>
  );

}



FormCategotyType.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,

};
