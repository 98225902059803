import React, { useEffect, useState } from "react";
import ReactFlow, { Handle } from "react-flow-renderer";
import { ButtonBase, Popover, Tooltip } from "@material-ui/core";
import dots from "../../../../assets/images/icons/dots-vertical.svg";
import {
  AutocompleteComponent,
  DialogComponent,
  PopoverComponent,
  SelectComponet,
  Spinner,
} from "../../../../Components";
import { DateTableFilterOperatorsEnum } from "../../ReportsBuilderView/ReportsBuilderEnums/ReportsBuilderEnums";
import { GetSubActions } from "../../../../Services";
import { CreateLeadForm } from "../WorkFlowForms/CreateLeadForm/CreateLeadForm";
import { useTranslation } from "react-i18next";
import { CreateLeadFormStatic } from "../WorkFlowForms/CreateLeadFormStatic";

const CustomNode = ({
  data,
  id,
  handleAddNewNode,
  handleAddConditionNode,
  handleDeleteNode,
  nodeType,
  edges,
  Trigers,
  GetTrigerSubActionsAPI,
  nodes,
  // GetSubActionsAPI
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const hasChildren = edges.some((edge) => edge.source === id);
  const [TypeOfApeove, setTypeOfApeove] = useState(null);
  const [selectedData, setSelectedDataTrmp] = useState(null);
  const [SelectedData, setSelectedData] = useState(null);
  const [tempSelectData, settempSelectData] = useState(null);
  const [subActions, setSubActions] = useState([]);
  const [isOpen, setisOpen] = useState(false);

  const [LeadFormData, setLeadFormData] = useState([]);

  const GetSubActionsAPI = async (
    actionId,
    actionType,
    actionValue,
    anchor
  ) => {
    const response = await GetSubActions({
      actionId: actionId ? +actionId : null,
      actionType,
      actionValue,
    });

    if (response) {
      setSubActions(response);
    } else {
      console.warn("Failed to fetch sub-actions");
      setSubActions([]);
    }
    return response;
  };

  const handleAddClick = (selectedData, LeadFormData) => {
    if (!hasChildren) {
      const actionName = selectedData?.actionName || "Default Action s"; // Use actionName from selectedData
      handleAddNewNode(id, "down", actionName, selectedData, LeadFormData);
    }
  };

  const handleConditionYesClick = (event) => {
    setTypeOfApeove("down-left");
    const APIResult = GetSubActionsAPI(
      data?.parentData?.trigerId || data.actionId,
      "Condition",
      "true"
    );
    if (APIResult) setActionsPopover(event.currentTarget);
  };

  const handleConditionNoClick = (event) => {
    setTypeOfApeove("down-right");
    const APIResult = GetSubActionsAPI(
      data?.parentData?.trigerId || data.actionId,
      "Condition",
      "false"
    );
    if (APIResult) setActionsPopover(event.currentTarget);
  };

  const handleAddConditionClick = (SelectData, TypeOfApeove, LeadFormData) => {
    const conditionAction =
      (SelectData &&
        SelectData.triggers &&
        SelectData.triggers[0]?.actionName) ||
      (SelectData && SelectData.actionName) ||
      "Default Condition";
    // Pass LeadFormData to handleAddConditionNode
    handleAddConditionNode(
      id,
      TypeOfApeove || "down",
      { conditionAction },
      SelectData,
      LeadFormData
    );
  };

  const handleSubbClick = (event) => {
    setTypeOfApeove("down");
    const APIResult = GetSubActionsAPI(data?.actionId, "Action", null);
    if (APIResult) setActionsPopover(event.currentTarget);
  };

  const [ActionsPopover, setActionsPopover] = useState(null);
  const [isOpenDialogReldo, setisOpenDialogReldo] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const actionsPopoverClickedHandler = (event) => {
    setActionsPopover(event.currentTarget);
  };

  const actionsPopoverCloseHandler = () => {
    setActionsPopover(null);
  };

  const onActionsClickedFolder = (actionEnum, activeData, SelectData) => {
    setSelectedDataTrmp(SelectData);
    setSelectedData(SelectData);
    if (actionEnum === "Condition") {
      setisOpenDialogReldo(true);
    } else if (actionEnum === "delete") {
      handleDeleteNode(id);
    } else if (actionEnum === "Wait") {
      setisOpenDialogReldo(true);
    } else if (actionEnum === "Action") {
      handleAddClick(SelectData);
    } else if (actionEnum === "AddAction") {
      setisOpenDialogReldo(true);
    }
  };

  const onActionsClickedsubActionsFolder = (
    SelectData,
    TypeOfApeove,
    LeadFormData
  ) => {
    if (SelectData.actionType === "Condition") {
      handleAddConditionClick(SelectData, TypeOfApeove, LeadFormData);
      setSelectedDataTrmp(SelectData);
    } else if (SelectData.actionType === "Action") {
      setSelectedDataTrmp(SelectData);
      handleHardReload(TypeOfApeove, SelectData, LeadFormData);
    }
  };

  const updateTableListOptionActions = (triggers) => {
    return triggers
      .map((trigger) => {
        if (trigger.actionType === "Condition") {
          return {
            key: `Condition-${trigger.actionId}`,
            value: `IF / ELSE - ${trigger.actionName}`,
            keyName: "Condition",
            icon: "", //mdi mdi-vector-triangle
            trigger,
          };
        } else if (trigger.actionType === "Action") {
          return {
            key: `Action-${trigger.actionId}`,
            value: trigger.actionName,
            keyName: "Action",
            icon: "", //mdi mdi-vector-triangle
            trigger,
          };
        } else if (trigger.actionType === "Wait") {
          return {
            key: `Wait-${trigger.actionId}`,
            value: "Wait",
            keyName: "Wait",
            icon: "mdi mdi-timer-sand",
            trigger,
          };
        }
        return null; // Exclude unsupported action types
      })
      .filter(Boolean); // Remove null values
  };

  const parseValue = (value) => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return {}; // Return an empty object in case of parsing failure
    }
  };

  const ConditionDescription = ({ data }) => {
    // Assuming data is the correct prop
    const parsedValue = parseValue(data.Value);  // Using 'data' instead of 'condition'
  
    return (
      <div className="condition-description">
        <p>
          <strong>Form ID:</strong> {data.FormId} |  {/* Use 'data' */}
          <strong>Search Key:</strong> {data.SearchKey} | 
          <strong>Operator:</strong> {data.Operator === 1 ? 'Is equal' : 'Other operator'} | 
          <strong>Operand:</strong> {data.Operand} | 
          <strong>Lookup Name:</strong> {parsedValue.lookupItemName} | 
          <strong>Description:</strong> {parsedValue.description} | 
          <strong>Parent Lookup:</strong> {parsedValue.lookupItemParents ? JSON.parse(parsedValue.lookupItemParents).map(parent => parent.LookupItemName).join(', ') : 'N/A'}
        </p>
      </div>
    );
  };
  


  const handleHardReload = (TypeOfApeove, SelectData, LeadFormData) => {
    setIsLoading(true);
    const actionName = (SelectData && SelectData.actionName) || "Action"; // Use selected Action name or fallback
    // Your hard reload logic here
    if (TypeOfApeove === "down-right") {
      handleAddNewNode(id, "down-right", actionName, SelectData, LeadFormData);
    } else if (TypeOfApeove === "down-left") {
      handleAddNewNode(id, "down-left", actionName, SelectData, LeadFormData);
    } else {
      handleAddClick(SelectData, LeadFormData); // `handleAddClick` will use `selectedData.actionName` if set
    }
  };

  const handleNodeDoubleClick = () => {
    // Delete the node and its children
    handleDeleteNode(id);
  };
  const [selectedOperator, setSelectedOperator] = useState(null);

  const handleChange = (newValue) => {
    setSelectedOperator(newValue);
  };

  const getData = () => {
    return Object.values(DateTableFilterOperatorsEnum);
    // return Object.values(TextTableFilterOperatorsEnum);
  };

  const [selectedBox, setSelectedBox] = useState(null);

  const handleBoxClick = (Action) => {
    setSelectedBox(Action.actionId);
    setSelectedDataTrmp(Action); // Store the selected Action object here
  };

  return (
    <div
      style={{
        border: "1px solid #1a192b",
        borderRadius: "5px",
        backgroundColor: "#fff",
        minWidth: "150px",
        maxWidth: "210px",
        textAlign: "center",
        boxShadow: "0px 3px 6px rgba(0,0,0,0.1)",
        position: "relative",
        fontFamily: "Arial, sans-serif",
        zIndex: "10",
      }}
    >
      <Spinner isActive={isLoading} isAbsolute />
      <PopoverComponent
        idRef="headfdsopovercogRef"
        attachedWith={ActionsPopover}
        popoverClasses="top-ppp"
        handleClose={actionsPopoverCloseHandler}
        component={
          <div className="Popap-Option-CustomNode">
            {edges &&
              edges.length === 0 &&
              updateTableListOptionActions(Trigers).map((item, index) => (
                <ButtonBase
                  className="Option"
                  key={`OptionKey${index + 1}`}
                  onClick={() => {
                    onActionsClickedFolder(item.keyName, data, item?.trigger);
                    setSelectedDataTrmp(item?.trigger);
                    setActionsPopover(null);
                    settempSelectData(item?.trigger);
                  }}
                >
                  <div className="value">{item.value}{item.value}</div>
                </ButtonBase>
              ))}
        
            {/* Render a section for "Condition" actions */}
            {subActions.some((Action) => Action.actionType === "Condition") && (
              <div className="section-condition">
                <h3 className="section-header">Condition</h3> {/* Label for Conditions */}
                <div className="section-content">
                  {subActions.map((Action, index) => {
                    if (Action.actionType === "Condition") {
                      return (
                        <div key={Action.actionId} className="condition-item">
                          <ButtonBase
                            className="Condition-Option"
                            key={`OptionKey${index + 1}`}
                            onClick={() => {
                              setSelectedData(Action);
                              handleBoxClick(Action);
                              const hasRequiredFormFields = [
                                "activityTypeId",
                                "SourceId",
                                "psi_opportunity",
                                "LeadsType",
                                "LeadActivityTypeForm-activity",
                                "MediaDetailsWhitelist",
                              ].some((key) => Action?.formName?.includes(key));
        
                              if (hasRequiredFormFields) {
                                setisOpenDialogReldo(true);
                              } else if (
                                Action &&
                                Action.formName?.trim() === "MainContactForm-contact"
                              ) {
                                setisOpenDialogReldo(true);
                              } else if (
                                Action &&
                                Action.formName?.trim() === "MainLeadFrom-lead"
                              ) {
                                setisOpenDialogReldo(true);
                              } else if (Action && Action.actionType !== "Action") {
                                onActionsClickedsubActionsFolder(
                                  Action,
                                  TypeOfApeove,
                                  LeadFormData
                                );
                              } else {
                                onActionsClickedsubActionsFolder(
                                  Action,
                                  TypeOfApeove,
                                  LeadFormData
                                );
                              }
                            }}
                          >
                            <div className="actionType-item-Condition">{Action.actionName}</div>
                          </ButtonBase>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            )}
        
            {/* Render a section for "Action" items */}
            {subActions.some((Action) => Action.actionType === "Action") && (
              <div className="section-action">
                <h3 className="section-header">Action</h3> {/* Label for Actions */}
                <div className="section-content">
                  {subActions.map((Action, index) => {
                    if (Action.actionType === "Action") {
                      return (
                        <div key={Action.actionId} className="action-item">
                          <ButtonBase
                            className="Option"
                            key={`OptionKey${index + 1}`}
                            onClick={() => {
                              setSelectedData(Action);
                              handleBoxClick(Action);
                              const hasRequiredFormFields = [
                                "activityTypeId",
                                "SourceId",
                                "psi_opportunity",
                                "LeadsType",
                                "LeadActivityTypeForm-activity",
                                "MediaDetailsWhitelist",
                              ].some((key) => Action?.formName?.includes(key));
        
                              if (hasRequiredFormFields) {
                                setisOpenDialogReldo(true);
                              } else if (
                                Action &&
                                Action.formName?.trim() === "MainContactForm-contact"
                              ) {
                                setisOpenDialogReldo(true);
                              } else if (
                                Action &&
                                Action.formName?.trim() === "MainLeadFrom-lead"
                              ) {
                                setisOpenDialogReldo(true);
                              } else if (Action && Action.actionType !== "Action") {
                                onActionsClickedsubActionsFolder(
                                  Action,
                                  TypeOfApeove,
                                  LeadFormData
                                );
                              } else {
                                onActionsClickedsubActionsFolder(
                                  Action,
                                  TypeOfApeove,
                                  LeadFormData
                                );
                              }
                            }}
                          >
                            <div className="actionType-item-Action">{Action.actionName}</div>
                          </ButtonBase>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            )}
          </div>
        }
        
      />
      {
        <DialogComponent
          titleText={`${`Add `} ( ${
            (SelectedData && SelectedData.actionType) ||
            (data && data.description) ||
            ""
          } )      ${`Details`}   `}
          saveText={`${`Confirm`}`}
          saveType="button"
          maxWidth="md"
          dialogContent={
            <div className="d-flex-column-center">
              <span className="mdi mdi-help-rhombus-outline c-primary mdi-48px" />

              <div className="icon-box-slider">
                <h2>
                  {(SelectedData && SelectedData.actionName) ||
                    (SelectedData && SelectedData.actionType) ||
                    (data && data.label) ||
                    (data && data.description) ||
                    ""}
                </h2>
                <p>
                  {`Add Condition quick way to add visuals to functional navigation. Icons can help make content easier to memorize and understand.` ||
                    (SelectedData && SelectedData.description) ||
                    ""}
                </p>
                <div className="icon-box-container">
                  {SelectedData && (
                    <>
                      {[
                        "MainContactForm-contact",
                        "MainLeadFrom-lead",
                      ].includes(SelectedData.formName?.trim()) ? (
                        <CreateLeadForm
                          data={data}
                          SelectedData={SelectedData}
                          LeadFormData={LeadFormData}
                          setLeadFormData={setLeadFormData}
                        />
                      ) : (
                        <CreateLeadFormStatic
                          LeadFormData={LeadFormData}
                          setLeadFormData={setLeadFormData}
                          formData={SelectedData}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
              <div
                className="pt-3 flex flex-center-work"
                style={{ textAlignVertical: "center", textAlign: "center" }}
              >
                {/* {`${(`userMenuView.Relode-description`)}`} */}

                <div
                  className="flex-center-work"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                ></div>
              </div>
            </div>
          }
          saveClasses="btns theme-solid c-info: w-100 mx-2 mb-2"
          isOpen={isOpenDialogReldo}
          onSaveClicked={() => {
            setisOpenDialogReldo(false);
            onActionsClickedsubActionsFolder(
              SelectedData,
              TypeOfApeove,
              LeadFormData
            );
          }}
          onCloseClicked={() => setisOpenDialogReldo(false)}
          onCancelClicked={() => setisOpenDialogReldo(false)}
        />
      }
      <Handle
        isConnectable={false}
        type="target"
        position="top"
        style={{ background: "#555" }}
      />
      <div
        className=""
        label={data.label}
        style={{
          fontWeight: "bold",
          fontSize: "16px",
          marginBottom: "5px",
          width: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {data.label}
      </div>
      <div
        className={`description ${
          nodeType !== "Condition" ? "default" : "condition"
        }`}
        // onClick={() => setisOpenDialogReldo2(true)}
      >
          
        {/* <ConditionDescription data={data} /> */}
        {data.description}
      </div>
      {nodeType === "Condition" && (
        <>
          <div
            onClick={
              !edges.some(
                (edge) =>
                  edge.source === id && edge.data?.conditionType === "yes"
              )
                ? handleConditionYesClick
                : undefined
            }
            className={`condition-button yes ${
              edges.some(
                (edge) =>
                  edge.source === id && edge.data?.conditionType === "yes"
              )
                ? "disabled"
                : ""
            }`}
          >
            YES
          </div>
          <div
            onClick={
              !edges.some(
                (edge) =>
                  edge.source === id && edge.data?.conditionType === "no"
              )
                ? handleConditionNoClick
                : undefined
            }
            className={`condition-button no ${
              edges.some(
                (edge) =>
                  edge.source === id && edge.data?.conditionType === "no"
              )
                ? "disabled"
                : ""
            }`}
          >
            NO
          </div>
        </>
      )}
      {nodeType !== "Condition" && !hasChildren && (
        <div className="dropdown-workflow" onClick={(e) => handleSubbClick(e)}>
          <img alt="folder" className="dots-vertical" src={dots} />
        </div>
      )}
      {!data?.triggerData && (
        <div className="trash-workflow mt-3" onClick={(e) => setisOpen(true)}>
          <span className="mdi mdi-trash-can-outline"></span>
        </div>
      )}
      <Handle
        isConnectable={false}
        isValidConnection={false}
        type="source"
        position="bottom"
        style={{ background: "#555" }}
      />
      <DialogComponent
        titleText="confirm-message"
        saveText={t(`${translationPath}confirm`)}
        SmothMove
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <Spinner isActive={isLoading} isAbsolute />
            <span className="mdi mdi-close-octagon c-danger mdi-48px" />
            <span className="fz-18px fw-bold">
              {`${`${t(`${translationPath}delete-description`)}` || ""}`}
              <span className="d-flex-center  fz-22px fw-bold mt-2">
                {" "}
                ( {data.label || ""} ){" "}
              </span>
            </span>
          </div>
        }
        saveClasses="btns theme-solid bg-danger w-100 mx-2 mb-2"
        isOpen={isOpen}
        onSaveClicked={(e) => handleNodeDoubleClick()}
        onCloseClicked={() => setisOpen(false)}
        onCancelClicked={() => setisOpen(false)}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </div>
  );
};

export default CustomNode;
