import React, { useState } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";

//styles
import useStyles from "./styles";


const CustomNumberSelector = ({ valuesArray, onChange }) => {
  const styles = useStyles();

  const [selectedValue, setSelectedValue] = useState(valuesArray[0]);

  const handleSelect = (num) => {
    setSelectedValue(num);
    if (onChange) onChange(num);
  };

  return (
    <Box className={styles.containerNumbersSelector}>
      {valuesArray &&
        valuesArray.length > 0 &&
        valuesArray.map((value, index) => (
          <Box
            className={clsx(styles.containerValue, {
              [styles.selectedValueStyle]: selectedValue === value,
            })}
            onClick={() => handleSelect(value)}
          >
            {value}
          </Box>
        ))}
    </Box>
  );
};

export default CustomNumberSelector;
