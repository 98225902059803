import React, {
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import _ from "lodash";
import moment from "moment";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  CustomDateRangePicker,
  CustomIconButton,
  CustomInput,
  CustomSelect,
  ViewModeSwitcher,
  RolePinSideTab,
  VIEW_MODES,
  CustomButton,
  SortingDropdown,
  CustomPermission,
} from "../../../../../Components";
import { AddToFavouriteDialog } from "..";
import { useVerticalNav } from "../../../../../Contexts/VerticalNavContext";
import { useSelectedTheme, useTranslate } from "../../../../../Hooks";
import { LeadsClassTypesEnum } from "../../../../../Enums";
import { ContactsPermissions } from "../../../../../Permissions";
import { useNewLayoutShared } from "../../../../../Contexts/NewLayoutSharedContext";

// Styles
import useStyles from "./styles";

// Icons
import {
  ColumnsThree,
  SettingsFour,
  CloseXIcon,
  MergeContactsIcon,
  LinkIcon,
  ShieldIcon,
  StarOneIcon,
} from "../../../../../assets/icons";

function QuickFilterSection({
  viewMode,
  onChangeViewMode,
  setUpdateSelectedColumnItems,
  allFormFields,
  tableColumns,
  setIsBulkUpdateModal,
  setIsFavoriteDialogOpen,
  isFavoriteDialogOpen,
  favoriteFolders,
  setFavoriteFolders,
  isFavoriteFoldersLoading,
}) {
  const { contactsData, isLoading, setAdvancedSearchBody, advancedSearchBody } =
    useNewLayoutShared();
  const dispatch = useDispatch();
  const history = useHistory();

  const { translate } = useTranslate("ContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const styles = useStyles();

  const [isPinTabOpen, setIsPinTabOpen] = useState(false);

  const handleClosePinSideTab = () => {
    setIsPinTabOpen(false);
  };

  const [selectedDateOption, setSelectedDateOption] = useState(1);

  const fromDateKey = useMemo(() => {
    return selectedDateOption === 1 ? "fromDate" : "updatedFromDate";
  }, [selectedDateOption]);

  const toDateKey = useMemo(() => {
    return selectedDateOption === 1 ? "toDate" : "updatedToDate";
  }, [selectedDateOption]);

  const [showQuickFiltersSection, setShowQuickFiltersSection] = useState(true);

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
    selectedDateType: 1,
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const { setAlertBoxContent, actionableItems, setActionableItems } =
    useVerticalNav();

  const handleSelectTypeChange = (newValue) => {
    setActionableItems({
      selectedIds: [],
      action: null,
    });
    setAdvancedSearchBody((prev) => {
      const { contactTypeStr, ...restCriteria } = prev.criteria;

      // Check if the new value is 0 and the previous value was also 0
      if (!newValue && !contactTypeStr) {
        return prev; // No state update needed
      }

      return {
        ...prev,
        criteria: !newValue
          ? restCriteria
          : {
              ...restCriteria,
              contactTypeStr: [{ searchType: 1, value: newValue }],
            },
      };
    });
  };

  const handleContactOpportunityChange = (newValue) => {
    if (newValue === "no") {
      setActionableItems({
        selectedIds: [],
        action: null,
      });
    }

    setAdvancedSearchBody((prev) => {
      const { opportunityContact, ...restCriteria } = prev.criteria;

      return {
        ...prev,
        criteria: !newValue
          ? restCriteria // remove opportunityContact if !newValue
          : {
              ...restCriteria,
              opportunityContact: [{ searchType: 1, value: newValue }],
            },
      };
    });
  };

  const handleApplyDateFilter = (ranges) => {
    setAdvancedSearchBody((prev) => {
      const { [fromDateKey]: _, [toDateKey]: __, ...restBody } = prev;
      const isDateRangeEmpty = !ranges.startDate && !ranges.endDate;

      if (isDateRangeEmpty) {
        return {
          ...restBody,
        };
      }

      return {
        ...prev,
        [fromDateKey]: moment(ranges.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss"),
        [toDateKey]: moment(ranges.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      };
    });
  };

  useEffect(() => {
    if (!dateFilter.startDate && !dateFilter.endDate) {
      return;
    }

    setAdvancedSearchBody((prev) => {
      const { fromDate, updatedFromDate, toDate, updatedToDate, ...restBody } =
        prev;
      const isDateRangeEmpty = !dateFilter.startDate && !dateFilter.endDate;

      if (isDateRangeEmpty) {
        return {
          prev,
        };
      }

      return {
        ...restBody,
        [fromDateKey]: moment(dateFilter.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss"),
        [toDateKey]: moment(dateFilter.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      };
    });
  }, [selectedDateOption]);

  const getDefaultContactTypeValue = useCallback(() => {
    const contactType =
      advancedSearchBody.criteria?.contactTypeStr?.[0]?.value ?? "";

    if (!contactType)
      return advancedSearchBody.criteria?.contactTypeStr?.[0]?.value ?? "";

    return contactType.charAt(0).toUpperCase() + contactType.slice(1);
  }, [advancedSearchBody.criteria?.contactTypeStr]);

  const getDefaultContactOpportunityValue = useCallback(() => {
    const contactOpportunity =
      advancedSearchBody.criteria?.opportunityContact?.[0]?.value ?? "";

    return contactOpportunity;
  }, [advancedSearchBody.criteria?.opportunityContact]);

  const handleApplyButton = () => {
    if (
      actionableItems.action === "merge" &&
      actionableItems?.selectedIds?.length > 1
    ) {
      const filteredContacts = actionableItems?.selectedIds
        ?.filter((el) => el?.isWithTransaction !== 1) // Filter elements where isWithTransaction !== 1
        ?.slice(0, 10);

      const userTypeId = contactsData?.result?.find(
        (contact) => contact?.contactId || contact?.id === filteredContacts[0]
      )?.userTypeId;

      dispatch({
        type: "CONTACTS_MERGE_REQUEST",
        payload: {
          ContactsMergeIds: filteredContacts,
          isOriginalWithTransactions: 0,
        },
      });

      history.push(`/home/Contacts-CRM/merge?userTypeId=${userTypeId}`);
    } else if (actionableItems.action === "bulk_update") {
      if (
        advancedSearchBody?.criteria?.contactTypeStr &&
        advancedSearchBody?.criteria?.contactTypeStr?.length > 0 &&
        actionableItems?.selectedIds?.length > 0
      ) {
        const userTypeId =
          advancedSearchBody?.criteria?.contactTypeStr?.[0]?.searchType;

        const filteredContacts = contactsData?.result?.filter(
          (item) =>
            actionableItems?.selectedIds?.includes(item?.contactId) ||
            actionableItems?.selectedIds?.includes(item?.id)
        );

        localStorage.setItem(
          "bulk-assign-contacts-items",
          JSON.stringify(filteredContacts)
        );

        history.push(
          `/home/Contacts-CRM/contact-bulk-update?type=${userTypeId}`
        );
      }
    } else if (actionableItems.action === "pull") {
      setActionableItems((prev) => ({
        ...prev,
        isConfirmationDialogOpen: true,
        isConfirmed: false,
      }));
    } else if (actionableItems.action === "favourite") {
      setIsFavoriteDialogOpen(true);
    }
  };

  return (
    <>
      <Box className={styles.filtersContainer}>
        <Box className={styles.flex} style={{ flexFlow: "wrap" }}>
          {actionableItems?.selectedIds?.length > 0 && (
            <>
              <CustomIconButton
                variant="text"
                size="none"
                boxShadow="none"
                color="tertiaryColor"
                hideHoverBg
              >
                <CloseXIcon
                  onClick={() =>
                    setActionableItems({
                      selectedIds: [],
                      action: null,
                    })
                  }
                  width="20"
                  height="20"
                  fill={palette.button.secondary_fg}
                />
              </CustomIconButton>

              <Box className={styles.selectedCount}>
                {actionableItems?.selectedIds?.length} selected
              </Box>
            </>
          )}

          <CustomPermission
            permissionsList={Object.values(ContactsPermissions)}
            permissionsId={ContactsPermissions.MergeContacts.permissionsId}
          >
            <CustomButton
              boxShadow="xs"
              size="lg"
              variant="outlined"
              color="secondary"
              onClick={() => {
                setActionableItems({
                  selectedIds: [],
                  action: "merge",
                });
              }}
              startIcon={
                <MergeContactsIcon
                  width="20"
                  height="20"
                  fill={palette.button.secondary_fg}
                />
              }
              hasToolTip={true}
              toolTipMessage="You can only select up to 10 contacts, and obsolete contacts can't have transactions."
            >
              Merge
            </CustomButton>
          </CustomPermission>

          <CustomPermission
            permissionsList={Object.values(ContactsPermissions)}
            permissionsId={ContactsPermissions.BulkUpdate.permissionsId}
          >
            <CustomButton
              boxShadow="xs"
              size="lg"
              variant="outlined"
              color="secondary"
              onClick={() => {
                setActionableItems({
                  selectedIds: [],
                  action: "bulk_update",
                });

                setIsBulkUpdateModal(true);
              }}
              startIcon={
                <LinkIcon
                  width="20"
                  height="20"
                  fill={palette.button.secondary_fg}
                />
              }
              hasToolTip={true}
              toolTipMessage="You can only select up to 25 contacts"
            >
              Bulk update
            </CustomButton>
          </CustomPermission>

          <CustomPermission
            permissionsList={Object.values(ContactsPermissions)}
            permissionsId={
              ContactsPermissions.PullToContactOpportunity.permissionsId
            }
          >
            <CustomButton
              boxShadow="xs"
              size="lg"
              variant="outlined"
              color="secondary"
              onClick={() => {
                // Check if the action is already 'pull' before setting it again
                setActionableItems((prev) => {
                  if (prev.action !== "pull") {
                    setAdvancedSearchBody((prev) => {
                      const { ...restCriteria } = prev.criteria;

                      return {
                        ...prev,
                        criteria: {
                          ...restCriteria,
                          opportunityContact: [{ searchType: 1, value: "yes" }],
                        },
                      };
                    });

                    return {
                      selectedIds: [],
                      action: "pull",
                    };
                  }
                  return prev; // Keep the current state if action is already 'pull'
                });
              }}
              startIcon={
                <ShieldIcon
                  width="20"
                  height="20"
                  fill={palette.button.secondary_fg}
                />
              }
            >
              Pull to contact opportunity
            </CustomButton>
          </CustomPermission>

          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="outlined"
            color="secondary"
            onClick={() => {
              // Update the state based on the previous state
              setAdvancedSearchBody((prevState) => {
                // Check if FavoriteFolderId exists in the previous state's criteria
                const updatedCriteria = { ...prevState.criteria };

                // Only remove FavoriteFolderId if it exists
                if (updatedCriteria.hasOwnProperty("FavoriteFolderId")) {
                  delete updatedCriteria.FavoriteFolderId;
                } else {
                  // If FavoriteFolderId does not exist, return the prevState unchanged
                  return prevState;
                }

                // Return the updated state with the modified criteria
                return {
                  ...prevState,
                  criteria: updatedCriteria,
                };
              });

              setActionableItems({
                selectedIds: [],
                action: "favourite",
              });
            }}
            startIcon={
              <StarOneIcon
                width="20"
                height="20"
                fill={palette.button.secondary_fg}
              />
            }
          >
            Add to favourite
          </CustomButton>

          {actionableItems?.action &&
            ((actionableItems?.action === "merge" &&
              actionableItems?.selectedIds?.length > 1) ||
              (actionableItems?.action === "favourite" &&
                actionableItems?.selectedIds?.length > 0) ||
              ((actionableItems?.action === "bulk_update" ||
                actionableItems?.action === "pull") &&
                actionableItems?.selectedIds?.length > 0)) && (
              <CustomButton
                size="lg"
                variant="text"
                color="tertiary"
                onClick={handleApplyButton}
              >
                Apply action
              </CustomButton>
            )}

          {actionableItems?.action && (
            <CustomButton
              size="lg"
              variant="text"
              color="tertiary"
              onClick={() => {
                setActionableItems({
                  selectedIds: [],
                  action: null,
                });
              }}
            >
              Cancel
            </CustomButton>
          )}
        </Box>
        <Box className={styles.flex}>
          <CustomPermission
            permissionsList={Object.values(ContactsPermissions)}
            permissionsId={
              ContactsPermissions.ViewAndSearchContacts.permissionsId
            }
          >
            <Box>
              <SortingDropdown
                isLoading={isLoading}
                setAdvancedSearchBody={setAdvancedSearchBody}
                advancedSearchBody={advancedSearchBody}
                translate={translate}
              />
            </Box>
            <CustomIconButton
              variant="outlined"
              size="md"
              boxShadow="none"
              color="secondary"
              onClick={() => setShowQuickFiltersSection((prev) => !prev)}
            >
              <SettingsFour
                width="20"
                height="20"
                fill={palette.button.secondary_fg}
              />
            </CustomIconButton>

            {viewMode === VIEW_MODES.TABLE && (
              <CustomIconButton
                variant="outlined"
                size="md"
                boxShadow="none"
                color="secondary"
                onClick={() => setIsPinTabOpen((prev) => !prev)}
              >
                <ColumnsThree
                  width="20"
                  height="20"
                  fill={palette.button.secondary_fg}
                />
              </CustomIconButton>
            )}

            <ViewModeSwitcher
              viewMode={viewMode}
              onChangeViewMode={onChangeViewMode}
              isLoading={isLoading}
            />
          </CustomPermission>
        </Box>
      </Box>

      {showQuickFiltersSection && (
        <CustomPermission
          permissionsList={Object.values(ContactsPermissions)}
          permissionsId={
            ContactsPermissions.ViewAndSearchContacts.permissionsId
          }
        >
          <Box className={styles.quickFilters}>
            <Box className={styles.fieldWrapper}>
              <Box className={styles.selectFields}>
                <CustomSelect
                  options={[
                    { id: "Individual", name: "Individual" },
                    { id: "Corporate", name: "Corporate" },
                  ]}
                  onValueChange={handleSelectTypeChange}
                  emptyItem={{
                    id: 0,
                    name: "Select Type",
                    isDisabled: false,
                  }}
                  variant="default"
                  disabled={isLoading}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  defaultValue={getDefaultContactTypeValue()}
                  customSelectClasses={styles.customSelect}
                />

                <CustomSelect
                  isMulti
                  isClearable
                  placeholder="Lead Class"
                  options={Object.values(LeadsClassTypesEnum)}
                  disabled={isLoading}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.key}
                  onValueChange={(selectedValues) => {
                    setAdvancedSearchBody((prev) => ({
                      ...prev,
                      LeadClasses: selectedValues,
                    }));
                  }}
                  defaultValue={advancedSearchBody?.LeadClasses}
                  customSelectClasses={styles.customSelect}
                />

                <CustomSelect
                  options={favoriteFolders}
                  onValueChange={(value) => {
                    setAdvancedSearchBody((prevBody) => {
                      // Create a new criteria based on the value
                      let newCriteria = { ...prevBody.criteria };

                      if (!value) {
                        // If value is undefined or null, delete FavoriteFolderId from criteria
                        delete newCriteria.FavoriteFolderId;
                      } else {
                        // Otherwise, set the FavoriteFolderId with the new value
                        newCriteria.FavoriteFolderId = [
                          { searchType: 0, value },
                        ];
                      }

                      // Only update state if the criteria actually changes
                      if (!_.isEqual(prevBody.criteria, newCriteria)) {
                        return { ...prevBody, criteria: newCriteria };
                      }

                      return prevBody; // Return unchanged state if no actual change
                    });
                  }}
                  emptyItem={{
                    id: 0,
                    name: "My favourite",
                    isDisabled: false,
                  }}
                  variant="default"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.favoriteFolderId}
                  customSelectClasses={styles.customSelect}
                  disabled={isFavoriteFoldersLoading || isLoading}
                  defaultValue={
                    advancedSearchBody?.criteria?.["FavoriteFolderId"]?.[0]
                      ?.value ?? ""
                  }
                />

                <CustomSelect
                  options={[
                    { id: "yes", name: "Yes" },
                    { id: "no", name: "No" },
                  ]}
                  onValueChange={handleContactOpportunityChange}
                  emptyItem={{
                    id: 0,
                    name: "Contact opportunity",
                    isDisabled: false,
                  }}
                  variant="default"
                  disabled={isLoading}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  defaultValue={getDefaultContactOpportunityValue()}
                  customSelectClasses={styles.contactOpportunity}
                />
              </Box>
              <Box>
                <CustomInput
                  type="text"
                  placeholder="Name, Mobile, Email"
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    setAdvancedSearchBody((prev) => {
                      const { All, ...restCriteria } = prev.criteria;
                      // Check if the new value is empty and the previous value was also empty
                      if (!value && !All) {
                        return prev; // No state update needed
                      }
                      return {
                        ...prev,
                        criteria: !value
                          ? restCriteria
                          : {
                              ...restCriteria,
                              All: [{ searchType: 2, value }],
                            },
                      };
                    });
                  }}
                  debounceTime={1000}
                  disabled={isLoading}
                  value={
                    advancedSearchBody.criteria?.All?.[0]?.value
                      ? advancedSearchBody.criteria?.All?.[0]?.value
                      : ""
                  }
                  inputContainerOverrideStyles={styles.customInputContainer}
                />
              </Box>
            </Box>
            <Box
              className={styles.fieldWrapper}
              style={{ justifyContent: "flex-end" }}
            >
              <Box style={{ maxWidth: "165px" }}>
                <CustomSelect
                  width={"165px"}
                  options={[
                    { key: 1, value: translate("created-date") },
                    { key: 2, value: translate("update-on-date") },
                  ]}
                  onValueChange={(e) => setSelectedDateOption(e)}
                  variant="default"
                  disabled={isLoading}
                  getOptionLabel={(option) => option.value}
                  getOptionValue={(option) => option.key}
                  defaultValue={selectedDateOption} // created date by default
                />
              </Box>

              <CustomDateRangePicker
                onClearClicked={() => {
                  setDateFilter(dateRangeDefault);

                  setAdvancedSearchBody((prev) => {
                    const {
                      [fromDateKey]: _,
                      [toDateKey]: __,
                      ...restBody
                    } = prev;

                    return {
                      ...restBody,
                    };
                  });
                }}
                ranges={[dateFilter]}
                onDateChanged={(selectedDate) => {
                  setDateFilter((item) => ({
                    ...item,
                    startDate:
                      selectedDate.selection &&
                      selectedDate.selection.startDate,
                    endDate: !selectedDate.selection?.endDate
                      ? null
                      : new Date(
                          moment(
                            selectedDate.selection &&
                              selectedDate.selection.endDate
                          ).endOf("day")
                        ),
                    key: "selection",
                  }));
                }}
                emptyLabel="dd/mm/yyyy"
                displayFormat="MMM D, YYYY"
                onSave={handleApplyDateFilter}
              />
            </Box>
          </Box>
        </CustomPermission>
      )}

      <RolePinSideTab
        setUpdateSelectedColumnItems={setUpdateSelectedColumnItems}
        open={isPinTabOpen}
        onClose={handleClosePinSideTab}
        allFormFields={allFormFields}
        tableColumns={tableColumns}
      />

      <AddToFavouriteDialog
        open={isFavoriteDialogOpen}
        onClose={() => setIsFavoriteDialogOpen(false)}
        favoriteFolders={favoriteFolders}
        setFavoriteFolders={setFavoriteFolders}
      />
    </>
  );
}

export default QuickFilterSection;
