import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    startAdornmentIcon: {

    },
    endAdornmentIcon: {

    },
    datePickerInput: {

    },
    dialogClass: {
      '& .MuiPickersCalendarHeader-root': {
        display: 'none !important',
      },
    },
  };
});
