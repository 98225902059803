import React, { useEffect, useState } from "react";
import CustomHistoryComponentTabs from "./TabsData/CustomHistoryComponentTabs";
import { CustomTabs } from "../CustomTabs";
import { CustomHistorySideMenu } from "../CustoumHistorySideMenu";
import { Box } from "@material-ui/core";
//styles
import useStyles from "./styles";

function CustomHistoryTabsComponent({
  isOpen,
  isOpenChanged,
  activeItem,
  typeId,
  formType,
  operationType,
  isPropertyManagementView,
  rotationSchemeId,
}) {
  const styles = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [tabsData, setTabsData] = useState([]);
  const [updatedCount, setUpdatedCount] = useState(null);
  const [sideMenuTitle, setSideMenuTitle] = useState("History");
  const [showBackIcon, setShowBackIcon] = useState(false);
  const [componentSwitch, setComponentSwitch] = useState(false);

  const {
    contactHistory,
    propertyHistory,
    propertyManagmentHistory,
    saleUnitHistory,
    leaseUnitHistory,
    unitPropertyManagmentHistory,
    saleLeadsHistory,
    leaseLeadHistory,
    callCenterLeadHistory,
    propertyManagmentLeadHistory,
    LeadsMortgageHistory,
    contactLeadHistory,
    leadOwnerUnitSaleHistory,
    leadOwnerUnitLeaseHistory,
    leadOwnerLeadHistory,
    rotationSchema,
    manageAgents,
  } = CustomHistoryComponentTabs();

  useEffect(() => {
    if (typeId === "contact")
      setTabsData(contactHistory);
    else if (typeId === "property" && !isPropertyManagementView)
      setTabsData(propertyHistory);
    else if (typeId === "property" && isPropertyManagementView)
      setTabsData(propertyManagmentHistory);
    else if (typeId === "unit" && operationType && operationType === "sale")
      setTabsData(saleUnitHistory);
    else if (typeId === "unit" && operationType && operationType === "lease")
      setTabsData(leaseUnitHistory);
    else if (
      typeId === "unit" &&
      operationType &&
      operationType === "unitPropertyManagment"
    )
      setTabsData(unitPropertyManagmentHistory);
    else if (typeId === "lead" && operationType && operationType === "sale")
      setTabsData(saleLeadsHistory);
    else if (typeId === "lead" && operationType && operationType === "lease")
      setTabsData(leaseLeadHistory);
    else if (
      typeId === "lead" &&
      operationType &&
      operationType === "callCenter"
    )
      setTabsData(callCenterLeadHistory);
    else if (
      typeId === "lead" &&
      operationType &&
      operationType === "leadPropertyManagment"
    )
      setTabsData(propertyManagmentLeadHistory);
    else if (
      typeId === "lead" &&
      operationType &&
      operationType === "LeadsMortgage"
    )
      setTabsData(LeadsMortgageHistory);
    else if (
      typeId === "lead" &&
      operationType &&
      operationType === "leadFromContact"
    )
      setTabsData(contactLeadHistory);
    else if (typeId === "leadOwnerUnitSale")
      setTabsData(leadOwnerUnitSaleHistory);
    else if (typeId === "leadOwnerUnitLease")
      setTabsData(leadOwnerUnitLeaseHistory);
    else if (typeId === "leadOwnerLeads")
      setTabsData(leadOwnerLeadHistory);
    else if (typeId === "rotationSchema")
      setTabsData(rotationSchema);
    else if (typeId === "manageAgents")
      setTabsData(manageAgents);
  }, [CustomHistoryComponentTabs]);

  const handleTabChange = (e, newTap) => {
    setActiveTab(newTap);
  };
  const handleUpdatedCountForTabs = ({ totalCount }) => {
    setUpdatedCount(totalCount);
  };

  const handleComponentSwitch = ({
    IsshowBackIcon,
    newTitle,
    isComponentSwitch,
  }) => {
    setShowBackIcon(IsshowBackIcon);
    setSideMenuTitle(newTitle);
    setComponentSwitch(isComponentSwitch);
  };

  const handlegoBackToHistory = () => {
    setShowBackIcon(false);
    setSideMenuTitle("History");
    setComponentSwitch(false);
  };

  useEffect(() => {
    handlegoBackToHistory();
    handleUpdatedCountForTabs({ totalCount: null });
  }, [activeTab]);

  return (
    <CustomHistorySideMenu
      open={isOpen}
      onClose={isOpenChanged}
      title={sideMenuTitle}
      showBackIcon={showBackIcon}
      goBackHandle={handlegoBackToHistory}
      content={
       
          <CustomTabs
            classesname={styles.containerTabsScroller}
            tabValue={activeTab}
            onTabChange={handleTabChange}
            tabs={tabsData}
            dynamicProps={{
              formType,
              typeId,
              handleUpdatedCountForTabs,
              updatedCount,
              handleComponentSwitch,
              componentSwitch,
            }}
          />
      
      }
    />
  );
}
export default CustomHistoryTabsComponent;
