import React, { useCallback, useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { AutocompleteComponent } from "../../../../../../../Components";
import {
  FormsIdsEnum,
  ClientsSegmentationFieldsEnum,
} from "../../../../../../../Enums";
import { GetAllFormFieldTabsByFormId } from "../../../../../../../Services";
import { formItemsBuilder4, getErrorByName } from "../../../../../../../Helper";

export const FieldName = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  item,
  index,
  schema,
  fields,
  setFields,
  SelectedData
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const [formId, setFormId] = useState(0);
  const [formData, setFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      index !== -1 &&
      state.conditions &&
      state.conditions[index] &&
      state.conditions[index].category &&
      state.conditions[index].category === "contacts"
    ) {
      if (
        state.conditions[index].categoryType &&
        state.conditions[index].categoryType === 1
      )
        setFormId(FormsIdsEnum.contactsIndividual.id);
      else if (
        state.conditions[index].categoryType &&
        state.conditions[index].categoryType === 2
      )
        setFormId(FormsIdsEnum.contactsCorporate.id);
      else {
        setFormId(0);
      }
    } else if (
      index !== -1 &&
      state.conditions &&
      state.conditions[index] &&
      state.conditions[index].category &&
      state.conditions[index].category === "leads"
    ) {
      if (
        state.conditions[index].categoryType &&
        state.conditions[index].categoryType === 6
      )
        setFormId(FormsIdsEnum.leadsOwner.id);
      else if (
        state.conditions[index].categoryType &&
        state.conditions[index].categoryType === 7
      )
        setFormId(FormsIdsEnum.leadsSeeker.id);
      else {
        setFormId(0);
      }
    } else if (
      index !== -1 &&
      state.conditions &&
      state.conditions[index] &&
      state.conditions[index].category &&
      state.conditions[index].category === "activities"
    ) {
      if (
        state.conditions[index].categoryType &&
        state.conditions[index].categoryType === 8
      )
        setFormId(FormsIdsEnum.activities.id);
      else {
        setFormId(0);
      }
    }
  }, [state]);

  const getAllFormFieldTabsByFormId = useCallback(async () => {
    setIsLoading(true);

    const result = await GetAllFormFieldTabsByFormId({ formId: formId });
    if (!(result && result.status && result.status !== 200)) {
      setFormData(formItemsBuilder4(result[0]));
    } else {
      setFormData([]);
    }

    setIsLoading(false);
  }, [formId]);

  useEffect(() => {
    if (formId && formId !== 8) getAllFormFieldTabsByFormId();
    else {
      setFormData([]);
      setFields([]);
    }
  }, [getAllFormFieldTabsByFormId, formId]);

  useEffect(() => {
    if (formId && formData.length > 0) {
      let listOfFields = [];
      formData.map((array) => {
        array.map((element) => {
          listOfFields.push(element);
        });
      });
      const list = listOfFields
        .filter(
          (f) =>
            f &&
            f.field &&
            f.field &&
            f.field.FieldType &&
            f.field.FieldType !== "UploadFiles"
        )
        .filter((f2) => f2 && f2.field.id !== "map");
      const sizeAreaTo = {
        data: {
          typy: "string",
          title: "Size Area To",
          description: "Main Information",
          errorMsg: "",
          iconField: "",
          default: "",
          isSearchable: false,
          searchableKey: "",
          displayPath: "sizeAreaTo",
          regExp: "^[+-]?[0-9]+(?:\\.[0-9]+)?$",
          errorMsg: "Fill size Area To in a correct way",
          DtoName: "",
          enum: [],
        },
        field: {
          id: "sizeAreaTo",
          FieldType: "textField",
          isRequired: false,
        },
      };

      const sizeAreaFrom = {
        data: {
          typy: "string",
          title: "Size Area From",
          description: "Main Information",
          minNumber: "200",
          maxNumber: "10000",
          errorMsg: "",
          iconField: "",
          default: "",
          isSearchable: false,
          searchableKey: "",
          displayPath: "sizeAreaFrom",
          regExp: "^[+-]?[0-9]+(?:\\.[0-9]+)?$",
          errorMsg: "Fill size Area From in a correct way",
          DtoName: "",
          enum: [],
        },
        field: {
          id: "sizeAreaFrom",
          FieldType: "textField",
          isRequired: false,
        },
      };

      const budgetTo = {
        data: {
          typy: "string",
          title: "Budget To",
          description: "Main Information",
          errorMsg: "",
          iconField: "",
          default: "",
          isSearchable: false,
          searchableKey: "",
          displayPath: "budgetTo",
          regExp: "^[+-]?[0-9]+(?:\\.[0-9]+)?$",
          errorMsg: "Fill budget To in a correct way",
          DtoName: "",
          enum: [],
        },
        field: {
          id: "budgetTo",
          FieldType: "textField",
          isRequired: false,
        },
      };

      const budgetFrom = {
        data: {
          typy: "string",
          title: "Budget From",
          description: "Main Information",
          displayPath: "budgetFrom",
          regExp: "^[+-]?[0-9]+(?:\\.[0-9]+)?$",
          errorMsg: "Fill budget From in a correct way",
          iconField: "",
          default: "",
          isSearchable: false,
          searchableKey: "",
          DtoName: "",
          enum: [],
        },
        field: {
          id: "budgetFrom",
          FieldType: "textField",
          isRequired: false,
        },
      };

      const sizeAreaSqmTo = {
        data: {
          typy: "string",
          title: "Size Area Sqm To",
          description: "Main Information",
          errorMsg: "",
          iconField: "",
          default: "",
          isSearchable: false,
          searchableKey: "",
          displayPath: "sizeAreaSqmTo",
          regExp: "^[+-]?[0-9]+(?:\\.[0-9]+)?$",
          errorMsg: "Fill size Area Sqm To in a correct way",
          DtoName: "",
          enum: [],
        },
        field: {
          id: "sizeAreaSqmTo",
          FieldType: "textField",
          isRequired: false,
        },
      };

      const sizeAreaSqmFrom = {
        data: {
          typy: "string",
          title: "Size Area Sqm From",
          description: "Main Information",
          minNumber: "200",
          maxNumber: "10000",
          errorMsg: "",
          iconField: "",
          default: "",
          isSearchable: false,
          searchableKey: "",
          displayPath: "sizeAreaSqmFrom",
          regExp: "^[+-]?[0-9]+(?:\\.[0-9]+)?$",
          errorMsg: "Fill size Area Sqm From in a correct way",
          DtoName: "",
          enum: [],
        },
        field: {
          id: "sizeAreaSqmFrom",
          FieldType: "textField",
          isRequired: false,
        },
      };
      let isSizeFound = false;
      let isSizeSqmFound = false;
      let sizeSqmIndex = -1;

      let isBudgetFound = false;
      let sizeIndex = -1;
      let budgetIndex = -1;
      list.map((i, index1) => {
        if (i && i.field.id === "size_sqft") {
          isSizeFound = true;
          sizeIndex = index1;
        } else if (i && i.field && i.field.id === "budget") {
          budgetIndex = index1;
          isBudgetFound = true;
        } else if (i && i.field && i.field.id === "size_sqm") {
          sizeSqmIndex = index1;
          isSizeSqmFound = true;
        }
      });
      if (isSizeFound) {
        list.splice(sizeIndex, 1);
        list.splice(list.length, 0, sizeAreaFrom, sizeAreaTo);
      }
      if (isBudgetFound) {
        list.splice(budgetIndex - 1, 1);
        list.splice(list.length, 0, budgetFrom, budgetTo);
      }

      if (isSizeSqmFound) {
        list.splice(sizeSqmIndex - 1, 1);
        list.splice(list.length, 0, sizeAreaSqmFrom, sizeAreaSqmTo);
      }

      if (formId === 1 || formId === 2) {
        ClientsSegmentationFieldsEnum.Contact.map((element) => {
          list.push(element);
        });
      }
      if (formId === 6) {
        ClientsSegmentationFieldsEnum.OwnerLead.map((element) => {
          list.push(element);
        });
      }
      if (formId === 6 || formId === 7) {
        ClientsSegmentationFieldsEnum.Lead.map((element) => {
          list.push(element);
        });
      }
      setFields(list);
    } else if (formId === 8) {
      let activities = [];
      ClientsSegmentationFieldsEnum.Activities.map((element) => {
        activities.push(element);
      });
      setFields(activities);
    } else setFields([]);
  }, [formData, formId]);

  return (
    <div>
      {item && item.categoryType && (
        <AutocompleteComponent
          idRef={`form${index + 1}-${formId}`}
          multiple={false}
          labelValue={!index ? t(`${translationPath}Segment`) : ""}
          data={fields || []}
          isLoading={isLoading}
          selectedValues={item.fieldName}
          chipsLabel={(option) =>
            (option && option.data && option.data.title) || ""
          }
          displayLabel={(option) =>
            (option && option.data && option.data.title) || ""
          }
          withoutSearchButton
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          error={getErrorByName(schema, "fieldName").error}
          onChange={(event, newValue) => {
            let updatelist = state.conditions;
            updatelist[index] = {
              ...updatelist[index],
              searchKey:
                (newValue && newValue.data && newValue.data.searchableKey) ||
                (newValue && newValue.data && newValue.data.displayPath),
              displayPath:
                (newValue && newValue.data && newValue.data.displayPath) ||
                (newValue && newValue.data && newValue.data.searchableKey),
              fieldName: newValue,
              value: null,
              operator: SelectedData.actionType !== "Condition" ? 1 : null,
            };
            setState({ id: "conditions", value: updatelist });
          }}
        />
      )}
    </div>
  );
};

FieldName.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  translationPathForData: PropTypes.string.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  fields: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  setFields: PropTypes.func.isRequired,
};
