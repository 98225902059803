import React from "react";
import { CustomDialog } from "../../../../CustomDialog";
import { useTranslate } from "../../../../../../Hooks";
import { Box } from "@material-ui/core";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import { CustomCopyToClipboard } from "../../../../CustomCopyToClipboard";


//styles
import useStyles from "./styles";

const SimilarPropertyMatchesDialog = ({ onOpen, onClose }) => {
  const styles = useStyles();
  const { translate } = useTranslate("HeaderView");
  const {
    setDuplicatedProprety,
    duplicatedProprety,
  } = useVerticalNav();

  const AddNewPropretyHandler = () => {
    setDuplicatedProprety ({
        ...duplicatedProprety,
        PropretyFuzzyMatchingPropertiesDialogOpened:false,
        PropretySucsessValidateDialog :true,
    })
  };

  return (
    <CustomDialog
      open={onOpen}
      onClose={() => {
        onClose();
      }}
      title={translate("Similar-property-matches")}
      subtitle={translate(
        "We-found-properties-with-similar-details-Please-review-the-list-to-ensure-the-property-is-unique-before-proceeding"
      )}
      cancelText={translate("Close")}
      confirmText={translate("Add-new-property")}
      onConfirm={() => {
        AddNewPropretyHandler();
      }}
      width={"688px"}
      content={
        <Box className={styles.wrapperSimilarPropertyMatchesDialog}>
          {duplicatedProprety.fuzzyMatchingProperties.length > 0 &&
            duplicatedProprety.fuzzyMatchingProperties.map((similarPropretyItem) => (
              <Box className={styles.containerCardDuplicated}>
                <Box className={styles.wapperPropretyInfo}>
                  <CustomCopyToClipboard
                    data={similarPropretyItem?.propertyId} // copy
                    childrenData={"#" + similarPropretyItem?.propertyId} // render
                    copyDoneShowTime={1000}
                  />
                  <Box className={styles.propretyTitle}>
                    {similarPropretyItem?.propertyName}
                  </Box>
                  <Box className={styles.propretyAddress}>
                    {[
                      similarPropretyItem?.countryId,
                      similarPropretyItem?.cityId,
                      similarPropretyItem?.districtId,
                      similarPropretyItem?.communityId,
                      similarPropretyItem?.subCommunityId,
                    ]
                      .filter(Boolean)
                      .join(" ,")}
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      }
    />
  );
};

export default SimilarPropertyMatchesDialog;
