import React, { useEffect, useRef, useState } from "react";
import { Box, Paper } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { CloseXIcon } from "../../../assets/icons";
import { CustomButton, CustomInput, PhoneNumberInput, EmailInput } from "../..";
import { useSelectedTheme, useTranslate } from "../../../Hooks";
import { useDebouncedAction } from "../../../Hooks/DebouncedAction";
import { CheckContactForDuplicates, GetContacts } from "../../../Services";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";

// Styles
import useStyles from "./styles";

// Validation Schema
const getValidationSchema = (activeCard) => {
  const phoneValidation = Yup.string()
    .required("Phone is required")
    .matches(
      /^\+?[1-9]\d{9,14}$/,
      activeCard === "Individual" ? "Invalid phone number" : "Invalid landline"
    );

  if (activeCard === "Individual") {
    return Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      phone: phoneValidation,
    });
  } else {
    return Yup.object().shape({
      companyName: Yup.string().required("Company name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      phone: phoneValidation,
    });
  }
};

const AddContact = ({ onClose }) => {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");
  const { isDarkMode } = useSelector((state) => state.theme);
  const {
    theme: { palette },
  } = useSelectedTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [activeCard, setActiveCard] = useState("Individual");
  const [isEmailExist, setIsEmailExist] = useState(false);

  const prevValueRef = useRef(""); // Store the previous value
  const formikRef = useRef(null); // Create a ref for Formik

  const initialValues =
    activeCard === "Individual"
      ? {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        }
      : {
          companyName: "",
          email: "",
          phone: "",
        };

  const { setAlertBoxContent, setDuplicatedContact, setAddNewContact } =
    useVerticalNav();

  const handleCardClick = (cardType) => {
    setActiveCard(cardType);
  };

  const checkDuplication = async (value) => {
    const res = await GetContacts({
      pageIndex: 0,
      pageSize: 1,
      search: value,
      isAdvance: false,
    });

    return !isEmpty(res.result);
  };

  const checkDuplicateFields = useDebouncedAction(async ({ value }) => {
    setCheckingEmail(true);

    const exists = await checkDuplication(value);
    if (exists) {
      setIsEmailExist(true);
    } else {
      setIsEmailExist(false);
    }

    setCheckingEmail(false);
  }, 1000);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.resetForm({ values: initialValues });
      prevValueRef.current = "";
    }
  }, [activeCard]); // Trigger when activeCard or initialValues change

  return (
    <Box className={styles.overlay}>
      <Paper className={styles.modal}>
        <Formik
          initialValues={initialValues}
          validationSchema={getValidationSchema(activeCard)}
          onSubmit={async (values) => {
            setIsLoading(true);

            setDuplicatedContact({
              isDuplicate: false,
              matchingContacts: [],
            });

            try {
              const body = {
                contactsType: activeCard === "Individual" ? 1 : 2,
              };
              if (activeCard === "Individual") {
                body.individualCheckDublicate = {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  mobileNumber: values.phone,
                };
              } else {
                body.corporateCheckDublicate = {
                  companyName: values.companyName,
                  landlineNumber: values.phone,
                  companyEmailAddress: values.email,
                };
              }
              const res = await CheckContactForDuplicates(body);

              setDuplicatedContact(res);

              setAddNewContact({
                addContactModalOpened: false,
                contact: values,
              });
            } catch (error) {
              setAlertBoxContent({
                display: true,
                variant: "error",
                title: "Failed to add the contact.",
                onClose: () => {
                  setAlertBoxContent(null);
                },
              });
            } finally {
              setIsLoading(false);
            }
          }}
          innerRef={formikRef} // Attach Formik instance to the ref
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit} autocomplete="off">
              <Box className={styles.scrollDiv}>
                {/* Header Actions */}
                <Box className={styles.content}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <span className={styles.text}>
                      {translate("addNewContacts")}
                    </span>

                    <CloseXIcon
                      onClick={onClose}
                      style={{ cursor: "pointer" }}
                      width="24"
                      height="24"
                      fill={palette.foreground.quinary}
                    />
                  </Box>

                  <p>{translate("addContactsTitle")}</p>
                </Box>

                {/* Body */}
                <Box className={styles.container}>
                  <Box
                    className={`${styles.card} ${
                      activeCard === "Individual" ? styles.activeCard : ""
                    }`}
                    onClick={() => {
                      handleCardClick("Individual");
                    }}
                  >
                    <img
                      width="64px"
                      height="64px"
                      src={`/images/contacts/individualType${
                        isDarkMode ? "Dark" : "Light"
                      }Icon.svg`}
                      alt="IndividualTypeIcon"
                    />

                    <span>{translate("individualText")}</span>
                    <p>{translate("individualContactsText")}</p>
                  </Box>

                  <Box
                    className={`${styles.card} ${
                      activeCard === "Corporate" ? styles.activeCard : ""
                    }`}
                    onClick={() => {
                      handleCardClick("Corporate");
                    }}
                  >
                    <img
                      src={`/images/contacts/corporateType${
                        isDarkMode ? "Dark" : "Light"
                      }Icon.svg`}
                      width="64px"
                      height="64px"
                      alt="CorporateTypeIcon"
                    />

                    <span>{translate("corporateText")}</span>
                    <p>{translate("corporateContactsText")}</p>
                  </Box>
                </Box>

                {activeCard === "Individual" ? (
                  <Box>
                    <Box className={styles.fieldContainer}>
                      <span>{translate("first_name")} </span>
                      <Box className={styles.errorMessageContainer}>
                        <CustomInput
                          name="first-name"
                          hasSearchIcon={false}
                          inputContainerOverrideStyles={styles.inputFullWidth}
                          placeholder="Enter first name"
                          type="text"
                          value={values.firstName}
                          onChange={(e) =>
                            setFieldValue("firstName", e.target.value)
                          }
                          autoComplete="off"
                          hasError={touched.firstName && errors.firstName}
                          errorMessage={
                            touched.firstName && errors.firstName
                              ? errors.firstName
                              : null
                          }
                          submitted={touched.firstName && errors.firstName}
                        />
                      </Box>
                    </Box>

                    <Box className={styles.fieldContainer}>
                      <span>{translate("last_name")} </span>
                      <Box className={styles.errorMessageContainer}>
                        <CustomInput
                          name="last-name"
                          hasSearchIcon={false}
                          inputContainerOverrideStyles={styles.inputFullWidth}
                          placeholder="Enter last name"
                          type="text"
                          value={values.lastName}
                          onChange={(e) =>
                            setFieldValue("lastName", e.target.value)
                          }
                          autoComplete="off"
                          hasError={touched.lastName && errors.lastName}
                          errorMessage={
                            touched.lastName && errors.lastName
                              ? errors.lastName
                              : null
                          }
                          submitted={touched.lastName && errors.lastName}
                        />
                      </Box>
                    </Box>

                    <Box className={styles.fieldContainer}>
                      <span>{translate("phone_number")} </span>
                      <Box className={styles.errorMessageContainer}>
                        <PhoneNumberInput
                          name="phoneNumber-ref"
                          idRef="phoneNumberRef"
                          inputPlaceholder="+1 (555) 000-0000"
                          value={values.phone}
                          country="ae"
                          onInputChanged={(value) => {
                            setFieldValue("phone", value);
                          }}
                          error={
                            touched.phone && errors.phone
                              ? errors.phone
                              : undefined
                          }
                        />
                      </Box>
                    </Box>

                    <Box className={styles.fieldContainer}>
                      <span>{translate("email")}</span>
                      <Box className={styles.errorMessageContainer}>
                        <EmailInput
                          idRef={"email-ref"}
                          isLoading={checkingEmail}
                          isDisabled={checkingEmail}
                          value={values.email}
                          helperText={
                            isEmailExist ? translate("duplicate_email") : ""
                          }
                          error={
                            touched.email && errors.email ? errors.email : null
                          }
                          errorMessage={
                            touched.email && errors.email ? errors.email : null
                          }
                          onInputBlur={(e) => {
                            // Check if the value has changed
                            const currentValue = e.target.value;
                            setTimeout(() => {
                              if (currentValue !== prevValueRef.current) {
                                prevValueRef.current = currentValue; // Update the previous value
                                if (
                                  currentValue &&
                                  !(touched.email && errors.email)
                                ) {
                                  checkDuplicateFields({ value: currentValue }); // Call the function
                                }
                              }
                            }, 800);
                          }}
                          inputPlaceholder="Enter email"
                          onInputChanged={(newValue) => {
                            setIsEmailExist(false);
                            setFieldValue("email", newValue);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box className={styles.fieldContainer}>
                      <span>{translate("email")}</span>
                      <Box className={styles.errorMessageContainer}>
                        <EmailInput
                          idRef={"email-ref"}
                          isLoading={checkingEmail}
                          isDisabled={checkingEmail}
                          value={values.email}
                          helperText={
                            isEmailExist ? translate("duplicate_email") : ""
                          }
                          error={
                            touched.email && errors.email ? errors.email : null
                          }
                          errorMessage={
                            touched.email && errors.email ? errors.email : null
                          }
                          onInputBlur={(e) => {
                            // Check if the value has changed
                            const currentValue = e.target.value;
                            setTimeout(() => {
                              if (currentValue !== prevValueRef.current) {
                                prevValueRef.current = currentValue; // Update the previous value
                                if (
                                  currentValue &&
                                  !(touched.email && errors.email)
                                ) {
                                  checkDuplicateFields({ value: currentValue }); // Call the function
                                }
                              }
                            }, 800);
                          }}
                          inputPlaceholder="Enter email"
                          onInputChanged={(newValue) => {
                            setIsEmailExist(false);
                            setFieldValue("email", newValue);
                          }}
                        />
                      </Box>
                    </Box>

                    <Box className={styles.fieldContainer}>
                      <span>{translate("works-company-name")}</span>
                      <Box className={styles.errorMessageContainer}>
                        <CustomInput
                          name="company-name"
                          hasSearchIcon={false}
                          inputContainerOverrideStyles={styles.inputFullWidth}
                          placeholder="Enter Company name"
                          type="text"
                          value={values.companyName}
                          onChange={(e) =>
                            setFieldValue("companyName", e.target.value)
                          }
                          autoComplete="off"
                          hasError={touched.companyName && errors.companyName}
                          errorMessage={
                            touched.companyName && errors.companyName
                              ? errors.companyName
                              : null
                          }
                          submitted={touched.companyName && errors.companyName}
                        />
                      </Box>
                    </Box>

                    <Box className={styles.fieldContainer}>
                      <span>{translate("landline")} </span>
                      <Box className={styles.errorMessageContainer}>
                        <PhoneNumberInput
                          name="landlineRef"
                          idRef="landlineRef"
                          inputPlaceholder="+1 (555) 000-0000"
                          value={values.phone}
                          country="ae"
                          onInputChanged={(value) => {
                            setFieldValue("phone", value);
                          }}
                          error={
                            touched.phone && errors.phone
                              ? errors.phone
                              : undefined
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                )}

                {/* Footer Actions */}
                <Box className={styles.footerActions}>
                  <CustomButton
                    variant="outlined"
                    color="secondary"
                    onClick={onClose}
                  >
                    {translate("CANCEL_BUTTON_LABEL")}
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading || checkingEmail}
                  >
                    {translate("Add")}
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default AddContact;
