import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { InfoItem, InfoSection } from "..";
import { useSelectedTheme, useTranslate } from "../../../../../Hooks";

// Styles
import useStyles from "./styles";

function DetailsTab({ activeItem }) {
  const {
    theme: { palette },
  } = useSelectedTheme();

  const { translate } = useTranslate("NewPropertiesView");

  const styles = useStyles();

  return (
    <>
      <InfoSection title={translate("Property details")}>
        <InfoItem
          label={translate("Master developer")}
          value={activeItem?.master_developer?.name}
        />
        <InfoItem
          label={translate("Owner")}
          value={activeItem?.property_owner || "N/A"}
        />
        <InfoItem
          label={translate("Handover date")}
          value={moment(activeItem?.handover_date).format("DD-MM-YYYY")}
        />

        <InfoItem
          label={translate("Property type")}
          value={activeItem?.property_type?.lookupItemName || "N/A"}
        />

        <InfoItem
          label={translate("Property usage")}
          value={activeItem?.property_usage || "N/A"}
        />

        <InfoItem
          label={translate("COUNTRY")}
          value={activeItem?.country?.lookupItemName || "N/A"}
          countryCode={activeItem?.country?.lookupItemCode}
        />
        <InfoItem
          label={translate("CITY")}
          value={activeItem?.city?.lookupItemName || "N/A"}
        />

        <InfoItem
          label={translate("District")}
          value={activeItem?.district?.lookupItemName || "N/A"}
        />
        <InfoItem
          label={translate("COMMUNITY")}
          value={activeItem?.community?.lookupItemName || "N/A"}
        />

        <InfoItem
          label={translate("CREATED_BY")}
          value={activeItem?.createdBy}
        />
        <InfoItem
          label={translate("CREATED_DATE")}
          value={moment(activeItem?.createdOn).format("DD-MM-YYYY")}
        />
      </InfoSection>
    </>
  );
}

DetailsTab.propTypes = {
  activeItem: PropTypes.objectOf(PropTypes.any),
};

DetailsTab.defaultProps = {
  activeItem: {},
};

export default DetailsTab;
