import React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  ArrowNarrowLeft,
  ArrowNarrowRight,
} from "../../../../../../../assets/icons";
import { useSelectedTheme } from "../../../../../../../Hooks";
import { CustomIconButton } from "../../../../../CustomIconButton";

//styles
import useStyles from "./styles";

const CustomPaginationHistorySideMenu = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}) => {
  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleIncrease = () => {
    if (currentPage < totalPages - 1) {
      onPageChange(currentPage + 1);
    }
  };

  const handleDecrease = () => {
    if (currentPage > 0) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <Box className={styles.containerPaginationHistory}>
      <Box className={styles.containerArrow}>
        <CustomIconButton
          variant="text"
          size="none"
          boxShadow="none"
          color="tertiaryColor"
          hideHoverBg
          onClick={() => {
            handleDecrease();
          }}
          disabled={currentPage === 0} 
        >
          <ArrowNarrowLeft
            width="20"
            height="20"
            fill={palette.foreground.navigation["nav-item-button-icon-fg"]}
          />
        </CustomIconButton>
      </Box>

      <Typography className={styles.pageCountTitle}>
       {`Page ${currentPage + 1} of ${totalPages}`}
      </Typography>

      <Box className={styles.containerArrow}>
        <CustomIconButton
          variant="text"
          size="none"
          boxShadow="none"
          color="tertiaryColor"
          hideHoverBg
          onClick={() => {
            handleIncrease();
          }}
          disabled={currentPage === totalPages - 1} 
        >
          <ArrowNarrowRight
            width="20"
            height="20"
            fill={palette.foreground.navigation["nav-item-button-icon-fg"]}
          />
        </CustomIconButton>
      </Box>
    </Box>
  );
};
export default CustomPaginationHistorySideMenu;
