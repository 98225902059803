export const AssignmentLeadRule = (value, setData, itemList, formType) => {
  const referredToIndex = itemList.indexOf(
    itemList.find((f) => f.field.id === 'referredto')
  );

  if (referredToIndex !== -1) {
    if (formType === 1 && value === 'Yes')
      setData(itemList[referredToIndex].field.id, {});
    else if (formType && formType === 2 && value && value === 'Yes')
      setData(null, 0, 'referredto');
  }
};
