import React, { useState, useEffect, useContext, useCallback } from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import { historyTableHeaderData } from "./historyTableHeaderData";
import {
  BasicTable,
  TableSkeleton,
  CustomPagination,
  NoDataFound,
  KYCHistoryTableActions,
} from "../../../Components";
import { useQuery } from "../../../Hooks";
import { getDownloadableLink } from "../../../Helper";
import { GetAllSharedKycDocuments } from "../../../Services";
import { TableActions } from "../../../Enums";
import { useNewLayoutShared } from "../../../Contexts/NewLayoutSharedContext";

function KYCHistory() {
  const query = useQuery();
  const contactId = Number(query.get("id"));
  const formType = Number(query.get("formType"));

  const { isDarkMode } = useSelector((state) => state.theme);
  const [pagination, setPagination] = useState({
    pageSize: 25,
    pageIndex: 1,
  });
  const [inputValue, setInputValue] = useState(pagination.pageIndex);
  const [isLoading, setIsLoading] = useState(false);
  const [kycDocs, setKycDocs] = useState([]);

  const getAllSharedKycDocuments = async () => {
    setIsLoading(true);
    const res = await GetAllSharedKycDocuments({
      contactId,
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
    });
    if (!(res && res.status && res.status !== 200)) {
      setKycDocs(res || []);
    } else {
      setKycDocs([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllSharedKycDocuments();
  }, [pagination]);

  const downloadPdfFile = (pdfFile) => {
    try {
      const linkElement = document.createElement("a");
      linkElement.setAttribute("download", pdfFile?.documentName);
      linkElement.href = getDownloadableLink(pdfFile?.documentId);

      document.body.appendChild(linkElement);
      linkElement.click();
      linkElement.remove();
    } catch (error) {}
  };

  const { kycHistoryActions, setKycHistoryActions } = useNewLayoutShared();

  const tableActionClicked = useCallback(
    (actionEnum) => {
      // Ensure the active item exists before proceeding
      if (!kycHistoryActions?.activeItem) {
        return;
      }

      if (actionEnum === TableActions.openFile.key) {
        setKycHistoryActions((prev) => ({
          ...prev,
          isViewOpen: true,
        }));
      }

      if (actionEnum === TableActions.share.key) {
        setKycHistoryActions((prev) => ({
          ...prev,
          isShareOpen: true,
        }));
      }

      if (actionEnum === TableActions.RiskRating.key) {
        setKycHistoryActions((prev) => ({
          ...prev,
          isRiskRatingClicked: true,
        }));
      }
      if (actionEnum === TableActions.agentApprovedCheck.key) {
        if (
          kycHistoryActions?.activeItem?.sharedKycDocmentHistoryId &&
          formType
        ) {
          window.open(
            `/share/agent-approval-kyc?documentId=${kycHistoryActions?.activeItem?.sharedKycDocmentHistoryId}&formType=${formType}`,
            "_blank"
          );
        } else {
          console.error("Missing documentId or formType.");
        }
      }

      if (actionEnum === TableActions.downloadText.key) {
        try {
          downloadPdfFile(kycHistoryActions?.activeItem);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [kycHistoryActions?.activeItem]
  );

  return (
    <Box>
      <Box>
        {isLoading ? (
          <TableSkeleton rowsNum={12} />
        ) : kycDocs?.result?.length > 0 ? (
          <BasicTable
            rowsData={kycDocs?.result?.map((item, index) => ({
              ...item,
              id: index + 1,
            }))}
            setActiveItemOnRowClick={(item) => {
              setKycHistoryActions((prev) => ({
                ...prev,
                activeItem: {
                  ...item,
                  templateFileId: item?.documentFileGuid || null,
                  templateFileName: "KycDocumentTemplate.pdf",
                  templateText: "",
                  documentName: "KycDocumentTemplate.pdf",
                  documentId: item?.documentFileGuid,
                },
              }));
            }}
            tableActions={({ anchorPosition }) => {
              return (
                <KYCHistoryTableActions
                  onShare={() => {
                    tableActionClicked("share");
                  }}
                  onDownload={() => {
                    tableActionClicked("downloadText");
                  }}
                  onChangeRating={() => {
                    tableActionClicked("RiskRating");
                  }}
                  onPreview={() => {
                    tableActionClicked("openFile");
                  }}
                  onApproval={
                    kycHistoryActions?.activeItem?.postStatusInfo?.enumName.toLowerCase() ===
                      "approved" &&
                    kycHistoryActions?.activeItem?.agentPostStatusInfo?.enumName.toLowerCase() ===
                      "pending"
                      ? () => tableActionClicked("agentApprovedCheck")
                      : null
                  }
                  anchorPosition={anchorPosition}
                />
              );
            }}
            rowHeight={84}
            columns={historyTableHeaderData(isDarkMode)}
          />
        ) : (
          <NoDataFound title="History" />
        )}
      </Box>

      <CustomPagination
        hideInMobile
        totalItems={kycDocs?.result?.totalCount}
        itemsPerPage={pagination.pageSize}
        currentPage={pagination.pageIndex}
        inputValue={inputValue}
        setInputValue={setInputValue}
        onPageChange={(page) =>
          setPagination((prev) => ({ ...prev, pageIndex: page }))
        }
        onItemsPerPageChange={(items) =>
          setPagination((prev) => ({ ...prev, pageSize: items }))
        }
        isLoading={isLoading}
      />
    </Box>
  );
}

export default KYCHistory;
