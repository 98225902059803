import React, {
    useEffect, useCallback, useState, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../Hooks';
import { LeadAuditorComponent } from './LeadAuditorComponent'
import { AgentsComponent } from './AgentsComponent'
import {
    Spinner,
    ActionsButtonsComponent,
    PaginationComponent,
    PermissionsComponent,
} from '../../../Components';
import { ActionsButtonsEnum } from '../../../Enums';
import { GetAllLeadAuditors, GetAllLeadAuditorAgents, RemoveAgentsFromLeadAuditor, GetTeam, GetAllAgentsServices, GetAllLeadAuditorsByName } from '../../../Services';
import { GetAllTeamsByTeamLeaderId } from '../../../Services/Team';
import { GetAllTeamLeadUsers } from '../../../Services/userServices';
import { showError, showSuccess, bottomBoxComponentUpdate, returnPropsByPermissions } from '../../../Helper';
import './AdminAssignComponent.scss'
import { OpenAssignDialog } from './OpenAssignDialog';
import { LeadOwnerAdminAssignAgentPermissions } from '../../../Permissions';

export const AdminAssignComponent = () => {
    const parentTranslationPath = 'leadOwnerView';
    const translationPath = '';
    const { t } = useTranslation(parentTranslationPath);
    const searchTimer = useRef();
    useTitle(t(`${translationPath}AdminAssign`));

    const [isLoading, setIsLoading] = useState({
        all: false,
        getAllLeadAuditor: false,
        getAllAgents: false,
        getAllTeamLeads: false,
        getAllTeam: false,
        getAllAgentResult: false
    });
    const [currPage, setCurrPage] = useState(10);
    const [withCheckbox, setWithCheckbox] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [leadAuditorId, setLeadAuditorId] = useState(null);
    const [filterByAgent, setFilterByAgent] = useState(null);
    const [isOpenAssignDialog, setIsOpenAssignDialog] = useState(false);
    const [lastList, setLastList] = useState(false);
    const [filterByTeams, setFilterByTeams] = useState(null)
    const [allTeams, setAllTeams] = useState([])
    const [allTeamLeads, setAllTeamLeads] = useState(null)
    const [filterByTeamLead, setFilterByTeamLead] = useState(null);
    const [allAgents, setAllAgents] = useState(null);
    const [isAssignAgent, setIsAssignAgent] = useState(null);
    const [checkedCards, setCheckedCards] = useState('');
    const [checkedCardsIds, setCheckedCardsIds] = useState('');
    const [teamSearch, setTeamSearch] = useState('');
    const [teamLeadSearch, setTeamLeadSearch] = useState('');
    const [agentSearch, setAgentSearch] = useState('');
    const [leadAuditorSearch, setLeadAuditorSearch] = useState('');
    const [filter, setFilter] = useState({
        pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
        pageIndex: 0,
    });

    const [leadAuditorOptions, setLeadAuditorOptions] = useState({
        result: [],
        totalCount: 0
    });
    const [allLeadAuditor, setAllLeadAuditor] = useState({
        result: [],
        totalCount: 0
    });
    const [allLeadAuditorAgents, setAllLeadAuditorAgents] = useState({
        result: [],
        totalCount: 0
    });

    const searchHandler = (event) => {
        const { value } = event.target;
        if (searchTimer.current) clearTimeout(searchTimer.current);

        if (event.target.id === 'TeamLeadFilterRef') searchTimer.current = setTimeout(() => {
            setTeamLeadSearch(value)
        }, 700);
        if (event.target.id === 'TeamFilterRef') searchTimer.current = setTimeout(() => {
            setTeamSearch(value)
        }, 700);
        if (event.target.id === 'agentFilterRef') searchTimer.current = setTimeout(() => {
            setAgentSearch(value)
        }, 700);
        if (event.target.id === 'LeadAuditorRef') searchTimer.current = setTimeout(() => {
            setLeadAuditorSearch(value)
        }, 700);
    };
    const onActionButtonChanged = (activeAction) => {
        setWithCheckbox(false)
        setCheckedCards([]);
        setCheckedCardsIds([]);
        onPageIndexChanged(0);
        if (activeAction === ActionsButtonsEnum[9].id) {
            setWithCheckbox(true);
            setSelectedAction(activeAction)
        }
        if (activeAction === ActionsButtonsEnum[10].id && leadAuditorId) {
            setWithCheckbox(activeAction);
            setSelectedAction(activeAction)
        }
        if (selectedAction === activeAction) {
            setWithCheckbox(false)
            setSelectedAction(null)
        }
    };
    const onActionsButtonClicked = useCallback(
        (activeAction) => {
            if (activeAction === ActionsButtonsEnum[9].id) {
                setIsOpenAssignDialog(true);
                setLeadAuditorSearch('');
            }
            if (activeAction === ActionsButtonsEnum[10].id) {
                removeAgentsFromLeadAuditor();
            }
        }, [checkedCards, checkedCardsIds]);

    const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
    };
    const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    };

    const cardCheckboxClicked = useCallback((element) => {
        setCheckedCards((items) => {
            const index = items.findIndex((item) => item.agentID === element.agentID);
            if (index !== -1) items.splice(index, 1);
            else items.push(element);
            return [...items];
        });
        setCheckedCardsIds((items) => {
            const index = items.findIndex((item) => item === element.agentID);
            if (index !== -1) items.splice(index, 1);
            else items.push(element.agentID);
            return [...items];
        });
    }, []);

    const removeAgentsFromLeadAuditor = useCallback(async () => {
        const body = {
            leadAuditorId: leadAuditorId,
            agentsIds: checkedCardsIds
        }
        const res = await RemoveAgentsFromLeadAuditor(body);
        if (!(res && res.status && res.status !== 200)) {
            showSuccess(t(`${translationPath}Unassign-to-lead-owner-Successfully`));
            getAllLeadAuditorAgents();
            setCheckedCards([]);
            setCheckedCardsIds([]);
            setIsAssignAgent(null);
        } else {
            showError(t(`${translationPath}faild-to-unAssign-to-lead-owner`))
            getAllLeadAuditorAgents();
            setCheckedCards([]);
            setCheckedCardsIds([]);
            setIsAssignAgent(null);
        }
    }, [leadAuditorId, checkedCardsIds]);

    const getAllLeadAuditors = useCallback(async (filterByLeadAuditor) => {
        if (returnPropsByPermissions(LeadOwnerAdminAssignAgentPermissions.ViewAdminAssignAgents.permissionsId)) {
            setIsLoading(((loading) => ({ ...loading, all: true })));
            const body = {
                leadAuditorId: filterByLeadAuditor && filterByLeadAuditor.leadAuditorId,
            }
            const res = await GetAllLeadAuditors(body, filter.pageIndex + 1, currPage)
            if (!(res && res.status && res.status !== 200)) {
                setAllLeadAuditor({
                    result: res.result,
                    totalCount: res.totalCount
                })
            }
            else {
                setAllLeadAuditor({
                    result: [],
                    totalCount: 0
                })
            }
            setIsLoading(((loading) => ({ ...loading, all: false })));
        }
    }, [currPage]);

    const getAllLeadAuditorAgents = useCallback(async () => {
        if (returnPropsByPermissions(LeadOwnerAdminAssignAgentPermissions.ViewAdminAssignAgents.permissionsId)) {
            setIsLoading(((loading) => ({ ...loading, getAllAgentResult: true })));
            const body = {
                leadAuditorId: leadAuditorId,
                agentId: filterByAgent && filterByAgent.agentId,
                teamId: filterByTeams && filterByTeams.teamsId,
                teamLeadId: filterByTeamLead && filterByTeamLead.usersId,
                isAssignAgent: isAssignAgent
            }
            const res = await GetAllLeadAuditorAgents(body, filter.pageIndex + 1, filter.pageSize)
            if (!(res && res.status && res.status !== 200)) {
                setAllLeadAuditorAgents({
                    result: res.result,
                    totalCount: res.totalCount
                })
                setLastList(true);
            }
            else {
                setAllLeadAuditorAgents({
                    result: [],
                    totalCount: 0
                })
            }
            setIsLoading(((loading) => ({ ...loading, getAllAgentResult: false })));
        }
    }, [leadAuditorId, filter, filterByAgent, filterByTeams, filterByTeamLead, isAssignAgent]);

    const reloadPage = () => {
        if (leadAuditorId) setLeadAuditorId(null)
        else getAllLeadAuditorAgents();
        getAllLeadAuditors();
        setCheckedCards([]);
        setCheckedCardsIds([]);
        setIsAssignAgent(null);
    }

    const getAllTeamLeadUsers = useCallback(async () => {
        setIsLoading(((loading) => ({ ...loading, getAllTeamLeads: true })));

        const res = await GetAllTeamLeadUsers({ pageIndex: 1, pageSize: 25, search: teamLeadSearch });
        if (!(res && res.status && res.status !== 200)) setAllTeamLeads(res.result)
        else setAllTeamLeads([])
        setIsLoading(((loading) => ({ ...loading, getAllTeamLeads: false })));
    }, [teamLeadSearch]);

    const getAllTeamsByTeamLeaderId = useCallback(async () => {
        setIsLoading(((loading) => ({ ...loading, getAllTeam: true })));
        const payLoad = {
            pageIndex: 1,
            pageSize: 10,
            userId: (filterByTeamLead && filterByTeamLead.usersId) || null,
            search: teamSearch || ''
        }
        const res = await GetAllTeamsByTeamLeaderId(payLoad);
        if (!(res && res.status && res.status !== 200)) setAllTeams(res)
        else setAllTeams([])
        setIsLoading(((loading) => ({ ...loading, getAllTeam: false })));
    }, [filterByTeamLead, teamSearch]);

    const getAllAgents = useCallback(async () => {
        setIsLoading(((loading) => ({ ...loading, getAllAgents: true })));
        const res = await GetAllAgentsServices({ pageIndex: 1, pageSize: 25, agentName: agentSearch });
        if (!(res && res.status && res.status !== 200)) setAllAgents(res.result)
        else setAllAgents([])
        setIsLoading(((loading) => ({ ...loading, getAllAgents: false })));
    }, [agentSearch]);

    const getAllLeadAuditorsByName = useCallback(async () => {
        setIsLoading(((loading) => ({ ...loading, getAllLeadAuditor: true })));
        const res = await GetAllLeadAuditorsByName(leadAuditorSearch, 1, 10);
        if (!(res && res.status && res.status !== 200)) setLeadAuditorOptions({
            result: res.result,
            totalCount: res.length
        })
        else setLeadAuditorOptions({
            result: [],
            totalCount: 0
        })
        setIsLoading(((loading) => ({ ...loading, getAllLeadAuditor: false })));
    }, [leadAuditorSearch]);

    useEffect(() => {
        try {
            const fetchData = async () => {
                setIsLoading(((loading) => ({ ...loading, all: true })));
                const res = await GetAllLeadAuditors({}, 1, currPage)
                if (!(res && res.status && res.status !== 200)) {
                    setAllLeadAuditor({
                        result: res.result || [],
                        totalCount: res.totalCount || 0
                    })
                }
                setIsLoading(((loading) => ({ ...loading, all: false })));
            };

            if (!lastList && !isLoading.all) fetchData();
        } catch (error) {
            console.error('error', error);
        }
    }, [currPage, lastList]);
    useEffect(() => {
        if (returnPropsByPermissions(LeadOwnerAdminAssignAgentPermissions.ViewAdminAssignAgents.permissionsId)) {
            bottomBoxComponentUpdate(
                <PaginationComponent
                    pageIndex={filter.pageIndex}
                    pageSize={filter.pageSize}
                    totalCount={(allLeadAuditorAgents && allLeadAuditorAgents.totalCount) || 0}
                    onPageIndexChanged={onPageIndexChanged}
                    onPageSizeChanged={onPageSizeChanged}
                />
            );
        }

    });

    useEffect(() => {
        getAllTeamsByTeamLeaderId();
    }, [getAllTeamsByTeamLeaderId])
    useEffect(() => {
        getAllTeamLeadUsers();
    }, [getAllTeamLeadUsers])
    useEffect(() => {
        getAllLeadAuditorAgents()
    }, [getAllLeadAuditorAgents]);
    useEffect(() => {
        getAllAgents()
    }, [getAllAgents]);
    useEffect(() => {
        getAllLeadAuditorsByName()
    }, [getAllLeadAuditorsByName]);


    return (

        <div className='view-wrapper'>
            <Spinner isActive={isLoading.all || isLoading.getAllAgentResult} />
            <div className='header-section'>
                <PermissionsComponent
                    permissionsList={Object.values(LeadOwnerAdminAssignAgentPermissions)}
                    permissionsId={LeadOwnerAdminAssignAgentPermissions.AssignUnAssignAgents.permissionsId}>
                    <ActionsButtonsComponent
                        enableAssign={true}
                        closeAction
                        enableUnAssign={leadAuditorId && true}
                        typeData={[
                            { id: '1', name: 'select' },
                        ]}
                        setSelectedAction={setSelectedAction}
                        assignmentAction='select'
                        onActionsButtonClicked={onActionsButtonClicked}
                        onActionButtonChanged={onActionButtonChanged}
                        withText='select'
                        selectedAction={selectedAction}
                        wrapperClasses={'action-btn'}
                    />
                </PermissionsComponent>
            </div>
            <PermissionsComponent
                permissionsList={Object.values(LeadOwnerAdminAssignAgentPermissions)}
                permissionsId={LeadOwnerAdminAssignAgentPermissions.ViewAdminAssignAgents.permissionsId}>

                <div className='assignment-crads-wrapper d-flex m-5'>
                    <LeadAuditorComponent
                        allLeadAuditor={allLeadAuditor}
                        setLeadAuditorId={setLeadAuditorId}
                        leadAuditorId={leadAuditorId}
                        setCheckedCardsIds={setCheckedCardsIds}
                        setCheckedCards={setCheckedCards}
                        reloadPage={reloadPage}
                        currPage={currPage}
                        setLastList={setLastList}
                        setCurrPage={setCurrPage}
                        isLoading={isLoading}
                        getAllLeadAuditors={getAllLeadAuditors}
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                        searchHandler={searchHandler}
                        isAssignAgent={isAssignAgent}
                        setIsAssignAgent={setIsAssignAgent}
                        leadAuditorOptions={leadAuditorOptions}
                    />
                    <AgentsComponent
                        allLeadAuditorAgents={allLeadAuditorAgents}
                        allLeadAuditor={allLeadAuditor}
                        withCheckbox={withCheckbox}
                        checkedCardsIds={checkedCardsIds}
                        cardCheckboxClicked={cardCheckboxClicked}
                        filterByAgent={filterByAgent}
                        setFilterByAgent={setFilterByAgent}
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                        filterByTeams={filterByTeams}
                        setFilterByTeams={setFilterByTeams}
                        searchHandler={searchHandler}
                        allTeams={allTeams}
                        filterByTeamLead={filterByTeamLead}
                        setFilterByTeamLead={setFilterByTeamLead}
                        allTeamLeads={allTeamLeads}
                        allAgents={allAgents}
                        isAssignAgent={isAssignAgent}
                        setIsAssignAgent={setIsAssignAgent}
                        setLeadAuditorId={setLeadAuditorId}
                        isLoading={isLoading}

                    />
                </div>
            </PermissionsComponent>

            <OpenAssignDialog
                isOpen={isOpenAssignDialog}
                setIsOpenAssignDialog={setIsOpenAssignDialog}
                data={leadAuditorOptions}
                checkedCardsIds={checkedCardsIds}
                reloadPage={reloadPage}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                searchHandler={searchHandler}
                isLoading={isLoading.getAllLeadAuditor}
            />
        </div>

    );
};
