import React from "react";

function SilverIcon({ fill, ...restProps }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M20.0003 3.71V13.29C20.0003 16.05 17.7603 18.29 15.0003 18.29H5.00031C4.54031 18.29 4.10031 18.23 3.67031 18.11C3.05031 17.94 2.85031 17.15 3.31031 16.69L13.9403 6.06C14.1603 5.84 14.4903 5.79 14.8003 5.85C15.1203 5.91 15.4703 5.82 15.7203 5.58L18.2903 3C19.2303 2.06 20.0003 2.37 20.0003 3.71Z"
        fill="#98A2B3"
      />
      <path
        opacity="0.4"
        d="M12.64 5.36002L2.17 15.83C1.69 16.31 0.89 16.19 0.57 15.59C0.2 14.91 0 14.12 0 13.29V3.71002C0 2.37002 0.77 2.06002 1.71 3.00002L4.29 5.59002C4.68 5.97002 5.32 5.97002 5.71 5.59002L9.29 2.00002C9.68 1.61002 10.32 1.61002 10.71 2.00002L12.65 3.94002C13.03 4.33002 13.03 4.97002 12.64 5.36002Z"
        fill="#98A2B3"
      />
    </svg>
  );
}

export default SilverIcon;
