import React from "react";
import { FormControlLabel, Radio } from "@material-ui/core";
import clsx from "clsx";

// styles
import useStyles from "./styles";

const CustomRadioButton = ({ value,label, checked, onChange, ...props }) => {
  const styles = useStyles();

  return (
    <FormControlLabel
      className={styles.containerLableCheck}
      control={
        <Radio
          value={value}
          checked={checked}
          className={styles.radioRoot}
          onChange={onChange}
          disableRipple
          color="default"
          checkedIcon={
            <span className={clsx(styles.radioIcon, styles.radioCheckedIcon)} />
          }
          icon={<span className={styles.radioIcon} />}
          {...props}
        />
      }
      label={label}
    />
  );
};

export default CustomRadioButton;
