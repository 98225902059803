import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import {
  BasicTable,
  TableEditDeleteActions,
  ContactCard,
  ContactCardSkeleton,
  CustomButton,
  CustomCheckbox,
  CustomIconButton,
  CustomPagination,
  NoDataFound,
  TableSkeleton,
  VIEW_MODES,
  ViewModeSwitcher,
  CustomTableHeader,
} from "../../../../../../Components";
import {
  contactsDetailsGet,
  DiscardContact,
  GetAllContactUnitTransactions,
  GetAllFormFieldsByFormId,
  GetCustomDuplicateContact,
  GetDuplicateCriteria,
} from "../../../../../../Services";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import { useQuery, useSelectedTheme } from "../../../../../../Hooks";
import { ContactsMapper } from "../../../../ContactsView";
import {
  CorporateContactDuplicatesCriteriaEnum,
  FormsIdsEnum,
  IndividualContactDuplicatesCriteriaEnum,
  TableFilterOperatorsEnum,
} from "../../../../../../Enums";
import { ContactDuplicatesTableHeaderData } from "./ContactDuplicatesTableHeaderData";
import ShowHideTableColumns from "./UI/ShowHideTableColumns";
import { flattenObject } from "../../../../../../Helper";

// Styles
import useStyles from "./styles";

// Icons
import {
  CloseXIcon,
  ColumnsThree,
  MergeContactsIcon,
  EditOne,
  TrashOne,
} from "../../../../../../assets/icons";

function ContactDocuments() {
  const styles = useStyles();

  const { isDarkMode } = useSelector((state) => state.theme);

  const dispatch = useDispatch();

  const history = useHistory();

  const { setAlertBoxContent, actionableItems, setActionableItems } =
    useVerticalNav();

  const {
    theme: { palette },
  } = useSelectedTheme();

  const initialCriteriaState = {
    Corporate: {
      companyName: false,
      email: false,
      mobile: false,
    },
    Individual: {
      firstName: false,
      lastName: false,
      email: false,
      mobile: false,
      nationality: false,
      idNumber: false,
      passportNumber: false,
    },
  };

  // Criteria Reducer function
  const criteriaReducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_CRITERIA":
        return {
          ...state,
          [action.payload.contactType]: {
            ...state[action.payload.contactType],
            [action.payload.field]: action.payload.value,
          },
        };
      case "SET_ALL_CRITERIA":
        return {
          ...action.payload,
        };
      default:
        return state;
    }
  };

  const [viewMode, setViewMode] = useState(VIEW_MODES.GRID);

  const [isDiscardChanged, setIsDiscardChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isDuplicateCriteriaLoading, setIsDuplicateCriteriaLoading] =
    useState(true);
  const [isContactDetailsLoading, setIsContactDetailsLoading] = useState(true);

  const [isWithDiscard, setIsWithDiscard] = useState(false);

  const [criteriaState, dispatchCriteriaAction] = useReducer(
    criteriaReducer,
    initialCriteriaState
  );

  const [duplicatesContacts, setDuplicatesContacts] = useState({
    result: [],
    totalCount: 0,
  });
  const [contactDetails, setContactDetails] = useState(null);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 25,
  });
  const [inputValue, setInputValue] = useState(pagination.currentPage);

  const [tableColumns, setTableColumns] = useState(
    ContactDuplicatesTableHeaderData(isDarkMode)
  );
  const [updateSelectedColumnItems, setUpdateSelectedColumnItems] =
    useState(tableColumns);

  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState(
    ContactDuplicatesTableHeaderData(isDarkMode)
      .filter((item) => item.isDefaultFilterColumn)
      .map((column) => column.id)
  );
  const [activeItemTableSelected, setActiveItemTableSelected] = useState(null);

  const [allFormFields, setAllFormFields] = useState([]);
  const [isFormFieldsLoading, setIsFormFieldsLoading] = useState(true);
  const [isPinTabOpen, setIsPinTabOpen] = useState(false);
  const [filterModalData, setFilterModalData] = useState({
    selectedId: undefined,
    data: [],
    selectedColumns: [],
  });

  const flattenRows = (rows) => rows.map((row) => flattenObject(row));
  const flattenedRows = flattenRows([...duplicatesContacts?.result]);

  const MatchContactMapper = (contact) => ({
    Corporate: {
      companyName: contact?.company_name || "",
      email: contact?.general_email?.email || "",
      mobile: contact?.landline_number?.phone || "",
    },
    Individual: {
      firstName: contact?.first_name || "",
      lastName: contact?.last_name || "",
      email: contact?.email_address?.email || "",
      mobile: contact?.mobile?.phone || "",
      nationality: contact?.nationality?.lookupItemName || "",
      idNumber: contact?.id_card_no || "",
      passportNumber: contact?.passport_no || "",
    },
  });

  const query = useQuery();
  const contactId = query.get("id");
  const formTypeId = query.get("formType");

  const isIndividual = formTypeId === "1";
  const isCorporate = formTypeId === "2";

  const individualFieldLabels = {
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    mobile: "Mobile",
    nationality: "Nationality",
    idNumber: "ID number",
    passportNumber: "Passport number",
  };

  const corporateFieldLabels = {
    companyName: "Company name",
    email: "Email",
    mobile: "Mobile",
  };

  const getCustomDuplicateContact = useCallback(async () => {
    if (!contactDetails) return;

    setIsLoading(true);

    let localCriteria = {};

    if (isIndividual) {
      Object.keys(criteriaState.Individual).forEach((item) => {
        const isCriteriaSelected = criteriaState.Individual[item];
        const criteriaDisplayPath =
          IndividualContactDuplicatesCriteriaEnum[item]?.displayPath;
        if (isCriteriaSelected && criteriaDisplayPath) {
          localCriteria[criteriaDisplayPath] = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: contactDetails.Individual[item],
            },
          ];
        }
      });
    } else if (isCorporate) {
      Object.keys(criteriaState.Corporate).forEach((item) => {
        const isCriteriaSelected = criteriaState.Corporate[item];
        const criteriaDisplayPath =
          CorporateContactDuplicatesCriteriaEnum[item]?.displayPath;
        if (isCriteriaSelected && criteriaDisplayPath) {
          localCriteria[criteriaDisplayPath] = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: contactDetails.Corporate[item],
            },
          ];
        }
      });
    }

    const body = {
      pageIndex: pagination.currentPage,
      pageSize: pagination.itemsPerPage,
      matchContactId: contactId,
      withDiscard: isWithDiscard,
      criteria: localCriteria,
    };

    const res = await GetCustomDuplicateContact(body);
    if (!(res && res.status && res.status !== 200)) {
      setDuplicatesContacts({
        result: ((res && res.result) || []).map((item) =>
          ContactsMapper(item, JSON.parse(item.contactJson).contact)
        ),
        totalCount: (res && res.totalCount) || 0,
      });
      setIsDiscardChanged(false);
    } else {
      setDuplicatesContacts({
        result: [],
        totalCount: 0,
      });
      setIsDiscardChanged(false);
    }
    setIsLoading(false);
  }, [
    pagination,
    criteriaState,
    isWithDiscard,
    contactDetails,
    contactId,
    isIndividual,
    isCorporate,
  ]);

  const getContactDetails = useCallback(async () => {
    setIsContactDetailsLoading(true);

    const res = await contactsDetailsGet({ id: contactId });
    if (!(res && res.status && res.status !== 200)) {
      if (res) {
        setContactDetails(MatchContactMapper(res.contact));
      }
    } else {
      setContactDetails({});
    }
    setIsContactDetailsLoading(false);
  }, [contactId]);

  const getDuplicateCriteria = useCallback(async () => {
    setIsDuplicateCriteriaLoading(true);

    const res = await GetDuplicateCriteria();
    if (!(res && res.status && res.status !== 200)) {
      const contactDuplicateCriteria = res.find(
        (item) => item.contactDuplicateCriteriaId === +formTypeId
      );

      if (contactDuplicateCriteria) {
        dispatchCriteriaAction({
          type: "SET_ALL_CRITERIA",
          payload: {
            Corporate: {
              companyName: contactDuplicateCriteria.companyName,
              email: contactDuplicateCriteria.email,
              mobile: contactDuplicateCriteria.mobile,
            },
            Individual: {
              firstName: contactDuplicateCriteria.firstName,
              lastName: contactDuplicateCriteria.lastName,
              email: contactDuplicateCriteria.email,
              mobile: contactDuplicateCriteria.mobile,
              nationality: contactDuplicateCriteria.nationality,
              idNumber: contactDuplicateCriteria.idNumber,
              passportNumber: contactDuplicateCriteria.passportNumber,
            },
          },
        });
      }
    }
    setIsDuplicateCriteriaLoading(false);
  }, [formTypeId]);

  useEffect(() => {
    if (
      !isDuplicateCriteriaLoading &&
      !isContactDetailsLoading &&
      contactDetails &&
      criteriaState
    ) {
      getCustomDuplicateContact();
    }
  }, [
    isDuplicateCriteriaLoading,
    isContactDetailsLoading,
    pagination,
    contactId,
    criteriaState,
    contactDetails,
    getCustomDuplicateContact,
  ]);

  const getUniqueFields = (fields) => {
    return fields.filter(
      (e) =>
        e.formFieldName !== "nationality" && e.formFieldName !== "email_address"
    );
  };

  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsFormFieldsLoading(true);
    try {
      const [contactsIndividualFields, contactsCorporateFields] =
        await Promise.all([
          GetAllFormFieldsByFormId(FormsIdsEnum.contactsIndividual.id),
          GetAllFormFieldsByFormId(FormsIdsEnum.contactsCorporate.id),
        ]);

      if (
        Array.isArray(contactsIndividualFields) &&
        Array.isArray(contactsCorporateFields)
      ) {
        const concantinateFields = [
          ...contactsIndividualFields,
          ...contactsCorporateFields,
        ]
          .filter(
            (field, index, array) =>
              array.findIndex(
                (element) => element.formFieldKey === field.formFieldKey
              ) === index
          )
          .filter(
            (e) =>
              e.formFieldName !== "company_logoimage" &&
              e.formFieldName !== "contact_image" &&
              e.formFieldName !== "contact_classifications" &&
              e.formFieldName !== "contact_preference" &&
              e.formFieldName !== "map" &&
              e.formFieldName !== "contacts_person"
          )
          .map((field) => ({
            ...field,
            id: field.formFieldId || null,
            key: field.formFieldKey || null,
            isDate: field.uiWidgetType === "alt-date" || false,
            disableColumnMenu: true,
            minWidth: 190,
            headerName:
              (field.formFieldTitle && field.formFieldTitle.replace("*", "")) ||
              "",
            field: field.displayPath || "",
            fieldType:
              field?.uiWidgetType === "alt-date"
                ? "datePicker"
                : field?.uiWidgetType,
            isNumber:
              (field.propertyJson &&
                JSON.parse(field.propertyJson).schema &&
                field.propertyJson &&
                JSON.parse(field.propertyJson).schema.specialKey ===
                  "currency") ||
              (field.propertyJson &&
                JSON.parse(field.propertyJson).schema &&
                field.propertyJson &&
                JSON.parse(field.propertyJson).schema.specialKey ===
                  "decimal") ||
              (field.propertyJson &&
                JSON.parse(field.propertyJson).schema &&
                field.propertyJson &&
                JSON.parse(field.propertyJson).schema.specialKey === "size"),
            searchableKey: field.searchableKey,
            renderHeader: (params) => (
              <CustomTableHeader
                params={undefined}
                title={
                  (field.formFieldTitle &&
                    field.formFieldTitle.replace("*", "")) ||
                  ""
                }
              />
            ),
            isSortable: false,
            filterable: false,
          }));

        const fieldsList = getUniqueFields(concantinateFields);
        setAllFormFields(fieldsList);
      } else {
        setAllFormFields([]);
      }
    } catch (error) {
      console.error("Error fetching form fields:", error);
      setAllFormFields([]);
    } finally {
      setIsFormFieldsLoading(false);
    }
  }, []);

  useEffect(() => {
    setTableColumns([
      ...ContactDuplicatesTableHeaderData(isDarkMode).filter(
        (item) =>
          selectedTableFilterColumns.findIndex(
            (element) => element === item.id
          ) !== -1
      ),
      ...allFormFields
        .filter(
          (item) =>
            selectedTableFilterColumns.findIndex(
              (element) => element === item.formFieldId
            ) !== -1
        )
        .map((field) => ({
          id: field.formFieldId || null,
          key: field.formFieldKey || null,
          isDate: field.uiWidgetType === "alt-date" || false,
          label:
            (field.formFieldTitle && field.formFieldTitle.replace("*", "")) ||
            "",
          input: field.displayPath || "",
        })),
    ]);
  }, [allFormFields, selectedTableFilterColumns]);

  useEffect(() => {
    getDuplicateCriteria();
    getContactDetails();
    getAllFormFieldsByFormId();
  }, []);

  const createDiscardContact = async (discardContactId) => {
    try {
      await DiscardContact({ contactId, discardContactId });

      // Update duplicatesContacts state to remove the discarded contact
      setDuplicatesContacts((prevState) => ({
        ...prevState,
        result: prevState.result.filter(
          (contact) => contact.id !== discardContactId
        ),
        totalCount: prevState.totalCount - 1,
      }));

      setAlertBoxContent({
        display: true,
        variant: "success",
        title: "Contact Duplicate Discarded Successfully!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });

      setActiveItemTableSelected(null);
    } catch (error) {
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to Discard Contact Duplicate!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    }
  };

  const getAllContactUnitTransactions = useCallback(async () => {
    const res = await GetAllContactUnitTransactions(
      {
        pageIndex: pagination.currentPage - 1,
        pageSize: pagination.itemsPerPage,
      },
      contactId
    );

    if (!(res && res.status && res.status !== 200)) {
      const isContactHasTransactions =
        res && res.result && res.result.length > 0;
      return isContactHasTransactions;
    }
  }, [pagination]);

  const handleApply = async () => {
    if (
      actionableItems.action === "merge" &&
      actionableItems?.selectedIds?.length > 1
    ) {
      const isOriginalWithTransactions = await getAllContactUnitTransactions();

      const filteredContacts = actionableItems?.selectedIds
        ?.filter((el) => el?.isWithTransaction !== 1) // Filter elements where isWithTransaction !== 1
        ?.slice(0, 10);

      const userTypeId = duplicatesContacts?.result?.find(
        (contact) => contact?.contactId || contact?.id === filteredContacts[0]
      )?.userTypeId;

      dispatch({
        type: "CONTACTS_MERGE_REQUEST",
        payload: {
          ContactsMergeIds: filteredContacts,
          isOriginalWithTransactions,
        },
      });

      history.push(`/home/Contacts-CRM/merge?userTypeId=${userTypeId}`);
    }
  };

  return (
    <Box>
      <Box className={styles.actionsContainer}>
        <Box className={styles.mergeActionWrapper}>
          <Box className={styles.selectedActionWrapper}>
            {actionableItems?.selectedIds?.length > 0 && (
              <>
                <CustomIconButton
                  variant="text"
                  size="none"
                  boxShadow="none"
                  color="tertiaryColor"
                  hideHoverBg
                >
                  <CloseXIcon
                    onClick={() =>
                      setActionableItems({
                        selectedIds: [],
                        action: null,
                      })
                    }
                    width="20"
                    height="20"
                    fill={palette.button.secondary_fg}
                  />
                </CustomIconButton>
                <Box className={styles.selectedCount}>
                  {actionableItems?.selectedIds?.length} selected
                </Box>
              </>
            )}
          </Box>
          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="outlined"
            color="secondary"
            onClick={() => {
              setActionableItems({
                selectedIds: [],
                action: "merge",
              });
            }}
            startIcon={
              <MergeContactsIcon
                width="20"
                height="20"
                fill={palette.button.secondary_fg}
              />
            }
            hasToolTip={true}
            toolTipMessage="You can only select up to 10 contacts, and obsolete contacts can't have transactions."
          >
            Merge
          </CustomButton>

          {actionableItems?.action &&
            actionableItems?.action === "merge" &&
            actionableItems?.selectedIds?.length > 1 && (
              <CustomButton
                size="lg"
                variant="text"
                color="tertiary"
                onClick={handleApply}
              >
                Apply action
              </CustomButton>
            )}

          {actionableItems?.action && (
            <CustomButton
              size="lg"
              variant="text"
              color="tertiary"
              onClick={() => {
                console.log("cancel clicked");
              }}
            >
              Cancel
            </CustomButton>
          )}
        </Box>
        <Box className={styles.viewModeWrapper}>
          {viewMode === VIEW_MODES.TABLE && (
            <CustomIconButton
              variant="outlined"
              size="md"
              boxShadow="none"
              color="secondary"
              onClick={() => setIsPinTabOpen((prev) => !prev)}
            >
              <ColumnsThree
                width="20"
                height="20"
                fill={palette.button.secondary_fg}
              />
            </CustomIconButton>
          )}
          <ViewModeSwitcher
            viewMode={viewMode}
            onChangeViewMode={(value) => setViewMode(value)}
            isLoading={false}
          />
        </Box>
      </Box>
      <Box className={styles.criteriaFiltersWrapper}>
        {isIndividual &&
          Object.keys(criteriaState.Individual).map((field) => (
            <Box className={styles.filteredItemWrapper} key={field}>
              <CustomCheckbox
                checked={criteriaState.Individual[field]}
                onChange={() => {
                  dispatchCriteriaAction({
                    type: "UPDATE_CRITERIA",
                    payload: {
                      contactType: "Individual",
                      field,
                      value: !criteriaState.Individual[field],
                    },
                  });
                }}
                disabled={isLoading}
              />
              <span>{individualFieldLabels[field]}</span>
            </Box>
          ))}
        {isCorporate &&
          Object.keys(criteriaState.Corporate).map((field) => (
            <Box className={styles.filteredItemWrapper} key={field}>
              <CustomCheckbox
                checked={criteriaState.Corporate[field]}
                onChange={() => {
                  dispatchCriteriaAction({
                    type: "UPDATE_CRITERIA",
                    payload: {
                      contactType: "Corporate",
                      field,
                      value: !criteriaState.Corporate[field],
                    },
                  });
                }}
                disabled={isLoading}
              />
              <span>{corporateFieldLabels[field]}</span>
            </Box>
          ))}

        <Box className={styles.filteredItemWrapper}>
          <CustomCheckbox
            checked={isWithDiscard}
            onChange={() => setIsWithDiscard(!isWithDiscard)}
            disabled={isLoading}
          />
          <span>With discard</span>
        </Box>
      </Box>
      <Box>
        {viewMode === VIEW_MODES.GRID && (
          <Box className={styles.gridContainer}>
            {isLoading ? (
              Array.from({ length: 25 }).map((_, index) => (
                <ContactCardSkeleton index={index} />
              ))
            ) : duplicatesContacts?.result?.length > 0 ? (
              duplicatesContacts?.result?.map((contact) => (
                <ContactCard
                  item={contact}
                  setActiveItem={(active) => {
                    console.log("setActiveItem", active);
                  }}
                  customMenuOptions={[
                    {
                      label: "Edit",
                      icon: (
                        <EditOne
                          width="16"
                          height="16"
                          fill={palette.foreground.quarterary}
                        />
                      ),
                      handleOnclick: () => {
                        history.push(
                          `/home/Contacts-CRM/contact-profile-edit?formType=${formTypeId}&id=${contact?.id}`
                        );
                      },
                    },
                    {
                      label: "Discard Duplicate",
                      icon: (
                        <TrashOne
                          width="16"
                          height="16"
                          fill={palette.foreground.quarterary}
                        />
                      ),
                      handleOnclick: () => {
                        createDiscardContact(contact?.id);
                      },
                    },
                  ]}
                />
              ))
            ) : (
              <NoDataFound title="duplicate contacts" />
            )}
          </Box>
        )}

        {viewMode === VIEW_MODES.TABLE && (
          <Box>
            {isLoading ? (
              <TableSkeleton rowsNum={12} />
            ) : duplicatesContacts?.result?.length > 0 ? (
              <BasicTable
                tableActions={({ anchorPosition }) => {
                  if (Boolean(activeItemTableSelected)) {
                    return (
                      <TableEditDeleteActions
                        anchorPosition={anchorPosition}
                        onDelete={() => {
                          createDiscardContact(activeItemTableSelected?.id);
                        }}
                        onEdit={() => {
                          history.push(
                            `/home/Contacts-CRM/contact-profile-edit?formType=${formTypeId}&id=${activeItemTableSelected?.id}`
                          );
                        }}
                      />
                    );
                  }
                }}
                pageSize={pagination.itemsPerPage}
                rowsData={flattenedRows || []}
                setActiveItemOnRowClick={(contact) => {
                  setActiveItemTableSelected(contact);
                }}
                columns={updateSelectedColumnItems?.map((column) => ({
                  ...column,
                  renderHeader: (params) => (
                    <CustomTableHeader
                      params={params}
                      title={column.headerName}
                      isHiddenFilter
                    />
                  ),
                }))}
                rowHeight={84}
                borderRadius="12px 12px 12px 12px"
              />
            ) : (
              <NoDataFound />
            )}
          </Box>
        )}
      </Box>

      <CustomPagination
        hideInMobile
        totalItems={duplicatesContacts?.totalCount}
        itemsPerPage={pagination.itemsPerPage}
        currentPage={pagination.currentPage}
        inputValue={inputValue}
        setInputValue={setInputValue}
        onPageChange={(page) =>
          setPagination((prev) => ({ ...prev, currentPage: page }))
        }
        onItemsPerPageChange={(items) =>
          setPagination((prev) => ({ ...prev, itemsPerPage: items }))
        }
        isLoading={isLoading}
      />
      <ShowHideTableColumns
        setUpdateSelectedColumnItems={setUpdateSelectedColumnItems}
        open={isPinTabOpen}
        onClose={() => setIsPinTabOpen(false)}
        allFormFields={ContactDuplicatesTableHeaderData(isDarkMode).concat(
          allFormFields.filter(
            (item) =>
              ContactDuplicatesTableHeaderData(isDarkMode).findIndex(
                (element) =>
                  element?.fieldKey === item?.formFieldKey ||
                  element?.fieldKey === item?.displayPath
              ) === -1
          )
        )}
        tableColumns={updateSelectedColumnItems}
        filterModalData={filterModalData}
        setFilterModalData={setFilterModalData}
      />
    </Box>
  );
}

export default ContactDocuments;
