import React, {
  useEffect,
  useState,
  useReducer,
  useCallback,
  useRef,
} from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError } from "../../../../Helper";
import { MicrosoftToken } from "../../../../Services";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import { SelectComponet } from "../../../../Components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ButtonBase, ButtonGroup, CircularProgress } from "@material-ui/core";
import { CellsSpinner } from "../../../../SharedComponents/CellSpinnerComponent/CellsSpinner";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";
import { PowerBiEnum } from "../../../../Enums";

const parentTranslationPath = "LeadsView";
const translationPath = "";

export const AdminLeadPool = ({ startDatecmp2, endDatecmp2 }) => {
  const { t } = useTranslation(parentTranslationPath);
  const [ShowReport2, setShowReport2] = useState(false);
  const [ShowReportdata, setShowReportdata] = useState(false);
  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);

  const [monthsAgo, setMonthsAgo] = useState(1);
  const [bookmarks, setBookmarks] = useState([]);
  const [error, setError] = useState(null);

  const [Assigndate, setAssigndate] = useState(false);
  const [Leads, setLeads] = useState(false);

  const [data, setdata] = useState([]);
  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };
  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const currentDate = new Date();
  const countmonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - monthsAgo)
  );

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate()));
  const count2 = new Date(new Date().setDate(today.getDate()));
  const datetimes = moment(count)
    .startOf("month")
    .format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (startDatecmp2 &&
          moment(startDatecmp2).startOf("day").format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
      toDate:
        (endDatecmp2 &&
          moment(endDatecmp2).endOf("day").format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [startDatecmp2, endDatecmp2]);

  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "LeadPoolBi",
      column: "CreatedOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setShowReport(true);
      setReport(res);
      // setRender(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };

  const loadBookmarks = async () => {
    const report = window.report;
    let pages = await report.getPages();

    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        const fetchedBookmarks = await bookmarksManager.getBookmarks();
        setBookmarks(fetchedBookmarks);
        
         
      } catch (error) {
        console.error("Error loading bookmarks:", error);
        setError(error);
      }
    }
  };

  const Pagenavi = async (pagestate) => {
    const report = window.report;
    let pages = await report.getPages();

    if (report) {
      try {
        report.setPage(pagestate);
      } catch (error) {
        console.error("Error applying:", error);
      }
    }
  };

  useEffect(() => {
    Microsoft(false);
  }, []);

  return (
    <div className="dashboardMain-PowerBIEmbed">
      <div>
        <div className="mainCardDashboards">
          {!render ? (
            <div className="loadingDiv">
              <CellSpinnerBi isActive={!ShowReport2} isAbsolute blue />
            </div>
          ) : (
            <div></div>
          )}

          <div className="dashboardMain cardDashboard2">
            <CellSpinnerBi isActive={ShowReportdata} isAbsolute blue wi />
            <PowerBIEmbed
              embedConfig={{
                type: "report",
                id: PowerBiEnum.LeadPool.reportid,
                embedUrl: PowerBiEnum.LeadPool.url,
                accessToken: report,
                filters: [advancedFilter],
                settings: {
                  customLayout: {
                    displayOption: models.DisplayOption.FitToWidth,
                  },
                  filterPaneEnabled: false,
                  navContentPaneEnabled: false,
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                  background: models.BackgroundType.Transparent,
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "loaded",
                    (event) => {
                      setRender(true);
                    },
                  ],
                  [
                    "dataSelected",

                    (event) => {
                      setShowReportdata(true);
                    },
                  ],

                  [
                    "rendered",
                    (event) => {
                      setShowReport2(true);
                      setShowReportdata(false);

                      // loadBookmarks();
                    },
                  ],

                  ["pageChanged", (event) => {}],
                  ["visualRendered", (event) => {}],

                  [
                    "error",
                    (event) => {
                      const error = event.detail;
                      if (
                        error.detailedMessage === "Get report failed" ||
                        error.detailedMessage ===
                          "Access token has expired, resubmit with a new access token" ||
                        error.detailedMessage ===
                          "Fail to initialize - Could not resolve cluster"
                      ) {
                        Microsoft(true);
                        // setRender(false);
                      } else console.log(error.detailedMessage);
                      showError(error.detailedMessage);
                    },
                  ],
                ])
              }
              cssClassName={
                ShowReport
                  ? "report-style-class-Show"
                  : "report-style-class-hide"
              }
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
