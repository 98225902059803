import React, { useCallback, useState } from "react";
import { CustomTimeline } from "../../../../../CustomTimeline";
import moment from "moment";
import { Box, Typography } from "@material-ui/core";
import { RequestTypeEnum } from "../../../../../../../Enums";
import { useSelectedTheme, useTranslate } from "../../../../../../../Hooks";

//styles
import useStyles from "./styles";
import { CustomIconButton } from "../../../../../CustomIconButton";
import {
  ArrowNarrowRight,
  DropdownIcon,
} from "../../../../../../../assets/icons";
import { getDownloadableLink, setLoading } from "../../../../../../../Helper";

function ContactHistoryTimeLine({
  timelineItems,
  formsContent,
  typeId,
  formType,
  pathName,
}) {
  return (
    <CustomTimeline
      timelineData={timelineItems}
      component={TimelineItemContent}
      componentProps={{ formsContent, typeId, formType, pathName }}
      timelinePrefixComponent={TimelinePrefixContent}
      timelineDotTopMargin="15px"
    />
  );
}
export default ContactHistoryTimeLine;

function TimelineItemContent({
  item,
  formsContent,
  typeId,
  formType,
  pathName,
}) {
  const { translate } = useTranslate("NewContactsView");
  const {
    theme: { palette },
  } = useSelectedTheme();
  const styles = useStyles();

  const [isOpenTransactionDetails, setIsOpenTransactionDetails] =
    useState(false);
  const [openTransactionId, setOpenTransactionId] = useState(null);
  const [changeFields, setChangeFields] = useState([]);

  const findRequestType = (requestType) => {
    const requestTypeName = RequestTypeEnum.find(
      (item) => item.key === requestType
    );
    if (requestTypeName) return requestTypeName.value;
    return "Read";
  };

  const displayTransactionDetails = (item) => {
    setOpenTransactionId((prev) =>
      prev === item?.dfMTransactionId ? null : item?.dfMTransactionId
    );
  };
  const contactFieldChangeMapper = useCallback(
    (itemValue) => {
      setLoading(true);
      const changes = [];
      const beforeChangeArr = JSON.parse(itemValue.response)[typeId];
      const afterChangeArr = JSON.parse(itemValue.responseAfterChanged);

      formsContent.map(({ type, el }) => {
        const item = beforeChangeArr[el];
        const afterChangeValue = afterChangeArr && afterChangeArr[el];

        let isEqual = true;
        isEqual = JSON.stringify(item) === JSON.stringify(afterChangeArr[el]);

        if (!item && !afterChangeArr[el]) isEqual = true;

        if (!isEqual) {
          if (
            (item && item.lookupItemName) ||
            (afterChangeValue && afterChangeValue.lookupItemName)
          ) {
            changes.push({
              fieldName: el
                .replace("_", " ")
                .replace("-", " ")
                .replace("-", " ")
                .replace("-", " "),
              changedFrom: item && item.lookupItemName,
              changedTo: afterChangeValue && afterChangeValue.lookupItemName,
            });
          } else if (
            (item && item.phone && type !== "searchField") ||
            (afterChangeValue &&
              afterChangeValue.phone &&
              type !== "searchField")
          ) {
            changes.push({
              fieldName: el
                .replace("_", " ")
                .replace("_", " ")
                .replace("_", " ")
                .replace("_", " "),
              changedFrom: item && item.phone && displayPhones(item),
              changedTo:
                afterChangeValue &&
                afterChangeValue.phone &&
                displayPhones(afterChangeValue),
            });
          } else if (
            (item && item.name) ||
            (afterChangeValue && afterChangeValue.name)
          ) {
            changes.push({
              fieldName: el
                .replace("_", " ")
                .replace("_", " ")
                .replace("_", " ")
                .replace("_", " "),
              changedFrom: item && item.name,
              changedTo: afterChangeValue && afterChangeValue.name,
            });
          } else if (
            (item && item.email) ||
            (afterChangeValue && afterChangeValue.email)
          ) {
            changes.push({
              fieldName: el
                .replace("_", " ")
                .replace("_", " ")
                .replace("_", " ")
                .replace("_", " "),
              changedFrom: item && item.email && displayEmailAddress(item),
              changedTo:
                afterChangeValue &&
                afterChangeValue.email &&
                displayEmailAddress(afterChangeValue),
            });
          } else {
            changes.push({
              fieldName: el
                .replace("_", " ")
                .replace("_", " ")
                .replace("_", " ")
                .replace("_", " "),
              changedFrom: item,
              changedTo: afterChangeValue,
            });
          }
        }
        return changes;
      });
      setChangeFields(() => changes.length > 0 && changes);
      setLoading(false);
    },
    [formsContent, pathName]
  );

  const displayEmailAddress = (emailObject) => {
    let allEmails = [];
    if (emailObject && emailObject.email) allEmails.push(emailObject.email);

    if (emailObject && emailObject.others) {
      emailObject.others.map((emailItem) => {
        allEmails.push(emailItem);
      });
    }
    return allEmails;
  };

  const displayPhones = (phoneObject) => {
    let allPhones = [];
    if (phoneObject && phoneObject.phone) allPhones.push(phoneObject.phone);

    if (phoneObject && phoneObject.others) {
      phoneObject.others.map((phoneItem) => {
        allPhones.push(phoneItem);
      });
    }
    return allPhones;
  };
  const getAvatarSrc = (item) => {
    if (item?.createdByImage) {
      return getDownloadableLink(item?.createdByImage);
    }
    const parsedResponse = item?.response ? JSON.parse(item.response) : null;
    const gender = parsedResponse?.contact?.gender?.lookupItemName;
    return gender === "Female"
      ? "/images/contacts/FemaleAvatar.svg"
      : "/images/contacts/MaleAvatar.svg";
  };
  const objectIfIsArray = (list) =>
    list &&
    list.map((item, index) => (
      <span>
        {" "}
        {(item &&
          (item.lookupItemName ||
            item.name ||
            (item.email_address && item.email_address.email) ||
            item.phone ||
            item.fullName)) ||
          item}{" "}
        {list.length - 1 != index && <span> , </span>}
      </span>
    ));

  return (
    <Box className={styles.wrapperHistoryTimelineItemContent}>
      <Box className={styles.HistoryTimelineItemContent}>
        <Box className={styles.containerHistoryInformation}>
          <Box className={styles.containerHeaderHistory}>
            <Box className={styles.containerImage}>
              <img className={styles.imageWrapper} src={getAvatarSrc(item)} />
            </Box>
            <Typography className={styles.createdByTitle}>
              {item?.createdByUserName}
            </Typography>
          </Box>
          <Box className={styles.containerTypeHistory}>
            <Typography className={styles.typeTitle}>
              {translate("type")} :
            </Typography>
            <Typography className={styles.typeValue}>
              {findRequestType(item?.requestType)}
            </Typography>
          </Box>
        </Box>
        {item && findRequestType(item.requestType) === "Update" && (
          <CustomIconButton
            variant="text"
            size="none"
            boxShadow="none"
            color="tertiaryColor"
            hideHoverBg
            onClick={() => {
              if (openTransactionId !== item?.dfMTransactionId) {
                contactFieldChangeMapper(item);
              }
              displayTransactionDetails(item);
            }}
          >
            <DropdownIcon
              width="20"
              height="20"
              fill={palette.foreground.navigation["nav-item-button-icon-fg"]}
              style={{
                transform:
                  openTransactionId === item?.dfMTransactionId
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            />
          </CustomIconButton>
        )}
      </Box>
      {changeFields &&
        changeFields?.length > 0 &&
        openTransactionId === item.dfMTransactionId && (
          <Box className={styles.containerTableContactFieldChange}>
            <Box className={styles.headerTable}>
              <Box className={styles.tableCellTitle}>
                {translate("Field-name")}
              </Box>
              <Box className={styles.tableCellTitle}>
                {translate("Changed-from")}
              </Box>
              <Box className={styles.tableCellTitle}> </Box>
              <Box className={styles.tableCellTitle}>
                {translate("Changed-to")}
              </Box>
            </Box>
            {changeFields.map((item) => (
              <Box className={styles.bodyTable}>
                <Box className={styles.tableCellTitle}>{item.fieldName}</Box>
                <Box className={styles.tableCellValue}>
                  {(item.changedFrom &&
                    (typeof item.changedFrom === "string" ||
                      typeof item.changedFrom === "number") &&
                    item.changedFrom) ||
                    (typeof item.changedFrom === "object" &&
                    item.changedFrom !== null
                      ? Array.isArray(item.changedFrom)
                        ? objectIfIsArray(item.changedFrom)
                        : JSON.stringify(item.changedFrom)
                      : "")}
                </Box>
                <ArrowNarrowRight
                  width="20"
                  height="20"
                  fill={palette.text.tertiary}
                  className={styles.tableCellTitle}
                />
                <Box className={styles.tableCellValue}>
                  {(item.changedTo &&
                    (typeof item.changedTo === "string" ||
                      typeof item.changedTo === "number") &&
                    item.changedTo) ||
                    (typeof item.changedTo === "object" &&
                    item.changedTo !== null
                      ? Array.isArray(item.changedTo)
                        ? objectIfIsArray(item.changedTo)
                        : JSON.stringify(item.changedTo)
                      : "")}
                </Box>
              </Box>
            ))}
          </Box>
        )}
    </Box>
  );
}
function TimelinePrefixContent({ item }) {
  const styles = useStyles();
  const formatedDateTime = (dateTime) => {
    if (!dateTime) return { day: null, time: null, year: null };
    const day = moment(dateTime).locale("en").format("D MMM");
    const year = moment(dateTime).locale("en").format("YYYY");
    const time = moment(dateTime).locale("en").format("h:mm a");

    return { day, time, year };
  };
  return (
    <Box className={styles.containerTimelinePrefixContent}>
      <Box className={styles.dayTitle}>
        {formatedDateTime(item.createdOn).day}
      </Box>
      <Box className={styles.yearTitle}>
        {formatedDateTime(item.createdOn).year}
      </Box>
      <Box className={styles.timeTitle}>
        {formatedDateTime(item.createdOn).time}
      </Box>
    </Box>
  );
}
