/* eslint-disable no-nested-ternary */
import React, {
  useState, useCallback, useEffect, useRef
} from 'react';
import Button from '@material-ui/core/Button';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import i18next from 'i18next';
import { Tooltip, ButtonBase } from '@material-ui/core';
import {
  AMLStatusEnum,
  ActionsEnum,
  ContactTypeEnum,
  LoadableImageEnum,
  UnitsOperationTypeEnum,
  UnitsStatusEnum,
} from '../../../../../Enums';
import {
  GlobalHistory,
  showError,
  showinfo,
  showSuccess,
  sideMenuIsOpenUpdate,
  returnPropsByPermissions,
  returnPropsByPermissions2,
  returnPropsByPermissions3,
  getDownloadableLink
} from '../../../../../Helper';
import {
  DialogComponent,
  FacebookGalleryComponent,
  LoadableImageComponant,
  SelectComponet,
  Spinner,
} from '../../../../../Components';
import {
  archiveUnitsPut,
  CanSetUnitAsReservedOrLeasedOrSale,
  GetUnitAvailableStatus,
} from '../../../../../Services';
import { UnitStatusDraftDialog } from './Dialogs';
import { LeadOwnerUnitLeasePermissions } from '../../../../../Permissions';
import { UnitStatusAvailableDialog } from './Dialogs/UnitStatusAvailableDialog/UnitStatusAvailableDialog';
import { ArchiveState } from '../../../../../assets/json/StaticValue.json';
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';
import { ActivitiesManagementDialog } from '../../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import ActivityDetailsComponent from '../../../../DFMSharedComponents/ActivityDetails/ActivityDetailsComponent';
import {  UnitMPIGaugeChartComponent } from '../../../UnitsSalesView/UnitsSalesUtilities/CardDetailsComponent/UnitMPIGaugeChartComponent/UnitMPIGaugeChartComponent';


function CardDetailsComponent({
  activeData,
  cardDetailsActionClicked,
  reloadData,
  parentTranslationPath,
  translationPath,
  from,
  displyOpenFileButton,
  displyMatchingButton,
}) {
  const textArea = useRef(null);
  const [isPropertyManagementView, setIsPropertyManagementView] = useState(false);
  const pathName = window.location.pathname.split('/home/')[1].split('/view')[0];
  const [addActivity, setAddActivity] = useState(false);
  const [activitydetails, setActivityDetails] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [unitAvailableStatuses, setUnitAvailableStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [isOpenDraftDialog, setIsOpenDraftDialog] = useState(false);
  const [isOpenAvailableDialog, setIsOpenAvailableDialog] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [makeTransactionOnUnitList, setMakeTransactionOnUnitList] = useState([]);
  // const imageHandler = useCallback(
  //   (imageIndex) => () => {
  //     setActiveImageIndex(imageIndex);
  //   },
  //   []
  // );
  const archiveUnits = useCallback(async () => {
    await archiveUnitsPut(activeData.id);
    showSuccess(t(`${translationPath}Successarchive`));
    sideMenuIsOpenUpdate(false);
    setOpen(false);
    reloadData();
  }, [activeData.id, reloadData, t, translationPath]);
  const getIsAlloweGetUnitAvailableStatusus = () => returnPropsByPermissions(LeadOwnerUnitLeasePermissions.MakeTransactionOnUnitLeaseToBeAvailable.permissionsId);
  const getIsAlloweGetUnitReservedOrLeased = () => returnPropsByPermissions3(LeadOwnerUnitLeasePermissions.SetUnitAsLease.permissionsId, LeadOwnerUnitLeasePermissions.SetUnitAsReserveForLease.permissionsId);

  const GetCanSetUnitAsReservedOrLeasedOrSale = async (newStatus) => {
    const res = await CanSetUnitAsReservedOrLeasedOrSale(activeData.id, newStatus);
    if (res) {
      GlobalHistory.push(
        `/home/lead-owner-units-lease/unit-status-management?status=${newStatus}&id=${activeData.id}&from=${from}&rowVersion=${activeData.rowVersion}`
      );
    } else {
      setSelectedStatus(null);
      showError(t(`${translationPath}There-is-no-lease-lead-owner-for-this-unit`));
    }
  };

  const onSelectChanged = async (newStatus) => {
    setSelectedStatus(newStatus);
    if (newStatus === UnitsStatusEnum.Draft.key) {
      setIsOpenDraftDialog(true);
      return;
    }
    else if (
      newStatus === UnitsStatusEnum.Available.key &&
      getIsAlloweGetUnitAvailableStatusus()
    ) {
      setIsOpenAvailableDialog(true);
      return;
    }

    else if (
      (getIsAlloweGetUnitReservedOrLeased())
    ) {
      GetCanSetUnitAsReservedOrLeasedOrSale(newStatus);

    }

    else showError(t(`${translationPath}Dont-have-permissions-in-this-role`));
  };

  const getUnitAvailableStatus = useCallback(async () => {
    setIsLoading(true);
    const res = await GetUnitAvailableStatus(activeData.id, UnitsOperationTypeEnum.rent.key);
    if (!(res && res.status && res.status !== 200)) setUnitAvailableStatuses(res);
    else setUnitAvailableStatuses([]);
    setIsLoading(false);
  }, [activeData.id]);

  useEffect(() => {
    if (activeData && activeData.id) getUnitAvailableStatus();
    setSelectedStatus(null);
  }, [getUnitAvailableStatus]);

  const copyTextToClipboard = (itemId) => {
    const context = textArea.current;
    if (itemId && context) {
      context.value = itemId;
      context.select();
      document.execCommand('copy');
      showinfo(`${t('Shared:Copy-id-successfully')}  (${itemId})`);
    } else
      showinfo(`${t('Shared:Copy-id-successfully')}  (${itemId})`);
  };
  const getDefaultUnitImage = useCallback(
    (unitType) => ContactTypeEnum[unitType] && ContactTypeEnum[unitType].defaultImg,
    []
  );



  const getTransactionListForUnit = () => {
    const list = [];
    const transactionList =
      Object.values(UnitsStatusEnum).filter(
        (item) =>
          item.showInSelect &&
          (!item.effectedOperationType ||
            activeData.operationType === item.effectedOperationType ||
            activeData.operationType === UnitsOperationTypeEnum.rentAndSale.key) &&
          unitAvailableStatuses.findIndex((element) => element === item.key) !== -1
      );
    transactionList.length && transactionList.map((item) => {
      if (item && item.value === 'available' && returnPropsByPermissions(LeadOwnerUnitLeasePermissions.MakeTransactionOnUnitLeaseToBeAvailable.permissionsId))
        list.push(item);
      if (item && item.value === 'reserved-for-lease' && returnPropsByPermissions(LeadOwnerUnitLeasePermissions.SetUnitAsReserveForLease.permissionsId))
        list.push(item);
      else if (item && item.value === 'leased' && returnPropsByPermissions(LeadOwnerUnitLeasePermissions.SetUnitAsLease.permissionsId))
        list.push(item);
      else if (item && item.value === 'draft' && returnPropsByPermissions(LeadOwnerUnitLeasePermissions.MakeTransactionOnUnitLeaseToBeDraft.permissionsId))
        list.push(item);
    });


    setMakeTransactionOnUnitList(list);
  };

  useEffect(() => {
    getTransactionListForUnit();
  }, [activeData, unitAvailableStatuses]);

  return (
    <div className="units-card-detaild-wrapper p-relative">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="w-100 px-3 mb-3">
        {makeTransactionOnUnitList.length > 0 && (
          <SelectComponet
            idRef="unitStatusRef"
            data={makeTransactionOnUnitList}
            emptyItem={{
              value: null,
              text: "make-transaction",
              isHiddenOnOpen: true,
            }}
            value={selectedStatus}
            translationPathForData={translationPath}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            onSelectChanged={onSelectChanged}
            valueInput="key"
            textInput="value"
          />
        )}
      </div>
      <div className="status-wrapper">
        <div
          className={`body-status ${
            (activeData &&
              activeData.leaseUnitStatus &&
              activeData.leaseUnitStatus.value) === "available" &&
            "c-success-light"
          }`}
        >
          <div className="body-status-type mx-1 ">
            {t(`${translationPath}status`)}
          </div>
          {" : "}
          <span
            className={`body-status mx-2 ${
              (activeData &&
                activeData.leaseUnitStatus &&
                activeData.leaseUnitStatus.value) === "reserved-for-lease" &&
              "status-wrapper-text"
            }`}
          >
            <span className="status-wrapper-text-pen">
              {" "}
              {AMLStatusEnum[activeData && activeData.leaseUnitStatus] &&
                AMLStatusEnum[activeData && activeData.leaseUnitStatus].name &&
                t(
                  `Shared:${
                    (AMLStatusEnum[activeData && activeData.leaseUnitStatus] &&
                      AMLStatusEnum[activeData && activeData.leaseUnitStatus]
                        .name) ||
                    ""
                  }`
                )}
            </span>{" "}
            {activeData &&
              activeData.leaseUnitStatus &&
              activeData.leaseUnitStatus.value !== undefined &&
              ((activeData &&
                activeData.leaseUnitStatus &&
                t(
                  `${translationPath}${
                    activeData.leaseUnitStatus.value === "Resale"
                      ? "sale"
                      : activeData.leaseUnitStatus.value === "New Lease"
                      ? "leased"
                      : activeData.leaseUnitStatus.value
                  }`
                )) ||
                "N/A")}
          </span>
        </div>
      </div>
      <div className="archive-bbt">
        <Button
          onClick={() => setOpen(true)}
          disabled={ArchiveState}
          className="MuiButtonBase-root MuiButton-root MuiButton-text btns-icon theme-solid mx-2"
          title={t(`${translationPath}ArchiveUnit`)}
        >
          <span className="MuiButton-label">
            <span className="mdi mdi-inbox-multiple" />
          </span>
          <span className="MuiTouchRipple-root" />
        </Button>
      </div>
      {activeData && (
        <div className="side-menu-wrapper">
          <div>
            <div className="cards-header-wrapper mb-1">
              <LoadableImageComponant
                classes="details-img"
                type={LoadableImageEnum.div.key}
                alt={t(`${translationPath}unit-image`)}
                src={
                  (activeData &&
                    activeData.allunitImages &&
                    getDownloadableLink(
                      activeData.allunitImages.fileId,
                      200,
                      20
                    )) ||
                  getDefaultUnitImage(activeData && activeData.type)
                }
              />
            </div>
            <div className="mb-1 mt-3">
              <div className="data-schedule-button">
                {returnPropsByPermissions(
                  LeadOwnerUnitLeasePermissions.AddNewActivity.permissionsId
                ) && (
                  <div
                    className={`add-activity-button ${
                      addActivity ? "selected" : ""
                    }`}
                  >
                    <Button
                      onClick={() => {
                        setAddActivity(true);
                        setActivityDetails(false);
                      }}
                      className={`btns ${addActivity ? "theme-outline" : ""}`}
                    >
                      {t(`${translationPath}add-activity`)}
                    </Button>
                  </div>
                )}
                <div
                  className={`details-button ${
                    activitydetails ? "selected" : ""
                  }`}
                >
                  <Button
                    onClick={() => {
                      setAddActivity(false);
                      setActivityDetails(true);
                    }}
                    className={`btns ${activitydetails ? "theme-outline" : ""}`}
                  >
                    {t(`${translationPath}ActivityDetails`)}
                  </Button>
                </div>
              </div>
            </div>
            <div className="properety-plan d-flex-center my-2">
              {activeData && activeData.name}
            </div>
            <div className="price-wrapper">
              <div
                className={`for-lable ${
                  activeData && activeData.unitOperationType
                }`}
              >
                {t(`${translationPath}for`)}
              </div>
              <div className={activeData && activeData.unitOperationType}>
                {`  ${activeData && activeData.unitOperationType}`}:
              </div>
              <div className="unit-price">
                {`   ${
                  activeData && activeData.rent_price_fees
                    ? activeData.rent_price_fees.rentPerYear ||
                      activeData.rentPerYear ||
                      activeData.rent_price_fees
                    : "N/A"
                } AED`}
              </div>
            </div>
            <div className="flat-contents-wrapper">
              {activeData &&
                activeData.flatContent &&
                activeData.flatContent.map((subItem, subIndex) => (
                  <div
                    key={`detailsFlatContentsItemRef${subIndex + 1}`}
                    className="flat-content-item"
                  >
                    <span
                      className={`flat-content-icon ${subItem.iconClasses} mdi-18px`}
                    />
                    <span className="px-1">{subItem.value}</span>
                    <span>
                      {subItem.title && (
                        <span className="flat-content-text">
                          {t(`${translationPath}${subItem.title}`)}
                        </span>
                      )}
                    </span>
                  </div>
                ))}
            </div>
            <div className="id-date-wrapper pl-2 pr-2">
              <div className="created-on">
                <span className="details-icon mdi mdi-calendar mdi-16px" />
                <span>
                  <span className="details-text fw-simi-bold">
                    {t(`${translationPath}created`)}:
                  </span>
                  <span className="px-1">
                    {(activeData &&
                      activeData.createdOn &&
                      moment(activeData.createdOn)
                        .locale(i18next.language)
                        .format("DD/MM/YYYY")) ||
                      "N/A"}
                  </span>
                </span>
              </div>
              <div className="contact-id-wrapper">
                {t(`${translationPath}id`)}:
                <Tooltip title={t(`${translationPath}copy`)}>
                  <CopyToClipboardComponents
                    data={activeData.id}
                    childrenData={activeData.id}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="px-3 mb-3 slider-data">
              {activeData &&
                activeData.details &&
                activitydetails === false &&
                activeData.details.map((item, index) => (
                  <React.Fragment key={`detailsRef${index + 1}}`}>
                    {item.value && item.title !== "owner-mobile-number" && (
                      <div className="px-3 mb-2">
                        <span className="texts gray-primary-bold">
                          {`${t(item.title)}`}:
                        </span>
                        <span className="texts s-gray-primary">{`  ${item.value}`}</span>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              <div className="d-flex-center">
                <UnitMPIGaugeChartComponent
                  activeData={activeData}
                  unitId={activeData.id}
                  operationType={UnitsOperationTypeEnum.rent.key}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </div>
              {activitydetails && (
                <ActivityDetailsComponent
                  data={activeData}
                  parentTranslationPath={"UnitsView"}
                  type="unitLeadOwner"
                  cardId={activeData.id}
                />
              )}
            </div>
          </div>
          <div className="side-menu-actions">
            <div className="d-flex-center">
              {displyOpenFileButton && (
                <Button
                  className="btns theme-solid mx-2 mb-2"
                  onClick={cardDetailsActionClicked(
                    ActionsEnum.folder.key,
                    activeData
                  )}
                >
                  <span className="icons i-folder-white" />
                  <span className="mx-2">
                    {t(`${translationPath}open-file`)}
                  </span>
                </Button>
              )}
            </div>
          </div>
        </div>
      )}

      <FacebookGalleryComponent
        data={(activeData && activeData.unitImages) || []}
        isOpen={(activeImageIndex !== null && activeData && true) || false}
        activeImageIndex={activeImageIndex}
        titleText="unit-images"
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onOpenChanged={() => {
          setActiveImageIndex(null);
        }}
      />

      {isOpenDraftDialog && (
        <UnitStatusDraftDialog
          activeItem={activeData}
          isOpen={isOpenDraftDialog}
          reloadData={() => {
            setIsOpenDraftDialog(false);
            if (reloadData) reloadData();
          }}
          isOpenChanged={() => {
            setIsOpenDraftDialog(false);
          }}
        />
      )}
      <UnitStatusAvailableDialog
        activeItem={activeData}
        isOpen={isOpenAvailableDialog}
        reloadData={() => {
          setIsOpenAvailableDialog(false);
          if (reloadData) reloadData();
        }}
        isOpenChanged={() => {
          setIsOpenAvailableDialog(false);
        }}
      />
      <DialogComponent
        isOpen={open}
        onCancelClicked={() => setOpen(false)}
        onCloseClicked={() => setOpen(false)}
        translationPath={translationPath}
        parentTranslationPath="UnitsView"
        titleText="ArchiveUnit"
        onSubmit={(e) => {
          e.preventDefault();
          archiveUnits();
        }}
        maxWidth="sm"
        dialogContent={
          <span>{t(`${translationPath}MassageArchiveUnits`)}</span>
        }
      />

      {addActivity && (
        <ActivitiesManagementDialog
          open={addActivity}
          onSave={() => {
            setAddActivity(false);
          }}
          close={() => {
            setAddActivity(false);
          }}
          actionItemId={activeData?.id}
          translationPath={""}
          parentTranslationPath={"ContactProfileManagementView"}
        />
      )}
    </div>
  );
}

CardDetailsComponent.propTypes = {
  activeData: PropTypes.instanceOf(Object),
  reloadData: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string,
  from: PropTypes.number,
  cardDetailsActionClicked: PropTypes.func,
  displyOpenFileButton: PropTypes.bool,
  displyMatchingButton: PropTypes.bool,
};
CardDetailsComponent.defaultProps = {
  translationPath: 'utilities.cardDetailsComponent.',
  activeData: null,
  from: 1,
  cardDetailsActionClicked: () => { },
  displyOpenFileButton: false,
  displyMatchingButton: false,
};
export { CardDetailsComponent };
