import { TransactionsDetails, ContactDetails } from '../Sections';
import { AssignedAgentsDetails } from '../../../SalesTransactionsView/SalesTransactionsProfile/Sections/Details/Sections/AssignedAgentsDetails/AssignedAgentsDetails';

export const SalesTransactionsDetailsTabsData = [
  {
    label: 'transaction-details',
    component: TransactionsDetails,
  },
  {
    label: 'contact-details',
    component: ContactDetails,
  },
  {
    label: 'assigned-agents',
    component: AssignedAgentsDetails,
  },
];
