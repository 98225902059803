import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  bottomBoxComponentUpdate,
  returnPropsByPermissions,
  showError,
  showSuccess,
} from "../../../../Helper";
import { TableActions } from "../../../../Enums";
import {
  DialogComponent,
  PaginationComponent,
  Spinner,
  Tables,
  NoSearchResultComponent,
  TabsComponent,
} from "../../../../Components";
import { AgentsTabelDialogView } from "../AgentsDialogsView/AgentsTabelDialogView";
import {
  GetAllAgentsServices,
  UpdateAgentInRotationStatus,
} from "../../../../Services/AgentsServices";
import { AgentsPermissions } from "../../../../Permissions";
import { BulkStatusUpdateDialog } from "../AgentsDialogsView/BulkStatusUpdateDialog";
import { ManageRotaionView } from "../AgentsDialogsView/ManageRotaionDialogView";
import { StatusReasonDialog } from "./StatusReasonDialog";
import AgentCallsDialog from "./AgentCallsDialog";
import AgentHistoryTab from "./components/AgentHistoryTab";
import "./components/AgentHistoryTabStyles.scss";

export const AgentsTabelView = ({
  parentTranslationPath,
  translationPath,
  filter,
  actionType,
  selectedAgentIds,
  setSelectedAgentIds,
  setFilter,
  setSearchedItem,
  isStatusBulkUpdateDialogOpen,
  setIsStatusBulkUpdateDialogOpen,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isOpenStatusReasonDialog, setIsOpenStatusReasonDialog] =
    useState(false);
  const [agentCallsDialogOpen, setAgentCallsDialogOpen] = useState(false);
  const [agentStatusReason, setAgentStatusReason] = useState({
    reason: null,
    notes: "",
  });
  const [agentsData, setAgentsData] = useState({
    result: [],
    totalCount: 0,
  });
  const [activeAgent, setActiveAgent] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };

  const getAgents = useCallback(async () => {
    setIsLoading(true);
    const response = await GetAllAgentsServices({ ...filter });
    if (!(response && response.status && response.status !== 200)) {
      setAgentsData({
        result: (response && response.result) || [],
        totalCount: (response && response.totalCount) || 0,
      });
    } else {
      setAgentsData({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [filter]);

  const reloadData = () => {
    getAgents();
  };

  const handleInRotationClick = (item) => {
    if (item) {
      const { agentId } = item;
      const isBlocked = item.isBlocked;
      const isInRotation = !item.isInRotation;
      updateAgentInRotationStatus({
        agentId: agentId,
        isInRotation: isInRotation,
        isBlocked: isBlocked,
      });
    } else if (activeItem) {
      const { agentId } = activeItem;
      const isBlocked = activeItem.isBlocked;
      const isInRotation = !activeItem.isInRotation;
      updateAgentInRotationStatus({
        agentId: agentId,
        isInRotation: isInRotation,
        reasonId: agentStatusReason?.reason?.lookupItemId,
        notes: agentStatusReason?.notes,
        isBlocked: isBlocked,
      });
    }
  };

  const handleIsBlockedClick = (item) => {
    if (item) {
      const { agentId } = item;
      const isBlocked = !item.isBlocked;
      const isInRotation = item.isInRotation;
      updateAgentIsBlockedStatus({
        agentId: agentId,
        isBlocked: isBlocked,
        isInRotation: isInRotation,
      });
    } else if (activeItem) {
      const { agentId } = activeItem;
      const isInRotation = activeItem.isInRotation;
      const isBlocked = !activeItem.isBlocked;
      updateAgentIsBlockedStatus({
        agentId: agentId,
        isBlocked: isBlocked,
        isInRotation: isInRotation,
      });
    }
  };

  const updateAgentInRotationStatus = useCallback(
    async ({ agentId, isInRotation, reasonId, notes, isBlocked }) => {
      setIsLoading(true);
      const result = await UpdateAgentInRotationStatus({
        agentId,
        isInRotation,
        reasonId,
        notes,
        isBlocked,
      });
      if (!(result && result.status && result.status !== 200)) {
        showSuccess(t`${translationPath}Edit-agent-in-rotation-successfully`);
        getAgents();
      } else {
        showError(t`${translationPath}Edit-agent-in-rotation-failed`);
      }

      setIsLoading(false);
    },
    [getAgents]
  );

  const updateAgentIsBlockedStatus = useCallback(
    async ({ agentId, isBlocked, isInRotation }) => {
      setIsLoading(true);
      const result = await UpdateAgentInRotationStatus({
        agentId,
        isBlocked,
        isInRotation,
      });
      if (!(result && result.status && result.status !== 200)) {
        showSuccess(t`${translationPath}Edit-agent-block-status-successfully`);
        getAgents();
      } else {
        showError(t`${translationPath}Edit-agent-block-status-failed`);
      }

      setIsLoading(false);
    },
    [getAgents]
  );

  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions.editText.key) {
      setActiveItem(item);
      setOpenDialog(true);
    } else if (actionEnum === TableActions.AI.key) {
      setAgentCallsDialogOpen(true);
      setActiveAgent(item);
    }
  }, []);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({
      ...item,
      pageIndex,
      search: "",
      filterBy: null,
      orderBy: null,
    }));
    setSearchedItem("");
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({
      ...item,
      pageIndex: 0,
      search: "",
      pageSize,
      filterBy: null,
      orderBy: null,
    }));
    setSearchedItem("");
  };

  const getActionTableWithPermissions = () => {
    const list = [
      {
        enum: TableActions.AI.key,
        title: "agent-calls",
      },
    ];
    if (
      returnPropsByPermissions(AgentsPermissions.EditAgentFile.permissionsId)
    ) {
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      });
    }
    return list;
  };

  const getIsSelected = useCallback(
    (row) =>
      selectedAgentIds &&
      selectedAgentIds.findIndex((item) => item === row.agentId) !== -1,
    [selectedAgentIds]
  );

  const getIsSelectedAll = useCallback(() => {
    const tableRecordsCount =
      (agentsData && agentsData.result && agentsData.result.length) || 0;
    const selectedRecordsCount =
      (selectedAgentIds && selectedAgentIds.length) || 0;

    return selectedRecordsCount === tableRecordsCount || false;
  }, [agentsData, selectedAgentIds]);

  const onSelectClicked = useCallback(
    (row) => {
      const localSelectedAgentIds = [...selectedAgentIds];
      const itemIndex = localSelectedAgentIds
        ? localSelectedAgentIds.findIndex((item) => item === row.agentId)
        : -1;
      if (itemIndex !== -1) localSelectedAgentIds.splice(itemIndex, 1);
      else localSelectedAgentIds.push(row.agentId);

      setSelectedAgentIds(localSelectedAgentIds);
    },
    [selectedAgentIds, actionType]
  );

  const onSelectAllClicked = () => {
    if (getIsSelectedAll()) {
      setSelectedAgentIds([]);
    } else if (agentsData?.result) {
      const localSelectedAgentIds = agentsData.result.map(
        (item) => item.agentId
      );
      setSelectedAgentIds(localSelectedAgentIds);
    }
  };

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={agentsData.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  useEffect(() => {
    if (sortBy) {
      setSearchedItem("");
      setFilter((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
        search: "",
        pageIndex: 0,
      }));
    }
  }, [sortBy]);

  useEffect(() => {
    setTimeout(() => {
      getAgents();
    }, 700);
  }, [getAgents]);
  return (
    <div className="Agents-wrapper">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="w-100 px-2">
        {(agentsData && agentsData.totalCount === 0 && (
          <>
            <NoSearchResultComponent />
          </>
        )) || (
          <Tables
            data={agentsData.result}
            headerData={[
              {
                id: 1,
                label: t(`${translationPath}Agent-name`),
                input: "agentName",
                isSortable: true,
              },
              {
                id: 2,
                label: t(`${translationPath}Agent-Email`),
                input: "email",
                isSortable: true,
                component: (item) => <div>{item.agentEmail || "N/A"}</div>,
              },
              {
                id: 3,
                label: t(`${translationPath}Agent-mobile`),
                isSortable: true,
                input: "agentMobile",
                // component: (item) => <div>{(item.agentMobile) || 'N/A'}</div>,
              },
              {
                id: 4,
                label: t(`${translationPath}In-rotation-now`),
                component: (item) => (
                  <div id="in-rotation-ON/Off-wraper">
                    {(item && item.isInRotation === true && (
                      <div
                        id="rotation-ON/Off-bbt"
                        className="ROTATION-ON"
                        onClick={() => {
                          setIsOpenStatusReasonDialog(true);
                          setActiveItem(item);
                        }}
                      >
                        {t(`${translationPath}On`)}
                      </div>
                    )) || (
                        <div
                          className="ROTATION-Off"
                          onClick={() => handleInRotationClick(item)}
                        >
                          {t(`${translationPath}Off`)}
                        </div>
                      ) ||
                      "N/A"}
                  </div>
                ),
              },
              {
                id: 5,
                label: t(`${translationPath}Is-Blocked`),
                component: (item) => (
                  <div id="Is-Blocked-ON/Off-wraper">
                    {(item && item.isBlocked === true && (
                      <div
                        id="Is-Blocked-ON/Off-bbt"
                        className="ROTATION-ON"
                        onClick={() => {
                          setActiveItem(item);
                          handleIsBlockedClick(item);
                        }}
                      >
                        {t(`${translationPath}On`)}
                      </div>
                    )) || (
                        <div
                          className="ROTATION-Off"
                          onClick={() => handleIsBlockedClick(item)}
                        >
                          {t(`${translationPath}Off`)}
                        </div>
                      ) ||
                      "N/A"}
                  </div>
                ),
              },
              // {
              //   id: 5,
              //   label: t(`${translationPath}In-call-rotation-now`),
              //   component: (item) => (
              //     <div id="in-call-rotation-ON/Off-wraper">
              //       {(item && item.isInCallRotation && (
              //         <div
              //           id="call-rotation-ON/Off-bbt"
              //           className="ROTATION-ON"
              //         >
              //           {t(`${translationPath}On`)}
              //         </div>
              //       )) || (
              //           <div
              //             className="ROTATION-Off"
              //           >
              //             {t(`${translationPath}Off`)}
              //           </div>
              //         ) ||
              //         "N/A"}
              //     </div>
              //   ),
              // },
              {
                id: 6,
                label: t(`${translationPath}agentSchemas`),
                component: (item) => (
                  <div>
                    {item &&
                      item.agentSchemas.map(
                        (el) => `${(el && el.name) || " "}, `
                      )}
                  </div>
                ),
              },
              {
                id: 7,
                isSortable: true,
                label: t(`${translationPath}Lead-cap`),
                input: "leadCapacity",
              },
              {
                id: 8,
                isSortable: true,
                label: t(`${translationPath}monthlyCapacity`),
                input: "monthlyCapacity",
              },
              {
                id: 9,
                isSortable: true,
                label: t(`${translationPath}weeklyCapacity`),
                input: "weeklyCapacity",
              },
              {
                id: 10,
                isSortable: true,
                label: t(`${translationPath}dailyCapacity`),
                input: "dailyCapacity",
              },
              {
                id: 11,
                isSortable: true,
                label: t(`${translationPath}Lead-pool-Cap`),
                input: "leadPoolCapacity",
              },
              {
                id: 12,
                isSortable: true,
                label: t(`${translationPath}lead-pool-capacity/monthly`),
                input: "monthlyPoolCapacity",
              },
              {
                id: 13,
                isSortable: true,
                label: t(`${translationPath}lead-pool-capacity/weekly`),
                input: "weeklyPoolCapacity",
              },
              {
                id: 14,
                isSortable: true,
                label: t(`${translationPath}lead-pool-capacity/daily`),
                input: "dailyPoolCapacity",
              },
              {
                id: 15,
                isSortable: true,
                label: t(`${translationPath}leadsCount`),
                input: "leadsCount",
              },
              {
                id: 16,
                isSortable: true,
                label: t(`${translationPath}branchName`),
                input: "branchName",
              },
              {
                id: 17,
                label: t(`${translationPath}Created-date`),
                component: (item) => (
                  <div>
                    {(item.createdDate &&
                      moment(item.createdDate).format("YYYY-MM-DD")) ||
                      "N/A"}
                  </div>
                ),
                input: "createdOn",
                isSortable: true,
              },
              {
                id: 18,
                isSortable: true,
                label: t(`${translationPath}language`),
                component: (item) => (
                  <div>
                    {item.languages &&
                      item.languages.map((el) => `${el.lookupItemName}, `)}
                  </div>
                ),
              },
              {
                id: 19,
                isSortable: true,
                label: t(`${translationPath}currentMonthLeadCapacity`),
                input: "currentMonthLeadCapacity",
              },
              {
                id: 20,
                label: t(`${translationPath}TeamLeadName`),
                input: "teamNames",
                component: (element) => (
                  <span>{!element.leadNames ? "N/A" : element.leadNames}</span>
                ),
              },
              {
                id: 21,
                label: t(`${translationPath}TeamName`),
                input: "leadNames",
                component: (element) => (
                  <span>{!element.teamNames ? "N/A" : element.teamNames}</span>
                ),
              },
            ]}
            defaultActions={getActionTableWithPermissions()}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            selectAllOptions={
              actionType.id && {
                getIsSelected,
                onSelectClicked,
                disabledRows: [],
                onSelectAllClicked,
                withCheckAll: true,
                selectedRows: selectedAgentIds,
              }
            }
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            totalItems={agentsData.result ? agentsData.result.length : 0}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            setSortBy={setSortBy}
          />
        )}
      </div>

      {openDialog && (
        <DialogComponent
          isOpen={openDialog}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={agentsData.totalCount}
          titleClasses="DialogComponent-WorkingHoursDialogView"
          wrapperClasses="wrapperClasses-WorkingHoursDialogView"
          titleText="Manage-Agent"
          onCloseClicked={() => {
            setOpenDialog(false);
            setActiveTab(0);
          }}
          maxWidth="lg"
          dialogContent={
            <>
              <TabsComponent
                data={[
                  {
                    tab: "Agent View",
                  },
                  {
                    tab: "Agent Managment",
                  },
                  {
                    tab: "History",
                  },
                ]}
                labelInput="tab"
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                themeClasses={`${
                  activeTab !== 2 ? "theme-curved" : "theme-solid"
                }`}
                currentTab={activeTab}
                onTabChanged={onTabChanged}
              />
              {activeTab === 0 && (
                <div className="m-4">
                  <AgentsTabelDialogView
                    onCancelClicked={() => {
                      setOpenDialog(false);
                      setActiveTab(0);
                    }}
                    activeItem={activeItem}
                    relode={() =>
                      setFilter((item) => ({
                        ...item,
                        pageSize: filter.pageSize,
                        pageIndex: filter.pageIndex,
                        search: "",
                        filterBy: null,
                        orderBy: null,
                      }))
                    }
                  />
                </div>
              )}
              {activeTab === 1 && (
                <div className="m-4">
                  <ManageRotaionView
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    activeItem={activeItem}
                    onCancelClicked={() => {
                      setOpenDialog(false);
                      setActiveTab(0);
                    }}
                  />
                </div>
              )}
              {activeTab === 2 && (
                <div className="m-2">
                  <AgentHistoryTab
                    activeItem={activeItem}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>
              )}
            </>
          }
        />
      )}
      {isStatusBulkUpdateDialogOpen && (
        <BulkStatusUpdateDialog
          isOpen={isStatusBulkUpdateDialogOpen}
          onSave={() => {
            setSelectedAgentIds([]);
            getAgents();
            setIsStatusBulkUpdateDialogOpen(false);
          }}
          onClose={() => {
            setSelectedAgentIds([]);
            setIsStatusBulkUpdateDialogOpen(false);
          }}
          agentsId={selectedAgentIds}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenStatusReasonDialog && (
        <StatusReasonDialog
          isOpen={isOpenStatusReasonDialog}
          onSave={() => {
            setIsOpenStatusReasonDialog(false);
            handleInRotationClick();
            setAgentStatusReason(null);
          }}
          onClose={() => {
            setIsOpenStatusReasonDialog(false);
            setAgentStatusReason(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          setAgentStatusReason={setAgentStatusReason}
          agentStatusReason={agentStatusReason}
        />
      )}
      {agentCallsDialogOpen && (
        <AgentCallsDialog
          open
          onClose={() => setAgentCallsDialogOpen(false)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          agentId={activeAgent.agentId}
        />
      )}
    </div>
  );
};

AgentsTabelView.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  setFilter: PropTypes.func.isRequired,
  setSearchedItem: PropTypes.func.isRequired,
};
