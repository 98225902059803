import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { MicrosoftToken } from '../../../../Services/DashboardService';
import { PowerBiEnum } from '../../../../Enums';
import { GlobalTranslate, showError } from '../../../../Helper';
import { useTitle } from '../../../../Hooks';
import { SpinnerDashboards } from '../SpinnerDashboards/SpinnerDashboards';
import { CellSpinnerBi } from '../CellSpinnerBi/CellSpinnerBi';

export const AgentActivatedLeads = () => {
  const [report, setReport] = useState();
  const [userid, setUserid] = useState(null);
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);

  useTitle(GlobalTranslate.t('Dashboard:agentsaleslisting-dashboard'));
  const Filter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'Users',
      column: 'UsersId',
    },
    operator: 'In',
    values: [userid],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport('');
      showError('Contact Your Bi Admin For Help');
    }
  };

  useEffect(() => {
    Microsoft(false);
    setUserid(JSON.parse(localStorage.getItem('session')).userId || null);
  }, []);
  return (
    <div className='dashboardMain-PowerBIEmbed'>
                   <CellSpinnerBi isActive={!ShowReport} isAbsolute />

      {render && (
        <div className='dashboardMain'>

          <PowerBIEmbed
            embedConfig={{
              type: 'report',
              id: PowerBiEnum.ActivatedLeadid.reportid,
              embedUrl: PowerBiEnum.ActivatedLeadid.url,
              accessToken: report,
              pageView: 'fitToWidth',
              filters: [Filter],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  () => {
                    setShowReport(true);

                    console.info('Report loaded');
                  },
                ],
                [
                  'rendered',
                  () => {
                    console.info('Report rendered');
                  },
                ],
                [
                  'error',
                  (event, embed) => {
                    const error = event.detail;

                    if (
                      error.detailedMessage === 'Get report failed' ||
                      error.detailedMessage ===
                      'Access token has expired, resubmit with a new access token' ||
                      error.detailedMessage ===
                      'Fail to initialize - Could not resolve cluster'
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport ? "report-style-class-Show" : "report-style-class-hide"
            }            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
      )}
    </div>
  );
};
