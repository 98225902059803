import React, { useCallback, useEffect, useReducer } from 'react'
import { CorporateKycFormMap } from './CorporateKycFormMap'
import { FormTablesGenerator } from '../Utilities/FormTablesGenerator/FormTablesGenerator'
import { GetContacts, GetLookupItemsByLookupTypeName } from '../../../../../../../../../../Services';
import { CorporateKycFormText } from './CorporateKycFormText';

export const CorporateForm = ({
  clientFormRef,
  selected,
  onSelectedChange,
  clientFormMap,
  checkFormValidation
}) => {

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [data, setData] = useReducer(reducer, {
    Contacts: [],
    ResidenceStatus: [],
    MethodOfPayment: [],
    Country: [],
    Yes_No: [
      { value: true, key: "Yes" },
      { value: false, key: "No" },
    ],
  });
  
  const getLookupsByName = async (lookUpName, dataKey) => {    
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName,
      pageSize: 150,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: dataKey, value: res?.result || [] });
    else setData({ id: dataKey, value: [] });
  };

  const getContacts = async (search) => {
    const res = await GetContacts({
      pageIndex: 0, pageSize: 10, search, isAdvance: false
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "Contacts", value: res?.result || [] });
    else setData({ id: "Contacts", value: [] });
  };

  const getSalutation = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "Salutation",
      pageSize: 150,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)){
      const mappedOptions = res?.result && 
              res?.result.map(item => ({ value: item.lookupItemId, key: item.lookupItemName }));
      setData({ id: "Salutation", value: mappedOptions || [] });
    }
    else setData({ id: "Salutation", value: [] });
  };

  useEffect(() => {
    getSalutation();
  }, []);

  return (
    <>
      <FormTablesGenerator
        formMap={CorporateKycFormMap}
        formText={CorporateKycFormText}
        selected={selected}
        optionsData={data}
        onSelectedChange={onSelectedChange}
        getLookupsByName={getLookupsByName}
        getContactOptions={getContacts}
        checkFormValidation={checkFormValidation}
      />
      <div className="client-form" ref={clientFormRef} >
        <FormTablesGenerator
          formMap={clientFormMap}
          formText={CorporateKycFormText}
          selected={selected}
          optionsData={data}
          isClientForm
        />
      </div>
    </>
  )
}

