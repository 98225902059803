import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    containerPaginationHistory: {
      bottom: "0px",
      padding: "16px",
      borderTop: `1px solid ${theme.palette.border.secondary}`,
      borderRadius: "12px",
      width: "100%",
      left: "0px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderRadius: "0px",
      alignItems:'center'
    },
    containerArrow: {
      padding: "8px",
      border: `1px solid ${theme.palette.border.secondary}`,
      borderRadius: "8px",
    },pageCountTitle:{
        color:theme.palette.text.secondary,
        fontSize:'14px',
        fontWeight:'500',
        lineHeight:'20px'
    }
  };
});
