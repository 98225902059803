import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Box, Typography, Avatar } from "@material-ui/core";
import { getPublicDownloadableLink } from "../../../../../Helper";
import { useSelectedTheme } from "../../../../../Hooks";
import { UpdateMainEntitiesComment, DeleteMainEntitiesComment } from "../../../../../Services";
import { useVerticalNav } from "../../../../../Contexts/VerticalNavContext";
import { CustomButton } from "../../../CustomButton";

// styles
import useStyles from "./styles";

// Icons
import { CheckIcon, CloseXIcon } from "../../../../../assets/icons";

function ContactComment({ comment, setComments, contactId }) {
  
  const styles = useStyles();

  const [isEditing, setIsEditing] = useState(false);
  const [editedComment, setEditedComment] = useState(comment?.comment);
  const [originalComment, setOriginalComment] = useState(comment?.comment);

  const loginResponse = useSelector((state) => state.login.loginResponse);

  const { setAlertBoxContent } = useVerticalNav();
  
  const {
    theme: { palette },
  } = useSelectedTheme();

  const handleEditComment = () => {
    setIsEditing(true);
    setOriginalComment(editedComment);
  };

  const handleSaveComment = async () => {
    setIsEditing(false);
    if (editedComment !== comment.comment) {
      try {
        await UpdateMainEntitiesComment(comment?.mainEntitiesCommentId, {
          mainEntitiesCommentId: comment?.mainEntitiesCommentId,
          dfmType: 1,
          entityId: contactId,
          comment: editedComment,
        });
        setComments((prevComments) =>
          prevComments.map((c) =>
            c.id === comment.id ? { ...c, comment: editedComment } : c
          )
        );
        setAlertBoxContent({
          display: true,
          variant: "success",
          title: "Contact Comment Updated Successfully!",
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      } catch (error) {
        console.error("Failed to update comment:", error);
        setAlertBoxContent({
          display: true,
          variant: "error",
          title: "Failed to Update Contact Comment!",
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await DeleteMainEntitiesComment(commentId);

      setComments((prevComments) => prevComments.filter((c) => c.mainEntitiesCommentId !== commentId));

      setAlertBoxContent({
        display: true,
        variant: "success",
        title: "Contact Comment Deleted Successfully!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } catch (error) {
      console.error("Failed to delete comment:", error);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to Delete Contact Comment!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    }
  };

  const handleCancelEdit = () => {
    setEditedComment(originalComment);
    setIsEditing(false);
  };


  return (
    <Box className={styles.commentContainer}>
      <Avatar
        className={comment?.creatorProfileImg ? styles.avatar : styles.defaultAvatar}
        src={
          comment?.creatorProfileImg
            ? getPublicDownloadableLink(comment?.creatorProfileImg)
            : "/images/contacts/MaleAvatar.svg"
        }
      ></Avatar>
      <Box style={{ width: "100%" }}>
        {!isEditing ? (
          <Typography className={styles.comment} variant="body1">
            {editedComment}
          </Typography>
        ) : (
          <textarea
            className={styles.editComment}
            value={editedComment}
            onChange={(e) => setEditedComment(e.target.value)}
            rows={3}
          />
        )}

        <Box className={styles.actionsWrapper}>
          {!isEditing ? (
            <>
              <CustomButton
                boxShadow="none"
                size="sm"
                variant="text"
                color="tertiaryGray"
                style={{ minWidth: "fit-content" }}
                onClick={handleEditComment}
                disabled={comment?.createdBy !== loginResponse?.userId}
              >
                Edit
              </CustomButton>
              <CustomButton
                boxShadow="none"
                size="sm"
                variant="text"
                color="tertiaryGray"
                style={{ minWidth: "fit-content" }}
                onClick={() => handleDeleteComment(comment?.mainEntitiesCommentId)}
                disabled={comment?.createdBy !== loginResponse?.userId}
              >
                Delete
              </CustomButton>
            </>
          ) : (
            <>
              <CloseXIcon
                width="24"
                height="24"
                fill={palette.foreground.quarterary}
                onClick={handleCancelEdit}
                style={{ cursor: "pointer" }}
              />
              <CheckIcon
                width="24"
                height="24"
                fill={palette.foreground.quarterary}
                onClick={handleSaveComment}
                style={{ cursor: "pointer" }}
              />
            </>
          )}
        </Box>

        <Box className={styles.commentDetails}>
          <Typography variant="body2" className={styles.commentName}>
            {comment?.creatorFullName}
          </Typography>
          <Typography variant="body2" className={styles.commentDate}>
            {comment?.createdOn ? moment(comment?.createdOn).format("D MMM YYYY") : ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ContactComment;
