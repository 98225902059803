import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  const { border, background } = theme.palette;

  return {
    radioRoot: {
      margin: 0,
      padding: 0,
    },
    radioIcon: {
      borderRadius: "50%",
      border: `2px solid ${border.border_disabled}`,
      width: 16,
      height: 16,
      backgroundColor: background.paper,
      "input:hover ~ &": {
        border: `2px solid ${background.brand_solid}`,
      },
      "input:disabled ~ &": {
        border: `2px solid ${border.border_disabled}`,
        backgroundColor: background.disabled,
      },
    },
    radioCheckedIcon: {
      borderRadius: "50%",
      border: `2px solid ${background.brand_solid}`,
      backgroundColor: background.brand_solid,
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: background.paper,
      },
    },containerLableCheck :{
      display : 'flex',
      alignItems:'center',
      gap:'8px',
      marginLeft:'0px'
    }
  };
});
