import React, { useEffect, useState } from "react";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../../../../../../Components";
import {
  AddAndEditMainNumbersDialog,
  MainNumbersCardsView,
  ConvoloExtensionsView ,
  AddAndEditExtension  ,
  ConvoloExtensionsFilters
} from "./ConvoloBranchNumberComponent";


export const ConvoloBranchNumberView = ({
  parentTranslationPath,
  translationPath,
  branchId 
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [activeCard, setActiveCard] = useState(null);
  const [isLoading, setIsLoading] = useState({
    allMainNumbers: false,
    branchInfo: false,
    allExtensionsNumbersByMainId : false , 
    bulkUpdateExtensionStatus: false,
    importExtensionsNumbers: false,
    addOrEditExtensionsNumbers: false,
  });
    const [filter, setFilter] = useState({
       isActive: true ,
       number: null,
      });
   const [checkedDetailed, setCheckedDetailed] = useState([]);
  const [isOpenMainNumberDialog, setIsOpenMainNumberDialog] = useState(false);
    const [mainNumbersByBranchIdInfo, setMainNumbersByBranchIdInfo] =
      useState(null);
  const [isOpenExtensionNumberDialog, setIsOpenExtensionNumberDialog] = useState(false);
  const [reloadAllExtensions , setReloadAllExtensions]  = useState(false) ; 

  const [isReleadData, setIsReleadData] = useState(false);
  const [reloadExtensionsNumbersByMainId, setReloadExtensionsNumbersByMainId] = useState({isReleadData : false  , mainNumberId : null  , mainNumber : null});


  return (
    <>
      <div className="dialog-content-wrapper-convolo mt-2">
        <Spinner isActive={(Object.values(isLoading).some((s)=> s))} isAbsolute />
          
        
        <div className="d-flex mb-2 mt-2 px-2">
          <ButtonBase
            className="btns theme-solid mt-2"
            onClick={() => setIsOpenMainNumberDialog(true)}
          >
            <span className="mdi mdi-plus" />
            <span className="px-1">
              {t(`${translationPath}add-new-main-number`)}
            </span>
          </ButtonBase>
        </div>

        <div className="w-100 d-flex-center flex-wrap p-2">
          <MainNumbersCardsView
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            branchId={branchId}
            isReleadData={isReleadData}
            setIsReleadData={setIsReleadData}
            onCardClick={(item) => {
              setReloadExtensionsNumbersByMainId({
                isReleadData : !reloadExtensionsNumbersByMainId.isReleadData , 
                mainNumberId : item.mainNumberId  , 
                mainNumber : item.mainNumber 

              }) ;   
            }}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            mainNumbersByBranchIdInfo={mainNumbersByBranchIdInfo}
            setMainNumbersByBranchIdInfo={setMainNumbersByBranchIdInfo}
            reloadExtensionsNumbersByMainId={reloadExtensionsNumbersByMainId}
            activeCard={activeCard}
            setActiveCard={setActiveCard}
          />
        </div>
        <div className="w-100 d-flex-column b-bottom">
          <ConvoloExtensionsFilters
           translationPath={translationPath}
           parentTranslationPath={parentTranslationPath}
           setFilter={setFilter}
            filter={filter}
            checkedDetailed={checkedDetailed}
            setCheckedDetailed={setCheckedDetailed}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setReloadAllExtensions={setReloadAllExtensions}
            reloadAllExtensions={reloadAllExtensions}
            />    
        </div>
        <div className="w-100 d-flex-column-v-center p-2 b-bottom"> 
          <ConvoloExtensionsView
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            reloadExtensionsNumbersByMainId={reloadExtensionsNumbersByMainId}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            branchId={branchId}
            setFilter={setFilter}
            filter={filter}
            reloadAllExtensions={reloadAllExtensions}
            checkedDetailed={checkedDetailed}
            setCheckedDetailed={setCheckedDetailed}
            mainNumbersByBranchIdInfo={mainNumbersByBranchIdInfo && mainNumbersByBranchIdInfo.branchMainNumbers &&  mainNumbersByBranchIdInfo.branchMainNumbers[0] && mainNumbersByBranchIdInfo.branchMainNumbers[0].mainNumberId}

            />
           <hr/>  
          </div>
     
        <div className="w-100 d-flex-column mb-3 mt-3 b-bottom">
          <div className="px-2 d-flex-center fj-end">
          <ButtonBase
            className="btns  mt-2"
            onClick={() => setIsOpenExtensionNumberDialog(true)}
          >
            <span className="mdi mdi-upload" />
            <span className="px-1">
              {t(`${translationPath}import-or-add-extension`)}
            </span>
          </ButtonBase> </div>
        </div>
        <AddAndEditMainNumbersDialog
          isOpen={isOpenMainNumberDialog}
          onSave={(status) => {
            setIsOpenMainNumberDialog(false);
            if(status)
            setIsReleadData(!isReleadData);
          }}
          onClose={() => {
            setIsOpenMainNumberDialog(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          branchId={branchId}
          mainNumberId={null}
        />
        <AddAndEditExtension
          isOpen={isOpenExtensionNumberDialog}
          onSave={(status) => {
            setIsOpenExtensionNumberDialog(false);
          }}
          onClose={() => {
            setIsOpenExtensionNumberDialog(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          branchId={branchId}
          setReloadAllExtensions={setReloadAllExtensions}
          reloadAllExtensions={reloadAllExtensions}
          reloadExtensionsNumbersByMainId={reloadExtensionsNumbersByMainId}
          activeCard={activeCard}
        />
      </div>
    </>
  );
};
