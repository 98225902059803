import React from "react";

function BronzeIcon({ fill, ...restProps }) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M20.2503 3.71V13.29C20.2503 16.05 18.0103 18.29 15.2503 18.29H5.25031C4.79031 18.29 4.35031 18.23 3.92031 18.11C3.30031 17.94 3.10031 17.15 3.56031 16.69L14.1903 6.06C14.4103 5.84 14.7403 5.79 15.0503 5.85C15.3703 5.91 15.7203 5.82 15.9703 5.58L18.5403 3C19.4803 2.06 20.2503 2.37 20.2503 3.71Z"
        fill="#A15C07"
      />
      <path
        opacity="0.4"
        d="M12.89 5.36002L2.42 15.83C1.94 16.31 1.14 16.19 0.82 15.59C0.45 14.91 0.25 14.12 0.25 13.29V3.71002C0.25 2.37002 1.02 2.06002 1.96 3.00002L4.54 5.59002C4.93 5.97002 5.57 5.97002 5.96 5.59002L9.54 2.00002C9.93 1.61002 10.57 1.61002 10.96 2.00002L12.9 3.94002C13.28 4.33002 13.28 4.97002 12.89 5.36002Z"
        fill="#A15C07"
      />
    </svg>
  );
}

export default BronzeIcon;
